import { IApplyCouponByIdParams, IQualifyItem, IQualifyItemExt, IRemovedCoupon, ITotalCouponPrice, IvItem } from "./interfaces/coupons.interface";
import { IUserCampaignCodesData, IUserLoyaltyReward } from "../models/user.interface";
import { IIncompleteDiscount, IOrder, IOrderCoupon, IOrderItem } from "../models/order.interface";
import { IDataCoupon } from "../models/data.interface";
import { Names } from "../utils/i18n";
import { Common } from "../common";
import { Util } from "../utils/util";
import { CouponIdList, CouponTypeList } from "../types/enums";
import { DialogCreators } from "../utils/dialog-creators";
import { OnlineOrderingUtil } from "./online-ordering-util";
import { ITempIncompleteDiscount } from "../interfaces/temp.interface";
import { Data } from "../models/data";
import { User } from "../models/user";
import { ILoyaltyProvider } from "../services/loyalty-provider/loyalty-provider.interface";
import { DataLayerService } from "../services/data-layer-service";
import { OnlineOrderingService } from "./online-ordering-service";

// 2DO: Finish data conversion
export class Coupon {
    private readonly _data: Data;
    private readonly _user: User;
    private readonly _dataLayer: DataLayerService;
    private readonly _orderingService: OnlineOrderingService;

    constructor(data: Data, user: User, dataLayer: DataLayerService, orderingService: OnlineOrderingService) {
        this._data = data;
        this._user = user;
        this._dataLayer = dataLayer;
        this._orderingService = orderingService;
    }

    public RemoveCouponAll = async (order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider): Promise<{ couponId: number, offerCode: string | null }[]> => {
        return await this.RemoveCouponByItemKey(order, incompleteDiscounts, loyaltyProvider, null);
    }

    public RemoveCouponByItemKey = async (order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider,itemKey: number | null, refresh: boolean = false): Promise<IRemovedCoupon[]> => {
        const itemsCoupons = order.ItemsCoupons;
        const removedCouponKeys: number[] = [];
        for (let i = itemsCoupons.length; i >= 0; i--) {
            const iCoupon = itemsCoupons[i];
            if (iCoupon.ItemKey === itemKey || itemKey === null) {
                if (!removedCouponKeys.includes(iCoupon.CouponKey)) {
                    removedCouponKeys.push(iCoupon.CouponKey);
                }
                this._removeDiscountFromItem(order, iCoupon.ItemKey, iCoupon.HalfIndex, iCoupon.BeforeTaxDiscount, iCoupon.AfterTaxDiscount, (iCoupon.DiscountedQuantity === 1));
                itemsCoupons.splice(i, 1);
            }
        }

        const rewards = this._user.getProperty("LoyaltyData")?.Rewards;

        const removedCouponIDs: IRemovedCoupon[] = [];
        for (const couponKey of removedCouponKeys) {
            const coupon = order.Coupons.find(x => x.CouponKey === couponKey);
            if (!coupon)
                continue;

            let rewardId = null;
            const reward = rewards?.find(x => x.RewardId == Number(coupon.RewardId));
            if (reward)
                rewardId = reward.RewardId;

            removedCouponIDs.push({
                couponId: coupon.CouponId,
                offerCode: (coupon.IsCampaignCode || coupon.IsPromotionCode) ? coupon.CouponCode : null,
                rewardId: rewardId,
                isBankedCurrency: coupon.IsBankedCurrency
            });
        }

        for (const couponKey of removedCouponKeys)
            await this.RemoveCouponByKey(order, incompleteDiscounts, loyaltyProvider, couponKey, refresh);

        return removedCouponIDs;
    }

    private _removeDiscountFromItem = (order: IOrder, itemKey: number, halfIndex: number, beforeTaxDiscount: number, afterTaxDiscount: number, clearDiscountedQuantity: boolean): number => {
        let arrayIndex = -1;
        const itemsOrder = order.Items;
        const itemsData = this._data.getProperty("Items") || []
        for (const [index, oItem] of itemsOrder.entries()) {
            if (oItem.ItemKey === itemKey && oItem.HalfIndex === halfIndex) {
                oItem.BeforeTaxDiscount -= beforeTaxDiscount;
                oItem.AfterTaxDiscount -= afterTaxDiscount;
                if (clearDiscountedQuantity) {
                    oItem.DiscountedQuantity -= 1;
                }
                const mItem = itemsData.find(x => x.ItemId === oItem.ItemId) || null;

                const dependencies = {
                    taxesData: this._data.getProperty("Taxes") || [],
                    zipTaxesData: this._data.getProperty("ZipTaxes") || [],
                    isCallCenter: this._data.getProperty("Settings")?.ISCC || false,
                    orderTypeId: order.OrderTypeID,
                    address: globalThis.aOLO.Temp.Address
                };
                OnlineOrderingUtil.TaxItem(mItem, oItem, dependencies);
                arrayIndex = index;
            } else if (oItem.ItemKey === itemKey && oItem.HalfCount == 1)
                if (clearDiscountedQuantity && oItem.DiscountedQuantity > 0) {
                    oItem.DiscountedQuantity -= 1;
                }
        }
        return arrayIndex;
    }

    public RemoveCouponByKey = async (order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, couponKey: number, refresh: boolean): Promise<void> => {
        let i = order.ItemsCoupons.length;
        let itemKey = 0;
        while (i--) {
            const iCoupon = order.ItemsCoupons[i];
            if (iCoupon.CouponKey === couponKey) {
                itemKey = iCoupon.ItemKey;
                this._removeDiscountFromItem(order, iCoupon.ItemKey, iCoupon.HalfIndex, iCoupon.BeforeTaxDiscount, iCoupon.AfterTaxDiscount, (iCoupon.DiscountedQuantity === 1));
                order.ItemsCoupons.splice(i, 1);
            }
        }

        for (let i = 0; i < order.Coupons.length; i++) {
            if (order.Coupons[i].CouponKey === couponKey) {
                const couponCode = order.Coupons[i].CouponCode;

                // 2DO: Refactor
                // Loyalty Discount
                //if (order.Coupons[i].CouponId === 7 && itemKey !== 0)
                //    await OnlineOrderingUtil.RedeemPoints(Number(couponCode), itemKey, aOLO);

                // invalidate if single use coupon
                if (couponCode.toLowerCase().includes("suc_"))
                    await this._invalidateSingleUseCoupon(order, couponCode);

                order.Coupons.splice(i, 1);
                break;
            }
        }

        if (refresh) {
            OnlineOrderingUtil.ReCalculateTax(globalThis.aOLO);
            // Refactor?? this._functions.GUI_SetOrder(aOLO, aOLOModules);
            await this.AutoApplyCoupons(order, incompleteDiscounts, loyaltyProvider);
        }
    }

    public ApplyCouponByID = async (params: IApplyCouponByIdParams): Promise<boolean | null> => {
        const {
            order,
            incompleteDiscounts,
            loyaltyProvider,
            couponId,
            discount = null,
            showErrorMsg = false,
            showApplyMsg = false,
            couponCode = null,
            itemKey = null,
            channelId = null,
            autoApplying = false,
            isCampaignCode = false,
            isPromotionCode = false,
            rewardId = null,
            runBatch = true,
            isBankedCurrency = false
        } = params;

        if (order.Total != 0) {
            const coupon = this.GetCoupon(couponId, true, order.OrderTypeID, channelId);
            if (!coupon)
                return false;

            if (couponId <= 11 && discount) {
                coupon.MaximumDiscountAmount = discount;
                coupon.Discount = discount;
            }
            return await this.ApplyCoupon(order, incompleteDiscounts, loyaltyProvider, coupon, (couponCode ? couponCode : null), showErrorMsg, showApplyMsg, itemKey, autoApplying, isCampaignCode, isPromotionCode, rewardId, runBatch, isBankedCurrency);
        } else if (showErrorMsg)
            DialogCreators.messageBoxOk(Names("CannotUseCouponTotalZero"), globalThis.aOLO.buttonHoverStyle);
        return false;
    }

    public ApplyCoupon = async (order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, coupon: IDataCoupon, couponCode: string | null, showErrorMsg: boolean, showApplyMsg: boolean, itemKey?: number | null, autoApplying?: boolean, isCampaignCode?: boolean, isPromotionCode?: boolean, rewardId?: number | null, runBatch: boolean = true, isBankedCurrency: boolean = false): Promise<boolean | null> => {
        let reward = null;
        const loyaltyData = this._user.getProperty("LoyaltyData");
        if (loyaltyData && loyaltyData.Rewards.length > 0)
            if (rewardId && !couponCode)
                reward = loyaltyData.Rewards.find(x => x.RewardId === rewardId);
            else
                reward = loyaltyData.Rewards.find(x => x.RewardId === Number(couponCode));

        let offer = null;
        const offers = this._user.getProperty("Offers");
        if ((isCampaignCode || isPromotionCode) && offers && offers.Codes.length > 0)
            offer = offers.Codes.find(x => x.CouponCode.toLowerCase() === couponCode);


        if (coupon.IsScheduled && !this.CheckCouponSCHS(order, coupon)) {
            if (showErrorMsg) {
                DialogCreators.messageBoxOk(Names("InvalidScheduleCode"), globalThis.aOLO.buttonHoverStyle);
            }
            return false;
        }

        if (reward && reward.ExpiryDate && !loyaltyProvider.discountValidForOrderTime(reward.ExpiryDate)) {
            if (showErrorMsg) {
                DialogCreators.messageBoxOk(Names("InvalidReward"), globalThis.aOLO.buttonHoverStyle);
            }
            return false;
        }

        if (coupon.CouponId !== CouponIdList.LOYALTY_REDEEM_ITEM && !reward && !this._checkOneCouponOnly(order, autoApplying))
            return null;

        if (!couponCode) couponCode = ""; //TODO
        let returnValue = true;
        let isActive = true;
        let couponKey = 0;
        let discountAmount = 0.00;
        let qualifiedList: IvItem[] = [];
        let totalAmounts;
        let oItem: IOrderItem;
        let nItem: IOrderItem;

        if (!this.checkMinimumOrderAmount(order, coupon, autoApplying || false, false)) {
            if (!incompleteDiscounts.some(cpn => cpn.couponID === coupon.CouponId)) {
                incompleteDiscounts.push({ couponID: coupon.CouponId, couponCode: couponCode, channelID: null, isCampaignCode: isCampaignCode, isPromotionCode: isPromotionCode });
                order.Coupons = order.Coupons.filter(cpn => cpn.CouponId !== coupon.CouponId);
                // Refactor??: await this._functions.GUI_SetOrder(aOLO, aOLOModules);
            }
            return false;
        }

        if (!this._checkCouponLimit(order, coupon, autoApplying))
            return false;

        // Get Qualifying Items for the given coupon
        qualifiedList = this._getQualifiedItemList(order, coupon, itemKey, reward);

        if (qualifiedList?.length > 0) {
            totalAmounts = this._getTotalCouponItemsPrices(qualifiedList);
            const cDiscount = coupon.Discount;
            switch (coupon.CouponTypeId) {
                case CouponTypeList.MANAGER_DISCOUNT:
                case CouponTypeList.NEXT_ORDER_CREDIT:
                case CouponTypeList.LOYALTY_DISCOUNT:
                case CouponTypeList.EMPLOYEE_DISCOUNT:
                case CouponTypeList.EMPLOYEE_CONSUMPTION:
                    if (coupon.CouponId === CouponIdList.MANAGER_DOLLAR_OFF ||
                        coupon.CouponId === CouponIdList.LOYALTY_OFFER_THIRDPARTY ||
                        coupon.CouponId === CouponIdList.LOYALTY_REWARD_THIRDPARTY
                    ) {
                        discountAmount = cDiscount;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_PERCENT_OFF_ORDER ||
                        coupon.CouponId === CouponIdList.EMPLOYEE_DISCOUNT ||
                        coupon.CouponId === CouponIdList.EMPLOYEE_CONSUMPTION
                    ) {
                        discountAmount = (order.SubTotal - order.Discount + order.OrderTypeSubType.OrderTypeCharge) * cDiscount / 100;
                        isActive = true;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_FIXED_PRICE) {
                        discountAmount = totalAmounts.OrderItemPrice - cDiscount;
                        isActive = true;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_NEXT_ORDER_CREDIT_DOLLAR_OFF) {
                        discountAmount = cDiscount;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_NEXT_ORDER_CREDIT_PERCENT_OFF) {
                        discountAmount = (order.SubTotal - order.Discount + order.OrderTypeSubType.OrderTypeCharge) * cDiscount / 100;
                        isActive = true;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_PERCENT_OFF_ITEM) {
                        discountAmount = (totalAmounts.OrderItemPrice * coupon.Discount / 100);
                    } else if (coupon.CouponId === CouponIdList.LOYALTY_REDEEM_ITEM) {
                        discountAmount = totalAmounts.OrderItemPrice;
                    }
                    break;
                case CouponTypeList.DOLLAR_OFF:
                    discountAmount = cDiscount;
                    isActive = true;
                    break;
                case CouponTypeList.FIXED_PRICE:
                    if (qualifiedList) {
                        let iPrice = totalAmounts.CouponItemPrice;
                        if (iPrice > totalAmounts.OrderItemPrice) iPrice = totalAmounts.OrderItemPrice;
                        discountAmount = iPrice - cDiscount - totalAmounts.CouponAddOnPrice;
                    }
                    //discountAmount = totalAmounts.CouponItemPrice - cDiscount - totalAmounts.CouponAddOnPrice;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER:
                    discountAmount = (order.SubTotal - order.Discount) * cDiscount / 100;
                    isActive = true;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_AN_ITEM:
                    discountAmount = (totalAmounts.OrderItemPrice * cDiscount / 100) - totalAmounts.CouponAddOnPrice;
                    //if (coupon.Items.length === 0) isActive = true;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_AN_ITEM_IN_CATEGORY_OR_BOGO_IN_CATEGORY:
                    discountAmount = totalAmounts.OrderItemPrice * cDiscount / 100;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_ALL_ITEMS_IN_CATEGORY:
                    discountAmount = totalAmounts.OrderItemPrice * cDiscount / 100;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_ITEM_IN_CATEGORY_BASED_ON_PRICE:
                    discountAmount = (totalAmounts.CouponItemPrice * cDiscount / 100) - totalAmounts.CouponAddOnPrice;
                    break;
                case CouponTypeList.UPGRADE:
                    if (qualifiedList) {
                        //vItem.oPrice = vItem.oPrice / vItem.HalfCount;
                        let price = 0.00;
                        // if half and half Item got qualified check both halfs for price
                        if (qualifiedList.length > 1) {
                            let highiestPrice = 0.0;
                            let AveragePrice = 0.0;
                            for (let j = 0; j < qualifiedList.length; j++) {
                                oItem = order.Items[qualifiedList[j].ItemArrayIndex];
                                for (let i = 0; i < coupon.Upgrades.length; i++) {
                                    if (oItem.SizeId === coupon.Upgrades[i].BuySizeId) {
                                        const mItem = this._data.getProperty("Items")[oItem.Index];
                                        const orderTypePriceGroupId = order.OrderTypeSubType.PriceGroupId;
                                        const defaultPriceGroup = this._data.getProperty("DefaultPriceGroupId");
                                        const nonChargeableDisplayGroups = this._data.getProperty("Settings").NCDG;
                                        const preModifiers = this._data.getProperty("PreModifiers");
                                        let price = OnlineOrderingUtil.GetItemPrice(mItem, oItem.SizeId, oItem.Modifiers, orderTypePriceGroupId, defaultPriceGroup, nonChargeableDisplayGroups, preModifiers).Price;
                                        if (price < 0)
                                            price = -price;

                                        if (price > highiestPrice)
                                            highiestPrice = price;

                                        AveragePrice += price;
                                        break;
                                    }
                                }
                            }

                            if (this._data.getProperty("Settings").HHP === 1) {
                                price = highiestPrice;
                            } else if (this._data.getProperty("Settings").HHP === 2) {
                                price = Util.Float2(AveragePrice / Util.Float2(qualifiedList.length));
                            }
                        } else {
                            let oItem = order.Items[qualifiedList[0].ItemArrayIndex];
                            for (let i = 0; i < coupon.Upgrades.length; i++) {
                                if (oItem.SizeId === coupon.Upgrades[i].BuySizeId) {
                                    nItem = Util.deepClone(oItem);
                                    nItem.SizeId = coupon.Upgrades[i].PriceSizeId;
                                    let priced = OnlineOrderingUtil.PriceItem(nItem, globalThis.aOLO);
                                    if (priced)
                                        price = nItem.Price;
                                }
                                break;
                            }
                        }
                        let orderItemPrice = 0;
                        for (let i = 0; i < qualifiedList.length; i++) {
                            orderItemPrice += qualifiedList[i].oPrice;
                        }
                        if (price > 0) {
                            discountAmount = orderItemPrice - price;
                        }
                    }
                    break;
                case CouponTypeList.QUANTITY_PRICE:
                case CouponTypeList.MIX_AND_MATCH_PRICE:
                    discountAmount = 0;
                    for (const item of qualifiedList) {
                        if (item.HalfCount && item.cPrice > (cDiscount / item.HalfCount)) {
                            discountAmount += (item.cPrice - (cDiscount / item.HalfCount) - item.AddOn);
                        }
                    }
                    isActive = true;
                    break;
                case CouponTypeList.MIX_AND_MATCH_PERCENTAGE:
                    discountAmount = 0;
                    for (const item of qualifiedList) {
                        if (item.oPrice > 0) {
                            discountAmount += (item.oPrice * cDiscount / 100) - item.AddOn;
                        }
                    }
                    isActive = true;
                    break;
                case CouponTypeList.QUANTITY_DISCOUNT:
                    discountAmount = 0;
                    if (qualifiedList) {
                        for (const item of qualifiedList) {
                            if (item.HalfCount && item.cPrice > (cDiscount / item.HalfCount)) {
                                discountAmount += (cDiscount / item.HalfCount);
                            }
                        }
                        discountAmount = discountAmount - totalAmounts.CouponAddOnPrice;
                    }
                    isActive = true;
                    break;
            }
            discountAmount = Util.Float2(discountAmount);
            if (discountAmount > 0) {
                let maxDiscount = coupon.MaximumDiscountAmount;
                if (maxDiscount > 0 && discountAmount > maxDiscount)
                    discountAmount = maxDiscount;

                if (discountAmount > order.SubTotal - order.Discount + order.OrderTypeSubType.OrderTypeCharge)
                    discountAmount = order.SubTotal - order.Discount + order.OrderTypeSubType.OrderTypeCharge;

                if (discountAmount > 0) {
                    this._splitDiscount(order, qualifiedList, totalAmounts.OrderItemPrice, discountAmount, coupon.IsBeforeTax, coupon.CouponTypeId);
                    this._addDiscountsToItems(order, qualifiedList);
                    couponKey = this._addCouponToOrder(order, coupon.CouponId, couponCode, coupon.Discount, discountAmount, coupon.IsBeforeTax, isActive, coupon.AllowOtherCoupons, coupon.ChannelID, autoApplying, isCampaignCode, isPromotionCode, rewardId, isBankedCurrency);
                    this._addCouponToItemsCoupon(order, qualifiedList, couponKey, coupon.Priority, isActive, (coupon.AllowOtherCoupons ? 0 : 1));
                    if (globalThis.aOLO.affectedItemsArrayIndexes) {
                        for (let i = 0; i < order.Items.length; i++) {
                            if (order.Items[i].DiscountedMarkedQuantity > 0 && globalThis.aOLO.affectedItemsArrayIndexes.indexOf(i) === -1)
                                globalThis.aOLO.affectedItemsArrayIndexes.push(i);
                        }
                    } else
                        OnlineOrderingUtil.ReCalculateTax(globalThis.aOLO);

                    if (coupon.AllowOtherCoupons)
                        this._clearMarkedItems(order);
                    else
                        this._useMarkedItems(order);

                    // Refactor??: await this._functions.GUI_SetOrder(aOLO, aOLOModules);

                } else
                    returnValue = false;
            } else {
                if (showErrorMsg)
                    DialogCreators.messageBoxOk(`${Names("CouponNotQualified")}</br>${Common.GetDescription(coupon.Descriptions, globalThis.aOLO.Temp.languageCode)}`, globalThis.aOLO.buttonHoverStyle);
                returnValue = false;
            }
        } else {
            if (showErrorMsg) {
                if (coupon.Descriptions)
                    DialogCreators.messageBoxOk(`${Names("CouponNotQualified")}</br>${Common.GetDescription(coupon.Descriptions, globalThis.aOLO.Temp.languageCode)}`, globalThis.aOLO.buttonHoverStyle);
                else
                    DialogCreators.messageBoxOk(Names("CouponNotQualified"), globalThis.aOLO.buttonHoverStyle);
            }
            returnValue = false;
        }

        if (returnValue) {
            // Check For Minimum Quantity Once more
            if (!this.checkMinimumOrderAmount(order, coupon, autoApplying || false, true)) {
                if (!incompleteDiscounts.some(cpn => cpn.couponID === coupon.CouponId)) {
                    incompleteDiscounts.push({ couponID: coupon.CouponId, couponCode: couponCode, channelID: null, isCampaignCode: isCampaignCode, isPromotionCode: isPromotionCode });
                    order.Coupons = order.Coupons.filter(cpn => cpn.CouponId !== coupon.CouponId);
                }
                await this.RemoveCouponByKey(order, incompleteDiscounts, loyaltyProvider, couponKey, false);
                OnlineOrderingUtil.ReCalculateTax(globalThis.aOLO);
                //Refactor? await this._functions.GUI_SetOrder(aOLO, aOLOModules);

                return false;
            }

            if (showApplyMsg)
                DialogCreators.messageBoxFade(Names("CouponApplied"));

            //if (aOLO.mediaMax576.matches || aOLO.mediaMax840.matches)
            //    OnlineOrderingUtil.ToggleOrderItems(false, aOLO);

            if (reward) {
                reward.Used = true;
                // 2DO: Refactor?
                //if (aOLO.Dialog.Rewards)
                //    aOLO.Dialog.Rewards.CloseDialog();
            } else if (offer)
                offer.Used = true;

            if (runBatch)
                loyaltyProvider.batchComparison(true);
        }

        return returnValue;
    }

    private _checkOneCouponOnly = (order: IOrder, autoApplying?: boolean): boolean => {
        const coupons = order.Coupons.filter(x => x.CouponId != CouponIdList.LOYALTY_REDEEM_ITEM);
        if (coupons.length > 0) {
            let oneCouponLimit = this._data.getProperty("Settings").CPNLIM;
            if (oneCouponLimit === 2 || oneCouponLimit === 3) {
                !autoApplying && DialogCreators.messageBoxOk(Names("CpnLimitOne"), globalThis.aOLO.buttonHoverStyle);
                return false;
            }
        }
        return true;
    }

    public checkMinimumOrderAmount = (order: IOrder, coupon: IDataCoupon, autoApplying: boolean, isVerifying: boolean): boolean => {
        const CalculationMethod = {
            IncludeAllWithDiscountedPrice: 0,
            IncludeAllWithOriginalPrice: 1,
            ExcludeItemsWithDiscountsOrLoyalty: 2,
            ExcludeDiscountedExceptLoyaltyWithOriginalPrice: 3,
            ExcludeDiscountedExceptLoyaltyWithDiscountedPrice: 4
        };

        if (coupon.CouponTypeId == CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER && isVerifying)
            return true;

        if (coupon.MinimumOrderAmount > 0) {
            let orderAmount = 0;

            this._calculateDiscountedQTYs(order);

            switch (coupon.CalculationMethodID) {
                case null:
                case undefined:
                case CalculationMethod.IncludeAllWithDiscountedPrice:
                    orderAmount = order.SubTotal - order.Discount;
                    break;
                case CalculationMethod.IncludeAllWithOriginalPrice:
                    orderAmount = order.SubTotal;
                    break;
                case CalculationMethod.ExcludeItemsWithDiscountsOrLoyalty:
                    for (const oItem of order.Items) {
                        orderAmount += this._calculateNonDiscountedItems(oItem);
                    }
                    break;
                case CalculationMethod.ExcludeDiscountedExceptLoyaltyWithOriginalPrice:
                    for (const oItem of order.Items) {
                        //handle any non discounted items
                        orderAmount += this._calculateNonDiscountedItems(oItem);

                        //handle loyalty with original price
                        if (oItem.LoyaltyDiscountsQTY > 0) {
                            orderAmount += Util.Float2(oItem.Price);
                        }
                    }
                    break;
                case CalculationMethod.ExcludeDiscountedExceptLoyaltyWithDiscountedPrice:
                    for (const oItem of order.Items) {
                        //handle any non discounted items
                        orderAmount += this._calculateNonDiscountedItems(oItem);

                        //handle loyalty with original price
                        if (oItem.LoyaltyDiscountsQTY > 0) {
                            orderAmount += oItem.LoyaltyDiscountsAmount;
                        }
                    }
                    break;
            }

            if (orderAmount < coupon.MinimumOrderAmount) {
                !autoApplying && DialogCreators.messageBoxOk(Names("CPNMINAMT").replace("??", coupon.MinimumOrderAmount.toFixed(2)), globalThis.aOLO.buttonHoverStyle);
                return false;
            }
        }
        return true;
    }

    private _calculateDiscountedQTYs = (order: IOrder): void => {
        //Calculate total discounted and total discounted loyalty for each item to help with calculation methods 2-4
        for (const oItem of order.Items) {
            let loyaltyDiscountsQty = 0;
            let nonLoyaltyDiscountsQTY = 0;
            let loyaltyDiscountsAmount = 0;
            const iCoupons = order.ItemsCoupons.filter(x => x.ItemKey == oItem.ItemKey && x.HalfIndex == oItem.HalfIndex);
            for (const iCpn of iCoupons) {
                const appliedCoupon = order.Coupons.find(x => x.CouponKey == iCpn.CouponKey);
                if (appliedCoupon?.RewardId != null) {
                    loyaltyDiscountsQty += 1;
                    loyaltyDiscountsAmount += Util.Float2(oItem.Price - (appliedCoupon.Amount / oItem.HalfCount));
                } else {
                    nonLoyaltyDiscountsQTY += 1;
                }
            }
            oItem.LoyaltyDiscountsQTY = loyaltyDiscountsQty;
            oItem.NonLoyaltyDiscountsQTY = nonLoyaltyDiscountsQTY;
            oItem.LoyaltyDiscountsAmount = loyaltyDiscountsAmount;
        }
    }

    private _calculateNonDiscountedItems = (oItem: IOrderItem): number => {
        let orderAmount = 0;
        const nonDiscountedQTY = oItem.Quantity - oItem.NonLoyaltyDiscountsQTY - oItem.LoyaltyDiscountsQTY;
        if (nonDiscountedQTY > 0) {
            orderAmount += Util.Float2(oItem.Price * nonDiscountedQTY);
        }
        return orderAmount
    }

    private _checkCouponLimit = (order: IOrder, coupon: IDataCoupon, autoApplying?: boolean): boolean => {
        const limit = coupon.LimitsPerOrder;
        if (limit <= 0)
            return true;

        const count = order.Coupons.filter(c => c.CouponId === coupon.CouponId).length;
        if (count >= limit) {
            !autoApplying && DialogCreators.messageBoxOk(Names("CPNLIMIT").replace("??", limit.toString()), globalThis.aOLO.buttonHoverStyle);
            return false;
        }

        return true;
    }

    private _getQualifiedItemList = (order: IOrder, coupon: IDataCoupon, itemKey?: number | null, reward?: IUserLoyaltyReward | null): IvItem[] => {
        let qualifiedList: IvItem[] = [];

        switch (true) {
            case coupon.CouponId === CouponIdList.LOYALTY_OFFER_THIRDPARTY:
            case coupon.CouponId === CouponIdList.LOYALTY_REWARD_THIRDPARTY:
                qualifiedList = this._qualifyAllItems(order, coupon, reward);
                break;

            case coupon.CouponTypeId === CouponTypeList.MANAGER_DISCOUNT:
                qualifiedList = this._qualifyAllItems(order, coupon, reward);
                break;

            case coupon.CouponTypeId === CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER:
                if (coupon.IsVerificationNeeded && coupon.Items && coupon.Items.length > 0) {
                    qualifiedList = this.QualifyCouponItems(order, coupon, false, false, true);
                    if (qualifiedList)
                        qualifiedList = this._qualifyAllItems(order);
                } else if (coupon.AllowOtherCoupons) {
                    qualifiedList = this._qualifyAllItems(order);
                } else {
                    qualifiedList = this._qualifyAllNotDiscountedItems(order);
                }
                break;

            case coupon.CouponTypeId === CouponTypeList.PERCENTAGE_OFF_AN_ITEM_IN_CATEGORY_OR_BOGO_IN_CATEGORY:
                qualifiedList = this._qualifyAnItemInCategory(order, coupon);
                break;

            case coupon.CouponTypeId === CouponTypeList.PERCENTAGE_OFF_ALL_ITEMS_IN_CATEGORY:
                qualifiedList = this._qualifyAllItemsInCategory(order, coupon);
                break;

            //case coupon.CouponTypeId === CouponTypeList.PERCENTAGE_OFF_TWO_ITEMS_IN_CATEGORY:
            //    qualifiedList = QualifyTwoItemsInCategory(coupon);
            //    break;
            case coupon.CouponTypeId === CouponTypeList.MIX_AND_MATCH_PERCENTAGE:
            case coupon.CouponTypeId === CouponTypeList.MIX_AND_MATCH_PRICE:
                qualifiedList = this.QualifyCouponItems(order, coupon, false, false, true);

                let allOthersQualifiedItems = this.QualifyCouponItems(order, coupon, false, true, false);
                while (allOthersQualifiedItems.length > 0) {
                    qualifiedList.push(...allOthersQualifiedItems);
                    allOthersQualifiedItems = this.QualifyCouponItems(order, coupon, false, true, false);
                }

                if (coupon.MinimumItemQuantity && qualifiedList.length < coupon.MinimumItemQuantity) {
                    qualifiedList = [];
                    this._clearMarkedItems(order);
                }
                break;

            case coupon.Items && coupon.Items?.length > 0:
                qualifiedList = this.QualifyCouponItems(order, coupon, false, false, true);
                break;

            case !coupon.IsVerificationNeeded:
                if (coupon.AllowOtherCoupons)
                    qualifiedList = (itemKey != null) ? this._qualifyAllItems(order).filter(x => x.ItemKey === itemKey) : this._qualifyAllItems(order);
                else
                    qualifiedList = (itemKey != null) ? this._qualifyAllNotDiscountedItems(order).filter(x => x.ItemKey === itemKey) : this._qualifyAllNotDiscountedItems(order);
                break;
        }

        return qualifiedList;
    }

    private _qualifyLoyaltyItems = (order: IOrder): IvItem[] => {
        let qualifiedGroupList: IvItem[] = [];

        for (const [index, oItem] of order.Items.entries()) {
            const mItem = this._data.getProperty("Items").find(x => x.ItemId === oItem.ItemId);
            if (mItem?.IsLoyalty) {
                oItem.DiscountedMarkedQuantity = oItem.Quantity;

                for (let j = 0; j < oItem.Quantity; j++) {
                    const vItem = {
                        ItemArrayIndex: index,
                        QTYIndex: j + 1,
                        cPrice: 0.00,
                        oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice / oItem.HalfCount : oItem.Price,
                        ItemKey: oItem.ItemKey,
                        HalfIndex: oItem.HalfIndex,
                        ItemRecID: oItem.ItemRecId,
                        AddOn: 0.00,
                        BeforeTaxDiscount: 0.0,
                        AfterTaxDiscount: 0.0
                    };
                    qualifiedGroupList.push(vItem);
                }
            }
        }
        return qualifiedGroupList;
    }

    private _qualifyAllItems = (order: IOrder, coupon?: IDataCoupon, reward?: IUserLoyaltyReward | null): IvItem[] => {
        if (coupon && !coupon.AllowOtherCoupons && order.Coupons.length > 0 && !reward)
            return [];

        let qualifiedGroupList: IvItem[] = [];
        for (const [index, oItem] of order.Items.entries()) {
            oItem.DiscountedMarkedQuantity = oItem.Quantity;

            for (let j = 0; j < oItem.Quantity; j++) {
                const vItem = {
                    ItemArrayIndex: index,
                    QTYIndex: j + 1,
                    cPrice: 0.00,
                    oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price,
                    ItemKey: oItem.ItemKey,
                    HalfIndex: oItem.HalfIndex,
                    ItemRecID: oItem.ItemRecId,
                    AddOn: 0.00,
                    BeforeTaxDiscount: 0.0,
                    AfterTaxDiscount: 0.0
                };
                qualifiedGroupList.push(vItem);
            }
        }

        return qualifiedGroupList;
    }

    private _qualifyAnItemInCategory = (order: IOrder, coupon: IDataCoupon): IvItem[] => {
        let qualifiedGroupList: IvItem[] = [];

        if (coupon.IsBOGO) {
            switch (coupon.BOGOSelectionTypeID) {
                case 1:  //Auto select - Discount the lowest priced item
                    var vItem1 = this._qualifyHighestItemInCategory(order, coupon);
                    if (vItem1) {
                        vItem1.cPrice = 0.00; // don't add coupon item price of the bogos first item
                        vItem1.oPrice = 0.00;
                        qualifiedGroupList.push(vItem1);
                        var vItem2 = this._qualifyLowestItemInCategory(order, coupon);
                        if (vItem2) {
                            qualifiedGroupList.push(vItem2);
                            return qualifiedGroupList;
                        } else {
                            let oItem = order.Items[vItem1.ItemArrayIndex];
                            if (oItem.DiscountedMarkedQuantity > 0)
                                oItem.DiscountedMarkedQuantity = 0;
                        }
                    }
                    break;
                case 2: //Auto select - Discount the second highest priced item
                    vItem1 = this._qualifyHighestItemInCategory(order, coupon);
                    if (vItem1) {
                        vItem1.cPrice = 0.00; // don't add coupon item price of the bogos first item
                        vItem1.oPrice = 0.00;
                        qualifiedGroupList.push(vItem1);
                        vItem2 = this._qualifyHighestItemInCategory(order, coupon);
                        if (vItem2) {
                            qualifiedGroupList.push(vItem2);
                            return qualifiedGroupList;
                        } else {
                            let oItem = order.Items[vItem1.ItemArrayIndex];
                            if (oItem.DiscountedMarkedQuantity > 0)
                                oItem.DiscountedMarkedQuantity = 0;
                        }
                    }
                    break;
                case 3: //Auto select - Discount the highest priced item
                    vItem1 = this._qualifyHighestItemInCategory(order, coupon);
                    if (vItem1) {
                        qualifiedGroupList.push(vItem1);
                        vItem2 = this._qualifyHighestItemInCategory(order, coupon);
                        if (vItem2) {
                            vItem2.cPrice = 0.00;
                            vItem2.oPrice = 0.00;
                            qualifiedGroupList.push(vItem2);
                            return qualifiedGroupList;
                        } else {
                            let oItem = order.Items[vItem1.ItemArrayIndex];
                            if (oItem.DiscountedMarkedQuantity > 0)
                                oItem.DiscountedMarkedQuantity = 0;
                        }
                    }
                    break;
            }
        } else {
            for (const cpnItmCat of coupon.ItemCategories) {
                for (const [index, oItem] of order.Items.entries()) {
                    if (!oItem.IsRemoved && ((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) < oItem.Quantity) || coupon.AllowOtherCoupons) {
                        let mItem = this._data.getProperty("Items").find(x => x.ItemId === oItem.ItemId) || null;

                        if (mItem?.ItemCategoryId !== cpnItmCat.CategoryId)
                            continue;

                        for (let k = 0; k < oItem.Quantity - oItem.DiscountedQuantity; k++) {
                            if (oItem.HalfCount > 1)
                                this._markAllHalves(order, oItem.ItemKey);
                            else
                                oItem.DiscountedMarkedQuantity += 1;

                            const vItem = {
                                ItemArrayIndex: index,
                                QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
                                cPrice: 0.00,
                                oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price,
                                ItemKey: oItem.ItemKey,
                                HalfIndex: oItem.HalfIndex,
                                ItemRecID: oItem.ItemRecId,
                                AddOn: 0.00,
                                BeforeTaxDiscount: 0.0,
                                AfterTaxDiscount: 0.0
                            };
                            qualifiedGroupList.push(vItem);
                        }
                    }
                }
            }
        }
        return qualifiedGroupList;
    }

    private _qualifyHighestItemInCategory = (order: IOrder, coupon: IDataCoupon): IvItem | null => {
        let maxPrice = 0.0;
        let itemIndex = -1;
        for (const cat of coupon.ItemCategories) {
            for (const [index, oItem] of order.Items.entries()) {
                if (((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) >= oItem.Quantity) && !coupon.AllowOtherCoupons)
                    continue;

                let mItem = this._data.getProperty("Items").find(x => x.ItemId === oItem.ItemId);
                if (mItem?.ItemCategoryId !== cat.CategoryId)
                    continue;

                const oPrice = (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price;
                if (oPrice > maxPrice) {
                    maxPrice = oPrice;
                    itemIndex = index;
                }
            }
        }

        if (itemIndex > -1) {
            let oItem = order.Items[itemIndex];
            if (oItem.HalfCount > 1)
                this._markAllHalves(order, oItem.ItemKey);
            else
                oItem.DiscountedMarkedQuantity += 1;

            const vItem = {
                ItemArrayIndex: itemIndex,
                QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
                oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price,
                cPrice: 0.00,
                ItemKey: oItem.ItemKey,
                HalfIndex: oItem.HalfIndex,
                ItemRecID: oItem.ItemRecId,
                AddOn: 0.00,
                BeforeTaxDiscount: 0.0,
                AfterTaxDiscount: 0.0
            };
            return vItem;
        }
        return null;
    }

    private _qualifyLowestItemInCategory = (order: IOrder, coupon: IDataCoupon): IvItem | null => {
        let minPrice = 1000000.0;
        let itemIndex = -1;
        for (const cat of coupon.ItemCategories) {
            for (const [index, oItem] of order.Items.entries()) {
                if (((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) >= oItem.Quantity) && !coupon.AllowOtherCoupons)
                    continue;

                let mItem = this._data.getProperty("Items").find(x => x.ItemId === oItem.ItemId);
                if (mItem?.ItemCategoryId !== cat.CategoryId)
                    continue;

                let oPrice = 0.0;
                if (oItem.HalfCount > 1)
                    oPrice = oItem.HalfHalfPrice;
                else
                    oPrice = oItem.Price;

                if (oPrice < minPrice) {
                    minPrice = oPrice;
                    itemIndex = index;
                }
            }
        }
        if (itemIndex > -1) {
            let oItem = order.Items[itemIndex];
            if (oItem.HalfCount > 1)
                this._markAllHalves(order, oItem.ItemKey);
            else
                oItem.DiscountedQuantity += 1;

            const vItem = {
                ItemArrayIndex: itemIndex,
                QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
                cPrice: 0.00,
                oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price,
                ItemKey: oItem.ItemKey,
                HalfIndex: oItem.HalfIndex,
                ItemRecID: oItem.ItemRecId,
                AddOn: 0.00,
                BeforeTaxDiscount: 0.0,
                AfterTaxDiscount: 0.0
            };
            return vItem;
        }
        return null;
    }

    private _qualifyAllItemsInCategory = (order: IOrder, coupon: IDataCoupon): IvItem[] => {
        let qualifiedGroupList: IvItem[] = [];

        for (const cat of coupon.ItemCategories) {
            for (const [index, oItem] of order.Items.entries()) {
                if ((((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) >= oItem.Quantity) && !coupon.AllowOtherCoupons) || oItem.IsRedeemed)
                    continue;

                let mItem = this._data.getProperty("Items").find(x => x.ItemId === oItem.ItemId);
                if (mItem?.ItemCategoryId !== cat.CategoryId)
                    continue;

                for (let k = 0; k < oItem.Quantity - oItem.DiscountedQuantity; k++) {
                    if (oItem.HalfCount > 1)
                        this._markAllHalves(order, oItem.ItemKey);
                    else
                        oItem.DiscountedMarkedQuantity += 1;

                    const vItem = {
                        ItemArrayIndex: index,
                        QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
                        cPrice: 0.00,
                        oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price,
                        ItemKey: oItem.ItemKey,
                        HalfIndex: oItem.HalfIndex,
                        ItemRecID: oItem.ItemRecId,
                        AddOn: 0.00,
                        BeforeTaxDiscount: 0.0,
                        AfterTaxDiscount: 0.0
                    };
                    qualifiedGroupList.push(vItem);
                }
            }
        }
        return qualifiedGroupList;
    }

    public QualifyCouponItems = (order: IOrder, coupon: IDataCoupon, autoApplying: boolean, qualifyAnyQTY: boolean, clearMarkedQTY: boolean, returnItems: boolean = false): IvItem[] => {
        const BOGOType = {
            Lowest: 1,
            Second_Highest: 2,
            Highest: 3
        };

        let QualifyingGroup = -1;
        let qualifiedGroupList: IvItem[] = [];
        let groupCount = 0;
        let vItem: IvItem | null = null;
        let vItemList: IvItem[] | null = null;

        const couponGroups = Array.from(new Set(coupon.Items.map(x => x.GroupIndex)));
        for (const group of couponGroups) {
            groupCount += 1;
            QualifyingGroup = group;
            if (coupon.IsBOGO && groupCount === 1) {
                switch (coupon.BOGOSelectionTypeID) {
                    case BOGOType.Lowest:  //Auto select - Discount the lowest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(order, coupon, QualifyingGroup, BOGOType.Highest);
                        break;
                    case BOGOType.Second_Highest: //Auto select - Discount the second highest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(order, coupon, QualifyingGroup, BOGOType.Highest);
                        break;
                    case BOGOType.Highest: //Auto select - Discount the highest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(order, coupon, QualifyingGroup, BOGOType.Highest);
                        break;
                }
                if (vItem) {
                    vItem.cPrice = 0.00; // don't add coupon item price of the bogos first item
                    vItem.oPrice = 0.00;
                    qualifiedGroupList.push(vItem);
                }
            } else if (coupon.IsBOGO && groupCount === 2) {
                switch (coupon.BOGOSelectionTypeID) {
                    case BOGOType.Lowest:  //Auto select - Discount the lowest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(order, coupon, QualifyingGroup, BOGOType.Lowest);
                        break;
                    case BOGOType.Second_Highest: //Auto select - Discount the second highest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(order, coupon, QualifyingGroup, BOGOType.Second_Highest);
                        break;
                    case BOGOType.Highest: //Auto select - Discount the highest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(order, coupon, QualifyingGroup, BOGOType.Highest);
                        break;
                }
                if (vItem) {
                    if (vItem.HalfIndex > 0) {
                        const qualifiedHalves = this._qualifyAllHalfs(order, vItem);
                        qualifiedGroupList = qualifiedGroupList.concat(qualifiedHalves);
                    } else
                        qualifiedGroupList.push(vItem);
                    vItemList = qualifiedGroupList;
                }
            } else {
                vItemList = this._qualifyCouponItemsGroupQTY(order, coupon, QualifyingGroup, autoApplying, qualifyAnyQTY);
                if (vItemList) {
                    for (const item of vItemList) {
                        vItem = item;

                        if (item.HalfIndex > 0)
                            qualifiedGroupList = qualifiedGroupList.concat(this._qualifyAllHalfs(order, item));
                        else
                            qualifiedGroupList.push(item);
                    }
                } else
                    vItem = null;
            }

            if (!vItem && !returnItems)
                break;
        }

        // if last group has qualified or there was no coupon items to qualified return the list otherwise claer marked items and return nothing;
        if (vItemList || QualifyingGroup === -1) {
            if (returnItems)
                order.Items.filter(x => x.DiscountedMarkedQuantity > 0).forEach(x => x.DiscountedMarkedQuantity = 0);
            return qualifiedGroupList;
        } else {
            if (clearMarkedQTY) {
                for (const oItem of order.Items) {
                    if (oItem.DiscountedMarkedQuantity > 0)
                        oItem.DiscountedMarkedQuantity = 0;
                }
            }
            if (returnItems)
                return qualifiedGroupList;
            return [];
        }
    }

    private _qualifyCouponItemsGroupByBOGOType = (order: IOrder, coupon: IDataCoupon, group: number, priceType: number): IvItem | null => {
        let oItemIndexes: IQualifyItemExt[] = [];
        let finalItem: IQualifyItemExt = {
            Index: -1,
            QTYIndex: -1,
            CouponItemIndex: -1
        };

        let groupItems = coupon.Items.filter(cpn => cpn.GroupIndex == group);
        // important don't change this to forEach
        for (let i = 0; i < groupItems.length; i++) {
            let cItem = groupItems[i];
            let qItem = this._findCouponItem(order, coupon, cItem.ItemId, cItem.SizeId, cItem.ModifierCount);
            if (qItem.Index > -1) {
                qItem.CouponItemIndex = i;
                oItemIndexes.push(qItem);
                i--; // important this make to look for same item again
            }
        }

        if (oItemIndexes.length > 0) {
            let iIndex = -1;
            let finalPrice = (priceType == 1) ? Number.MAX_VALUE : 0.00;
            let highestIndex: IQualifyItemExt | null = null;

            if (priceType == 2)
                highestIndex = oItemIndexes.find(x => order.Items[x.Index].Price == Math.max(...oItemIndexes.map(x => order.Items[x.Index].Price))) || null;

            for (const [index, oItemIndex] of oItemIndexes.entries()) {
                const item = order.Items[oItemIndex.Index];
                const iPrice = item.HalfCount > 1 ? item.HalfHalfPrice : item.Price;

                if (priceType == 1 && iPrice < finalPrice) {
                    finalPrice = iPrice;
                    finalItem = oItemIndex;
                    iIndex = index;
                } else if (priceType == 2) {
                    if (oItemIndexes.length > 1 && highestIndex && (oItemIndex.Index != highestIndex.Index || oItemIndex.QTYIndex != highestIndex.QTYIndex) && iPrice > finalPrice) {
                        finalPrice = iPrice;
                        finalItem = oItemIndex;
                        iIndex = index;
                    } else if (oItemIndexes.length - index == 1) {
                        finalItem = oItemIndex;
                        iIndex = index;
                    }
                } else if (priceType == 3 && iPrice > finalPrice) {
                    finalPrice = iPrice;
                    finalItem = oItemIndex;
                    iIndex = index;
                }
            }

            for (const [index, oItemIndex] of oItemIndexes.entries()) {
                if (index != iIndex) {
                    let item = order.Items[oItemIndex.Index];
                    if (item.HalfCount > 1)
                        this._unMarkAllHalves(order, item.ItemKey);
                    else
                        item.DiscountedMarkedQuantity -= 1;
                }
            }
        }

        if (finalItem.Index > -1) {
            const cItem = groupItems[finalItem.CouponItemIndex];
            return this._getQualifiedCouponItem(order, finalItem, cItem.ItemId, cItem.SizeId, cItem.ModifierCount, cItem.AddOnPrice);
        }
        return null;
    }

    private _getQualifiedCouponItem = (order: IOrder, qItem: IQualifyItem, cItemID: number, cSizeID: number, cModifierCount: number, cAddOnPrice: number): IvItem | null => {
        if (qItem.Index <= -1)
            return null;

        const oItem = order.Items[qItem.Index];
        const vItem = {
            ItemArrayIndex: qItem.Index,
            QTYIndex: qItem.QTYIndex,
            cPrice: this._priceCouponItem(cItemID, cSizeID, cModifierCount),
            oPrice: oItem.Price, //(oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price, ;BUG #36324 (Commented old code)
            ItemKey: oItem.ItemKey,
            HalfIndex: oItem.HalfIndex,
            HalfCount: oItem.HalfCount,
            ItemRecID: oItem.ItemRecId,
            AddOn: cAddOnPrice,
            BeforeTaxDiscount: 0.0,
            AfterTaxDiscount: 0.0
        };
        return vItem;
    }

    private _priceCouponItem = (itemID: number, sizeID: number, modifierCount: number): number => {
        const mItem = OnlineOrderingUtil.GetItem(itemID, this._data.getProperty("Items"));
        if (!mItem)
            return 0.00;

        const matchingPrice = mItem.Prices.find(price => price.SizeId === sizeID);
        if (!matchingPrice)
            return 0.00;

        let price = Util.Float2(matchingPrice.Price) + (Util.Float2(matchingPrice.PerModifierPrice) * modifierCount);
        price = Math.max(price, Util.Float2(matchingPrice.MinimumPrice));
        return price;
    }

    private _isItemEligibleForCoupon = (order: IOrder, oItem: IOrderItem, itemID: number, sizeID: number, modifierCount: number, coupon: IDataCoupon): boolean => {
        return ((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) < oItem.Quantity || (coupon.AllowOtherCoupons && oItem.DiscountedMarkedQuantity < oItem.Quantity))
            && oItem.ItemId === itemID
            && oItem.SizeId === sizeID
            && (!coupon.ExactMatch || (coupon.ExactMatch && this._getItemPricedModifierCount(oItem) >= modifierCount))
            && (!coupon.AllowOtherCoupons || (coupon.AllowOtherCoupons && !this._checkSameCouponAppliedQtyTimes(order, oItem, coupon)));
    };

    private _checkSameCouponAppliedQtyTimes = (order: IOrder, oItem: IOrderItem, coupon: IDataCoupon): boolean => {
        let itemCoupons = aOLO.Order.ItemsCoupons.filter(iCop => iCop.ItemKey == oItem.ItemKey && iCop.HalfIndex == oItem.HalfIndex && iCop.ItemRecId == oItem.ItemRecId);
        let count = 0;
        itemCoupons.forEach((iCpn) => {
            if (order.Coupons.find(x => x.CouponKey == iCpn.CouponKey && x.CouponId == coupon.CouponId)) {
                count++;
            }
        });
        return (count >= oItem.Quantity);
    }

    private _updateQualifyingItem = (order: IOrder, qItem: IQualifyItemExt, oItem: IOrderItem, i: number): IQualifyItemExt => {
        const updatedQItem: IQualifyItemExt = {
            Index: i,
            QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
            CouponItemIndex: qItem.CouponItemIndex
        };
        if (oItem.HalfCount > 1) {
            this._markAllHalves(order, oItem.ItemKey);
        } else {
            oItem.DiscountedMarkedQuantity += 1;  // Notice: This modifies the passed oItem directly.
        }
        return updatedQItem;
    };

    private _checkCouponAppliedToItem = (order: IOrder, oItem: IOrderItem, applyingCouponKeys: number[]): boolean => {
        return order.ItemsCoupons.some(x => x.ItemKey == oItem.ItemKey && applyingCouponKeys.includes(x.CouponKey));
    }

    private _findCouponItem = (order: IOrder, coupon: IDataCoupon, itemID: number, sizeID: number, modifierCount: number): IQualifyItemExt => {
        let qItem: IQualifyItemExt = {
            Index: -1,
            QTYIndex: -1,
            CouponItemIndex: -1
        };
        const applyingCouponKeys = order.Coupons.filter(c => c.CouponId == coupon.CouponId).map(c => c.CouponKey) || [];
        for (const [index, oItem] of order.Items.entries()) {
            if (this._isItemEligibleForCoupon(order, oItem, itemID, sizeID, modifierCount, coupon)) {
                qItem = this._updateQualifyingItem(order, qItem, oItem, index);
                break;
            }
        }
        return qItem;
    }

    private _getItemPricedModifierCount = (oItem: IOrderItem): number => {
        let mCount = 0;
        for (const mod of oItem.Modifiers) {
            if (mod.CountForPricing || mod.CountForPricingByItself)
                mCount += 1;
        }
        return mCount;
    }

    private _qualifyAllHalfs = (order: IOrder, vItem: IvItem): IvItem[] => {
        let vItems: IvItem[] = [];
        const halfCount = vItem.HalfCount || 1;
        const originalCPrice = vItem.cPrice;
        const cPrice = Util.Float2(vItem.cPrice / halfCount);
        const diffcPrice = Util.Float2(originalCPrice - (cPrice * halfCount));
        vItem.cPrice = cPrice + diffcPrice;
        //vItem.oPrice = Util.Float2(vItem.oPrice / halfCount); ;BUG #36324 (Commented old code)
        vItems.push(vItem);

        let discountedMarkedQTY = 0;
        for (const oItem of order.Items) {
            if (vItem.ItemKey === oItem.ItemKey && vItem.HalfIndex === oItem.HalfIndex) {
                discountedMarkedQTY = oItem.DiscountedMarkedQuantity;
                break;
            }
        }

        for (const [index, oItem] of order.Items.entries()) {
            if (vItem.ItemKey === oItem.ItemKey && vItem.HalfIndex !== oItem.HalfIndex) {
                oItem.DiscountedMarkedQuantity = discountedMarkedQTY;
                const vItm = {
                    ItemArrayIndex: index,
                    QTYIndex: vItem.QTYIndex,
                    cPrice: cPrice,
                    oPrice: vItem.oPrice,
                    ItemKey: oItem.ItemKey,
                    HalfIndex: oItem.HalfIndex,
                    HalfCount: oItem.HalfCount,
                    ItemRecID: oItem.ItemRecId,
                    AddOn: vItem.AddOn,
                    BeforeTaxDiscount: 0.0,
                    AfterTaxDiscount: 0.0,
                };
                vItems.push(vItm);
            }
        }

        return vItems;
    }

    private _qualifyCouponItemsGroupQTY = (order: IOrder, coupon: IDataCoupon, group: number, autoApplying: boolean, qualifyAnyQTY: boolean): IvItem[] | null => {
        let vItems: IvItem[] = [];
        const itemsToQualify = coupon.Items.filter(item => item.GroupIndex === group);
        for (const cItem of itemsToQualify) {
            const qty = cItem.Quantity;
            for (let j = 0; j < qty; j++) {
                const vItem = this._qualifyCouponItemsGroup(order, coupon, group, cItem.ItemIndex, autoApplying);
                if (vItem) {
                    vItems.push(vItem);
                } else if (!qualifyAnyQTY) {
                    vItems.forEach(vItem => {
                        let oItem = order.Items.find(x => x.ItemKey === vItem.ItemKey);
                        if (oItem) {
                            if (oItem.HalfCount > 1)
                                this._unMarkAllHalves(order, oItem.ItemKey);
                            else
                                oItem.DiscountedMarkedQuantity -= 1;
                        }
                    });
                    vItems = [];
                    break;
                }
            }
            if (vItems.length > 0 && !qualifyAnyQTY)
                return vItems;
        }

        return vItems.length > 0 ? vItems : null;
    }

    private _qualifyCouponItemsGroup = (order: IOrder, coupon: IDataCoupon, group: number, itemIndex: number, autoApplying: boolean): IvItem | null => {
        let qItem: IQualifyItem = { Index: -1, QTYIndex: -1 };
        let vItem;
        const cItem = coupon.Items.find(x => x.GroupIndex === group && x.ItemIndex === itemIndex);
        if (cItem) {
            qItem = this._findCouponItem(order, coupon, cItem.ItemId, cItem.SizeId, cItem.ModifierCount);
            if (qItem.Index === -1 && cItem.ModifierCount > 0 && ((!autoApplying && !coupon.ExactMatch) || (autoApplying && !coupon.AutoApplyExactMatch))) {
                let mCount = cItem.ModifierCount;
                while (mCount > 0) {
                    mCount -= 1;
                    qItem = this._findCouponItem(order, coupon, cItem.ItemId, cItem.SizeId, mCount);
                    if (qItem.Index > -1)
                        break;
                }
            }

            if (qItem.Index > -1)
                vItem = this._getQualifiedCouponItem(order, qItem, cItem.ItemId, cItem.SizeId, cItem.ModifierCount, cItem.AddOnPrice);
        }

        return vItem || null;
    }

    private _qualifyAllNotDiscountedItems = (order: IOrder, coupon?: IDataCoupon): IvItem[] => {
        if (coupon && !coupon.AllowOtherCoupons && order.Coupons.length > 0)
            return [];

        let qualifiedGroupList: IvItem[] = [];
        for (const [index, oItem] of order.Items.entries()) {
            if (oItem.Quantity <= oItem.DiscountedQuantity)
                continue;

            oItem.DiscountedMarkedQuantity = oItem.Quantity - oItem.DiscountedQuantity;
            for (let j = 0; j < oItem.Quantity; j++) {
                const vItem = {
                    ItemArrayIndex: index,
                    QTYIndex: j + 1,
                    cPrice: 0.00,
                    oPrice: oItem.Price,
                    ItemKey: oItem.ItemKey,
                    HalfIndex: oItem.HalfIndex,
                    ItemRecID: oItem.ItemRecId,
                    AddOn: 0.00,
                    BeforeTaxDiscount: 0.0,
                    AfterTaxDiscount: 0.0
                };

                if (oItem.HalfCount > 1)
                    vItem.oPrice = oItem.HalfHalfPrice;

                qualifiedGroupList.push(vItem);
            }

        }
        return qualifiedGroupList;
    }

    private _getTotalCouponItemsPrices = (qualifiedList: IvItem[]): ITotalCouponPrice => {
        let couponItemPrice = 0.0;
        let orderItemPrice = 0.0;
        let couponAddOnPrice = 0.0;

        for (const item of qualifiedList) {
            couponItemPrice += Util.Float2(item.cPrice);
            orderItemPrice += Util.Float2(item.oPrice);
            couponAddOnPrice += Util.Float2(item.AddOn);
        }

        return {
            CouponItemPrice: Util.Float2(couponItemPrice),
            OrderItemPrice: Util.Float2(orderItemPrice),
            CouponAddOnPrice: Util.Float2(couponAddOnPrice),
        };
    }

    private _splitDiscount = (order: IOrder, qualifiedList: IvItem[], totalDiscountedItemsPrice: number, discountAmount: number, isBeforeTax: boolean, couponTypeID: number): void => {
        let tSplit = 0.00;
        if (isBeforeTax) {
            this._splitDiscountBeforeTax(order, qualifiedList, totalDiscountedItemsPrice, discountAmount, couponTypeID);
        } else {
            for (let i = 0; i < qualifiedList.length; i++) {
                if (qualifiedList[i].cPrice > 0) {
                    qualifiedList[i].AfterTaxDiscount = Util.Float2((qualifiedList[i].oPrice * discountAmount / totalDiscountedItemsPrice) - qualifiedList[i].AddOn);
                    tSplit += qualifiedList[i].AfterTaxDiscount;
                }
            }
            qualifiedList[qualifiedList.length - 1].AfterTaxDiscount += (discountAmount - tSplit) // this is to clear rounding discrepency
        }
    }

    private _splitDiscountBeforeTax = (order: IOrder, qualifiedList: IvItem[], totalDiscountedItemsPrice: number, discountAmount: number, couponTypeID: number): void => {
        const qualifiedListDiscountNotMaxed = [];
        let indexToClearRounding = 0;
        let tSplit = 0.00;
        let discountToRedistribute = 0.00;
        let actualDiscountAmount = discountAmount;
        let updatedTotalDiscountedItemsPrice = totalDiscountedItemsPrice;
        for (let i = 0; i < qualifiedList.length; i++) {
            if (qualifiedList[i].cPrice > 0 ||
                couponTypeID === CouponTypeList.MANAGER_DISCOUNT ||
                couponTypeID === CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER ||
                couponTypeID === CouponTypeList.PERCENTAGE_OFF_ALL_ITEMS_IN_CATEGORY ||
                couponTypeID === CouponTypeList.LOYALTY_DISCOUNT
            ) {
                const oItem = order.Items.find(x => x.ItemKey === qualifiedList[i].ItemKey);
                if (oItem) {
                    const beforeTaxDiscount = Util.Float2((qualifiedList[i].oPrice * discountAmount / totalDiscountedItemsPrice) - qualifiedList[i].AddOn);
                    //currentItemDiscount takes into account discounts applied previously from other coupons and discounts currently being applied through discount redistribution (if applicable)
                    const currentItemDiscount = oItem.AfterTaxDiscount + oItem.BeforeTaxDiscount + qualifiedList[i].AfterTaxDiscount + qualifiedList[i].BeforeTaxDiscount;
                    //apply the discount to the current' item's discount only if it hasn't surprassed item price
                    if (currentItemDiscount < qualifiedList[i].oPrice) {
                        qualifiedList[i].BeforeTaxDiscount += (currentItemDiscount + beforeTaxDiscount) > qualifiedList[i].oPrice ? qualifiedList[i].oPrice - currentItemDiscount : beforeTaxDiscount;
                        tSplit += beforeTaxDiscount;
                        qualifiedListDiscountNotMaxed.push(qualifiedList[i]);
                        indexToClearRounding = i;
                    } else {
                        discountToRedistribute += beforeTaxDiscount;
                        updatedTotalDiscountedItemsPrice -= qualifiedList[i].oPrice;
                        actualDiscountAmount = actualDiscountAmount - beforeTaxDiscount;
                    }
                }
            }
        }
        qualifiedList[indexToClearRounding].BeforeTaxDiscount += Util.Float2(actualDiscountAmount - tSplit) // this is to clear rounding discrepency
        if (discountToRedistribute > 0) {
            this._splitDiscountBeforeTax(order, qualifiedListDiscountNotMaxed, updatedTotalDiscountedItemsPrice, discountToRedistribute, couponTypeID);
        }
    }

    private _addDiscountsToItems = (order: IOrder, qualifiedList: IvItem[]): void => {
        for (const item of qualifiedList) {
            order.Items[item.ItemArrayIndex].BeforeTaxDiscount += item.BeforeTaxDiscount;
            order.Items[item.ItemArrayIndex].AfterTaxDiscount += item.AfterTaxDiscount;
        }
    }

    private _addCouponToOrder = (order: IOrder, couponID: number, couponCode: string, discount: number, discountAmount: number, isBeforeTax: boolean, isActive: boolean, allowOtherCoupons: boolean, channelID?: number | null, autoApplying?: boolean, isCampaignCode?: boolean, isPromotionCode?: boolean, rewardId?: number | null, isBankedCurrency?: boolean): number => {
        const oCoupon: IOrderCoupon = {
            CouponKey: (order.Coupons.length === 0) ? 1 : order.Coupons[order.Coupons.length - 1].CouponKey + 1,
            CouponId: couponID,
            CouponCode: couponCode,
            Discount: discount,
            Amount: discountAmount,
            IsBeforeTax: isBeforeTax,
            IsActive: isActive,
            AllowOtherCoupons: allowOtherCoupons,
            IsAdded: false,
            IsRemoved: false,
            AutoApplied: autoApplying || false,
            ChannelID: channelID || null,
            IsPromotionCode: isPromotionCode || false,
            IsCampaignCode: isCampaignCode || false,
            RewardId: rewardId || null,
            IsBankedCurrency: isBankedCurrency || false,
            RedemptionReference: null,
            BasketItemId: null
        };
        order.Coupons.push(oCoupon);
        return oCoupon.CouponKey;
    }

    private _addCouponToItemsCoupon = (order: IOrder, qualifiedList: IvItem[], couponKey: number, priority: number, isActive: boolean, allowOtherCoupons: number): void => {
        for (const item of qualifiedList) {
            const itemCoupon = {
                ItemKey: item.ItemKey,
                HalfIndex: item.HalfIndex,
                ItemRecId: item.ItemRecID,
                CouponKey: couponKey,
                QTYIndex: item.QTYIndex,
                BeforeTaxDiscount: item.BeforeTaxDiscount,
                AfterTaxDiscount: item.AfterTaxDiscount,
                DiscountedQuantity: allowOtherCoupons,
                IsActive: isActive,
                Priority: priority,
            };
            order.ItemsCoupons.push(itemCoupon);
        }
    }

    private _markAllHalves = (order: IOrder, itemKey: number): void => {
        for (const oItem of order.Items) {
            if (oItem.ItemKey === itemKey)
                oItem.DiscountedMarkedQuantity += 1;
        }
    }

    private _unMarkAllHalves = (order: IOrder, itemKey: number): void => {
        for (const oItem of order.Items) {
            if (oItem.ItemKey == itemKey)
                oItem.DiscountedMarkedQuantity -= 1;
        }
    }

    private _clearMarkedItems = (order: IOrder): void => {
        for (const oItem of order.Items) {
            oItem.DiscountedMarkedQuantity = 0;
        }
    }

    private _useMarkedItems = (order: IOrder): void => {
        for (const oItem of order.Items) {
            oItem.DiscountedQuantity += oItem.DiscountedMarkedQuantity;
            oItem.DiscountedMarkedQuantity = 0;
        }
    }

    public AutoApplyCoupons = async (order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider): Promise<void> => {
        const autoApplyCoupons: IDataCoupon[] = this._data.getProperty("Coupons").filter(cpn => cpn.IsAutoApply);

        for (const coupon of order.Coupons) {
            if (!coupon.RewardId) {
                const mCoupon = this.GetCoupon(coupon.CouponId, true, order.OrderTypeID, coupon.ChannelID);
                const couponTypesList = [CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER, CouponTypeList.PERCENTAGE_OFF_ALL_ITEMS_IN_CATEGORY, CouponTypeList.MIX_AND_MATCH_PRICE, CouponTypeList.MIX_AND_MATCH_PERCENTAGE];
                if (mCoupon && couponTypesList.includes(mCoupon.CouponTypeId)) {
                    await this.RemoveCouponByKey(order, incompleteDiscounts, loyaltyProvider, coupon.CouponKey, true);
                    const params: IApplyCouponByIdParams = {
                        order: order,
                        incompleteDiscounts: incompleteDiscounts,
                        loyaltyProvider: loyaltyProvider,
                        couponId: coupon.CouponId,
                        couponCode: coupon.CouponCode
                    };
                    await this.ApplyCouponByID(params);
                }
            }

            if (coupon.RewardId) {
                const reward = this._user.getProperty("LoyaltyData")?.Rewards.find(x => x.RewardId == coupon.RewardId);
                if (!reward)
                    continue;

                await loyaltyProvider.removeReward(reward);
                await loyaltyProvider.addReward(reward);
            } else if (coupon.IsBankedCurrency) {
                const amount = coupon.Amount;
                await loyaltyProvider.removeBankedCurrency();
                await loyaltyProvider.applyBankedCurrency(amount);
            }
        }

        // Try to Apply Incomplete Coupons
        const tempDiscounts = Util.deepClone(incompleteDiscounts) as ITempIncompleteDiscount[];
        for (const coupon of tempDiscounts) {
            if (coupon.rewardId) {
                const reward = this._user.getProperty("LoyaltyData")?.Rewards.find(x => x.RewardId == coupon.rewardId);
                if (reward) {
                    await loyaltyProvider.addReward(reward);
                    incompleteDiscounts = incompleteDiscounts.filter(x => x.rewardId !== coupon.rewardId);
                    //Refactor: OnlineOrderingUtil.GUI_SetOrder_Total(true, aOLO, aOLOModules);
                    break;
                }
            }

            const params: IApplyCouponByIdParams = {
                order: order,
                incompleteDiscounts: incompleteDiscounts,
                loyaltyProvider: loyaltyProvider,
                couponId: coupon.couponID,
                couponCode: coupon.couponCode,
                channelId: coupon.channelID,
                autoApplying: true,
                isCampaignCode: coupon.isCampaignCode,
                isPromotionCode: coupon.isPromotionCode
            };
            const result = await this.ApplyCouponByID(params);
            if (result)
                await this.RemoveIncompleteCouponById(incompleteDiscounts, loyaltyProvider, coupon.couponID);
        }

        for (const coupon of autoApplyCoupons) {
            const params: IApplyCouponByIdParams = {
                order: order,
                incompleteDiscounts: incompleteDiscounts,
                loyaltyProvider: loyaltyProvider,
                couponId: coupon.CouponId,
                autoApplying: true,
            };
            await this.ApplyCouponByID(params);
        }
    }

    public RemoveIncompleteCouponById = async (incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, couponId: number): Promise<void> => {
        const item = incompleteDiscounts.find(x => x.couponID === couponId);
        if (!item)
            return;

        if (item.rewardId) {
            const reward = this._user.getProperty("LoyaltyData")?.Rewards.find(x => x.RewardId == item.rewardId);
            if (reward)
                await loyaltyProvider.removeReward(reward);
        }

        if (item.isBankedCurrency) {
            await loyaltyProvider.removeBankedCurrency();
        }

        const offers = this._user.getProperty("Offers");
        if ((item.isCampaignCode || item.isPromotionCode) && offers?.Codes) {
            const offer = offers.Codes.find(x => x.CouponCode.toLowerCase() == item.couponCode);
            if (offer)
                offer.Used = false;
        }

        incompleteDiscounts = incompleteDiscounts.filter(x => x.couponID !== couponId);
        //Refactor: OnlineOrderingUtil.GUI_SetOrder_Total(true, aOLO, aOLOModules);
    }

    public GetCoupon = (couponId: number, checkOTST: boolean, orderTypeID: number | null, channelID: number | null): IDataCoupon | null => {
        const coupon = this._data.getProperty("Coupons").find(cpn => cpn.CouponId === couponId);
        if (coupon) {
            coupon.ChannelID = channelID || null;
        }
        if (checkOTST && coupon?.OrderTypeSubTypes && coupon?.OrderTypeSubTypes.length > 0)
            return (coupon.OrderTypeSubTypes.find(x => x.OrderTypeId === orderTypeID) ? coupon : null);

        return coupon || null;
    }

    public CheckCouponOTST = (coupon: IDataCoupon, orderTypeID: number): boolean => {
        return !!coupon.OrderTypeSubTypes?.find(x => x.OrderTypeId === orderTypeID);
    }

    public VerifyCouponDate = (order: IOrder, coupon: IDataCoupon): boolean => {
        if (coupon.StartDate == null && coupon.EndDate == null)
            return true;

        const tempOrderDate = order.FutureDate ? order.FutureDate : order.OrderStartTime;
        const orderDate = new Date(new Date(Util.deepClone(tempOrderDate)).setHours(0, 0, 0, 0));
        if (orderDate.getTime() >= new Date(coupon.StartDate?.replaceAll("-", "/") || "").getTime() && orderDate.getTime() <= new Date(coupon.EndDate?.replaceAll("-", "/") || "").getTime())
            return true;

        return false;
    }

    public CheckCouponSCHS = (order: IOrder, coupon: IDataCoupon): boolean => {
        if (!coupon.IsScheduled)
            return true;

        let promiseDate;
        let dayOfWeek = 0;
        let promiseMinutes = 0;
        if (order.FutureDate === null) {
            promiseDate = Util.NowStore(globalThis.aOLO.Temp.TimeOffset);
            const promiseBDate = new Date(Util.GetBUSDate(promiseDate, globalThis.aOLO));
            dayOfWeek = promiseBDate.getDay();
            promiseMinutes = promiseDate.getMinutes() + (60 * promiseDate.getHours());
        } else {
            promiseDate = new Date(order.FutureDate);
            dayOfWeek = promiseDate.getDay();
            promiseMinutes = order.FutureMinute;
        }

        return coupon.Schedules ? coupon.Schedules.some(schedule => {
            return schedule.WeekDayNumber === dayOfWeek && schedule.IsActive && schedule.StartDayMinute <= promiseMinutes && schedule.EndDayMinute >= promiseMinutes;
        }) : true;
    }

    public CheckCouponHasQualifyingItems = async (order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, couponId: number, couponCode?: string | null, orderID?: number | null, reward?: IUserLoyaltyReward | null, channelID?: number | null, isCampaignCode?: boolean, isPromotionCode?: boolean, offer?: IUserCampaignCodesData | null): Promise<void> => {
        let coupon = this.GetCoupon(couponId, true, order.OrderTypeID, channelID || null);

        if (!coupon && reward) {
            await this._loadCouponByIdAsync(reward.CouponId);
            coupon = this.GetCoupon(couponId, true, order.OrderTypeID, channelID || null);
        }

        if (!coupon)
            return;

        if (loyaltyProvider.onlyOneDiscountAllowed(reward?.RewardId || null))
            return;

        if (coupon.Items.length > 0) {
            const params: IApplyCouponByIdParams = {
                order: order,
                incompleteDiscounts: incompleteDiscounts,
                loyaltyProvider: loyaltyProvider,
                couponId: couponId,
                showApplyMsg: true,
                couponCode: couponCode ? couponCode : null,
                isCampaignCode: isCampaignCode,
                isPromotionCode: isPromotionCode,
                rewardId: reward ? reward.RewardId : null
            };
            const applied = await this.ApplyCouponByID(params);

            if (applied) {
                await this.RemoveIncompleteCouponById(incompleteDiscounts, loyaltyProvider, couponId);
            } else {
                // 2DO: Refactor
                //const mealDealFunctions = {
                //    DeleteItem: this._functions.DeleteItem,
                //    GUI_SetOrder: this._functions.GUI_SetOrder,
                //    Item_AddToOrder: this._functions.Item_AddToOrder
                //};

                //aOLO.Dialog.MealDeal = new MealDeal(mealDealFunctions, coupon, (couponCode ? couponCode : null), (orderID ? orderID : null), (reward ? reward.RewardId : null), isCampaignCode, isPromotionCode);

                if (this._dataLayer)
                    this._dataLayer.mealDealScrollListeners();
            }
        } else {
            if (couponCode) {
                const result = await this.ApplyCoupon(order, incompleteDiscounts, loyaltyProvider, coupon, couponCode, false, true, undefined, undefined, isCampaignCode, isPromotionCode);
                if (!result) {
                    //Refactor
                    //    this._functions.addIncompleteCouponToCart(coupon.CouponId, couponCode, aOLO.Temp.IncompleteDiscounts, aOLO, aOLOModules, channelID, isCampaignCode, isPromotionCode);
                } else {
                    if (offer)
                        offer.Used = true;
                }
            } else if (orderID) {
                const result = await this.ApplyCoupon(order, incompleteDiscounts, loyaltyProvider, coupon, `bx${orderID}`, false, true, undefined, undefined, isCampaignCode, isPromotionCode);
                //Refactor
                //if (!result)
                //    this._functions.addIncompleteCouponToCart(coupon.CouponId, '', aOLO.Temp.IncompleteDiscounts, aOLO, aOLOModules, channelID, isCampaignCode, isPromotionCode);
            } else if (reward) {
                const params: IApplyCouponByIdParams = {
                    order: order,
                    incompleteDiscounts: incompleteDiscounts,
                    loyaltyProvider: loyaltyProvider,
                    couponId: couponId,
                    showApplyMsg: true,
                    isCampaignCode: isCampaignCode,
                    isPromotionCode: isPromotionCode,
                    rewardId: reward.RewardId
                };
                const result = await this.ApplyCouponByID(params);
                if (result)
                    reward.Used = true;
                // Refactor
                //else
                //    this._functions.addIncompleteCouponToCart(coupon.CouponId, "", aOLO.Temp.IncompleteDiscounts, aOLO, aOLOModules, channelID, isCampaignCode, isPromotionCode, reward.RewardId);
            } else {
                const params: IApplyCouponByIdParams = {
                    order: order,
                    incompleteDiscounts: incompleteDiscounts,
                    loyaltyProvider: loyaltyProvider,
                    couponId: couponId,
                    showApplyMsg: true,
                    couponCode: couponCode ? couponCode : null,
                    isCampaignCode: isCampaignCode,
                    isPromotionCode: isPromotionCode
                };
                const result = await this.ApplyCouponByID(params);
                //Refactor
                //if (!result)
                //    this._functions.addIncompleteCouponToCart(coupon.CouponId, "", aOLO.Temp.IncompleteDiscounts, aOLO, aOLOModules, channelID, isCampaignCode, isPromotionCode);
            }
        }

        if (this._dataLayer)
            this._dataLayer.select_promotion(coupon, !!globalThis.aOLO.specialCode, couponCode);
    }

    private _loadCouponByIdAsync = async (couponId: number): Promise<void> => {
        const data = {
            CID: couponId,
            SKY: this._data.getProperty("StoreInfo").storeKey
        };
        const response = await this._orderingService.getCouponById(data, globalThis.aOLO);

        if (response.success) {
            const coupons = this._data.getProperty("Coupons");
            coupons.push(this._mapCoupon(response.coupon));
            this._data.setProperty("Coupons", coupons);
        } else if (!response.serverError)
            DialogCreators.messageBoxOk(Names("UnableToRetrieveReward"), globalThis.aOLO.buttonHoverStyle);
    }

    private _mapCoupon = (couponJSON: string): IDataCoupon => {
        const cpn = JSON.parse(couponJSON);
        const coupon = {
            CouponId: cpn.CPID,
            CouponTypeId: cpn.CPTID,
            AllowOtherCoupons: cpn.AOC,
            AutoApplyExactMatch: cpn.AAEXCMCH,
            BackgroundColor: cpn.TBGC,
            BOGOSelectionTypeID: cpn.BGSTID,
            Descriptions: cpn.DSCRs,
            Discount: cpn.DIC,
            ExactMatch: cpn.EXCMCH,
            ImageURL: cpn.IURL,
            IsBeforeTax: cpn.IBTX,
            IsBOGO: cpn.BOGO,
            IsHiddenOnline: cpn.IHDN,
            IsPriceReducer: cpn.IPR,
            IsProfileRequired: cpn.IOPR,
            IsScheduled: cpn.SCH,
            IsVerificationNeeded: cpn.IVN,
            IsAutoApply: cpn.IAA,
            IsAppOnly: false,
            LimitsPerOrder: cpn.LPO,
            MaximumDiscountAmount: cpn.MXAMT,
            MinimumItemQuantity: cpn.MINITMQTY,
            MinimumOrderAmount: cpn.MIAMT,
            CalculationMethodID: cpn.CALCID,
            Names: cpn.NAMs,
            Priority: cpn.PRITY,
            WebCategoryId: cpn.WCID,
            StartDate: cpn.SDATE,
            EndDate: cpn.EDATE,
            Codes: cpn.CCODS?.map((cd: any) => ({
                CouponCode: cd.CCOD
            })) || [],
            Items: cpn.CITMS?.map((itm: any) => ({
                AddOnPrice: itm.AOPRC,
                GroupIndex: itm.GRP,
                ItemId: itm.IID,
                ItemIndex: itm.IIDX,
                ModifierCount: itm.MCNT,
                Quantity: itm.QTY,
                SizeId: itm.SZID
            })) || [],
            ItemCategories: cpn.CITMCATS?.map((cat: any) => ({
                CategoryId: cat.CATID
            })) || [],
            OrderTypeSubTypes: cpn.OTSTS?.map((ot: any) => ({
                OrderTypeId: ot.OT,
                OrderTypeSubId: ot.OST
            })) || [],
            Schedules: cpn.SCHS?.map((sch: any) => ({
                WeekDayNumber: sch.WKDNO,
                IsActive: sch.ISACT,
                StartDayMinute: sch.STRTMIN,
                EndDayMinute: sch.ENDMIN
            })) || [],
            Upgrades: cpn.UGRDS?.map((upg: any) => ({
                BuySizeId: upg.BSZID,
                PriceSizeId: upg.PSZID,
                UpgradeIndex: upg.UGRDIDX
            })) || []
        };

        return coupon;
    }

    public ApplyCouponByCode = async (order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, couponCode: string, isOffer: boolean = false, isPromotion: boolean = false, isCampaign: boolean = false): Promise<void> => {
        if (couponCode === "") {
            DialogCreators.messageBoxOk(Names("InvalidCouponCode"), globalThis.aOLO.buttonHoverStyle);
            return;
        }

        if (loyaltyProvider.onlyOneDiscountAllowed(null))
            return;

        if (this.IsCouponNotAllowedInFundraiser(order))
            return;

        couponCode = couponCode.toLowerCase();

        // If coupon code from User Offers
        if (isPromotion) {
            await this._applyCouponByPromotionCode(order, incompleteDiscounts, loyaltyProvider,couponCode, isOffer, true);
            return;
        }

        // If coupon code from User Offers
        if (isCampaign) {
            await this._applyCouponByCampaignCode(order, incompleteDiscounts, loyaltyProvider,couponCode, isOffer, true);
            return;
        }

        const tempOrderDate = order.FutureDate ? order.FutureDate : order.OrderStartTime;
        const orderDate = new Date(new Date(Util.deepClone(tempOrderDate)).setHours(0, 0, 0, 0));
        // Check if normal coupon code, advertisement code, or channel code in that order
        const cpn = await this._getCouponByCode(couponCode, orderDate, this._data.getProperty("StoreInfo").storeId);
        if (cpn) {
            this._applyCouponByCode_Continue(order, incompleteDiscounts, loyaltyProvider,cpn, couponCode, false, false, null);
            return;
        }

        let found = false;

        // If deep link campaign or promotion coupon code
        if (!found)
            found = await this._getCouponByCloudDeepLink(order, incompleteDiscounts, loyaltyProvider, couponCode);

        // Check if coupon is a single use coupon
        if (!found) {
            const response = await this._validateSingleUseCoupon(order, couponCode);
            if (response.success) {
                if (response.orderNo && response.orderNo !== 0)
                    order.OrderNo = response.orderNo;

                if (response.orderId)
                    order.OrderID = response.orderId;

                found = await this._applyCouponByPromotionCode(order, incompleteDiscounts, loyaltyProvider,couponCode, isOffer, false, true);
            } else {
                if (response.message == "Promotion has been redeemed.") {
                    DialogCreators.messageBoxOk(Names("CouponRedeemed"), globalThis.aOLO.buttonHoverStyle);
                    return;
                }
                if (response.message == "Promotion cannot be applied multiple times.") {
                    DialogCreators.messageBoxOk(Names("CouponCannotApplyMultiple"), globalThis.aOLO.buttonHoverStyle);
                    return;
                }
            }
        }

        // Check if coupon code is a promotion
        if (!found)
            found = await this._applyCouponByPromotionCode(order, incompleteDiscounts, loyaltyProvider,couponCode, isOffer, false);

        // Check if coupon code is a campaign
        if (!found)
            found = await this._applyCouponByCampaignCode(order, incompleteDiscounts, loyaltyProvider,couponCode, isOffer, false);

        if (!found)
            DialogCreators.messageBoxOk(Names("InvalidCouponCode"), globalThis.aOLO.buttonHoverStyle);
    }

    public IsCouponNotAllowedInFundraiser(order: IOrder): boolean {
        const fundraiser = this._data.getProperty("Fundraisers").find(x => x.FundraiserId == order.Fundraiser?.ID);
        if (order.Fundraiser && fundraiser && !fundraiser.IsCouponAllowed) {
            DialogCreators.messageBoxOk(Names("CouponCodeErrorWithFundraiser").replace("??", fundraiser.Name), globalThis.aOLO.buttonHoverStyle);
            return true;
        }
        return false;
    }

    private async _getCouponByCloudDeepLink(order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, couponCode: string): Promise<boolean> {
        const cloudCoupon = globalThis.aOLO.CloudCoupon;
        if (cloudCoupon?.CouponCode.toLowerCase() !== couponCode)
            return false;

        if (cloudCoupon.IsCampaignCode) {
            const coupon = await this._getCouponByCampaignCode(cloudCoupon.CouponCode, cloudCoupon.StoreId, true);
            if (coupon) {
                this._applyCouponByCode_Continue(order, incompleteDiscounts, loyaltyProvider, coupon, couponCode, true, false, null);
                return true;
            }
        } else {
            const coupon = await this._getCouponByPromotionCode(cloudCoupon.CouponCode, cloudCoupon.StoreId, true);
            if (coupon) {
                this._applyCouponByCode_Continue(order, incompleteDiscounts, loyaltyProvider,coupon, couponCode, false, true, null);
                return true;
            }
        }

        return false;
    }

    private async _validateSingleUseCoupon(order: IOrder, couponCode: string) {
        const data = {
            couponCode: couponCode.replace(/-/g, ""),
            orderId: order.OrderID,
            storeKey: this._data.getProperty("StoreInfo").storeKey
        };

        return await this._orderingService.validatePromotionCode(data, globalThis.aOLO);
    }

    private async _invalidateSingleUseCoupon(order: IOrder, couponCode: string) {
        const data = {
            couponCode: couponCode,
            orderId: order.OrderID,
            storeKey: this._data.getProperty("StoreInfo").storeKey
        };

        return await this._orderingService.invalidatePromotionCode(data, globalThis.aOLO);
    }

    private async _applyCouponByPromotionCode(order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, couponCode: string, isOffer: boolean, error: boolean, isSUC?: boolean): Promise<boolean> {
        const coupon = await this._getCouponByPromotionCode(couponCode, this._data.getProperty("StoreInfo").storeId, error);
        if (!coupon)
            return false;

        const offer = isOffer ? this._user.getProperty("Offers")?.Codes.find(x => x.CouponCode.toLowerCase() === couponCode) || null : null;
        if (isSUC) {
            couponCode = `SUC_${couponCode}`
        }
        this._applyCouponByCode_Continue(order, incompleteDiscounts, loyaltyProvider, coupon, couponCode, false, true, offer);
        return true;
    }

    private async _applyCouponByCampaignCode(order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, couponCode: string, isOffer: boolean, error: boolean): Promise<boolean> {
        const coupon = await this._getCouponByCampaignCode(couponCode, this._data.getProperty("StoreInfo").storeId, error);
        if (!coupon)
            return false;

        const offer = isOffer ? this._user.getProperty("Offers")?.Codes.find(x => x.CouponCode.toLowerCase() === couponCode) || null : null;
        this._applyCouponByCode_Continue(order, incompleteDiscounts, loyaltyProvider, coupon, couponCode, true, false, offer);
        return true;
    }

    private _applyCouponByCode_Continue = (order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, coupon: IDataCoupon, couponCode: string, isCampaignCode: boolean, isPromotionCode: boolean, offer: IUserCampaignCodesData | null): void => {
        if (this.CheckCouponOTST(coupon, order.OrderTypeID)) {
            if (this.CheckCouponSCHS(order, coupon)) {
                this.CheckCouponHasQualifyingItems(order, incompleteDiscounts, loyaltyProvider, coupon.CouponId, couponCode, null, null, coupon.ChannelID, isCampaignCode, isPromotionCode, offer);
                Util.setElement("value", "txt_cart_coupon_code", "");
            } else
                DialogCreators.messageBoxOk(Names("InvalidScheduleCode"), globalThis.aOLO.buttonHoverStyle);
        } else
            DialogCreators.messageBoxOk(Names("InvalidOrderTypeCode").replace("??", OnlineOrderingUtil.GetOrderTypeName(order.OrderTypeID, globalThis.aOLO)), globalThis.aOLO.buttonHoverStyle);
    }

    private _getCouponByCode = async (couponCode: string, orderDate: Date, storeId: number): Promise<IDataCoupon | null> => {
        const data = {
            couponCode: couponCode,
            order_date: orderDate,
            storeId: storeId,
            is_app: Util.isAppView()
        };

        const response = await this._orderingService.getCouponByCouponCode(data, globalThis.aOLO);
        if (response.success && response.couponId != null) {
            const couponId = response.couponId;
            return this._data.getProperty("Coupons").find(x => x.CouponId === couponId) ?? null;
        }

        return null;
    }

    private _getCouponByPromotionCode = async (couponCode: string, storeId: number, error: boolean): Promise<IDataCoupon | null> => {
        const data = {
            couponCode: couponCode,
            storeId: storeId
        };
        const response = await this._orderingService.getCouponByPromotionCode(data, globalThis.aOLO);

        if (response.success && response.coupon != "") {
            const coupon = this._mapCoupon(response.coupon);
            const coupons = this._data.getProperty("Coupons");
            if (!coupons.some(x => x.CouponId == coupon.CouponId))
                coupons.push(coupon);
            return coupon;
        } else if (!response.success && !response.serverError && error)
            DialogCreators.messageBoxOk(Names("UnableToRetrieveSurveyCoupon"), globalThis.aOLO.buttonHoverStyle);

        return null;
    }

    private _getCouponByCampaignCode = async (couponCode: string, storeId: number, error: boolean): Promise<IDataCoupon | null> => {
        const data = {
            couponCode: couponCode,
            storeId: storeId
        };
        const response = await this._orderingService.getCouponByCampaignCode(data, globalThis.aOLO);

        if (response.success && response.coupon != "") {
            const coupon = this._mapCoupon(response.coupon);
            const coupons = this._data.getProperty("Coupons");
            if (!coupons.some(x => x.CouponId == coupon.CouponId))
                coupons.push(coupon);
            return coupon;
        } else if (!response.success && !response.serverError && error)
            DialogCreators.messageBoxOk(Names("UnableToRetrieveCampaignCoupon"), globalThis.aOLO.buttonHoverStyle);

        return null;
    }

    public RemoveNotApplicableOrderTypeCoupons = async (order: IOrder, incompleteDiscounts: IIncompleteDiscount[], loyaltyProvider: ILoyaltyProvider, orderTypeID: number): Promise<void> => {
        for (let i = order.Coupons.length - 1; i >= 0; i--) {
            let coupon = order.Coupons[i];
            let cpn = this.GetCoupon(coupon.CouponId, true, orderTypeID, coupon.ChannelID);
            if (cpn === null)
                await this.RemoveCouponByKey(order, incompleteDiscounts, loyaltyProvider, coupon.CouponKey, false);
        }
    }
}