import './track-order.css';

import { Util } from "../../../utils/util";
import { EventEmitter } from 'eventemitter3';
import { EventTypes } from '../../../core/event-bus';
import { Names } from '../../../utils/i18n';
import { User } from '../../../models/user';
import { Page } from '../page';
import { Pages } from '../../../types/enums';
import { Data } from '../../../models/data';
import { Order } from '../../../models/order';
import { TrackerService } from "../../../services/tracker-service";
import { ITrackOrder } from './track-order.interface';
import { DialogCreators } from '../../../utils/dialog-creators';

export class TrackOrder extends Page {
    private readonly _trackerService: TrackerService;
    private readonly _order: Order;
    private readonly _user: User;
    private readonly _item: ITrackOrder;

    constructor(eventBus: EventEmitter, trackerService: TrackerService, data: Data, order: Order, user: User) {
        super("page_trackorder", eventBus, data)
        this._trackerService = trackerService;
        this._user = user;
        this._order = order;
        this._item = this._initialModel();
        this._eventBusListeners();
    }

    private readonly _initialModel = (): ITrackOrder => {
        return {
            order: {
                address: null,
                firstName: "",
                id: 0,
                lastName: "",
                number: 0,
                phone: "",
                status: {
                    estimatedDeliveryTime: null,
                    printTime: "",
                    statusesInfo: [],
                    trackerStatusOK: false
                }
            },
            storeAddressInfo: {
                name: "",
                streetNumber: "",
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                address5: "",
                city: "",
                state: "",
                zip: "",
                countryId: 0,
                phone: ""
            }
        };
    }


    public async initAsync(): Promise<void> {
        if (this._data) {
            this._item.order.id = this._order.getProperty("OrderID") || 0;
        }

        if (this._item.order.id > 0) {
            const fetchResult = await this._fetchTrackOrderData();
            if (fetchResult === 0) {
                this._renderPageAsync();
            } else {
                DialogCreators.messageBoxOk(`${Names("TrackOrder_FetchTrackOrderData_Error")}`, globalThis.aOLO.buttonHoverStyle, () => { window.history.back(); });
            }
        }
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.LOCATION_CHANGE, this._onLocationChange);
    }

    private readonly _onLocationChange = async (page: Pages, orderId: number) => {
        if (page !== Pages.TRACK_ORDER) {
            this._clearPage();
            return;
        }

        if (orderId) {
            this._item.order.id = orderId || 0;
        }

        if (this._item.order.id > 0) {
            const fetchResult = await this._fetchTrackOrderData();
            if (fetchResult === 0) {
                this._renderPageAsync();
            } else {
                DialogCreators.messageBoxOk(`${Names("TrackOrder_FetchTrackOrderData_Error")}`, globalThis.aOLO.buttonHoverStyle, () => { window.history.back(); });
            }
        }
    }

    private _fetchTrackOrderData = async (): Promise<number> => {
        let data;
        if (this._user?.isLoggedIn()) {
            data = await this._trackerService.fetchTrackerUserData(this._item.order.id);
            if (data?.order == null) {
                return -1;
            } 
        } else {
            data = await this._trackerService.fetchTrackerData(this._item.order.id);
        }

        if (data) {
            this._item.order = data.order;
            this._item.storeAddressInfo = data.storeAddressInfo;
            return 0;
        }
        return -2;
    }

    private readonly _renderPageAsync = async (): Promise<void> => {
        Util.setElement("innerText", "div_header_center_text", Names("TrackOrder"));
        Util.setElement("innerText", "div_header_center_subtext", `${Names("Order")} ${Names("Number")}: ${this._item.order.id}`);
        Util.showElement("div_header_center_text");
        Util.showElement("div_header_center_subtext");
    }

    private readonly _clearPage = () => {
        Util.setElement("innerText", "div_header_center_text", "");
        Util.setElement("innerText", "div_header_center_subtext", "");
        Util.hideElement("div_header_center_text");
        Util.hideElement("div_header_center_subtext");
    }
}