import './menu-view-all.css';

import 'swiper/swiper-bundle.css';

import { EventTypes } from '../../../core/event-bus';
import { Util } from '../../../utils/util';
import { Common } from '../../../common';
import { IName } from '../../../interfaces/global.interfaces';
import { Data } from '../../../models/data';
import { IDataItem } from '../../../models/data.interface';
import { MenuItemViewAllCard } from '../../shared/MenuItemViewAllCard/menu-item-view-all-card';
import { Page } from '../page';
import { Order } from '../../../models/order';
import { OnlineOrderingUtil } from '../../../online-ordering/online-ordering-util';
import { CustomizeItem } from '../../popups/CustomizeItem/customize-item';
import EventEmitter from "eventemitter3";
import { DataLayerService } from '../../../services/data-layer-service';
import { ILoyaltyProvider } from '../../../services/loyalty-provider/loyalty-provider.interface';

/**
 * Class representing the Landing page.
 */
export class MenuViewAll extends Page {
    private readonly _order: Order;
    private readonly _dataLayer: DataLayerService;
    private readonly _loyaltyProvider: ILoyaltyProvider;
    private readonly _categoryId: number;
    private readonly _subcategoryId: number;

    /**
     * Creates an instance of the Landing page.
     * @constructor
     */
    constructor(eventBus: EventEmitter, data: Data, order: Order, dataLayer: DataLayerService, loyaltyProvider: ILoyaltyProvider, categoryId: number, subcategoryId: number) {
        super("page_menu_all", eventBus, data)
        this._order = order;
        this._dataLayer = dataLayer;
        this._loyaltyProvider = loyaltyProvider;
        this._categoryId = categoryId;
        this._subcategoryId = subcategoryId;
    }

    public initAsync = async (): Promise<void> => {
        this._showPage();
        await this._renderPageAsync(this._categoryId, this._subcategoryId);
    }

    private readonly _showPage = (): void => {
        Util.showComponent("menu_view_all");
    }

    private readonly _renderPageAsync = async (categoryId: number, subcategoryId?: number): Promise<void> => {
        const parentDiv = document.getElementById("div_menu_view_all");
        if (!parentDiv)
            return;

        parentDiv.innerHTML = "";

        if (!subcategoryId) {
            const categories = this._data.getProperty("Categories")?.find(x => x.ItemWebCategoryId === categoryId);
            this._setHeaderName(categories?.Names || []);

            const categoryItems = this._data.getProperty("Items")?.filter(x => x.WebCategoryId === categoryId && x.SubCategoryId === 0) || [];
            this._createMenuItemViewAllCards(parentDiv, categoryItems);
        } else {
            const subcategory = this._data.getProperty("SubCategories")?.find(subCat => subCat.ItemSubCategoryId === subcategoryId);
            this._setHeaderName(subcategory?.Names || []);

            const subCategoryItems = this._data.getProperty("Items")?.filter(x => x.SubCategoryId === subcategoryId) || [];
            this._createMenuItemViewAllCards(parentDiv, subCategoryItems);
        }
    }

    private readonly _createMenuItemViewAllCards = (parentDiv: HTMLElement, items: IDataItem[]): void => {
        for (const item of items) {
            const card = new MenuItemViewAllCard(item, globalThis.aOLO.Temp.languageCode, () => { this._menuItemCardOnClick(item, 1); });
            parentDiv.appendChild(card.element);
        }
    }

    private readonly _menuItemCardOnClick = (mItem: IDataItem, quantity: number): void => {
        const defaultSize = OnlineOrderingUtil.GetItemDefaultSize(mItem, this._order.getProperty("OrderTypeID") || 0, null, this._data.getProperty("Settings")?.DSZID || 0);
        Common.checkAgeAndCustomizeItem(mItem, globalThis.aOLO, () => {
            const dialog = new CustomizeItem(this._eventBus, this._data, this._order, this._dataLayer, this._loyaltyProvider, mItem, defaultSize.SizeId, quantity);
            dialog.init();
            dialog.open();
        });
    }

    private readonly _setHeaderName = (names: IName[]): void => {
        const name = Common.GetName(names, globalThis.aOLO.Temp.languageCode);
        const data = { name: name, ltagj: Util.toLtagj(names) };
        this._eventBus.emit(EventTypes.HEADER_NAME_CHANGE, data);
    }
}