import './navigation-bar.css';

import EventEmitter from 'eventemitter3';
import { EventTypes } from '../../../core/event-bus';
import { Pages } from '../../../types/enums';
import { Util } from '../../../utils/util';

export class NavigationBar {
    private readonly _eventBus: EventEmitter;
    private _routes: { id: string, location: Pages }[];

    constructor(eventBus: EventEmitter) {
        this._eventBus = eventBus;
        this._routes = this._createRoutes();
    }

    private _createRoutes = (): { id: string, location: Pages }[] => {
        return [
            { id: "btn_nav_home", location: Pages.LANDING },
            { id: "btn_nav_rewards", location: Pages.REWARDS },
            { id: "btn_nav_favorites", location: Pages.FAVORITES },
            { id: "btn_nav_menu", location: Pages.MENU },
            { id: "btn_nav_orders", location: Pages.ORDERS }];
    }

    public init = (): void => {
        this._eventBusListeners();
        this._setEventListeners();
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.LOCATION_CHANGE, (location: Pages) => {
            const route = this._routes.find(x => x.location == location);
            this._routeChange(route?.id || "", location);
        });
    }

    private readonly _setEventListeners = (): void => {
        for (const route of this._routes) {
            Util.setElement("onclick", route.id, () => {
                this._routeChange(route.id, route.location);
                this._eventBus.emit(EventTypes.LOCATION_CHANGE, route.location)
            });
        }
    }

    private readonly _routeChange = (id: string, location: Pages): void => {
        this._setActiveSelection(id);
        Util.showComponent(location);
    }

    private readonly _setActiveSelection = (id: string): void => {
        const navItems = document.querySelectorAll(".nav-item");
        for (const item of navItems) {
            if (item.id == id)
                item.classList.add("active");
            else
                item.classList.remove("active");
        }
    }
}