import { EventEmitter } from 'eventemitter3';
import { EventTypes } from '../core/event-bus';
import { IDataCoupon, IDataServiceCharge, IDataTax } from '../models/data.interface';
import { Common } from '../common';
import { Util } from '../utils/util';
import { Data } from './data';
import { IIncompleteDiscount, IOrder, IOrderItem, IOrderServiceChargeDetail, IOrderTax } from '../models/order.interface';
import { OnlineOrderingUtil } from '../online-ordering/online-ordering-util';
import { OrderType } from '../types/enums';
import { IServiceChargeDetail } from '../online-ordering/interfaces/online-ordering.interface';
import { IApplyCouponByIdParams, IApplyCouponByIdParamsPartial, IRemovedCoupon } from '../online-ordering/interfaces/coupons.interface';
import { Coupon } from '../online-ordering/coupon';
import { User } from './user';
import { ILoyaltyProvider } from '../services/loyalty-provider/loyalty-provider.interface';
import { DataLayerService } from '../services/data-layer-service';
import { OnlineOrderingService } from '../online-ordering/online-ordering-service';
import { IUserCampaignCodesData, IUserLoyaltyReward } from './user.interface';

export class Order {
    private readonly _eventBus: EventEmitter;
    private readonly _data: Data;
    private readonly _coupon: Coupon;
    private readonly _order: IOrder;
    private readonly _incompleteDiscounts: IIncompleteDiscount[] = [];

    constructor(eventBus: EventEmitter, data: Data, user: User, dataLayer: DataLayerService, orderingService: OnlineOrderingService) {
        this._eventBus = eventBus;
        this._data = data;
        this._coupon = new Coupon(data, user, dataLayer, orderingService);
        this._order = this._initiateOrder();
        this._eventBusListeners();
    }

    public getProperty<K extends keyof IOrder>(property: K): IOrder[K] {
        if (!this._order)
            return this._getDefaultValue(property);

        return this._order[property];
    }

    public setProperty< K extends keyof IOrder>(key: K, value: IOrder[K]): void {
        this._order[key] = value;
    }

    public getIncompleteDiscounts(): IIncompleteDiscount[] {
        return this._incompleteDiscounts;
    }

    private readonly _eventBusListeners = (): void => {
        this._eventBus.on(EventTypes.STORE_CHANGED, this._storeChangedAsync);
        this._eventBus.on(EventTypes.ORDER_TYPE_CHANGED, this._orderTypeChanged);
        //this._eventBus.on(EventTypes.ITEM_ADD, this._itemsAdded);
    }

    private readonly _storeChangedAsync = async (storeKey: string): Promise<void> => {
        //2DO: Check Cart items and store is still good.
    }

    private readonly _orderTypeChanged = async (orderTypeId: number): Promise<void> => {
        this._order.OrderTypeID = orderTypeId;
    }

    public readonly addItems = (items: IOrderItem[]): void => {
        for (const item of items) {
            let existingItem = this._order.Items.find(x => x.ItemRecId === item.ItemRecId);
            if (existingItem)
                existingItem = item;
            else
                this._order.Items.push(item);
        }

        this.TaxOrder();
        this.calculateTotals();
        this._eventBus.emit(EventTypes.ITEM_ADD);
    }

    public removeItemByItemRecId(itemRecId: number): void {
        this._order.Items = this._order.Items.filter(x => x.ItemRecId !== itemRecId);
    }

    private _getDefaultValue<K extends keyof IOrder>(property: K): IOrder[K] {
        // Fallback type map for default values
        const fallbackTypes: { [key in keyof IOrder]: IOrder[key] } = {
            AddressID: 0,
            Address: null,
            AmountDue: 0,
            BUSDate: null,
            CollectedTax: 0.0,
            Coupons: [],
            Discount: 0.0,
            Distance: 0,
            Donations: { Detail: [] },
            FutureDate: null,
            FutureMinute: -1,
            Fundraiser: null,
            FundraiserID: 0,
            GiftCards: [],
            GUID: "",
            IsTaxExempt: false,
            Items: [],
            ItemsCoupons: [],
            ItemsMakeList: [],
            Key: "",
            OrderID: 0,
            OrderNo: 0,
            OrderTypeCharge: 0.0,
            OrderTypeSubID: 7,
            OrderTypeID: 0,
            OrderStartTime: new Date(),
            OrderEndTime: null,
            OrderTypeSubType: {
                OrderTypeId: 0,
                OrderTypeSubId: 0,
                PriceGroupId: 0,
                Alias: "",
                AskCustomerInfo: false,
                AskCustomerAddress: false,
                DefaultPay: 0,
                EndMinute: 0,
                FutureOrders: false,
                MinAmount: 0,
                Names: [],
                OrderTypeCharge: 0,
                OrderTypeChargeTaxId: 0,
                PausedUntil: null,
                Rate: 0,
                StartMinute: 0,
                TaxTypeId: 0,
                TaxNames: [],
                WaitTime: 0
            },
            Payments: [],
            PrintTime: null,
            PromiseTime: null,
            ServiceCharges: {
                Amount: 0.00,
                Detail: [],
                Tax: 0.0
            },
            StoreID: 0,
            SubTotal: 0.0,
            Tax: 0.0,
            Taxes: [],
            TaxesDetail: [],
            Tip: 0.0,
            Total: 0.0,
            TotalDonation: 0.0,
            TaxInSubTotal: 0.0
        };

        return fallbackTypes[property];
    }

    private readonly _initiateOrder = (): IOrder => {
        const register = this._data.getProperty("Settings").STID;

        return {
            AddressID: 0,
            Address: null,
            AmountDue: 0,
            BUSDate: null,
            CollectedTax: 0.0,
            Coupons: [],
            Discount: 0.0,
            Distance: 0,
            Donations: { Detail: [] },
            FutureDate: null,
            FutureMinute: -1,
            Fundraiser: null,
            FundraiserID: 0,
            GiftCards: [],
            GUID: this._getNewOrderGUID(globalThis.aOLO.storeInfo.StoreX, register.toString(16)),
            IsTaxExempt: false,
            Items: [],
            ItemsCoupons: [],
            ItemsMakeList: [],
            Key: this._getNewOrderKey(register),
            OrderID: 0,
            OrderNo: 0,
            OrderTypeCharge: 0.0,
            OrderTypeSubID: 7, // online
            OrderTypeID: 0,
            OrderStartTime: new Date(),
            OrderEndTime: null,
            OrderTypeSubType: {
                OrderTypeId: 0,
                OrderTypeSubId: 0,
                PriceGroupId: 0,
                Alias: "",
                AskCustomerInfo: false,
                AskCustomerAddress: false,
                DefaultPay: 0,
                EndMinute: 0,
                FutureOrders: false,
                MinAmount: 0,
                Names: [],
                OrderTypeCharge: 0,
                OrderTypeChargeTaxId: 0,
                PausedUntil: null,
                Rate: 0,
                StartMinute: 0,
                TaxTypeId: 0,
                TaxNames: [],
                WaitTime: 0
            },
            Payments: [],
            PrintTime: null,
            PromiseTime: null,
            ServiceCharges: {
                Amount: 0.00,
                Detail: [],
                Tax: 0.0
            },
            StoreID: globalThis.aOLO.storeInfo.StoreID,
            SubTotal: 0.0,
            Tax: 0.0,
            Taxes: [],
            TaxesDetail: [],
            Tip: 0.0,
            Total: 0.0,
            TotalDonation: 0.0,
            TaxInSubTotal: 0.0
        };
    }

    private readonly _getNewOrderGUID = (storeX: string, registerX: string): string => {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        let s8 = (storeX + S4() + S4()).substring(0, 8);
        let r4 = (registerX + S4()).substring(0, 4);
        return (s8 + "-" + S4() + "-4" + S4().substring(0, 3) + "-" + r4 + "-" + S4() + S4() + S4()).toLowerCase();
    }

    private readonly _getNewOrderKey = (register: number): string => {
        try {
            let rnd = "7";
            rnd = rnd + Math.random().toString().replace(".", "");
            rnd = Util.Right(rnd, 1, "6");
            return Util.Right(register.toString().trim(), 7, "0") + rnd + Util.Right((parseInt((new Date().getTime() / 10).toString()).toString()), 11, "0");
        } catch (ex: unknown) {
            if (ex instanceof Error) {
                //Util.LogError("GetNewOrderKey", ex, this._aOLO);
            }
        }
        return "";
    }

    public readonly createBaseOrderItem = (): IOrderItem => {
        const oItem: IOrderItem = {
            ItemId: 0,
            Name: "",
            Index: 0,
            SizeId: 0,
            SizeNames: "",
            Quantity: 1,
            Modifiers: [],
            Price: 0.0,
            MinPrice: 0.0,
            HalfHalfPrice: 0.0,
            MenuPrice: 0.0,
            MenuPriceTax: 0.0,
            Tax: 0.0,
            PriceIncludesTax: false,
            Taxes: [],
            TaxAmount: 0.0,
            BeforeTaxDiscount: 0,
            AfterTaxDiscount: 0,
            LoyaltyDiscountsQTY: 0,
            NonLoyaltyDiscountsQTY: 0,
            LoyaltyDiscountsAmount: 0,
            DiscountedMarkedQuantity: 0,
            DiscountedQuantity: 0,
            ItemWebCategoryId: 0,
            ItemCategoryId: 0,
            ItemKey: -1,
            ItemRecId: 0,
            HalfCount: 1,
            HalfIndex: 0,
            Edit: false,
            Comment: "",
            IsBuildYourOwn: false,
            IsRedeemed: false
        };
        return oItem;
    }

    public getNextItemKey(): number {
        return this._order.Items.reduce((maxIndex: number, oItem: IOrderItem) => {
            return Math.max(maxIndex, oItem.ItemKey);
        }, 0) + 1;
    }

    public getItemRecordId(itemRecIds?: number[]): number {
        const items = this._order.Items.map(x => x.ItemRecId);
        if (itemRecIds)
            items.push(...itemRecIds);

        const maxRecId = items.reduce((max: number, itemRecId: number) => {
            if (itemRecId > max)
                return itemRecId;
            return max;
        }, 99);
        return maxRecId + 1;
    }

    public calculateTotals(): void {
        this._order.SubTotal = 0.0;
        this._order.Discount = 0.0;
        this._order.Tax = 0.0;

        this._order.TaxInSubTotal = this.fGetTaxInSubTotal();
        this._order.SubTotal = this.fGetSubTotal();

        for (const coupon of this._order.Coupons)
            this._order.Discount += coupon.Amount;

        this.fSetServiceCharges();

        for (const tax of this._order.Taxes)
            this._order.Tax += tax.Amount;

        this._order.CollectedTax = Util.Float2(this._order.Tax);

        this._order.Total = this.calculateOrderTotal();
        this._order.AmountDue = this._order.Total;

        this.CalculateGiftcardsInTotals();
    }

    public CalculateGiftcardsInTotals(): void {
        if (!globalThis.aOLO.Temp.GiftCards)
            return;

        let orderAmountDue = Util.Float2(this._order.AmountDue);
        for (const giftCard of globalThis.aOLO.Temp.GiftCards) {
            const gCard = giftCard;
            if (gCard.balance > 0) {
                if (gCard.balance > orderAmountDue)
                    gCard.appliedAmount = orderAmountDue;
                else
                    gCard.appliedAmount = Util.Float2(gCard.balance);
                orderAmountDue -= Util.Float2(gCard.appliedAmount);
            }
        }
        this._order.AmountDue = Util.Float2(orderAmountDue);
    }

    public calculateOrderTotal(): number {
        return Util.Float2(this._order.SubTotal)
            - Util.Float2(this._order.Discount)
            + Util.Float2(this._order.OrderTypeCharge)
            + Util.Float2(this._order.CollectedTax)
            + Util.Float2(this._order.Tip)
            + Util.Float2(this._order.ServiceCharges.Amount)
            + Util.Float2(this._order.TotalDonation)
            - Util.Float2(this._order.TaxInSubTotal);
    }

    public fGetTaxInSubTotal(): number {
        let subTotalTaxInc = 0;
        const items = this._order.Items.filter(x => x.PriceIncludesTax);

        for (const oItem of items)
            subTotalTaxInc += Util.Float2(oItem.Tax);

        return Util.Float2(subTotalTaxInc);
    }

    public fGetSubTotal(): number {
        let subTotal = 0;
        for (const oItem of this._order.Items)
            subTotal += Util.Float2(oItem.Price * oItem.Quantity);

        return Util.Float2(subTotal);
    }

    public fSetServiceCharges(): void {
        // Initialize and clear old values 
        this._order.ServiceCharges.Tax = 0;
        this._order.ServiceCharges.Amount = 0;
        this._order.ServiceCharges.Detail = [];

        const serviceCharges = this._data.getProperty("ServiceCharges") || [];

        if (serviceCharges.length === 0)
            return;

        const orderServiceCharges: IServiceChargeDetail = {
            Tax: 0,
            Amount: 0,
            Detail: []
        };

        for (const sc of serviceCharges) {
            const amount = this.GetServiceChargeAmount(sc) || 0;
            if (amount <= 0)
                continue;

            const tax = (sc.TaxId > 0 && sc.TaxRate) ? Util.Float5(amount * sc.TaxRate / 100) : 0;

            const serChrg: IOrderServiceChargeDetail = {
                ID: sc.ServiceChargeId,
                Name: Common.GetName(sc.Names, globalThis.aOLO.Temp.languageCode),
                Names: sc.Names,
                Amount: amount,
                Overwritten: false,
                Tax: tax,
                TaxID: sc.TaxId,
                TaxTID: sc.TaxTypeId,
                TaxNAM: sc.TaxName
            };

            orderServiceCharges.Amount += amount;
            orderServiceCharges.Tax += tax;
            orderServiceCharges.Detail.push(serChrg);
        }

        let totalTax = 0;
        let totalCharge = 0;

        for (const serviceCharge of orderServiceCharges.Detail) {
            const sch = serviceCharge;
            const oSch = this.GetExistingServiceCharge(sch.ID);
            if (oSch) {
                if (!oSch.Overwritten) {
                    totalCharge += sch.Amount;
                    totalTax += sch.Tax;
                    oSch.Amount = sch.Amount;
                    oSch.Tax = sch.Tax
                } else {
                    totalCharge += oSch.Amount;
                    totalTax += oSch.Tax;
                }
            } else {
                totalCharge += sch.Amount;
                totalTax += sch.Tax;
                this._order.ServiceCharges.Detail.push(sch);
            }
        }

        this._order.ServiceCharges.Amount = totalCharge;
        this._order.ServiceCharges.Tax = totalTax;
        this.TaxOrder();
    }

    public GetServiceChargeAmount(serviceCharge: IDataServiceCharge): number | null {
        let chargeableAmount = 0;
        if (serviceCharge.IncludesSubtotal)
            chargeableAmount += this._order.SubTotal;

        if (serviceCharge.IncludesDeliveryCharge)
            chargeableAmount += this._order.OrderTypeCharge;

        if (serviceCharge.IncludesTax)
            chargeableAmount += (this.fGetOrderTypeChargeTaxes() + this.fGetOrderItemTaxes());

        if (serviceCharge.MinAmount && chargeableAmount < serviceCharge.MinAmount)
            return null;
        if (serviceCharge.MaxAmount && chargeableAmount > serviceCharge.MaxAmount)
            return null;

        const otst = serviceCharge.OrderTypeSubTypes?.find(otst => otst.OrderTypeId === this._order.OrderTypeSubType?.OrderTypeId && otst.OrderTypeSubId === this._order.OrderTypeSubType?.OrderTypeSubId);
        if (!otst)
            return null;

        let chargeAmount = serviceCharge.FixAmount + (chargeableAmount * serviceCharge.Percentage) / 100;
        if (chargeAmount < serviceCharge.MinCharge)
            chargeAmount = serviceCharge.MinCharge;

        if (chargeAmount > serviceCharge.MaxCharge)
            chargeAmount = serviceCharge.MaxCharge;

        return chargeAmount;
    }

    public fGetOrderItemTaxes(): number {
        let totalTax = 0;

        for (const item of this._order.Items) {
            if (item.IsRemoved) {
                item.Taxes = [];
            } else {
                for (const tax of item.Taxes) {
                    totalTax += Util.Float5(tax.Amount);
                }
            }
        }

        return totalTax;
    }

    public fGetOrderTypeChargeTaxes(): number {
        if (this._order.OrderTypeCharge + this._order.SubTotal - this._order.Discount <= 0 || this._order.IsTaxExempt)
            return 0;

        const orderTypeCharge = this._order.OrderTypeCharge + Math.min(0, this._order.SubTotal - this._order.Discount);

        let taxRate = Util.Float5(this._order.OrderTypeSubType.Rate);
        if (this._data.getProperty("Settings")?.ISCC) {
            const stores = this._data.getProperty("Stores") || [];
            const storeTax = OnlineOrderingUtil.GetCallCenterStoreTaxRate(this._order.OrderTypeSubType.OrderTypeChargeTaxId, this._order.StoreID, stores);
            if (storeTax)
                taxRate = storeTax.taxRate;
        }

        if (this._order.OrderTypeID === OrderType.DELIVERY && aOLO.Temp.Address) {
            const zip = globalThis.aOLO.Temp.Address?.Zip;
            if (zip) {
                const zipTaxes = this._data.getProperty("ZipTaxes") || [];
                const zipTax = OnlineOrderingUtil.GetZipTaxes(this._order.OrderTypeSubType.OrderTypeChargeTaxId, zip, zipTaxes);
                if (zipTax !== null)
                    taxRate = zipTax.Rate;
            }
        }

        return Util.Float5(orderTypeCharge * taxRate / 100);
    }

    private GetExistingServiceCharge(serviceChargeId: number): IOrderServiceChargeDetail | null {
        return this._order.ServiceCharges.Detail.find(sc => sc.ID === serviceChargeId) || null;
    }

    public GetItemByItemKey(itemKey: number): IOrderItem | null {
        return this._order.Items.find(x => x.ItemKey == itemKey) || null;
    }

    public addIncompleteDiscount = (loyaltyProvider: ILoyaltyProvider, incompleteCoupon: IIncompleteDiscount): void => {
        if (this._incompleteDiscounts.some(cpn => cpn.couponID === incompleteCoupon.couponID))
            return;

        this._incompleteDiscounts.push({
            couponID: incompleteCoupon.couponID,
            couponCode: incompleteCoupon.couponCode,
            channelID: incompleteCoupon.channelID,
            isCampaignCode: incompleteCoupon.isCampaignCode,
            isPromotionCode: incompleteCoupon.isPromotionCode,
            rewardId: incompleteCoupon.rewardId || null,
            isBankedCurrency: incompleteCoupon.isBankedCurrency
        });

        loyaltyProvider.setIncompleteReward(incompleteCoupon.rewardId || null);
    }

    public TaxOrder = (): void => {
        const orderTaxes: IOrderTax[] = [];
        let orderTaxesDetail: IOrderTax[] = [];
        for (const oItem of this._order.Items) {
            let excludeTax = false;

            for (const tax of oItem.Taxes) {
                const taxData = this._data.getProperty("Taxes").find(x => x.TaxId === tax.TaxId);
                if (taxData && taxData.OrderTypesExcluded.includes(this._order.OrderTypeID)) {
                    if (tax.Amount > 0)
                        tax.Amount = 0;
                    excludeTax = true;
                    continue;
                }

                const itemTax = oItem.PriceIncludesTax ? Util.Float5(oItem.Tax) : Util.Float5(tax.Amount);
                const orderTax = orderTaxes.find(x => x.TaxTypeId == tax.TaxTypeId);
                if (!orderTax) {
                    const oTax = {
                        DisplayNextToItem: tax.DisplayNextToItem,
                        Amount: itemTax,
                        TaxTypeId: tax.TaxTypeId,
                        TaxTypeName: tax.TaxTypeName
                    } as IOrderTax;
                    orderTaxes.push(oTax);
                } else
                    orderTax.Amount = Util.Float5(orderTax.Amount + Util.Float5(itemTax));

                // Tax Detail
                const taxDef: IDataTax | undefined = Common.GetTaxDefinitionById(tax.TaxId, this._data.getProperty("Taxes"));
                if (taxDef) {
                    taxDef.Names = typeof taxDef.Names === 'string' ? JSON.parse(taxDef.Names) : taxDef.Names;
                    const itemTaxDetail = oItem.PriceIncludesTax ? Util.Float5(oItem.Tax) : Util.Float5(tax.Amount);
                    const orderTaxDetail = orderTaxesDetail.find(x => x.TaxId == tax.TaxId);
                    if (!orderTaxDetail) {
                        const oTax = {
                            DisplayNextToItem: tax.DisplayNextToItem,
                            Amount: itemTaxDetail,
                            TaxTypeId: tax.TaxTypeId,
                            TaxId: tax.TaxId,
                            TaxTypeName: taxDef.Names
                        } as IOrderTax;
                        orderTaxesDetail.push(oTax);
                    } else
                        orderTaxDetail.Amount += Util.Float5(itemTaxDetail);
                }
            }
            if (excludeTax)
                oItem.TaxAmount = 0;
        }

        if (this._order.OrderTypeCharge > 0) {
            const orderTypeChargeTax = this._getOrderTypeChargeTax();

            const orderTax = orderTaxes.find(x => x.TaxTypeId == this._order.OrderTypeSubType.TaxTypeId);
            if (!orderTax && this._order.OrderTypeSubType.TaxTypeId) {
                const oTax = {
                    DisplayNextToItem: false,
                    Amount: orderTypeChargeTax,
                    TaxTypeId: this._order.OrderTypeSubType.TaxTypeId,
                    TaxTypeName: this._order.OrderTypeSubType.TaxNames
                } as IOrderTax;
                orderTaxes.push(oTax);
            } else if (orderTax)
                orderTax.Amount += Util.Float5(orderTypeChargeTax);

            // Tax Detail
            const orderTypeChargeTaxId = this._order.OrderTypeSubType.OrderTypeChargeTaxId;
            const taxDef: IDataTax | undefined = Common.GetTaxDefinitionById(orderTypeChargeTaxId, this._data.getProperty("Taxes"));
            if (taxDef) {
                taxDef.Names = typeof taxDef.Names === 'string' ? JSON.parse(taxDef.Names) : taxDef.Names;
                const orderTaxDetail = orderTaxesDetail.find(x => x.TaxId == orderTypeChargeTaxId);
                if (!orderTaxDetail && this._order.OrderTypeSubType.TaxTypeId) {
                    const oTax = {
                        DisplayNextToItem: false,
                        Amount: orderTypeChargeTax,
                        TaxTypeId: this._order.OrderTypeSubType.TaxTypeId,
                        TaxId: orderTypeChargeTaxId,
                        TaxTypeName: taxDef.Names
                    } as IOrderTax;
                    orderTaxesDetail.push(oTax);
                } else if (orderTaxDetail)
                    orderTaxDetail.Amount += Util.Float5(orderTypeChargeTax);
            }
        }

        if (this._order.ServiceCharges?.Detail.length > 0) {
            for (const serviceCharge of this._order.ServiceCharges.Detail) {
                if (serviceCharge.Tax <= 0)
                    continue;

                const orderTax = orderTaxes.find(x => x.TaxTypeId == serviceCharge.TaxTID);
                if (!orderTax) {
                    const oTax = {
                        DisplayNextToItem: false,
                        Amount: serviceCharge.Tax,
                        TaxTypeId: serviceCharge.TaxTID,
                        TaxTypeName: serviceCharge.Names
                    } as IOrderTax;
                    orderTaxes.push(oTax);
                } else {
                    orderTax.Amount += Util.Float5(serviceCharge.Tax);
                }

                // Tax Detail
                const orderTaxDetail = orderTaxesDetail.find(x => x.TaxId == serviceCharge.TaxID);
                if (!orderTaxDetail) {
                    const taxDef: IDataTax | undefined = Common.GetTaxDefinitionById(serviceCharge.TaxID, this._data.getProperty("Taxes"));
                    if (taxDef) {
                        taxDef.Names = typeof taxDef.Names === 'string' ? JSON.parse(taxDef.Names) : taxDef.Names;
                        const oTax = {
                            DisplayNextToItem: false,
                            Amount: serviceCharge.Tax,
                            TaxTypeId: serviceCharge.TaxTID,
                            TaxId: serviceCharge.TaxID,
                            TaxTypeName: serviceCharge.Names
                        } as IOrderTax;
                        orderTaxesDetail.push(oTax);
                    }
                } else {
                    orderTaxDetail.Amount += Util.Float5(serviceCharge.Tax);
                }
            }
        }

        // Sorting Tax Detail array by TaxTypeName
        orderTaxesDetail = orderTaxesDetail.sort((a, b) => {
            const taxNameA: string | undefined = a.TaxTypeName?.find(x => x.CULT === globalThis.aOLO.Temp.languageCode)?.NAM;
            const taxNameB: string | undefined = b.TaxTypeName?.find(x => x.CULT === globalThis.aOLO.Temp.languageCode)?.NAM;
            if (taxNameA && taxNameB)
                return taxNameA.localeCompare(taxNameB);

            if (taxNameA)
                return 1;

            if (taxNameB)
                return -1;
            return 0;
        });

        this._order.Taxes = orderTaxes;
        this._order.TaxesDetail = orderTaxesDetail;
    }

    private _getOrderTypeChargeTax = (): number => {
        const taxRate = this._getTaxRate();
        return (taxRate > 0) ? (this._order.OrderTypeCharge * taxRate * 0.01) : 0;
    }

    private _getTaxRate = (): number => {
        let taxRate = Util.Float5(this._order.OrderTypeSubType.Rate);
        if (this._data.getProperty("Settings").ISCC) {
            const storeTax = OnlineOrderingUtil.GetCallCenterStoreTaxRate(this._order.OrderTypeSubType.OrderTypeChargeTaxId, this._order.StoreID, this._data.getProperty("Stores"));
            if (storeTax)
                taxRate = storeTax.taxRate;
        }

        // 2DO: Refactor the localAolo.Temp.Address
        //if (this._order.OrderTypeID === OrderType.DELIVERY) {
        //    if (localAolo.Temp.Address) {
        //        const zipTax = OnlineOrderingUtil.GetZipTaxes(this._order.OrderTypeSubType.OrderTypeChargeTaxId, localAolo.Temp.Address.Zip, this._data.getProperty("ZipTaxes"));
        //        if (zipTax)
        //            taxRate = Util.Float5(zipTax.Rate);
        //    }
        //}
        return taxRate;
    }

    public getOrderItemsByItemKey = (itemKey: number): IOrderItem[] => {
        return this._order.Items.filter(oItem => oItem.ItemKey === itemKey);
    }

    public couponGetCoupon = (couponId: number, checkOrderTypeSubType: boolean, orderTypeId: number | null, channelId?: number | null): IDataCoupon | null => {
        return this._coupon.GetCoupon(couponId, checkOrderTypeSubType, orderTypeId, channelId || null);
    }

    public removeCouponByKeyAsync = async (loyaltyProvider: ILoyaltyProvider, couponKey: number, refresh: boolean): Promise<void> => {
        await this._coupon.RemoveCouponByKey(this._order, this._incompleteDiscounts, loyaltyProvider, couponKey, refresh);
    }

    public removeCouponByItemKeyAsync = async (loyaltyProvider: ILoyaltyProvider, itemKey: number | null, refresh: boolean = false): Promise<IRemovedCoupon[]> => {
        return await this._coupon.RemoveCouponByItemKey(this._order, this._incompleteDiscounts, loyaltyProvider, itemKey, refresh);
    }

    public removeIncompleteCouponByIdAsync = async (loyaltyProvider: ILoyaltyProvider, couponId: number): Promise<void> => {
        await this._coupon.RemoveIncompleteCouponById(this._incompleteDiscounts, loyaltyProvider, couponId);
    }

    public isCouponNotAllowedInFundraiser = (): boolean => {
        return this._coupon.IsCouponNotAllowedInFundraiser(this._order);
    }

    public checkCouponHasQualifyingItemsAsync = async (loyaltyProvider: ILoyaltyProvider, couponId: number, couponCode?: string | null, orderId?: number | null, reward?: IUserLoyaltyReward | null, channelId?: number | null, isCampaignCode?: boolean, isPromotionCode?: boolean, offer?: IUserCampaignCodesData | null): Promise<void> => {
        await this._coupon.CheckCouponHasQualifyingItems(this._order, this._incompleteDiscounts, loyaltyProvider, couponId, couponCode, orderId, reward, channelId, isCampaignCode, isPromotionCode, offer);
    }

    public autoApplyCouponsAsync = async (loyaltyProvider: ILoyaltyProvider): Promise<void> => {
        await this._coupon.AutoApplyCoupons(this._order, this._incompleteDiscounts, loyaltyProvider);
    }

    public applyCouponByIdAsync = async (params: IApplyCouponByIdParamsPartial): Promise<boolean | null> => {
        const finalParams: IApplyCouponByIdParams = {
            order: this._order,
            incompleteDiscounts: this._incompleteDiscounts,
            loyaltyProvider: params.loyaltyProvider,
            couponId: params.couponId,
            discount: params.discount,
            showErrorMsg: params.showErrorMsg,
            showApplyMsg: params.showApplyMsg,
            couponCode: params.couponCode,
            itemKey: params.itemKey,
            channelId: params.channelId,
            autoApplying: params.autoApplying,
            isCampaignCode: params.isCampaignCode,
            isPromotionCode: params.isPromotionCode,
            rewardId: params.rewardId,
            runBatch: params.runBatch,
            isBankedCurrency: params.isBankedCurrency
        };
        return await this._coupon.ApplyCouponByID(finalParams);
    }

    public checkMinimumOrderAmount = (coupon: IDataCoupon, autoApplying: boolean, isVerifying: boolean): boolean => {
        return this._coupon.checkMinimumOrderAmount(this._order, coupon, autoApplying, isVerifying);
    }

    public checkCouponOrderTypeSubType = (coupon: IDataCoupon, orderTypeId: number): boolean => {
        return this._coupon.CheckCouponOTST(coupon, orderTypeId);
    }

    public checkCouponSchedule = (coupon: IDataCoupon): boolean => {
        return this._coupon.CheckCouponSCHS(this._order, coupon);
    }

    public verifyCouponDate = (coupon: IDataCoupon): boolean => {
        return this._coupon.VerifyCouponDate(this._order, coupon);
    }

    public getCoupon = (couponId: number, checkOrderTypeSubType: boolean, orderTypeId: number | null, channelId: number | null): IDataCoupon | null => {
        return this._coupon.GetCoupon(couponId, checkOrderTypeSubType, orderTypeId, channelId);
    }
}