import { IThirdPartyAddressModel } from "./interfaces/order-type.interface";
import {
    ICheckAddress, IGetAddressList, IServiceCompleteOrder, IServiceGetCouponCodeById,
    IServiceGetCouponByCode, IServiceGetCouponById,
    IServiceGetGiftCardBalance, IServiceGetLastWaitTimes, IServiceGetOrderNumberId, IServiceGetServerTime,
    IServiceProposedOrder, IServiceSaveOrder, IServiceValidatePromotionCode, IServiceValidatePromotionCodeResult, IServiceGetCouponByCodeOnly
} from "./interfaces/online-ordering-service.interface";
import { DialogCreators } from "../utils/dialog-creators";
import { AdoraHttpClient } from "../utils/http-client";
import { Names } from "../utils/i18n";
import { Util } from "../utils/util";
import { IaOLO } from "../interfaces/aolo.interface";

export class OnlineOrderingService {
    private _apiVersion: string = '1.0';
    private _apiOnlineOrderingUrl: string = `${location.origin}/api/v${this._apiVersion}/olo/`;
    private _apiOrderUrl: string = `${location.origin}/api/v${this._apiVersion}/order/`;
    private _addressLookupUrl: string = '';

    constructor (addressLookupUrl:string) {
        this._addressLookupUrl = addressLookupUrl
    }

    /**
    * Makes an HTTP request.
    * @private
    * @async
    * @param {string} method - The HTTP method (e.g. 'GET', 'POST', 'PUT', 'DELETE').
    * @param {string} url - The URL of the HTTP resource.
    * @param {object|null} payload - The payload to send with the request.
    * @param {string|null} token - The authorization token to send with the request.
    * @returns {Promise<Response>} A promise that resolves to the HTTP response.
    */
    private _httpRequest = async (method: string, url: string, localAolo: IaOLO, payload: object | null, token: string | null = null, refreshToken: string | null = null, showProgress: boolean = true, noCache: boolean = true): Promise<Response> => {
        let response = await AdoraHttpClient.httpRequest(showProgress, method, url, payload, token, refreshToken, noCache);

        if (response.status === 500) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            DialogCreators.messageBoxOk(Names("UnexpectedError500"), localAolo.buttonHoverStyle, null, null, result.errorId);
        }

        return response;
    }

    public postJSError = async (data: string, localAolo: IaOLO): Promise<void> => {
        await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}js-error`, localAolo, { "data": data }, null, null, false);
    }

    public getServerTime = async (storeKey: string, localAolo: IaOLO): Promise<IServiceGetServerTime | null> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}get-server-date`, localAolo, { "SKY": storeKey }, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    public thirdPartyEstimate = async (data: IThirdPartyAddressModel, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}third-party-estimate`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    public validatePromotionCode = async (data: IServiceValidatePromotionCode, localAolo: IaOLO): Promise<IServiceValidatePromotionCodeResult> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}validate-promotion-code`, localAolo, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response): { success: false };
    }

    public invalidatePromotionCode = async (data: IServiceValidatePromotionCode, localAolo: IaOLO): Promise<IServiceValidatePromotionCodeResult> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}invalidate-promotion-code`, localAolo, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public getOrderNumberId = async (data: any, localAolo: IaOLO): Promise<IServiceGetOrderNumberId> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}get-order-number-id`, localAolo, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public proposedOrder = async (data: any, localAolo: IaOLO): Promise<IServiceProposedOrder> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}proposed-order`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public completeOrder = async (data: any, localAolo: IaOLO): Promise<IServiceCompleteOrder> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}complete-order`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public saveOrder = async (order: string, localAolo: IaOLO): Promise<IServiceSaveOrder> => {
        const payload = await Util.encrypt(order, localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrderUrl}save-order`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, message: "" };
    }

    public saveOrderPayment = async (order: string, localAolo: IaOLO): Promise<IServiceSaveOrder> => {
        const payload = await Util.encrypt(order, localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrderUrl}save-order-payment`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, message: "" };
    }

    public getLastWaitTimes = async (storeKey: string, localAolo: IaOLO): Promise<IServiceGetLastWaitTimes> => {
        const response = await this._httpRequest('POST', `${this._apiOrderUrl}get-last-wait-times`, localAolo, { "SKY": storeKey }, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public getGiftCardBalance = async (data: { SKY: string, CNO: string, PIN: string }, localAolo: IaOLO): Promise<IServiceGetGiftCardBalance> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}get-gift-card-balance`, localAolo, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, cardNo: "", balance: 0 };
    }

    public getCouponById = async (data: { CID: number, SKY: string }, localAolo: IaOLO): Promise<IServiceGetCouponById> => {
        const response = await this._httpRequest('POST', `${this._apiOrderUrl}get-coupon-by-id`, localAolo, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, coupon: "", serverError: true };
    }

    public getCouponByCouponCode = async (data: IServiceGetCouponByCodeOnly, localAolo: IaOLO): Promise<IServiceGetCouponCodeById> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrderUrl}get-coupon-by-code`, localAolo, payload, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, coupon: "", serverError: true };
    }

    public getCouponByPromotionCode = async (data: IServiceGetCouponByCode, localAolo: IaOLO): Promise<IServiceGetCouponById> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrderUrl}get-coupon-by-promotion-code`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, coupon: "", serverError: true };
    }

    public getCouponByCampaignCode = async (data: IServiceGetCouponByCode, localAolo: IaOLO): Promise<IServiceGetCouponById> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrderUrl}get-coupon-by-campaign-code`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, coupon: "", serverError: true };
    }

    public getAddressList = async (payload: IGetAddressList, localAolo: IaOLO): Promise<{ Addr: any[] }> => {
        const response = await this._httpRequest('POST', `${this._addressLookupUrl}/GetStreets`, localAolo, payload, null, null, false,false);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : "";
    }

    public checkAddress = async (data: ICheckAddress, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}check-address`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    public getLimitedTimes = async (data: any, localAolo: IaOLO): Promise<{ success: boolean, data: { min: number, cnt: number }[] }> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}get-limited-times`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : {success: false, data: []};
    }

    public reserveLimitedTimes = async (data: any, localAolo: IaOLO): Promise<{ success: boolean, data: { min: number, cnt: number }[] | null }> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}reserve-limited-times`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, data: null };
    }

    public resetLimitedTimeTimer = async (data: any, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}reset-limited-times-timer`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    public removeOrderLimitedTimes = async (data: any, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrderingUrl}remove-order-limited-times`, localAolo, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }
}