import './signup.css';

import { Popup } from '../popup'
import { ProfileService } from '../../../services/profile-service';
import { TwoWayBindingHelper } from '../../../utils/two-way-binding';
import { IProfile } from '../../../services/profile.interfaces';
import EventEmitter from 'eventemitter3';
import { Data } from '../../../models/data';

export class Signup extends Popup {
    private readonly _eventBus: EventEmitter;
    private readonly _data: Data;
    private readonly _profileService: ProfileService;
    private readonly _item: IProfile;
    private readonly _bindingHelper: TwoWayBindingHelper<IProfile>;

    private readonly _submit: HTMLElement | null;

    constructor(eventBus: EventEmitter, data: Data, profileService: ProfileService) {
        super("modal_signup", true);

        this._eventBus = eventBus;
        this._data = data;
        this._profileService = profileService;
        this._item = this._createModel();
        this._bindingHelper = this._bindingHelper = new TwoWayBindingHelper(this._item);
        this._submit = document.getElementById("btn_signup_sign_in");
    }

    public override init(): void {
        super.init();
        this._setEventListeners();
    }

    protected readonly _setEventListeners = (): void => {
        this._submit?.addEventListener("click", this._submitSignup);
    }

    protected readonly _setEventBusListener = (): void => {}

    private readonly _setTwoWayListeners = (): void => {
        this._bindingHelper.bindToInput("Name", "txt_signup_fname");
        this._bindingHelper.bindToInput("LastName", "txt_signup_lname");
        this._bindingHelper.bindToInput("Phone", "txt_signup_phone");
        this._bindingHelper.bindToInput("Email", "txt_signup_email");
        this._bindingHelper.bindToInput("pwd", "txt_signup_password");
        this._bindingHelper.bindToInput("repeatPwd", "txt_signup_confirm");
        this._setTwoWayListeners();
    }

    private readonly _submitSignup = async () => {
        const success = await this._profileService.signUp(this._item);

        if(success) this.close();
    }

    //// 2DO: This smells
    private _createModel = (): IProfile => {
        const profile = {} as IProfile;
        profile.CustomerId = 0;
        profile.Name = "";
        profile.LastName = "";
        profile.CountryCodeId = 1;
        profile.MarketingCountryCodeId = 1;
        profile.Phone = "";
        profile.Email = "";
        profile.pwd = "";
        profile.repeatPwd = "";
        profile.DefaultStoreId = null;
        profile.DefaultStoreKey = "";

        //Addresses
        //profile.Addresses = JSON.parse(JSON.stringify(this._profileService?.user.getProperty("Addresses") || []))        //creating a deep copy of User addresses array...
        //    .sort((x: any, y: IUserAddress) => (+ y.IPRM - x.IPRM))      //... and then sorting it so that the default address is listed first
        //    .map((x: IUserAddress) => {                                  //... and then transform original wrong-named address array object to correctly-named
        //        return {
        //            AID: x.AID,
        //            STRNO: x.STRNO,
        //            ADDR1: x.ADDR1,
        //            ADDR2: x.ADDR2,
        //            ADDR3: x.ADDR3,
        //            ADDR4: x.ADDR4,
        //            ADDR5: x.ADDR5,
        //            XSTR: x.XSTR,
        //            CITY: x.CITY,
        //            STA: x.STA,
        //            ZIP: x.ZIP,
        //            CID: x.CID,
        //            LAT: x.LAT,
        //            LON: x.LON,
        //            IPRM: x.IPRM
        //        };
        //    }
        //    );

        //Dietry & Allergy
        profile.Dietary = [];
        profile.Allergies = [];

        //Loyalty & marketing comms setting
        //profile.IsMarketingText = aOLO.User.IsMarketingText || false;
        profile.BirthDate = null;
        profile.BirthMonth = 1;
        profile.BirthDay = 0;
        profile.IsMarketingEmail = false;
        profile.MarketingPhone = "";
        profile.IsMarketingSurvey = false;
        profile.IsLoyalty = false;
        profile.IsBlocked =  false;
        //profile.StoreId = this._data.getProperty("StoreInfo").storeId;
        //profile.ProfileId = aOLO.User.ProfileId;
        //profile.CultureCode = aOLO.User.CultureCode || "";
        //profile.TermsPrivacyPolicy = aOLO.User.TermsPrivacyPolicy || false;
        //profile.IsAge13 = aOLO.User.IsAge13 || false;

        //this._loyHide = profile.IsLoyalty;
        //this._signUp = false;

        //profile = this._cleanAddressesAndSetDefaultAddress(profile);

        //if (this._incompleteUser) {
        //    this._signUp = true;
        //    profile.TermsPrivacyPolicy = false;
        //    profile.IsAge13 = false;
        //}
        //}

        //this._loyOptedOut = false;
        //this.dirty = false;

        return profile;
    }

    //private _cleanAddressesAndSetDefaultAddress = (profile: IProfile): IProfile => {
    //    let duplCount = profile.Addresses.filter(x => x.IPRM === true).length;
    //    if (duplCount === 0) return;
    //    let arr = profile.Addresses.filter(x => x.IPRM === true).sort((x: any, y: IProfileAddress) => (x.AID - y.AID));
    //    if (!arr) return profile;
    //    for (let i = 0; i < arr.length; i++)
    //        arr[i].IPRM = false;
    //    arr[0].IPRM = true;
    //    return profile;
    //}
}