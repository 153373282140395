import './menu-item-view-all-card.css';

import { IDataItem } from '../../../models/data.interface';
import { Common } from '../../../common';
import { Util } from '../../../utils/util';
import { Names } from '../../../utils/i18n';

export class MenuItemViewAllCard {
    private readonly _item: IDataItem;
    private readonly _element: HTMLElement;

    constructor(item: IDataItem, languageCode: string, func?: Function) {
        this._item = item;
        this._element = this._createElement(languageCode);
        this._addClickHandler(func);
    }

    private _createElement(languageCode: string): HTMLElement {
        const name = Common.GetName(this._item.Names, languageCode);
        const description = Common.GetDescription(this._item.Descriptions, languageCode);

        const html = `
            <div id="div_menu_item_view_all_${this._item.ItemId}" class="menu-item-view-all-card" data-id="${this._item.ItemId}">
                <div class="menu-item-view-all-card__image-container">
                    ${(this._item.ImageURL && this._item.ImageURL != "") ? `<img class="menu-item-view-all-card__image" src="${this._item.ImageURL}" alt="${name}">` : ""}
                </div>
                <div class="menu-item-view-all-card__label-container">
                    <span class="menu-item-view-all-card__label" ltagj="${Util.toLtagj(this._item.Names)}">${name}</span>
                    <span class="menu-item-view-all-card__label-desc" ltagj="${Util.toLtagj(this._item.Descriptions)}">${description}</span>
                </div>
                 <div class="menu-item-view-all-card__button-container">
                    <button id="btn_menu_item_view_all_${this._item.ItemId}" ltag="Customize">${Names("Customize")}</button>
                </div>
            </div>`;

        return Util.createHtmlElementFromTemplate(html);
    }

    public get element(): HTMLElement {
        return this._element;
    }

    private _addClickHandler(func?: Function): void {
        if (!func)
            return;

        const button = this._element.querySelector(`btn_menu_item_view_all_${this._item.ItemId}`);
        if (button)
            Util.setElement("onclick", (button as HTMLElement), func);
    }
}