import { Dimmer } from "./dimmer"

export abstract class Popup {
    protected readonly _elementId: string;
    protected _element: HTMLElement | null = null;
    private readonly _hasCloseButton: boolean;
    private readonly _hasCloseOnBlur: boolean;
    private _dimmer: Dimmer | null;

    constructor(elementId: string, hasCloseButton?: boolean, hasCloseOnBlur?: boolean) {
        this._elementId = elementId;
        this._hasCloseButton = !!hasCloseButton;
        this._hasCloseOnBlur = !!hasCloseOnBlur;
        this._dimmer = null;
    }

    public init(): void {
        this._element = document.getElementById(this._elementId)

        const elementDelete = document.getElementById(this._elementId + "_delete");
        
        if (this._hasCloseButton) {
            elementDelete?.addEventListener("click", () => this.close())
        } else if(elementDelete) {
            elementDelete.style.display = "none"
        }

        this._dimmer = new Dimmer(this._elementId);
    }

    public open(): void {
        document.getElementById(this._elementId)?.classList.toggle("open", true);
        this?._dimmer?.on();

        if (this._hasCloseOnBlur) {
            document.body.addEventListener("click", this._onBlur);
        }
    }

    public close(): void {
        document.getElementById(this._elementId)?.classList.toggle("open", false);
        this._dimmer?.off();

        if (this._hasCloseOnBlur) {
            document.body.removeEventListener("click", this._onBlur);
        }
    }

    protected abstract _setEventListeners(): void;

    private _onBlur = (): void => {
        this.close();
    }
}