import './customize-item.css';

import EventEmitter from "eventemitter3";
import { Util } from '../../../utils/util';
import { DialogCreators } from '../../../utils/dialog-creators';
import { Names } from '../../../utils/i18n';
import { Common } from '../../../common';
import { CalorieDisplay, CouponIdList, HalfModifierSelection } from '../../../types/enums';
import { Popup } from '../popup';
import { IDataInstruction, IDataItem, IDataModifier, IDataModifierDisplayGroup, IDataSize, IItemModifierDisplayGroupModifier, IItemOrderTypeSize, IMenuItemPrice } from '../../../models/data.interface';
import { Order } from '../../../models/order';
import { Data } from '../../../models/data';
import { ICustomizeItem, ICustomizeItemBaseItem, ICustomizeItemEditItemModifier, ICustomizeItemModifier, ICustomizeItemMultiSelectCategories, ICustomizeItemSpecialInstructionTemp } from './customize-item.interface';
import { IOrderItem, IOrderItemModifier } from '../../../models/order.interface';
import { OnlineOrderingUtil } from '../../../online-ordering/online-ordering-util';
import { SegmentedButton } from '../../shared/SegmentedButton/segmented-button';
import { ISegmentedButtonData, ISegmentedButtonDataButton } from '../../shared/SegmentedButton/segmented-button.interface';
import { ITabBarData } from '../../shared/TabBar/tab-bar.interface';
import { TabBar } from '../../shared/TabBar/tab-bar';
import { IName } from '../../../interfaces/global.interfaces';
import { IGetItemTaxDependencies, IGetItemTaxoItem } from '../../../online-ordering/interfaces/online-ordering.interface';
import { IApplyCouponByIdParamsPartial, IRemovedCoupon } from '../../../online-ordering/interfaces/coupons.interface';
import { EventTypes } from '../../../core/event-bus';
import { DataLayerService } from '../../../services/data-layer-service';
import { ILoyaltyProvider } from '../../../services/loyalty-provider/loyalty-provider.interface';

// TODO:
//  - TEST: Code editing an existing item
//  - Test Items (Copy Cheese for 1 free Modifier; Everest small for Gluten free extra charge)
//  - Test Maximum and Minimum Modifier Scenarios

export class CustomizeItem extends Popup {
    private readonly _eventBus: EventEmitter;
    private readonly _data: Data;
    private readonly _order: Order;
    private readonly _dataLayer: DataLayerService;
    private readonly _loyaltyProvider: ILoyaltyProvider;
    private _item: ICustomizeItem;
    private readonly _mItem: IDataItem;

    constructor(eventBus: EventEmitter, data: Data, order: Order, dataLayer: DataLayerService, loyaltyProvider: ILoyaltyProvider, mItem: IDataItem, sizeId: number, quantity: number, itemsToEdit: IOrderItem[] = []) {
        super("modal_customize_item", true);
        this._eventBus = eventBus;
        this._data = data;
        this._order = order;
        this._dataLayer = dataLayer;
        this._loyaltyProvider = loyaltyProvider;
        this._mItem = mItem;
        this._item = this._createModel(sizeId, quantity, itemsToEdit);
    }

    /**
    * Creates the customization model for the item.
    * @param sizeId - The size ID of the item.
    * @param quantity - The quantity of the item.
    * @param itemsToEdit - The list of items to edit.
    * @returns {ICustomizeItem} The customized item model.
    */
    private readonly _createModel = (sizeId: number, quantity: number, itemsToEdit: IOrderItem[]): ICustomizeItem => {
        return {
            sizeId: sizeId,
            quantity: Math.max(quantity, 1),
            itemsToEdit: itemsToEdit,
            defaultMultiModifierWebCategoryId: null,
            baseItem: this._createBaseItem(),
            baseItem2: this._createBaseItem(),
            modifiers: [],
            instructions: [],
            comments: ""
        };
    }

    /**
     * Initializes the CustomizeItem class and sets up event listeners.
     */
    override init(): void {
        super.init();
        this._renderPage();
        this._setEventListeners();
    }

    /**
    * Retrieves the first base item with modifiers for the whole and left half.
    * @returns {ICustomizeItemBaseItem} The first base item with its modifiers.
    */
    get firstItem(): ICustomizeItemBaseItem {
        const modifiers = this._getModifiersByHalf(HalfModifierSelection.LEFT);
        this._item.baseItem.Modifiers = modifiers;

        return this._item.baseItem;
    }

    /**
     * Retrieves the second base item with modifiers for the whole and right half.
     * @returns {ICustomizeItemBaseItem} The second base item with its modifiers.
     */
    get secondItem(): ICustomizeItemBaseItem {
        const modifiers = this._getModifiersByHalf(HalfModifierSelection.RIGHT);
        this._item.baseItem2.Modifiers = modifiers;

        return this._item.baseItem2;
    }

    /**
     * Closes the customization modal and removes associated event listeners.
     */
    override close(): void {
        super.close();
        document.getElementById("btn_customize_item_quantity_subtract")?.removeEventListener("click", this._subtractQuantityOnClick);
        document.getElementById("btn_customize_item_quantity_add")?.removeEventListener("click", this._addQuantityOnClick);
        document.getElementById("btn_customize_item_add_to_order")?.removeEventListener("click", this._addToOrderOnClickAsync);
        Util.setElement("innerHTML", "div_customize_item_main_body", "");
    }

    /**
     * Creates a modifier and adds it to the item's modifiers list if it doesn't already exist.
     * @param modifierId - The unique ID of the modifier.
     * @param modifierDisplayGroupId - The display group ID associated with the modifier.
     * @param halfId - The half of the item the modifier is associated with.
     * @param premodifierId - The premodifier ID, if applicable.
     * @param isSelected - Whether the modifier is selected.
     * @param isDefault - Whether the modifier is a default selection.
     * @param modifier - The modifier object to associate, if any.
     */
    private _createModifier(modifierId: number, modifierDisplayGroupId: number, halfId: HalfModifierSelection, premodifierId: number, isSelected: boolean, isDefault: boolean, modifier: IOrderItemModifier | null): void {
        const tempModifier = this._item.modifiers.find(x => x.id === modifierId);
        if (!tempModifier)
            this._item.modifiers.push({ id: modifierId, modifierDisplayGroupId, halfId, premodifierId, isSelected, isDefault, modifier });
    }

    /**
     * Updates an existing modifier with new data.
     * @param data - An object containing the modifier ID and optional properties to update.
     */
    private _updateModifier(data: { modifierId: number, halfId?: HalfModifierSelection, premodifierId?: number, isSelected?: boolean, isDefault?: boolean, modifier?: IOrderItemModifier | null }): void {
        const tempModifier = this._item.modifiers.find(x => x.id === data.modifierId);
        if (tempModifier) {
            if (data.isDefault != undefined)
                tempModifier.isDefault = data.isDefault;

            if (data.halfId != undefined)
                tempModifier.halfId = data.halfId;

            if (data.premodifierId != undefined)
                tempModifier.premodifierId = data.premodifierId;

            if (data.isSelected != undefined)
                tempModifier.isSelected = data.isSelected;

            if (data.modifier != undefined)
                tempModifier.modifier = data.modifier;
        }
    }

    /**
     * Retrieves a modifier by its ID.
     * @param modifierId - The unique ID of the modifier to retrieve.
     * @returns {ICustomizeItemModifier | null} The modifier object if found, otherwise null.
     */
    private _getModifier(modifierId: number): ICustomizeItemModifier | null {
        return this._item.modifiers.find(x => x.id === modifierId) || null;
    }

    /**
     * Retrieves modifiers for the specified half of the item, combining them with whole modifiers.
     * @param halfId - The half (LEFT, RIGHT, or WHOLE) to retrieve modifiers for.
     * @returns {IOrderItemModifier[]} An array of modifiers applicable to the specified half.
     */
    private _getModifiersByHalf(halfId: HalfModifierSelection): IOrderItemModifier[] {
        const wholeModifiers = this._item.modifiers
            .filter(x => x.halfId === HalfModifierSelection.WHOLE && (x.isSelected || x.isDefault) && x.modifier != null)
            .map(x => x.modifier) as IOrderItemModifier[];

        if (halfId === HalfModifierSelection.LEFT) {
            const leftHalfModifiers = this._item.modifiers
                .filter(x => x.halfId === HalfModifierSelection.LEFT && (x.isSelected || x.isDefault) && x.modifier != null)
                .map(x => x.modifier) as IOrderItemModifier[];
            return leftHalfModifiers.concat(wholeModifiers);
        } else if (halfId === HalfModifierSelection.RIGHT) {
            const rightHalfModifiers = this._item.modifiers
                .filter(x => x.halfId === HalfModifierSelection.RIGHT && (x.isSelected || x.isDefault) && x.modifier != null)
                .map(x => x.modifier) as IOrderItemModifier[];
            return rightHalfModifiers.concat(wholeModifiers);
        } else
            return wholeModifiers;
    }

    /**
     * Sets up event listeners for quantity adjustment and adding the item to the order.
     */
    protected readonly _setEventListeners = (): void => {
        document.getElementById("btn_customize_item_quantity_subtract")?.addEventListener("click", this._subtractQuantityOnClick);
        document.getElementById("btn_customize_item_quantity_add")?.addEventListener("click", this._addQuantityOnClick);
        document.getElementById("btn_customize_item_add_to_order")?.addEventListener("click", this._addToOrderOnClickAsync);
    }

    /**
     * Renders the customization page for the item.
     * Updates UI elements and prepares item customization options.
     */
    private readonly _renderPage = (): void => {
        // Handle item image display
        if (this._mItem.ImageURL) {
            Util.setElement("src", "img_customize_item_image", this._mItem.ImageURL);
            Util.showElement("div_customize_item_image");
        } else {
            Util.hideElement("div_customize_item_image");
        }

        // Update item name and description
        Util.setElement("innerHTML", "h1_customize_item_name", Common.GetName(this._mItem.Names, globalThis.aOLO.Temp.languageCode));
        Util.setElement("innerHTML", "p_customize_item_description", Common.GetDescription(this._mItem.Descriptions, globalThis.aOLO.Temp.languageCode));

        // Clear and update main div content
        const mainDiv = document.getElementById("div_customize_item_main_body");
        if (!mainDiv)
            return;

        mainDiv.innerHTML = "";

        // 2DO: If screen is opened via meal deal
        //Util.setElement("disabled", "ddl_customize_item_item1_quantity", !!this._aOLO.Dialog.MealDeal);
        //Util.setElement("disabled", "ddl_customize_item_whole_quantity", !!this._aOLO.Dialog.MealDeal);
        this._scrollToTop();

        // Set item properties if editing
        if (this._isEditing()) {
            const itemToEdit = this._item.itemsToEdit[0];
            this._item.quantity = itemToEdit.Quantity;
            this._item.sizeId = itemToEdit.SizeId;
        }

        // Update UI and functionality
        this._createItemSelections();
        this._updateAllCalories();
        this._generateSpecialInstructionsSection();
        this._updateAddToOrderButtonText(Names("AddToOrder"));
        this.toggleItemCommentBoxVisibility();
        this._priceSelectedItem();

        this._scrollToTop();

        // Log view item if DataLayer is available
        if (this._dataLayer)
            this._dataLayer.view_item(this._createOrderItem(this.firstItem));
    }

    /**
     * Scrolls the customization content area to the top.
     */
    private readonly _scrollToTop = (): void => {
        const div = document.getElementById("div_customize_item_content");
        if (div)
            div.scrollTop = 0;
    }

    /**
     * Checks if the item is a half-and-half selection.
     * @returns {boolean} True if the item is a half-and-half selection, false otherwise.
     */
    private readonly _isHalfHalf = (): boolean => {
        return this._item.modifiers.some(x => x.isSelected && x.halfId !== HalfModifierSelection.WHOLE);
    }

    /**
     * Determines whether the current operation is editing an existing item.
     * @returns {boolean} True if editing, false otherwise.
     */
    private readonly _isEditing = (): boolean => {
        return this._item.itemsToEdit.length > 0;
    }

    /**
     * Retrieves the current items for customization.
     * @returns {ICustomizeItemBaseItem[]} Array of items for customization.
     */
    private readonly _getItems = (): ICustomizeItemBaseItem[] => {
        return this._isHalfHalf() ? [this.firstItem, this.secondItem] : [this.firstItem];
    }

    /**
     * Retrieves modifier information for an item being edited.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @returns {ICustomizeItemEditItemModifier | null} The modifier information or null if not found.
     */
    private readonly _getEditItemModifierInfo = (modifierDisplayGroupId: number, modifierId: number): ICustomizeItemEditItemModifier | null => {
        const [firstItem, secondItem] = this._item.itemsToEdit;
        const firstHalfModifier = firstItem.Modifiers.find(x => x.ModifierDisplayGroupId === modifierDisplayGroupId && x.ModifierId === modifierId);
        const secondHalfModifier = secondItem.Modifiers.find(x => x.ModifierDisplayGroupId === modifierDisplayGroupId && x.ModifierId === modifierId);

        if (!firstHalfModifier && !secondHalfModifier)
            return null;

        let premodifierId = this._data.getProperty("DefaultPreModifierId");
        let halfId = HalfModifierSelection.WHOLE;

        if (this._item.itemsToEdit.length == 2) {
            if (firstHalfModifier && !secondHalfModifier) {
                halfId = HalfModifierSelection.LEFT;
                premodifierId = firstHalfModifier.PreModifierId;
            } else if (!firstHalfModifier && secondHalfModifier) {
                halfId = HalfModifierSelection.RIGHT;
                premodifierId = secondHalfModifier.PreModifierId;
            } else if (firstHalfModifier) {
                premodifierId = firstHalfModifier.PreModifierId;
            }
        }

        return {
            id: modifierId,
            modifierDisplayGroupId: modifierDisplayGroupId,
            halfId: halfId,
            premodifierId: premodifierId
        };
    }

    /**
     * Creates an order item from the provided customization item.
     * @param {ICustomizeItemBaseItem} item - The customization base item.
     * @returns {IOrderItem} The order item with updated properties.
     */
    private readonly _createOrderItem = (item: ICustomizeItemBaseItem): IOrderItem => {
        const sizes = this._data.getProperty("Sizes");
        const sizeNames = JSON.stringify(sizes.find(s => s.SizeId === this._item.sizeId)?.Names || []);

        const oItem = this._order.createBaseOrderItem();
        oItem.ItemId = this._mItem.ItemId;
        oItem.Name = JSON.stringify(this._mItem.Names);
        oItem.Index = this._mItem.Index;
        oItem.SizeId = this._item.sizeId;
        oItem.SizeNames = sizeNames;
        oItem.Quantity = this._item.quantity;
        oItem.ItemWebCategoryId = this._mItem.WebCategoryId;
        oItem.ItemCategoryId = this._mItem.ItemCategoryId;
        oItem.IsBuildYourOwn = this._mItem.IsBuildYourOwn;
        oItem.Modifiers = item.Modifiers;
        oItem.PriceIncludesTax = item.PriceIncludesTax;
        oItem.Price = item.Price;
        oItem.MinPrice = item.MinPrice;
        oItem.HalfHalfPrice = item.HalfHalfPrice;
        oItem.MenuPrice = item.MenuPrice;
        oItem.MenuPriceTax = item.MenuPriceTax;
        oItem.Tax = item.Tax;
        oItem.TaxAmount = item.TaxAmount;
        oItem.Taxes = item.Taxes;
        oItem.AfterTaxDiscount = item.AfterTaxDiscount;
        oItem.BeforeTaxDiscount = item.BeforeTaxDiscount;
        oItem.ItemWebCategoryId = this._mItem.WebCategoryId;
        oItem.ItemCategoryId = this._mItem.ItemCategoryId;
        oItem.IsBuildYourOwn = this._mItem.IsBuildYourOwn;

        return oItem;
    }

    /**
     * Creates a base customization item with default values.
     * @returns {ICustomizeItemBaseItem} The base customization item.
     */
    private readonly _createBaseItem = (): ICustomizeItemBaseItem => {
        return {
            Modifiers: [],
            PriceIncludesTax: false,
            Price: 0.0,
            MinPrice: 0.0,
            HalfHalfPrice: 0.0,
            MenuPrice: 0.0,
            MenuPriceTax: 0.0,
            Tax: 0.0,
            TaxAmount: 0.0,
            Taxes: [],
            AfterTaxDiscount: 0,
            BeforeTaxDiscount: 0
        };
    }

    /**
     * Updates the text of the "Add to Order" button.
     * @param {string} buttonText - The text to display on the button.
     */
    private readonly _updateAddToOrderButtonText = (buttonText: string): void => {
        Util.setElement("innerText", "spn_customize_item_add_to_order", buttonText);
    }

    /**
     * Generates and displays the "Special Instructions" section in the customization UI.
     */
    private readonly _generateSpecialInstructionsSection = (): void => {
        Util.setElement("innerHTML", "div_customize_item_special_instructions", "");

        const instructions = this._data.getProperty("Instructions");
        if (instructions.length === 0)
            return;

        const parentDiv = document.getElementById("div_customize_item_special_instructions");
        if (!parentDiv)
            return;

        // Group instructions by `SpecialInstructionsId`
        const specialInstructions = this._groupInstructionsBySpecialInstructionId(instructions);

        // Create and append the header
        const header = this._createSectionHeader(Names("SpecialInstructions"), false, false);
        parentDiv.appendChild(header);

        // Create a wrapper for the instructions body
        const body = document.createElement("div");
        body.classList.add("customize-item-modifier-wrapper");
        parentDiv.appendChild(body);

        // Sort special instructions by ID, with ID 0 at the end
        this._sortSpecialInstructions(specialInstructions);

        // Generate and append special instruction groups
        for (const specialInstruction of specialInstructions) {
            const isRelevant = specialInstruction.instructions.some(instruction =>
                instruction.Items.some(x => x.ItemWebCategoryId === this._mItem.WebCategoryId && x.SizeId === this._item.sizeId)
            );
            if (!isRelevant)
                continue;

            const instructionGroup = this._generateInstructionGroup(specialInstruction);
            body.appendChild(instructionGroup);
        }
    }

    /**
     * Groups instructions by their `SpecialInstructionsId`.
     * @param instructions Array of instructions to group.
     * @returns An array of grouped instructions.
     */
    private readonly _groupInstructionsBySpecialInstructionId = (instructions: IDataInstruction[]): ICustomizeItemSpecialInstructionTemp[] => {
        const groupedInstructions: ICustomizeItemSpecialInstructionTemp[] = [];

        for (const instruction of instructions) {
            const group = groupedInstructions.find(x => x.id === instruction.SpecialInstructionsId);
            if (group) {
                group.instructions.push(instruction);
            } else {
                groupedInstructions.push({
                    id: instruction.SpecialInstructionsId,
                    name: instruction.SpecialInstructionsNames,
                    isMultiSelect: instruction.SpecialInstructionsId === 0 ? true : instruction.IsMultiSelect,
                    instructions: [instruction],
                });
            }
        }

        return groupedInstructions;
    }

    /**
     * Sorts special instructions by ID, placing ID 0 at the end.
     * @param specialInstructions Array of special instructions to sort.
     */
    private readonly _sortSpecialInstructions = (specialInstructions: ICustomizeItemSpecialInstructionTemp[]): void => {
        specialInstructions.sort((a, b) => {
            if (a.id === 0) return 1;
            if (b.id === 0) return -1;
            return a.id - b.id;
        });
    };

    /**
     * Generates a single instruction group.
     * @param specialInstruction The special instruction group data.
     * @returns The generated HTML element for the group.
     */
    private readonly _generateInstructionGroup = (specialInstruction: ICustomizeItemSpecialInstructionTemp): HTMLElement => {
        const groupDiv = document.createElement("div");
        groupDiv.classList.add("customize-item-instruction");
        groupDiv.setAttribute("aria-labelledby", `h3_customize_item_special_instruction_${specialInstruction.id}`);
        groupDiv.role = "group";

        // Create group header
        const headerTitle = specialInstruction.id === 0 ? Names("Other") : Common.GetName(specialInstruction.name, globalThis.aOLO.Temp.languageCode);
        const chooseText = specialInstruction.isMultiSelect ? Names("ChooseMany") : Names("ChooseOne");
        const header = `
            <div class="customize-item-instruction-group-header">
                <h3 id="h3_customize_item_special_instruction_${specialInstruction.id}">${headerTitle}</h3>
                <span>${chooseText}</span>
            </div>`;
        groupDiv.appendChild(Util.createHtmlElementFromTemplate(header));

        // Generate and append instruction options
        const instructionContainer = document.createElement("div");
        instructionContainer.classList.add("customize-item-instruction-group");

        for (const instruction of specialInstruction.instructions) {
            const isValidInstruction = instruction.Items.some(x => x.ItemWebCategoryId === this._mItem.WebCategoryId && x.SizeId === this._item.sizeId);
            if (!isValidInstruction)
                continue;

            const name = Common.GetNewName(instruction.Names, globalThis.aOLO.Temp.languageCode);
            const option = this._createInstructionOption(specialInstruction.id, instruction.CommentId, name, specialInstruction.isMultiSelect);
            instructionContainer.appendChild(option);
        }

        groupDiv.appendChild(instructionContainer);
        return groupDiv;
    }

    /**
     * Creates an option for a special instruction.
     * @param specialInstructionId The ID of the special instruction.
     * @param instructionId The ID of the instruction.
     * @param name The name of the instruction.
     * @param isMultiSelect Whether the instruction allows multiple selections.
     * @returns The generated HTML element for the instruction option.
     */
    private readonly _createInstructionOption = (specialInstructionId: number, instructionId: number, name: string, isMultiSelect: boolean, isSelected?: boolean): HTMLElement => {
        const html = `
            <label id="lbl_customize_item_instruction_${specialInstructionId}_${instructionId}" class="customize-item-modifier" for="inp_customize_item_instruction_${specialInstructionId}_${instructionId}">
                <div class="customize-item-modifier-label">
                    <div class="customize-item-modifier-label-info">
                        <span>${name}</span>
                    </div>
                </div>
                <div>
                    <div class="custom-radio">
                        <input type="${isMultiSelect ? "checkbox" : "radio"}" id="inp_customize_item_instruction_${specialInstructionId}_${instructionId}" name="inp_customize_item_instruction_${specialInstructionId}" ${isSelected ? "checked" : ""}>
                        <span class="checkmark"></span>
                    </div>
                </div>
            </label>`;

        const div = Util.createHtmlElementFromTemplate(html);
        div.onclick = (event: Event) => {
            this._handleInstructionClick(event, specialInstructionId, instructionId, isMultiSelect);
        };

        const checkbox = div.querySelector(`#inp_customize_item_instruction_${specialInstructionId}_${instructionId}`) as HTMLInputElement | null;
        if (checkbox)
            checkbox.onclick = (event: Event) => { event.stopPropagation(); };

        return div;
    }

    /**
     * Handles the click event for an instruction option.
     * @param event The click event.
     * @param specialInstructionId The ID of the special instruction.
     * @param instructionId The ID of the instruction.
     * @param isMultiSelect Whether the instruction allows multiple selections.
     */
    private readonly _handleInstructionClick = (event: Event, specialInstructionId: number, instructionId: number, isMultiSelect: boolean): void => {
        const checked = !Util.getElementChecked(`inp_customize_item_instruction_${specialInstructionId}_${instructionId}`);

        if (isMultiSelect) {
            if (checked)
                this._item.instructions.push({ specialInstructionId: specialInstructionId, commentId: instructionId });
            else
                this._item.instructions = this._item.instructions.filter(x => x.specialInstructionId !== specialInstructionId);
        } else {
            this._item.instructions = this._item.instructions.filter(x => x.specialInstructionId !== specialInstructionId);

            if (!checked) {
                event.preventDefault();
                Util.setElement("checked", `inp_customize_item_instruction_${specialInstructionId}_${instructionId}`, false);
            } else
                this._item.instructions.push({ specialInstructionId: specialInstructionId, commentId: instructionId });
        }
    }

    /**
     * Initializes item selections by setting the quantity, creating the GUI,
     * displaying item prices, and updating item calorie information.
     */
    private readonly _createItemSelections = (): void => {
        this._setQuantity(this._item.quantity);
        this._createItemGui();
        this._displayItemsPrice(this.firstItem.HalfHalfPrice);
        this._updateItemCalories(this.firstItem.Modifiers, this.secondItem.Modifiers);
    }

    /**
     * Sets the item quantity and updates the corresponding UI element.
     * @param {number} quantity - The quantity to set.
     */
    private readonly _setQuantity = (quantity: number): void => {
        this._item.quantity = quantity;
        Util.setElement("innerText", "spn_customize_item_quantity", quantity);
    }

    /**
     * Displays the item's price on the UI.
     * @param {number} halfHalfPrice - The price of the half-and-half item.
     */
    private readonly _displayItemsPrice = (halfHalfPrice: number): void => {
        Util.setElement("innerText", "spn_customize_item_price", `${Util.formatMoney(halfHalfPrice)}`);
    }

    /**
     * Creates the graphical user interface for customizing the item.
     * Dynamically adds sections like item sizes, single-choice modifiers,
     * and multi-choice modifiers to the UI.
     */
    private readonly _createItemGui = (): void => {
        let allowedSizes: number[] | null = null;

        // 2DO: Upgrade this to the checkboxes
        //if (oItem.Comment)
        //    Util.setElement("value", "txt_customize_item_comment", oItem.Comment);

        // 2DO: Update this to use meal deal maybe
        //if (this._aOLO.Dialog.MealDeal) {
        //    const coupon = this._aOLO.Modules.Coupon.GetCoupon(this._aOLO.Dialog.MealDeal.GetCouponId(), true, this._order.getProperty("OrderTypeID"));
        //    if (coupon?.Items) {
        //        const group = this._aOLO.Dialog.MealDeal.GetSelectedGroup();
        //        allowedSizes = coupon.Items.filter(x => x.GroupIndex == group && x.ItemId == this._mItem.ItemId).map(x => x.SizeId);
        //    }
        //}

        const mainDiv = document.getElementById("div_customize_item_main_body");
        if (!mainDiv)
            return;

        mainDiv.innerHTML = "";

        // Setup item sizes
        const sizesSection = this._setItemSizes(this._order.getProperty("OrderTypeID"), this._item.sizeId, allowedSizes);
        if (sizesSection)
            mainDiv.appendChild(sizesSection);

        // 2DO: Some MealDeal Logic into the singleChoice Modifiers
        //    if (this._aOLO.Dialog.MealDeal) {
        //        const coupon = this._aOLO.Modules.Coupon.GetCoupon(this._aOLO.Dialog.MealDeal.GetCouponId(), true, this._order.getProperty("OrderTypeID"));
        //        if (coupon?.Items) {
        //            const selectedGroup = this._aOLO.Dialog.MealDeal.GetSelectedGroup();
        //            const allowedSizes = coupon.Items.filter(x => x.GroupIndex == selectedGroup).map(x => x.ItemId);
        //            items = items.filter(x => allowedSizes.includes(x.ItemId));
        //        }
        //    }

        // Add single-choice modifiers
        const singleChoiceModifiers = this._setSingleChoiceModifiers();
        if (singleChoiceModifiers)
            mainDiv.appendChild(singleChoiceModifiers);

        // Add multi-choice modifiers
        const multiChoiceModifiers = this._setMultiChoiceModifiers();
        if (multiChoiceModifiers)
            mainDiv.appendChild(multiChoiceModifiers);

        // Display default multi-modifier group if specified
        if (this._item.defaultMultiModifierWebCategoryId)
            document.getElementById(`btn_customize_item_toppings_${this._item.defaultMultiModifierWebCategoryId}`)?.click();

        // Set default modifiers
        this._applyDefaultModifiers();
    }

    /**
     * Applies default modifiers to the item based on the current size and configuration.
     */
    private readonly _applyDefaultModifiers = (): void => {
        const modifierDisplayGroups = this._data.getProperty("ModifierDisplayGroups");
        const defaultModifiers = OnlineOrderingUtil.Item_Get_Default_Mods(this._mItem, this._item.sizeId, modifierDisplayGroups, this._data.getProperty("DefaultPreModifierId"));

        for (const defaultModifier of defaultModifiers) {
            const modifier = this._item.modifiers.find((x) => x.id === defaultModifier.ModifierId);
            if (modifier)
                modifier.modifier = defaultModifier;
        }
    };

    /**
     * Creates a section for item sizes based on the order type and allowed sizes.
     * Filters sizes and updates the selected size ID as needed.
     * @param {number} orderTypeId - The order type ID.
     * @param {number} sizeId - The currently selected size ID.
     * @param {number[] | null} [allowedSizes] - Optional list of allowed size IDs.
     * @returns {HTMLElement | null} The HTML element for the size section, or null if no sizes are applicable.
     */
    private readonly _setItemSizes = (orderTypeId: number, sizeId: number, allowedSizes?: number[] | null): HTMLElement | null => {
        let sizes = OnlineOrderingUtil.GetItemSizes(this._mItem, orderTypeId);
        if (sizes.length === 1 && sizeId === 0)
            return null;

        if (allowedSizes) {
            const sizeIds = sizes.map(x => x.SizeId);
            const validSizes = allowedSizes.filter(x => sizeIds.includes(x));

            if (validSizes.length > 0)
                sizes = sizes.filter(x => validSizes.includes(x.SizeId));

            if (!sizes.some(x => x.SizeId === sizeId))
                this._item.sizeId = sizes[0].SizeId
        }

        const wrapperDiv = document.createElement("div");
        wrapperDiv.classList.add("customize-item-modifier-wrapper");

        const groupHasImage = false;//modifiersData.some(obj => obj.ImageUrl && obj.ImageUrl.trim() !== "");
        for (const size of sizes) {
            // Check If size is available for QR
            if (globalThis.aOLO.QrCode && !size.IsOfferedQR)
                continue;

            const mSize = this._getSizeById(size.SizeId);
            if (!mSize)
                continue;

            const sizeDescription = Common.GetDescription(mSize.Descriptions, globalThis.aOLO.Temp.languageCode);
            const sizeName = `${Common.GetName(mSize.Names, globalThis.aOLO.Temp.languageCode)} (${sizeDescription})`;

            const isSelected = (size.SizeId === sizeId);
            const option = this._createModifierOption({
                modifierDisplayGroupId: null,
                modifierId: size.SizeId,
                id: `rdo_customize_item_size_${size.SizeId}`,
                name: sizeName,
                radioId: "rdo_customize_item_size",
                imageUrl: null,
                groupHasImage,
                isSelected,
                isMultiSelect: false
            });

            const div = document.createElement("div");
            div.appendChild(option);
            wrapperDiv.appendChild(div);

            if (isSelected)
                this._item.sizeId = sizeId;
        }

        const header = this._createSectionHeader(Names("Size"), true);
        const parentDiv = document.createElement("div");
        parentDiv.classList.add("modifiers-selection-parent");
        parentDiv.appendChild(header);
        parentDiv.appendChild(wrapperDiv);

        return parentDiv;
    }

    /**
     * Creates a section header with optional instructions for choosing items.
     * @param {string} header - The header text to display.
     * @param {boolean} [isChooseOne=true] - Indicates if the section allows choosing one option.
     * @param {boolean} [isChooseMany=false] - Indicates if the section allows choosing multiple options.
     * @returns {HTMLElement} The HTML element for the section header.
     */
    private readonly _createSectionHeader = (header: string, isChooseOne: boolean = true, isChooseMany: boolean = false): HTMLElement => {
        let chooseString = "";
        if (isChooseOne)
            chooseString = Names("ChooseOne");
        else if (isChooseMany)
            chooseString = Names("ChooseMany");

        const html = `
            <div class="item-section-header">
                <h2>${header}</h2>
                <span>${chooseString}</span>
            </div>`;

        return Util.createHtmlElementFromTemplate(html);
    }

    /**
     * Combines price and calorie information into a single formatted string.
     * @param {string} price - The formatted price string.
     * @param {string | null} calories - The calorie information or null.
     * @returns {string} A combined string of price and calories, or one if the other is missing.
     */
    private readonly _getPriceAndCaloriesSpan = (price: string, calories: string | null): string => {
        if (price != "" && calories != null)
            return `${price} | ${calories}`;
        else if (price != "")
            return price;
        else if (calories != null)
            return calories;

        return "";
    }

    /**
     * Creates a modifier option element for the UI.
     * @param {Object} params - Parameters for the modifier option.
     * @param {number | null} params.modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} params.modifierId - The ID of the modifier.
     * @param {string} params.id - The HTML element ID for the modifier.
     * @param {string} params.name - The name of the modifier.
     * @param {string} params.radioId - The name attribute for the input element.
     * @param {string | null} params.imageUrl - The URL of the modifier's image.
     * @param {boolean} [params.groupHasImage=false] - Whether the modifier group includes images.
     * @param {boolean} [params.isSelected=false] - Whether the modifier is selected.
     * @param {boolean} [params.isMultiSelect=false] - Whether the modifier is a multi-select option.
     * @returns {HTMLElement} The modifier option element.
     */
    private readonly _createModifierOption = ({
        modifierDisplayGroupId,
        modifierId,
        id,
        name,
        radioId,
        imageUrl,
        groupHasImage = false,
        isSelected = false,
        isMultiSelect = false,
    }: {
        modifierDisplayGroupId: number | null;
        modifierId: number;
        id: string;
        name: string;
        radioId: string;
        imageUrl: string | null;
        groupHasImage?: boolean;
        isSelected?: boolean;
        isMultiSelect?: boolean;
    }): HTMLElement => {
        const priceCaloriesId = modifierDisplayGroupId !== null
            ? `spn_customize_item_modifier_price_cal_${modifierDisplayGroupId}_${modifierId}`
            : `spn_customize_item_modifier_size_price_cal_${modifierId}`;

        let image = "";
        if (groupHasImage) {
            image = `
                <div class="customize-item-modifier-label-image">
                    ${(imageUrl !== "" && imageUrl !== null) ? `<img src="${imageUrl}" alt="" aria-hidden="true">` : ""}
                </div>`;
        }

        const html = `
            <label id="lbl_${id}" class="customize-item-modifier" for="${id}">
                <div class="customize-item-modifier-label">
                    ${image}
                    <div class="customize-item-modifier-label-info">
                        <span>${name}</span>
                        <span id="${priceCaloriesId}" class="price"></span>
                    </div>
                </div>
                <div>
                    <div class="custom-radio">
                        <input type="${isMultiSelect ? "checkbox" : "radio"}" id="${id}" name="${radioId}" ${isSelected ? "checked" : ""}>
                        <span class="checkmark"></span>
                    </div>
                </div>
            </label>`;

        const div = Util.createHtmlElementFromTemplate(html);
        div.onclick = (event: Event) => {
            if (modifierDisplayGroupId === null)
                this._itemSizeChangeOnClick(event, modifierId);
            else
                this._modifierOnClick(id, modifierDisplayGroupId, modifierId, isMultiSelect);
        };

        const checkbox = div.querySelector(`#${id}`) as HTMLInputElement | null;
        if (checkbox)
            checkbox.onclick = (event: Event) => { event.stopPropagation(); };

        return div;
    }

    /**
     * Handles the click event for a modifier.
     * @param {string} id - The HTML element ID for the modifier.
     * @param {number | null} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @param {boolean} [isMultiSelect=false] - Whether the modifier is a multi-select option.
     */
    private readonly _modifierOnClick = (id: string, modifierDisplayGroupId: number | null, modifierId: number, isMultiSelect: boolean = false) => {
        if (isMultiSelect)
            this._hideAndShowPreModifierAndHalfableButtonsCheckbox(modifierDisplayGroupId, modifierId);
        else
            this._hideAndShowPreModifierAndHalfableButtonsRadio(modifierDisplayGroupId, modifierId);

        const checkbox = document.getElementById(id) as HTMLInputElement | null;
        if (!checkbox)
            return;

        const isChecked = !checkbox.checked;
        const preModifierId = isChecked ? this._data.getProperty("DefaultPreModifierId") : this._data.getProperty("NonePreModifierId");
        const modifier = this._getModifier(modifierId);
        const halfId = modifier ? modifier.halfId : HalfModifierSelection.WHOLE;

        if (modifierDisplayGroupId) {
            const modifierAdded = this._alterSelectedItemModifier(modifierDisplayGroupId, modifierId, preModifierId, halfId, false, false);
            if (!modifierAdded)
                checkbox.checked = !isChecked;
        }
    }

    /**
     * Toggles visibility of pre-modifier and halfable buttons for radio inputs.
     * @param {number | null} modifierDisplayGroupId - The modifier display group ID.
     * @param {number} modifierId - The modifier ID.
     */
    private readonly _hideAndShowPreModifierAndHalfableButtonsRadio = (modifierDisplayGroupId: number | null, modifierId: number): void => {
        if (!modifierDisplayGroupId)
            return;

        const premodifierButtons = Array.from(document.querySelectorAll(`div[id^="div_customize_item_modifier_premodifier_selection_${modifierDisplayGroupId}_"]`)) as HTMLDivElement[];
        for (const button of premodifierButtons) {
            Util.hideElement(button);
        }

        const halfableButtons = Array.from(document.querySelectorAll(`div[id^="div_customize_item_modifier_halfable_selection_${modifierDisplayGroupId}_"]`)) as HTMLDivElement[];
        for (const button of halfableButtons) {
            Util.hideElement(button);
        }

        Util.showElement(`div_customize_item_modifier_premodifier_selection_${modifierDisplayGroupId}_${modifierId}`);
        Util.showElement(`div_customize_item_modifier_halfable_selection_${modifierDisplayGroupId}_${modifierId}`);
    }

    /**
     * Toggles visibility of pre-modifier and halfable buttons for checkbox inputs.
     * @param {number | null} modifierDisplayGroupId - The modifier display group ID.
     * @param {number} modifierId - The modifier ID.
     */
    private readonly _hideAndShowPreModifierAndHalfableButtonsCheckbox = (modifierDisplayGroupId: number | null, modifierId: number): void => {
        if (!modifierDisplayGroupId)
            return;

        const checkboxId = `chk_customize_item_modifier_display_group_${modifierDisplayGroupId}_${modifierId}`;
        const isChecked = Util.getElementChecked(checkboxId);

        const action = isChecked ? Util.hideElement : Util.showElement;
        action(`div_customize_item_modifier_premodifier_selection_${modifierDisplayGroupId}_${modifierId}`);
        action(`div_customize_item_modifier_halfable_selection_${modifierDisplayGroupId}_${modifierId}`);
    }

    /**
     * Creates a section for single-choice modifiers based on the item configuration.
     * @returns {HTMLElement | null} The HTML element containing single-choice modifiers, or null if none are applicable.
     */
    private readonly _setSingleChoiceModifiers = (): HTMLElement | null => {
        if (!this._mItem.ModifierDisplayGroups)
            return null;

        const parentDiv = document.createElement("div");
        const modifierDisplayGroups = this._data.getProperty("ModifierDisplayGroups");

        for (const itemDisplayGroup of this._mItem.ModifierDisplayGroups) {
            const modifierDisplayGroup = modifierDisplayGroups.find(x => x.ModifierDisplayGroupId === itemDisplayGroup.ModifierDisplayGroupId);
            if (!modifierDisplayGroup?.IsVisible)
                continue;

            const isSingleChoiceGroup = modifierDisplayGroup.MaxSelectionCount === 1 || modifierDisplayGroup.MustToggle;
            if (isSingleChoiceGroup) {
                const section = this._createSingleChoiceModifierSection(modifierDisplayGroup, itemDisplayGroup.Modifiers);
                parentDiv.appendChild(section);
            }
        }

        return parentDiv;
    }

    /**
     * Creates a section for a single-choice modifier group.
     * @param {IDataModifierDisplayGroup} modifierDisplayGroup - The data for the modifier display group.
     * @param {IItemModifierDisplayGroupModifier[]} modifiers - The list of modifiers in the group.
     * @returns {HTMLElement} The HTML element for the single-choice modifier section.
     */
    private readonly _createSingleChoiceModifierSection = (modifierDisplayGroup: IDataModifierDisplayGroup, modifiers: IItemModifierDisplayGroupModifier[]): HTMLElement => {
        const name = Common.GetName(modifierDisplayGroup.Names, globalThis.aOLO.Temp.languageCode);
        const header = this._createSectionHeader(name, true);

        const modifierContent = this._getSelectedModifier(modifierDisplayGroup, modifiers, null);

        const element = document.createElement("div");
        element.classList.add("modifiers-selection-parent");
        element.appendChild(header);
        element.appendChild(modifierContent);

        return element;
    }

    /**
     * Creates a section for multi-choice modifiers.
     * @returns {HTMLElement | null} The HTML element for the multi-choice modifiers section, or null if not applicable.
     */
    private readonly _setMultiChoiceModifiers = (): HTMLElement | null => {
        if (!this._mItem.ModifierDisplayGroups)
            return null;

        const header = this._createSectionHeader(Names("Toppings"), false, true);

        const defaultModifierDisplayGroups = this._getDefaultModifierDisplayGroups();
        if (defaultModifierDisplayGroups.length === 0)
            return null;

        const categoriesSections = this._createMultiChoiceCategorySections(defaultModifierDisplayGroups);
        this._populateMultiChoiceCategorySections(categoriesSections, defaultModifierDisplayGroups, this._data.getProperty("DefaultPreModifierId"));

        const element = document.createElement("div");
        element.classList.add("modifiers-selection-parent");
        element.appendChild(header);
        element.appendChild(categoriesSections);

        return element;
    }

    /**
     * Retrieves default modifier display groups applicable for multi-choice selections.
     * @returns {ICustomizeItemMultiSelectCategories[]} Filtered multi-choice modifier display groups.
     */
    private readonly _getDefaultModifierDisplayGroups = (): ICustomizeItemMultiSelectCategories[] => {
        const defaultModifierDisplayGroups: ICustomizeItemMultiSelectCategories[] = [];
        const modifierDisplayGroups = this._data.getProperty("ModifierDisplayGroups");

        for (const itemDisplayGroup of this._mItem.ModifierDisplayGroups) {
            const modifierDisplayGroup = modifierDisplayGroups.find(x => x.ModifierDisplayGroupId === itemDisplayGroup.ModifierDisplayGroupId);
            if (modifierDisplayGroup
                && modifierDisplayGroup.IsVisible
                && modifierDisplayGroup.MaxSelectionCount > 1
                && !modifierDisplayGroup.MustToggle
            ) {
                defaultModifierDisplayGroups.push({ defaultModifierDisplayGroup: modifierDisplayGroup, modifiers: itemDisplayGroup.Modifiers });
            }
        }

        return defaultModifierDisplayGroups;
    };

    /**
     * Creates the tabbed sections for multi-choice modifier categories.
     * @param {ICustomizeItemMultiSelectCategories[]} defaultModifierDisplayGroups - The default multi-choice modifier groups.
     * @returns {HTMLElement} The container element for the category sections.
     */
    private readonly _createMultiChoiceCategorySections = (defaultModifierDisplayGroups: ICustomizeItemMultiSelectCategories[]): HTMLElement => {
        const mainDiv = document.createElement("div");

        const tabData: ITabBarData = {
            buttonId: "btn_customize_item_toppings",
            tabs: []
        };

        const modifierCategories = this._data.getProperty("ModifierCategories");
        const self = this;
        for (const modifierDisplayGroup of defaultModifierDisplayGroups) {
            if (modifierDisplayGroup.modifiers.length == 0)
                continue;

            const modifierCategory = modifierCategories.find(x => x.ModifierWebCategoryId == modifierDisplayGroup.modifiers[0].ModifierWebCategoryId);
            if (!modifierCategory)
                continue;

            if (this._item.defaultMultiModifierWebCategoryId == null)
                this._item.defaultMultiModifierWebCategoryId = modifierCategory.ModifierWebCategoryId;

            const name = Common.GetName(modifierCategory.Names, globalThis.aOLO.Temp.languageCode)
            tabData.tabs.push({
                id: modifierCategory.ModifierWebCategoryId,
                text: name,
                function: () => self._showItemsOfCategory(modifierCategory.ModifierWebCategoryId)
            })
        }

        const tabBar = new TabBar(tabData);
        mainDiv.appendChild(tabBar.element);

        for (const modifierDisplayGroup of defaultModifierDisplayGroups) {
            const div = document.createElement("div");
            div.id = `div_customize_item_toppings_category_${modifierDisplayGroup.modifiers[0].ModifierWebCategoryId}`;
            div.setAttribute("name", "div_customize_item_toppings_category");
            div.classList.add("customize-item-modifier-wrapper");
            Util.hideElement(div);
            mainDiv.appendChild(div);
        }

        return mainDiv;
    }

    /**
     * Populates the category sections for multi-choice modifiers with content.
     * @param {HTMLElement} parentDiv - The container element for category sections.
     * @param {ICustomizeItemMultiSelectCategories[]} defaultModifierDisplayGroups - The default multi-choice modifier groups.
     * @param {number} premodifierId - The ID of the premodifier to apply.
     */
    private readonly _populateMultiChoiceCategorySections = (parentDiv: HTMLElement, defaultModifierDisplayGroups: ICustomizeItemMultiSelectCategories[], premodifierId: number) => {
        for (const modifierDisplayGroup of defaultModifierDisplayGroups) {
            const categoryDiv = parentDiv.querySelector(`#div_customize_item_toppings_category_${modifierDisplayGroup.modifiers[0].ModifierWebCategoryId}`) as HTMLElement | null;
            if (!categoryDiv)
                continue;

            this._populateMultiChoiceCategorySection(categoryDiv, modifierDisplayGroup, premodifierId);
        }
    }

    /**
     * Populates a category section with multi-choice modifiers.
     * @param {HTMLElement} categoryDiv - The container element for the category.
     * @param {ICustomizeItemMultiSelectCategories} defaultModifierDisplayGroup - The default multi-select modifier group.
     * @param {number} preModifierId - The ID of the pre-modifier.
     */
    private readonly _populateMultiChoiceCategorySection = (categoryDiv: HTMLElement, defaultModifierDisplayGroup: ICustomizeItemMultiSelectCategories, preModifierId: number) => {
        const modifiersData = this._data.getProperty("Modifiers");
        const groupHasImage = modifiersData.some(obj => obj.ImageUrl && obj.ImageUrl.trim() !== "");

        for (const modifier of defaultModifierDisplayGroup.modifiers) {
            const modifierData = modifiersData.find(x => x.ModifierId === modifier.ModifierId);
            if (!modifierData)
                continue;

            const modifierDisplayGroup = defaultModifierDisplayGroup.defaultModifierDisplayGroup;

            const oMod: IOrderItemModifier | null = null;//itemModifiers.find(x => x.ModifierId === modifier.ModifierId) || null;
            let isSelected = modifier.IsDefault;

            if (oMod !== null) {
                // 2DO:
                //isSelected = !(modifier.IsDefault && oMod.PreModifierId === this._aOLO.Temp.NonPreModID);
                //preModifierId = oMod.PreModifierId;
            }

            if (this._isEditing()) {
                const data = this._getEditItemModifierInfo(modifierDisplayGroup.ModifierDisplayGroupId, modifier.ModifierId);
                if (data)
                    this._createModifier(data.id, data.modifierDisplayGroupId, data.halfId, data.premodifierId, true, modifier.IsDefault, null);
                else
                    this._createModifier(modifier.ModifierId, modifierDisplayGroup.ModifierDisplayGroupId, HalfModifierSelection.WHOLE, preModifierId, false, modifier.IsDefault, null);
            } else
                this._createModifier(modifier.ModifierId, modifierDisplayGroup.ModifierDisplayGroupId, HalfModifierSelection.WHOLE, preModifierId, isSelected, modifier.IsDefault, null);

            const option = this._createModifierOptionForMultiChoice(modifierDisplayGroup, modifier, modifierData, groupHasImage, isSelected, preModifierId);
            categoryDiv.appendChild(option);
        }
    }

    /**
     * Creates an option for a multi-choice modifier.
     * @param {IDataModifierDisplayGroup} modifierDisplayGroup - The modifier display group data.
     * @param {IItemModifierDisplayGroupModifier} modifier - The modifier data.
     * @param {IDataModifier} modifierData - The additional modifier data.
     * @param {boolean} groupHasImage - Whether the group contains images.
     * @param {boolean} isSelected - Whether the modifier is selected.
     * @param {number} preModifierId - The ID of the pre-modifier.
     * @returns {HTMLElement} The HTML element for the modifier option.
     */
    private readonly _createModifierOptionForMultiChoice = (
        modifierDisplayGroup: IDataModifierDisplayGroup,
        modifier: IItemModifierDisplayGroupModifier,
        modifierData: IDataModifier,
        groupHasImage: boolean,
        isSelected: boolean,
        preModifierId: number
    ): HTMLElement => {
        const id = `chk_customize_item_modifier_display_group_${modifierDisplayGroup.ModifierDisplayGroupId}_${modifier.ModifierId}`;
        const divName = `chk_customize_item_modifier_display_group_${modifierDisplayGroup.ModifierDisplayGroupId}`;
        const name = Common.GetName(modifierData.Names, globalThis.aOLO.Temp.languageCode);

        const option = this._createModifierOption({
            modifierDisplayGroupId: modifierDisplayGroup.ModifierDisplayGroupId,
            modifierId: modifier.ModifierId,
            id,
            name,
            radioId: divName,
            imageUrl: modifierData.ImageUrl,
            groupHasImage,
            isSelected,
            isMultiSelect: true,
        });

        const optionDiv = document.createElement("div");
        optionDiv.appendChild(option);

        this._addModifierAdditionalElements(optionDiv, modifierDisplayGroup, modifier, isSelected, preModifierId);

        return optionDiv;
    }

    /**
     * Adds additional elements like pre-modifiers and halfable options to a modifier option.
     * @param {HTMLElement} optionDiv - The container for the modifier option.
     * @param {IDataModifierDisplayGroup} modifierDisplayGroup - The modifier display group data.
     * @param {IItemModifierDisplayGroupModifier} modifier - The modifier data.
     * @param {boolean} isSelected - Whether the modifier is selected.
     * @param {number} preModifierId - The ID of the pre-modifier.
     */
    private readonly _addModifierAdditionalElements = (
        optionDiv: HTMLElement,
        modifierDisplayGroup: IDataModifierDisplayGroup,
        modifier: IItemModifierDisplayGroupModifier,
        isSelected: boolean,
        preModifierId: number
    ): void => {
        const modifierDisplayGroupId = modifierDisplayGroup.ModifierDisplayGroupId;

        if (modifierDisplayGroup.IsWeightChangeable) {
            const premodifierBar = this._createPreModifiersSelectionBar({
                modifierDisplayGroupId,
                modifierId: modifier.ModifierId,
                preModifierId,
                includeNo: true,
                preModifierExceptions: modifier.PreModifierException,
                hidden: !isSelected
            });

            if (premodifierBar)
                optionDiv.appendChild(premodifierBar);
        }

        const halfableParentDiv = this._createHalfableParentDiv(modifierDisplayGroupId, modifier.ModifierId, !isSelected);
        optionDiv.appendChild(halfableParentDiv);

        if (modifierDisplayGroup.IsHalfable) {
            const halfableBar = this._createHalfableSelectionBar(modifierDisplayGroupId, modifier.ModifierId);
            halfableParentDiv.appendChild(halfableBar);
        }
    }

    /**
     * Creates a section for a selected modifier in a single-choice group.
     * @param {IDataModifierDisplayGroup} modifierDisplayGroup - The modifier display group.
     * @param {IItemModifierDisplayGroupModifier[]} modifiers - The modifiers in the group.
     * @param {number | null} selectedModifierId - The ID of the selected modifier, if any.
     * @returns {HTMLElement} The container for the selected modifier options.
     */
    private readonly _getSelectedModifier = (modifierDisplayGroup: IDataModifierDisplayGroup, modifiers: IItemModifierDisplayGroupModifier[], selectedModifierId: number | null): HTMLElement => {
        const preModifierId = this._data.getProperty("DefaultPreModifierId");

        const parentDiv = document.createElement("div");
        parentDiv.classList.add("customize-item-modifier-wrapper");

        const modifiersData = this._data.getProperty("Modifiers").filter(x => modifiers.map(y => y.ModifierId).includes(x.ModifierId));

        const groupHasImage = modifiersData.some(obj => obj.ImageUrl && obj.ImageUrl.trim() !== "");
        for (const modifier of modifiers) {
            const modifierData = modifiersData.find(x => x.ModifierId === modifier.ModifierId);
            if (!modifierData)
                continue;

            let isSelected = false;
            if ((selectedModifierId === null && modifier.IsDefault) || (selectedModifierId !== null && modifier.ModifierId === selectedModifierId))
                isSelected = true;

            if (this._isEditing()) {
                const data = this._getEditItemModifierInfo(modifierDisplayGroup.ModifierDisplayGroupId, modifier.ModifierId);
                if (data)
                    this._createModifier(data.id, data.modifierDisplayGroupId, data.halfId, data.premodifierId, true, modifier.IsDefault, null);
                else
                    this._createModifier(modifier.ModifierId, modifierDisplayGroup.ModifierDisplayGroupId, HalfModifierSelection.WHOLE, preModifierId, false, modifier.IsDefault, null);
            } else
                this._createModifier(modifier.ModifierId, modifierDisplayGroup.ModifierDisplayGroupId, HalfModifierSelection.WHOLE, preModifierId, isSelected, modifier.IsDefault, null);

            const name = Common.GetName(modifierData.Names, globalThis.aOLO.Temp.languageCode);
            const id = `rdo_customize_item_modifier_display_group_${modifierDisplayGroup.ModifierDisplayGroupId}_${modifier.ModifierId}`;
            const divName = `rdo_customize_item_modifier_display_group_${modifierDisplayGroup.ModifierDisplayGroupId}`;
            const option = this._createModifierOption({
                modifierDisplayGroupId: modifierDisplayGroup.ModifierDisplayGroupId,
                modifierId: modifier.ModifierId,
                id,
                name,
                radioId: divName,
                imageUrl: modifierData.ImageUrl,
                groupHasImage,
                isSelected
            });

            const optionDiv = document.createElement("div");
            optionDiv.appendChild(option);

            this._addModifierAdditionalElements(optionDiv, modifierDisplayGroup, modifier, isSelected, preModifierId);

            parentDiv.appendChild(optionDiv);
        }

        return parentDiv;
    }

    /**
     * Creates a pre-modifier selection bar for a given modifier.
     * @param {Object} params - Parameters for creating the selection bar.
     * @param {number} params.modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} params.modifierId - The ID of the modifier.
     * @param {number} params.preModifierId - The current pre-modifier ID.
     * @param {boolean} params.includeNo - Whether to include the "No" option.
     * @param {number[]} params.preModifierExceptions - List of pre-modifier exceptions.
     * @param {boolean} params.hidden - Whether the selection bar should be hidden initially.
     * @returns {HTMLElement | null} The pre-modifier selection bar or null if no pre-modifiers exist.
     */
    private readonly _createPreModifiersSelectionBar = ({
        modifierDisplayGroupId,
        modifierId,
        preModifierId,
        includeNo,
        preModifierExceptions,
        hidden,
    }: {
        modifierDisplayGroupId: number;
        modifierId: number;
        preModifierId: number;
        includeNo: boolean;
        preModifierExceptions: number[];
        hidden: boolean;
    }): HTMLElement | null => {
        const preModifiers = this._data.getProperty("PreModifiers");

        if (preModifiers.length === 0)
            return null;

        const self = this;
        const segmentedData: ISegmentedButtonData = {
            buttonId: `customize_item_premodifier_${modifierDisplayGroupId}_${modifierId}`,
            ariaGroupLabel: "Premodifier Selection",
            buttons: []
        };

        for (const preModifier of preModifiers) {
            if (preModifierExceptions.includes(preModifier.PreModifierId))
                continue;

            if (includeNo || preModifier.PreModifierId !== this._data.getProperty("NonePreModifierId")) {
                const name = Common.GetName(preModifier.Names, globalThis.aOLO.Temp.languageCode);
                const selected = (preModifier.PreModifierId === preModifierId);
                segmentedData.buttons.push({
                    id: preModifier.PreModifierId,
                    text: name,
                    selected: selected,
                    function: (event: Event) => {
                        if (!event.isTrusted)
                            return;

                        self._preModifierOnChange(modifierDisplayGroupId, modifierId, preModifier.PreModifierId);
                    }
                });
            }
        }

        const segmentedButton = new SegmentedButton(segmentedData);

        const parentDiv = document.createElement("div");
        parentDiv.id = `div_customize_item_modifier_premodifier_selection_${modifierDisplayGroupId}_${modifierId}`;
        parentDiv.classList.add("customize-item-premodifier-selection");
        parentDiv.appendChild(segmentedButton.element);

        if (hidden)
            Util.hideElement(parentDiv);

        return parentDiv;
    }

    /**
     * Displays the items of a specific category while hiding others.
     * @param {number} modifierWebCategoryId - The ID of the modifier web category to display.
     */
    private _showItemsOfCategory(modifierWebCategoryId: number) {
        const categoryDivs = document.getElementsByName("div_customize_item_toppings_category");
        for (const div of categoryDivs) {
            Util.hideElement(div);
        }

        Util.showElement(`div_customize_item_toppings_category_${modifierWebCategoryId}`);
    }

    /**
     * Handles changes to the selected pre-modifier.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @param {number} preModifierId - The new pre-modifier ID.
     */
    private _preModifierOnChange = (modifierDisplayGroupId: number, modifierId: number, preModifierId: number): void => {
        const modifier = this._getModifier(modifierId);
        if (modifier?.premodifierId === preModifierId)
            return;

        const halfId = modifier ? modifier.halfId : HalfModifierSelection.WHOLE;
        const modified = this._alterSelectedItemModifier(modifierDisplayGroupId, modifierId, preModifierId, halfId, false, false);
        if (!modified && modifier)
            document.getElementById(`btn_customize_item_premodifier_${modifier.modifierDisplayGroupId}_${modifier.id}_${modifier.premodifierId}`)?.click();
    }

    /**
     * Creates a parent div for halfable modifier options.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @param {boolean} hidden - Whether the div should be hidden initially.
     * @returns {HTMLElement} The parent div for halfable options.
     */
    private readonly _createHalfableParentDiv = (modifierDisplayGroupId: number, modifierId: number, hidden: boolean): HTMLElement => {
        const parentDiv = document.createElement("div");
        parentDiv.id = `div_customize_item_modifier_halfable_selection_${modifierDisplayGroupId}_${modifierId}`;
        parentDiv.classList.add("customize-item-premodifier-selection");

        if (hidden)
            Util.hideElement(parentDiv);

        return parentDiv;
    }

    /**
     * Creates a selection bar for halfable modifiers (left, whole, right).
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @returns {HTMLElement} The selection bar for halfable modifiers.
     */
    private readonly _createHalfableSelectionBar = (modifierDisplayGroupId: number, modifierId: number): HTMLElement => {
        const self = this;
        const segmentedData: ISegmentedButtonData = {
            buttonId: `customize_item_halfable_${modifierDisplayGroupId}_${modifierId}`,
            ariaGroupLabel: "Modifier Half Selection",
            buttons: [
                this._createHalfButtonConfig(Names("Left"), "icon-item-half-left", modifierDisplayGroupId, modifierId, HalfModifierSelection.LEFT, false),
                this._createHalfButtonConfig(Names("Whole"), "icon-item-whole", modifierDisplayGroupId, modifierId, HalfModifierSelection.WHOLE, true),
                this._createHalfButtonConfig(Names("Right"), "icon-item-half-right", modifierDisplayGroupId, modifierId, HalfModifierSelection.RIGHT, false),
            ]
        };

        const segmentedButton = new SegmentedButton(segmentedData);
        return segmentedButton.element;
    }

    /**
     * Creates a button configuration for a halfable selection (left, whole, right).
     * @param {string} text - The button text.
     * @param {string} icon - The icon class for the button.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @param {HalfModifierSelection} halfId - The half selection (LEFT, WHOLE, RIGHT).
     * @param {boolean} isSelected - Whether the button is selected by default.
     * @returns {ISegmentedButtonDataButton} The button configuration.
     */
    private readonly _createHalfButtonConfig = (
        text: string,
        icon: string,
        modifierDisplayGroupId: number,
        modifierId: number,
        halfId: HalfModifierSelection,
        isSelected: boolean
    ): ISegmentedButtonDataButton => {
        const self = this;
        const buttonText = this._createHalfButtonText(text, icon);
        return {
            id: halfId,
            text: buttonText,
            selected: isSelected,
            function: (event: Event) => self._halfHalfOnChange(event, modifierDisplayGroupId, modifierId, halfId),
        };
    }

    /**
     * Generates the HTML for a halfable button.
     * @param {string} text - The button text.
     * @param {string} icon - The icon class for the button.
     * @returns {string} The HTML string for the button.
     */
    private readonly _createHalfButtonText = (text: string, icon: string): string => {
        return `<div class="customize-item-half-button">
                    <span class="${icon}" aria-hidden="true"></span>
                    <span>${text}</span>
                </div>`;
    }

    /**
     * Handles changes to halfable modifier selection.
     * @param {Event} event - The click event.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @param {HalfModifierSelection} halfId - The half selection (LEFT, WHOLE, RIGHT).
     */
    private readonly _halfHalfOnChange = (event: Event, modifierDisplayGroupId: number, modifierId: number, halfId: HalfModifierSelection): void => {
        if (!event.isTrusted)
            return;

        const modifier = this._getModifier(modifierId);
        if (modifier?.halfId === halfId)
            return;

        const premodifierId = modifier ? modifier.premodifierId : this._data.getProperty("DefaultPreModifierId");
        const modified = this._alterSelectedItemModifier(modifierDisplayGroupId, modifierId, premodifierId, halfId, false, false);
        if (!modified && modifier)
            document.getElementById(`btn_customize_item_halfable_${modifier.modifierDisplayGroupId}_${modifier.id}_${modifier.halfId}`)?.click();
    }

    /**
     * Updates the displayed calorie information for the item.
     * @param {IOrderItemModifier[]} modifiers - The list of modifiers for the first half.
     * @param {IOrderItemModifier[]} modifiers2 - The list of modifiers for the second half.
     */
    private readonly _updateItemCalories = (modifiers: IOrderItemModifier[], modifiers2: IOrderItemModifier[]): void => {
        const calories = this._getItemCaloriesBySize(modifiers, modifiers2, this._order.getProperty("OrderTypeID"), this._item.sizeId);
        Util.setElement("innerText", "p_customize_item_calories", Common.GetName(calories, globalThis.aOLO.Temp.languageCode));
    }

    /**
     * Retrieves the calorie information for the item based on size and modifiers.
     * @param {IOrderItemModifier[]} modifiers - The list of modifiers for the first half.
     * @param {IOrderItemModifier[]} modifiers2 - The list of modifiers for the second half.
     * @param {number} orderTypeId - The ID of the order type.
     * @param {number} sizeId - The ID of the item size.
     * @returns {IName[]} The calorie information for the item.
     */
    private readonly _getItemCaloriesBySize = (modifiers: IOrderItemModifier[], modifiers2: IOrderItemModifier[], orderTypeId: number, sizeId: number): IName[] => {
        const secondHalfModifiers = this._isHalfHalf() ? modifiers2 : null;
        const calories = OnlineOrderingUtil.getItemCaloriesNew(this._mItem, modifiers, secondHalfModifiers, orderTypeId, sizeId, this._data.getProperty("DefaultPreModifierId"), globalThis.aOLO);
        return calories;
    }

    /**
     * Calculates and returns the calorie information for a modifier.
     * @param {IItemModifierDisplayGroupModifier} itemModifier - The item modifier data.
     * @param {IDataModifier} modifier - The data modifier details.
     * @returns {string | null} The formatted calorie text or null if unavailable.
     */
    private readonly _getModifierCalories = (itemModifier: IItemModifierDisplayGroupModifier, modifier: IDataModifier): string | null => {
        const servingCalories = OnlineOrderingUtil.getItemSizeMinMaxCalories(this._mItem, this._item.sizeId);
        const servingName = (this._mItem.ServingNames) ? Common.GetName(this._mItem.ServingNames, globalThis.aOLO.Temp.languageCode) : Names("Slice");

        let calorieText = OnlineOrderingUtil.GetModifierCalories(Common.GetDescription(modifier.Descriptions, globalThis.aOLO.Temp.languageCode)) || null;
        if (!calorieText) {
            const modifierCount = Math.max(OnlineOrderingUtil.getMenuItemModCount(this._mItem, itemModifier.MergedModifierDisplayGroupId), 1);

            const calories = OnlineOrderingUtil.getMenuItemModCalories(itemModifier, this._item.sizeId, modifierCount, this._data.getProperty("DefaultPreModifierId"), this._data.getProperty("Settings").PMODDM, this._data.getProperty("PreModifiers"), this._data.getProperty("DefaultPreModifierId"));
            if (calories > 0) {
                const perServingCalories = Math.ceil(calories / servingCalories.serves);
                if (this._mItem.CalorieDisplayId == CalorieDisplay.SERVING)
                    calorieText = Names("CaloriesPerServing")
                        .replace("{{calorie}}", perServingCalories.toString())
                        .replace("{{servingName}}", servingName);
                else if (this._mItem.CalorieDisplayId == CalorieDisplay.FRACTION)
                    calorieText = Names("CaloriesPerFraction")
                        .replace("{{calorie}}", perServingCalories.toString())
                        .replace("{{serving}}", servingCalories.serves.toString())
                        .replace("{{servingName}}", servingName);
            } else
                calorieText = null;
        }

        return calorieText;
    }

    /**
     * Alters the selected modifier for the item, handling constraints like size, pre-modifiers, and group rules.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @param {number} preModifierId - The ID of the pre-modifier.
     * @param {HalfModifierSelection} halfId - The half selection (LEFT, RIGHT, WHOLE).
     * @param {boolean} ignoreSize - Whether to ignore size constraints.
     * @param {boolean} [newModifier=true] - Whether the modifier is new.
     * @returns {boolean} True if the modifier was successfully altered, false otherwise.
     */
    private readonly _alterSelectedItemModifier = (modifierDisplayGroupId: number, modifierId: number, preModifierId: number, halfId: HalfModifierSelection, ignoreSize: boolean, newModifier: boolean = true): boolean => {
        const itemModifierDisplayGroup = this._mItem.ModifierDisplayGroups.find(x => x.ModifierDisplayGroupId === modifierDisplayGroupId);
        if (!itemModifierDisplayGroup)
            return false;

        const itemModifier = itemModifierDisplayGroup.Modifiers.find(x => x.ModifierId === modifierId);
        if (!itemModifier)
            return false;

        const modifierDisplayGroup = this._getModifierDisplayGroupById(modifierDisplayGroupId);
        if (!modifierDisplayGroup)
            return false;

        if (!this._checkModifierLimits(modifierDisplayGroupId, preModifierId, newModifier))
            return false;

        if (!ignoreSize && !this._validateModifierSize(modifierDisplayGroupId, modifierId, preModifierId, halfId, itemModifier, newModifier))
            return false;

        const adjusted = this._updateSelectedModifier(itemModifier, modifierDisplayGroup, modifierId, preModifierId, halfId);

        if (!adjusted && preModifierId !== this._data.getProperty("NonePreModifierId")) { // the line should state (!adjusted && preModID !== this._localAolo.Temp.NonPreModID); DO NOT CHANGE
            this._addNewModifier(modifierDisplayGroup, itemModifier, modifierId, preModifierId, halfId);

            const oItems = this._getItems();
            for (const oItem of oItems) {
                if (this._getItemPrice(oItem.Modifiers).Price < 0) {
                    this._updateModifier({ modifierId, isSelected: false, modifier: null });
                    DialogCreators.messageBoxOk(Names("Error_ReachedMaxNumberOfModifiers"), globalThis.aOLO.buttonHoverStyle);
                    return false;
                }
            }
        }

        this._priceSelectedItem();
        return true;
    }

    /**
     * Gets the name of the current size.
     * @returns {string} The name of the size, or an empty string if not found.
     */
    private readonly _getSizeName = (): string => {
        const itemSize = OnlineOrderingUtil.GetItemSize(this._mItem, this._order.getProperty("OrderTypeID"), this._item.sizeId);
        if (!itemSize)
            return "";

        const size = this._data.getProperty("Sizes").find((x) => x.SizeId === itemSize.SizeId);
        return size ? Common.GetName(size.Names, globalThis.aOLO.Temp.languageCode) : "";
    }

    /**
     * Checks whether the modifier meets the selection and group constraints.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} preModifierId - The ID of the pre-modifier.
     * @param {boolean} newModifier - Whether the modifier is new.
     * @returns {boolean} True if the modifier is valid, false otherwise.
     */
    private readonly _checkModifierLimits = (modifierDisplayGroupId: number, preModifierId: number, newModifier: boolean): boolean => {
        if (preModifierId !== this._data.getProperty("NonePreModifierId") && !this._checkDisplayGroupMax(modifierDisplayGroupId))
            return false;

        if (newModifier && preModifierId !== this._data.getProperty("NonePreModifierId") && !this._checkModifiersMax(modifierDisplayGroupId))
            return false;

        return true;
    }

    /**
     * Validates the size constraints for the modifier and handles errors if invalid.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @param {number} preModifierId - The ID of the pre-modifier.
     * @param {HalfModifierSelection} halfId - The half selection.
     * @param {IItemModifierDisplayGroupModifier} itemModifier - The item modifier data.
     * @param {boolean} newModifier - Whether the modifier is new.
     * @returns {boolean} True if the size is valid, false otherwise.
     */
    private readonly _validateModifierSize = (modifierDisplayGroupId: number, modifierId: number, preModifierId: number, halfId: HalfModifierSelection, itemModifier: IItemModifierDisplayGroupModifier, newModifier: boolean): boolean => {
        if (OnlineOrderingUtil.isModOfferedOnSize(itemModifier, this._item.sizeId))
            return true;

        // Handle invalid size logic, showing a dialog and allowing adjustments.
        this._handleInvalidModifierSizeOnAlter(modifierDisplayGroupId, modifierId, preModifierId, halfId, itemModifier, newModifier);
        return false;
    }

    /**
     * Handles the scenario where a modifier is invalid for the selected size.
     * Displays appropriate messages and provides options to adjust.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @param {number} preModifierId - The ID of the pre-modifier.
     * @param {HalfModifierSelection} halfId - The half selection.
     * @param {IItemModifierDisplayGroupModifier} itemModifier - The item modifier data.
     * @param {boolean} newModifier - Whether the modifier is new.
     */
    private readonly _handleInvalidModifierSizeOnAlter = (modifierDisplayGroupId: number, modifierId: number, preModifierId: number, halfId: HalfModifierSelection, itemModifier: IItemModifierDisplayGroupModifier, newModifier: boolean): void => {
        const sizeName = this._getSizeName();
        const itemName = Common.GetName(this._mItem.Names, globalThis.aOLO.Temp.languageCode);
        const modifier = this._data.getProperty("Modifiers").find(x => x.ModifierId === itemModifier.ModifierId);
        const modifierName = modifier ? Common.GetName(modifier.Names, globalThis.aOLO.Temp.languageCode) : "";

        // 2DO: Meal Deal Logic
        if (globalThis.aOLO.Dialog && globalThis.aOLO.Dialog.MealDeal) {
            this._handleMealDealInvalidModifier();
            return;
        }

        const message = Names("Error_SelectedModifierNoSizeSwitch").replaceAll("{{modifier}}", modifierName).replace("{{size}}", sizeName).replace("{{item}}", itemName);
        DialogCreators.messageBox(message, globalThis.aOLO.buttonHoverStyle, [
            {
                text: Names("Yes"),
                callBack: () => this._attemptModifierSizeSwitch(modifierDisplayGroupId, modifierId, preModifierId, halfId, itemModifier, newModifier)
            },
            {
                text: Names("No"),
                callBack: () => this._revertToFirstModifier(modifierDisplayGroupId)
            }]);
    }

    /**
     * Handles invalid modifiers in the context of a meal deal.
     */
    private readonly _handleMealDealInvalidModifier = (): void => {
        // TODO: Implement meal deal-specific logic for invalid modifiers.
        //    DialogCreators.messageBoxOk(Names("SelModNoSize").replace("{modName}", modName).replace("{sizeName}", sizeName).replace("{itemName}", itemName), this._localAolo.buttonHoverStyle);
        //    const currentModifierId = this._customize.Items[0].Modifiers[iDGIDX].ModifierId;
        //    const isHH = this._customize.Items.length > 1;
        //    if (isHH)
        //        Util.setElement("value", `ddl_customize_item_modifiers_whole_${iMdg.ModifierDisplayGroupId}`, currentModifierId);
        //    else
        //        Util.setElement("value", `ddl_customize_item_modifiers_${itmID}_${iMdg.ModifierDisplayGroupId}_${halfIndex}`, currentModifierId);
        //    return false;
    }

    /**
     * Attempts to switch to a valid size for the given modifier.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @param {number} modifierId - The ID of the modifier.
     * @param {number} preModifierId - The ID of the pre-modifier.
     * @param {HalfModifierSelection} halfId - The half selection.
     * @param {IItemModifierDisplayGroupModifier} itemModifier - The item modifier data.
     * @param {boolean} newModifier - Whether the modifier is new.
     */
    private readonly _attemptModifierSizeSwitch = (modifierDisplayGroupId: number, modifierId: number, preModifierId: number, halfId: HalfModifierSelection, itemModifier: IItemModifierDisplayGroupModifier, newModifier: boolean): void => {
        const sizeId = this._getModifierValidSize(itemModifier);

        if (sizeId !== null) {
            const modified = this._alterSelectedItemModifier(modifierDisplayGroupId, modifierId, preModifierId, halfId, true, newModifier);

            if (modified)
                document.getElementById(`lbl_rdo_customize_item_size_${sizeId}`)?.click();
        } else {
            DialogCreators.messageBoxOk(Names("Error_UnableToFindProperSizeToApply"), globalThis.aOLO.buttonHoverStyle);
            this._revertToSelectedModifier(modifierDisplayGroupId);
        }
    }

    /**
     * Reverts to the first modifier in the specified modifier display group.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     */
    private readonly _revertToFirstModifier = (modifierDisplayGroupId: number): void => {
        const firstModifier = this._getFirstModifierByModifierDisplayGroup(modifierDisplayGroupId);
        if (firstModifier)
            document.getElementById(`rdo_customize_item_modifier_display_group_${firstModifier.modifierDisplayGroupId}_${firstModifier.id}`)?.click();
    }

    /**
     * Reverts to the previously selected modifier in the specified display group.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     */
    private readonly _revertToSelectedModifier = (modifierDisplayGroupId: number): void => {
        const selectedModifier = this._item.modifiers.find(x => x.modifierDisplayGroupId === modifierDisplayGroupId && x.isSelected);
        if (selectedModifier)
            document.getElementById(`rdo_customize_item_modifier_display_group_${selectedModifier.modifierDisplayGroupId}_${selectedModifier.id}`)?.click();
    }

    /**
     * Updates the selected modifier, applying constraints for toggling and group rules.
     * @param {IItemModifierDisplayGroupModifier} itemModifier - The item modifier data.
     * @param {IDataModifierDisplayGroup} modifierDisplayGroup - The display group data.
     * @param {number} modifierId - The ID of the modifier.
     * @param {number} preModifierId - The ID of the pre-modifier.
     * @param {HalfModifierSelection} halfId - The half selection.
     * @returns {boolean} True if the modifier was successfully updated, false otherwise.
     */
    private readonly _updateSelectedModifier = (itemModifier: IItemModifierDisplayGroupModifier, modifierDisplayGroup: IDataModifierDisplayGroup, modifierId: number, preModifierId: number, halfId: HalfModifierSelection): boolean => {
        if (modifierDisplayGroup.MustToggle || modifierDisplayGroup.MaxSelectionCount === 1) {
            const modifier = this._getFirstModifierByModifierDisplayGroup(modifierDisplayGroup.ModifierDisplayGroupId);
            if (modifier) {
                this._updateModifier({ modifierId: modifier.id, isDefault: false, isSelected: false });
            }
        } else {
            let oItem = this.firstItem;
            if (halfId === HalfModifierSelection.RIGHT)
                oItem = this.secondItem;

            const modifier = oItem.Modifiers.find(x => x.ModifierId === modifierId);
            if (modifier) {
                if (itemModifier.IsDefault || preModifierId !== this._data.getProperty("NonePreModifierId")) {
                    this._updateModifier({ modifierId, halfId, premodifierId: preModifierId, isSelected: true });
                    this._updateModifiersPreModifiers();
                } else if (preModifierId === this._data.getProperty("NonePreModifierId")) {
                    this._updateModifier({ modifierId, halfId, premodifierId: preModifierId, isSelected: false });
                }
                return true;
            }
        }

        return false;
    }

    /**
     * Adds a new modifier to the order with the specified properties.
     * @param {IDataModifierDisplayGroup} modifierDisplayGroup - The display group data.
     * @param {IItemModifierDisplayGroupModifier} itemModifier - The item modifier data.
     * @param {number} modifierId - The ID of the modifier.
     * @param {number} preModifierId - The ID of the pre-modifier.
     * @param {HalfModifierSelection} halfId - The half selection.
     */
    private readonly _addNewModifier = (modifierDisplayGroup: IDataModifierDisplayGroup, itemModifier: IItemModifierDisplayGroupModifier, modifierId: number, preModifierId: number, halfId: HalfModifierSelection): void => {
        const newModifier: IOrderItemModifier = {
            ModifierId: modifierId,
            Name: itemModifier.Name,
            Index: itemModifier.Index,
            ModifierDisplayGroupIndex: 0, //itemModifierDisplayGroup.Index,
            iIndex: 0, //iModIDX,
            iModifierDisplayGroupIndex: 0, //iDGIDX,
            PreModifierId: preModifierId,
            IsDefault: itemModifier.IsDefault,
            ModifierDisplayGroupId: modifierDisplayGroup.ModifierDisplayGroupId,
            MergedModifierDisplayGroupId: itemModifier.MergedModifierDisplayGroupId,
            CountForPricing: itemModifier.CountForPricing,
            CountForPricingByItself: itemModifier.CountForPricingByItself,
            Price: 0,
        };

        this._updateModifier({ modifierId, halfId, premodifierId: preModifierId, isSelected: true, modifier: newModifier });
    }

    /**
     * Gets the first valid size for a modifier that has an applicable price.
     * @param {IItemModifierDisplayGroupModifier} itemModifier - The item modifier data.
     * @returns {number | null} The size ID if valid, or null otherwise.
     */
    private readonly _getModifierValidSize = (itemModifier: IItemModifierDisplayGroupModifier): number | null => {
        const sizes = OnlineOrderingUtil.GetItemSizes(this._mItem, this._order.getProperty("OrderTypeID"));
        for (const size of sizes) {
            const isHalfHalf = this._isHalfHalf();
            if (!isHalfHalf || (isHalfHalf && size.IsHalfable)) {
                const modPrice = OnlineOrderingUtil.GetModifierAddToPrice(itemModifier, size.SizeId);
                if (modPrice > -1)
                    return size.SizeId;
            }
        }
        return null;
    }

    /**
     * Handles item size change clicks, ensuring valid configurations.
     * @param {Event} event - The click event.
     * @param {number} sizeId - The ID of the selected size.
     */
    private readonly _itemSizeChangeOnClick = (event: Event, sizeId: number): void => {
        if (this._isHalfHalf()) {
            if (!this._checkSizeIsHalfable(sizeId) ||
                !this._checkItemModifiersForSizes(this.firstItem.Modifiers, sizeId) ||
                !this._checkItemModifiersForSizes(this.secondItem.Modifiers, sizeId)) {
                event.preventDefault();
                this._setSizeCheckbox(this._item.sizeId);
                return;
            }
        } else {
            if (!this._checkItemModifiersForSizes(this.firstItem.Modifiers, sizeId)) {
                event.preventDefault();
                this._setSizeCheckbox(this._item.sizeId);
                return;
            }
        }

        this._itemSizeChangeContinue(event, sizeId);
    }

    /**
     * Updates the UI to reflect the selected size.
     * @param {number} sizeId - The ID of the size to select.
     */
    private readonly _setSizeCheckbox = (sizeId: number): void => {
        Util.setElement("checked", `rdo_customize_item_size_${sizeId}`, true);
    }

    /**
     * Checks whether a specific size is valid for half-half configurations.
     * @param {number} sizeId - The ID of the size to validate.
     * @returns {boolean} True if the size is valid and halfable, false otherwise.
     */
    private _checkSizeIsHalfable = (sizeId: number): boolean => {
        const itemSize = OnlineOrderingUtil.GetItemSize(this._mItem, this._order.getProperty("OrderTypeID"), sizeId);

        if (itemSize === null || !itemSize.IsHalfable) {
            DialogCreators.messageBoxOk(Names("Error_HalfNotAvailableForSelectedSize"), globalThis.aOLO.buttonHoverStyle);
            return false;
        }

        return itemSize.IsHalfable;
    }

    /**
     * Checks whether the item modifiers are valid for a given size.
     * If invalid, adjusts the size or displays an error message.
     * @param {IOrderItemModifier[]} itemModifiers - The list of item modifiers to check.
     * @param {number} sizeId - The size ID to validate against.
     * @returns {boolean} True if all modifiers are valid for the size, false otherwise.
     */
    private readonly _checkItemModifiersForSizes = (itemModifiers: IOrderItemModifier[], sizeId: number): boolean => {
        const itemSize = OnlineOrderingUtil.GetItemSize(this._mItem, this._order.getProperty("OrderTypeID"), sizeId);

        for (const oMod of itemModifiers) {
            const miMdg = this._mItem.ModifierDisplayGroups.find(x => x.ModifierDisplayGroupId === oMod.ModifierDisplayGroupId);
            if (!miMdg)
                continue;

            const miMod = miMdg.Modifiers.find(x => x.ModifierId === oMod.ModifierId);
            if (!miMod)
                continue;

            if (!OnlineOrderingUtil.isModOfferedOnSize(miMod, sizeId)) {
                this._handleInvalidModifierSize(miMod, itemSize);

                return false;
            }
        }
        return true;
    }

    /**
     * Handles the case when a modifier is not valid for the selected size.
     * Displays an error message and adjusts the size if possible.
     * @param {IItemModifierDisplayGroupModifier} miMod - The invalid modifier.
     * @param {IItemOrderTypeSize | null} itemSize - The size details, if available.
     */
    private readonly _handleInvalidModifierSize = (miMod: IItemModifierDisplayGroupModifier, itemSize: IItemOrderTypeSize | null): void => {
        const modifier = this._data.getProperty("Modifiers").find(x => x.ModifierId === miMod.ModifierId);
        const modName = modifier ? Common.GetName(modifier.Names, globalThis.aOLO.Temp.languageCode) : "";
        const size = itemSize ? this._getSizeById(itemSize.SizeId) : null;
        const sizeName = size ? Common.GetName(size.Names, globalThis.aOLO.Temp.languageCode) : "";

        DialogCreators.messageBoxOk(
            Names("Error_ModifierIsNotBeingOfferedOnSize")
                .replaceAll("{{modifier}}", modName)
                .replaceAll("{{size}}", sizeName),
            globalThis.aOLO.buttonHoverStyle
        );

        // find largest appropriate size for given modifier
        const newSizeId = this._findLargestValidSizeForModifier(miMod);
        if (newSizeId)
            this._item.sizeId = newSizeId;
    }

    /**
     * Finds the largest valid size for a given modifier.
     * @param {IItemModifierDisplayGroupModifier} miMod - The modifier to check.
     * @returns {number | null} The size ID if valid, or null otherwise.
     */
    private readonly _findLargestValidSizeForModifier = (miMod: IItemModifierDisplayGroupModifier): number | null => {
        const itemSizes = OnlineOrderingUtil.GetItemSizes(this._mItem, this._order.getProperty("OrderTypeID")).sort((a, b) => b.Index - a.Index);
        return itemSizes.find(size => OnlineOrderingUtil.isModOfferedOnSize(miMod, size.SizeId))?.SizeId || null;
    }

    /**
     * Continues the item size change process after validation.
     * Updates item properties, price, and calorie information.
     * @param {Event} event - The event triggered by the size change.
     * @param {number} sizeId - The new size ID.
     */
    private _itemSizeChangeContinue = (event: Event, sizeId: number): void => {
        const modifiers = this._isHalfHalf() ? this.secondItem.Modifiers : this.firstItem.Modifiers;

        if (this._getItemPrice(modifiers).Price < 0) {
            event.preventDefault();
            DialogCreators.messageBoxOk(Names("Error_ReachedMaxNumberOfModifiers"), globalThis.aOLO.buttonHoverStyle);
            this._setSizeCheckbox(this._item.sizeId);
            return;
        }

        this._item.sizeId = sizeId;
        this._priceSelectedItem();
        this._displayItemsPrice(this.firstItem.HalfHalfPrice);
        this._updateAllCalories();
        this._checkHideHalfHalfBySize();
        this._generateSpecialInstructionsSection();
        this._updateItemCalories(this.firstItem.Modifiers, this.secondItem.Modifiers);
    }

    /**
     * Retrieves a modifier display group by its ID.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @returns {IDataModifierDisplayGroup | null} The modifier display group or null if not found.
     */
    private readonly _getModifierDisplayGroupById = (modifierDisplayGroupId: number): IDataModifierDisplayGroup | null => {
        const modifierDisplayGroups = this._data.getProperty("ModifierDisplayGroups");
        const modifierDisplayGroup = modifierDisplayGroups.find(x => x.ModifierDisplayGroupId === modifierDisplayGroupId);
        return modifierDisplayGroup || null;
    }

    /**
     * Retrieves a size by its ID.
     * @param {number} sizeId - The ID of the size.
     * @returns {IDataSize | null} The size or null if not found.
     */
    private readonly _getSizeById = (sizeId: number): IDataSize | null => {
        const sizes = this._data.getProperty("Sizes");
        const size = sizes.find(x => x.SizeId === sizeId);
        return size || null;
    }

    /**
     * Calculates and sets prices for the selected item(s).
     * Handles both single and half-half pricing scenarios.
     */
    private readonly _priceSelectedItem = (): void => {
        if (!this._mItem.Prices)
            return;

        const oItems = this._getItems();
        for (const oItem of oItems) {
            const iPrice = this._getItemPrice(oItem.Modifiers);
            const price = Math.abs(iPrice.Price);
            oItem.MenuPrice = price;
            oItem.MenuPriceTax = iPrice.Tax;
            oItem.Tax = iPrice.Tax;
            oItem.PriceIncludesTax = iPrice.TaxIncluded
            oItem.Price = price;
            oItem.MinPrice = iPrice.MinPrice;
            oItem.HalfHalfPrice = price;
        }

        if (this._isHalfHalf())
            this._calculateHalfHalfPricing();

        this._taxSelectedItem();
        this._displayItemsPrice(this.firstItem.HalfHalfPrice);
        this._updateItemCalories(this.firstItem.Modifiers, this.secondItem.Modifiers);
    }

    /**
     * Calculates the price of the current item based on its modifiers and pricing configurations.
     * @param {IOrderItemModifier[]} modifiers - An array of modifiers applied to the item.
     * @returns {IMenuItemPrice} - An object containing the calculated price details of the item.
     */
    private readonly _getItemPrice = (modifiers: IOrderItemModifier[]): IMenuItemPrice => {
        const orderTypePriceGroupId = this._order.getProperty("OrderTypeSubType").PriceGroupId;
        const defaultPriceGroup = this._data.getProperty("DefaultPriceGroupId");
        const nonChargeableDisplayGroups = this._data.getProperty("Settings").NCDG;
        const preModifiers = this._data.getProperty("PreModifiers");
        return OnlineOrderingUtil.GetItemPrice(this._mItem, this._item.sizeId, modifiers, orderTypePriceGroupId, defaultPriceGroup, nonChargeableDisplayGroups, preModifiers);
    }

    /**
     * Calculates pricing for half-half items.
     */
    private readonly _calculateHalfHalfPricing = (): void => {
        const highestPrice = this._data.getProperty("Settings").HHP === 1;
        const maxPrice = Math.max(0, this.firstItem.MenuPrice, this.secondItem.MenuPrice);
        const maxPriceTax = Math.max(0, this.firstItem.MenuPriceTax, this.secondItem.MenuPriceTax);
        const totalPrice = this.firstItem.MenuPrice + this.secondItem.MenuPrice;
        const totalPriceTax = this.firstItem.MenuPriceTax + this.secondItem.MenuPriceTax;

        const hhPrice = highestPrice ? Util.Float2(maxPrice) : Util.Float2(totalPrice / 2);
        const hhPriceTax = highestPrice ? Util.Float2(maxPriceTax) : Util.Float2(totalPriceTax / 2);

        this.firstItem.HalfHalfPrice = hhPrice;
        this.secondItem.HalfHalfPrice = hhPrice;

        if (highestPrice) {
            this.firstItem.Price = Util.Float2(hhPrice / 2);
            this.firstItem.Tax = Util.Float2(hhPriceTax / 2);
            this.secondItem.Price = Util.Float2(hhPrice / 2);
            this.secondItem.Tax = Util.Float2(hhPriceTax / 2);
        } else {
            this.firstItem.Price = Util.Float2(this.firstItem.MenuPrice / 2);
            this.firstItem.Tax = Util.Float2(this.firstItem.MenuPriceTax / 2);
            this.secondItem.Price = Util.Float2(this.secondItem.MenuPrice / 2);
            this.secondItem.Tax = Util.Float2(this.secondItem.MenuPriceTax / 2);
        }

        const totalAssignedPrice = this.firstItem.Price + this.secondItem.Price;
        if (totalAssignedPrice !== hhPrice) {
            const diff = Util.Float2(hhPrice - totalAssignedPrice);
            this.secondItem.Price = Util.Float2(this.secondItem.Price + diff);
        }

        this._setTaxOnItem(this.firstItem);
        this._setTaxOnItem(this.secondItem);
    }

    /**
     * Checks whether the current size is halfable and updates the UI accordingly.
     */
    private _checkHideHalfHalfBySize = (): void => {
        const isHalfableSize = this._checkIfSizeIsHalfable();
        this._hideAllHalfHalfToolbars(!isHalfableSize);
    }

    /**
     * Determines if the selected size is halfable.
     * @returns {boolean} True if the size is halfable, false otherwise.
     */
    private readonly _checkIfSizeIsHalfable = (): boolean => {
        if (!this._mItem.IsHalfable)
            return false;

        let isHalfable = true;
        const sizes = this._mItem.OrderTypes.find(x => x.OrderTypeId == this._order.getProperty("OrderTypeID"))?.Sizes;
        if (sizes)
            isHalfable = sizes.find(x => x.SizeId == this._item.sizeId)?.IsHalfable || false;

        return isHalfable;
    }

    /**
     * Hides or shows all half-half toolbars based on the given condition.
     * @param {boolean} hide - Whether to hide the toolbars.
     */
    private readonly _hideAllHalfHalfToolbars = (hide: boolean): void => {
        if (hide) {
            const toolbars = Array.from(document.querySelectorAll("div[id^='div_customize_item_modifier_halfable_selection_']")) as HTMLElement[];
            for (const toolbar of toolbars) {
                toolbar.innerHTML = "";
            }
        }

        for (const modifier of this._item.modifiers) {
            if (hide) {
                modifier.halfId = HalfModifierSelection.WHOLE;
            } else {
                this._showHalfableToolbar(modifier);
            }
        }
    }

    /**
     * Displays the halfable toolbar for a specific modifier if applicable.
     * @param {ICustomizeItemModifier} modifier - The modifier to check.
     */
    private readonly _showHalfableToolbar = (modifier: ICustomizeItemModifier): void => {
        const modifierDisplayGroup = this._getModifierDisplayGroupById(modifier.modifierDisplayGroupId);
        if (!modifierDisplayGroup?.IsHalfable)
            return;

        const parentDiv = document.getElementById(`div_customize_item_modifier_halfable_selection_${modifier.modifierDisplayGroupId}_${modifier.id}`);
        if (parentDiv && parentDiv.innerHTML === "") {
            const halfableBar = this._createHalfableSelectionBar(modifierDisplayGroup.ModifierDisplayGroupId, modifier.id);
            parentDiv.appendChild(halfableBar);
            if (modifier.isSelected)
                Util.showElement(parentDiv);
        }
    }

    /**
     * Calculates and sets taxes for the selected item(s).
     */
    private _taxSelectedItem = (): void => {
        const oItems = this._getItems();
        for (const oItem of oItems) {
            this._setTaxOnItem(oItem);
        }
    }

    /**
     * Sets tax details for the given item based on its properties.
     * @param {ICustomizeItemBaseItem} item - The item for which tax is being calculated.
     */
    private readonly _setTaxOnItem = (item: ICustomizeItemBaseItem): void => {
        const oItemTax: IGetItemTaxoItem = {
            SizeId: this._item.sizeId,
            Quantity: this._item.quantity,
            PriceIncludesTax: item.PriceIncludesTax,
            Price: item.Price,
            AfterTaxDiscount: item.AfterTaxDiscount,
            BeforeTaxDiscount: item.BeforeTaxDiscount
        };

        const taxData = OnlineOrderingUtil.GetItemTax(this._mItem, oItemTax, this._getItemTaxDataDependencies());
        item.TaxAmount = taxData.TaxableAmount;
        item.Taxes = taxData.Taxes;
        item.Tax = taxData.TotalTax;
    }

    private readonly _getItemTaxDataDependencies = (): IGetItemTaxDependencies => {
        return {
            taxesData: this._data.getProperty("Taxes"),
            zipTaxesData: this._data.getProperty("ZipTaxes"),
            isCallCenter: this._data.getProperty("Settings").ISCC,
            orderTypeId: this._order.getProperty("OrderTypeID"),
            address: globalThis.aOLO.Temp.Address
        };
    }

    /**
     * Checks if the maximum selection count for a modifier display group has been exceeded.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @returns {boolean} True if within the limit, false otherwise.
     */
    private _checkDisplayGroupMax = (modifierDisplayGroupId: number): boolean => {
        const modifierDisplayGroup = this._getModifierDisplayGroupById(modifierDisplayGroupId);
        if (!modifierDisplayGroup)
            return true;

        const oItems = this._getItems();
        for (const oItem of oItems) {
            const modCount = oItem.Modifiers.filter(x => x.ModifierDisplayGroupId === modifierDisplayGroup.ModifierDisplayGroupId).length;

            if (modifierDisplayGroup.MaxSelectionCount > 1
                && !modifierDisplayGroup.MustToggle
                && modifierDisplayGroup.IsVisible
                && modCount >= modifierDisplayGroup.MaxSelectionCount) {
                DialogCreators.messageBoxOk(Names("Error_ReachedMaxNumberOfModifiersOnItem"), globalThis.aOLO.buttonHoverStyle);
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if the total modifiers for a modifier display group exceed the maximum allowed.
     * @param {number} modifierDisplayGroupId - The ID of the modifier display group.
     * @returns {boolean} True if within the limit, false otherwise.
     */
    private _checkModifiersMax = (modifierDisplayGroupId: number): boolean => {
        const modifierDisplayGroup = this._getModifierDisplayGroupById(modifierDisplayGroupId);
        if (!modifierDisplayGroup)
            return true;

        if (modifierDisplayGroup.MaxSelectionCount == 1 || modifierDisplayGroup.MustToggle || !modifierDisplayGroup.IsVisible)
            return true;

        const oItems = this._getItems();
        for (const oItem of oItems) {
            const mCount = oItem.Modifiers.reduce((count, mod) => {
                if (!mod.IsDefault) {
                    const dGroup = this._getModifierDisplayGroup(mod.ModifierId);
                    if (dGroup && !dGroup.MustToggle && dGroup.MaxSelectionCount !== 1)
                        count++;
                }
                return count;
            }, 0);

            if (mCount >= this._mItem.MaximumModifierCount) {
                DialogCreators.messageBoxOk(Names("Error_ReachedMaxNumberOfModifiersOnItem"), globalThis.aOLO.buttonHoverStyle);
                return false;
            }
        }

        return true;
    }

    /**
     * Retrieves the modifier display group for a given modifier ID.
     * @param {number} modifierId - The ID of the modifier.
     * @returns {IDataModifierDisplayGroup | null} The modifier display group or null if not found.
     */
    private readonly _getModifierDisplayGroup = (modifierId: number): IDataModifierDisplayGroup | null => {
        const modifierDisplayGroups = this._data.getProperty("ModifierDisplayGroups");
        for (const modifierDisplayGroup of this._mItem.ModifierDisplayGroups) {
            const modifier = modifierDisplayGroup.Modifiers.find(m => m.ModifierId === modifierId);
            if (modifier)
                return modifierDisplayGroups.find(x => x.ModifierDisplayGroupId === modifierDisplayGroup.ModifierDisplayGroupId) || null;
        }

        return null;
    }

    /**
     * Retrieves the first selected modifier for a given modifier display group.
     * @param {number} ModifierDisplayGroupId - The ID of the modifier display group.
     * @returns {ICustomizeItemModifier | null} The first selected modifier or null if none is selected.
     */
    private readonly _getFirstModifierByModifierDisplayGroup = (ModifierDisplayGroupId: number): ICustomizeItemModifier | null => {
        return this._item.modifiers.find(x => x.modifierDisplayGroupId === ModifierDisplayGroupId && x.isSelected) || null;
    }

    /**
     * Updates the pre-modifier IDs for all selected modifiers in the item.
     */
    private readonly _updateModifiersPreModifiers = (): void => {
        for (const modifier of this._item.modifiers) {
            if (modifier.modifier)
                modifier.modifier.PreModifierId = modifier.premodifierId;
        }
    }

    /**
     * Updates the calorie and price information for all sizes and modifiers.
     */
    private readonly _updateAllCalories = (): void => {
        this._updateSizeCalories();
        this._updateModifierCalories();
    }

    /**
     * Updates the calorie and price information for all available sizes.
     */
    private readonly _updateSizeCalories = (): void => {
        const sizes = OnlineOrderingUtil.GetItemSizes(this._mItem, this._order.getProperty("OrderTypeID"));

        for (const size of sizes) {
            const div = document.getElementById(`spn_customize_item_modifier_size_price_cal_${size.SizeId}`);
            if (!div)
                continue;

            const caloriesNames = this._getItemCaloriesBySize(this.firstItem.Modifiers, this.secondItem.Modifiers, this._order.getProperty("OrderTypeID"), size.SizeId);
            const calories = Common.GetName(caloriesNames, globalThis.aOLO.Temp.languageCode) || null;

            const price = Util.formatMoney(OnlineOrderingUtil.getItemPriceForSize(this._mItem.Prices, size.SizeId));
            const priceCalories = this._getPriceAndCaloriesSpan(price, calories);
            div.innerHTML = priceCalories;
        }
    }

    /**
     * Updates the calorie and price information for all selected modifiers.
     */
    private readonly _updateModifierCalories = (): void => {
        for (const modifier of this._item.modifiers) {
            const div = document.getElementById(`spn_customize_item_modifier_price_cal_${modifier.modifierDisplayGroupId}_${modifier.id}`);
            if (!div)
                continue;

            const modifierData = this._data.getProperty("Modifiers").find(x => x.ModifierId === modifier.id);
            const modifierDisplayGroups = this._mItem.ModifierDisplayGroups.find(x => x.ModifierDisplayGroupId === modifier.modifierDisplayGroupId);
            const iModifier = modifierDisplayGroups?.Modifiers.find(x => x.ModifierId === modifier.id);

            if (!iModifier || !modifierData)
                continue;

            const calories = this._getModifierCalories(iModifier, modifierData);
            const price = this._getModifierPrice();
            div.innerHTML = this._getPriceAndCaloriesSpan(price, calories);
        }
    }

    /**
     * Retrieves the price for a modifier.
     * @returns {string} The formatted price for the modifier.
     */
    private readonly _getModifierPrice = (): string => {
        return ""; // Placeholder for actual logic to retrieve modifier price.
    }

    /**
     * Reduces the item quantity by one when the "subtract" button is clicked.
     * Prevents reducing below 1.
     */
    private readonly _subtractQuantityOnClick = (): void => {
        if (this._item.quantity > 1)
            this._setQuantity(this._item.quantity - 1);
    }

    /**
     * Increases the item quantity by one when the "add" button is clicked.
     */
    private readonly _addQuantityOnClick = (): void => {
        this._setQuantity(this._item.quantity + 1);
    }

    /**
     * Handles the "Add to Order" button click event asynchronously.
     * Validates the item configuration, adds the selected items to the order,
     * and triggers additional processes such as meal deal handling and coupon application.
     */
    private readonly _addToOrderOnClickAsync = async (): Promise<void> => {
        let itemKey = -1;

        const oItem1 = this._createOrderItem(this.firstItem);
        const oItem2 = this._createOrderItem(this.secondItem);

        const modifierDisplayGroups = this._data.getProperty("ModifierDisplayGroups");
        const isValid = this._validateItemConfiguration(oItem1, oItem2, modifierDisplayGroups);

        if (isValid) {
            itemKey = await this._addSelectedToOrderContinueAsync();
            if (itemKey == -1)
                return;
        }

        this._handlePostOrderProcesses(itemKey);
    }

    /**
     * Validates the item configuration for both halves (if applicable) and checks if it is complete.
     * @param {IOrderItem} oItem1 - The first half or full item.
     * @param {IOrderItem} oItem2 - The second half item, if applicable.
     * @param {IDataModifierDisplayGroup[]} modifierDisplayGroups - The list of modifier display groups.
     * @returns {boolean} True if the item configuration is valid, false otherwise.
     */
    private readonly _validateItemConfiguration = (oItem1: IOrderItem, oItem2: IOrderItem, modifierDisplayGroups: IDataModifierDisplayGroup[]): boolean => {
        let halfChecked1 = true;
        let halfChecked2 = true;

        if (this._isHalfHalf()) {
            halfChecked1 = OnlineOrderingUtil.IsItemComplete(this._mItem, oItem1, modifierDisplayGroups, true, Names("Error_SelectToppings1Half"), globalThis.aOLO);
            halfChecked2 = OnlineOrderingUtil.IsItemComplete(this._mItem, oItem2, modifierDisplayGroups, halfChecked1, Names("Error_SelectToppings2Half"), globalThis.aOLO);
        } else
            halfChecked1 = OnlineOrderingUtil.IsItemComplete(this._mItem, oItem1, modifierDisplayGroups, true, null, globalThis.aOLO);

        return halfChecked1 && halfChecked2;
    }

    /**
     * Handles post-order processes such as meal deal handling, coupon application, and loyalty updates.
     * @param {number} itemKey - The key of the added item in the order.
     */
    private readonly _handlePostOrderProcesses = async (itemKey: number): Promise<void> => {
        // 2DO: Convert This to an event bus event
        const mealDealFinished = await OnlineOrderingUtil.MealDealAddToOrder(itemKey, globalThis.aOLO);

        if (mealDealFinished) {
            delete globalThis.aOLO.Dialog.MealDeal;
        } else if (!this._isEditing()) {
            await this._order.autoApplyCouponsAsync(this._loyaltyProvider);
        }
        //////////////////////

        await this._loyaltyProvider.batchComparison(true);

        if (this._dataLayer && itemKey !== -1) {
            const addedItem = this._order.getProperty("Items").find(x => x.ItemKey === itemKey);
            this._dataLayer.add_to_cart(addedItem);
        }
    }

    /**
     * Adds the selected item(s) to the order asynchronously, handling editing, coupon removal, and half-half logic.
     * @returns {Promise<number>} The key of the added item in the order.
     */
    private _addSelectedToOrderContinueAsync = async (): Promise<number> => {
        let removedCouponIds: IRemovedCoupon[] = [];

        if (this._isEditing())
            removedCouponIds = await this._handleEditingCoupons();

        const itemKey = this._order.getNextItemKey();
        const halfIndex = this._isHalfHalf() ? 1 : 0;
        const item = this._prepareOrderItem(this.firstItem, itemKey, halfIndex, undefined, removedCouponIds);

        // 2DO: Add Instruction Info
        //if (item.Instructions && document.getElementById("icon_customize_item_instructions_title")?.classList.contains('icon-show-less')) {
        //    const chkInstruction = Array.from(document.getElementsByName('chk_customize_item_Instructions')) as HTMLInputElement[];
        //    for (const instruction of chkInstruction) {
        //        if (instruction.checked)
        //            item.Instructions.push({ CommentId: parseInt(instruction.value) });
        //    }
        //}

        let item2 = null;
        if (this._isHalfHalf())
            item2 = this._prepareOrderItem(this.secondItem, itemKey, 2, item.ItemRecId);

        if (this._isEditing())
            await this._handlePostEditingAdjustments(item, removedCouponIds);

        this._finalizeOrderItems(item, item2);

        // 2DO: Refactor this
        this.close();

        //if (this._localAolo.Dialog.CustomizeItem)
        //    this.CloseDialog();
        return item.ItemKey;
    }

    /**
     * Handles coupon adjustments when editing an existing item.
     * @returns {Promise<IRemovedCoupon[]>} The list of removed coupons.
     */
    private readonly _handleEditingCoupons = async (): Promise<IRemovedCoupon[]> => {
        const currentItem = this._item.itemsToEdit[0];
        const couponKeys = this._order.getProperty("ItemsCoupons").filter(x => x.ItemKey == currentItem.ItemKey).map(x => x.CouponKey);

        const redeemItemCouponKey = this._order.getProperty("Coupons").filter(x => couponKeys.includes(x.CouponKey)).find(x => x.CouponId == CouponIdList.LOYALTY_REDEEM_ITEM);

        if (redeemItemCouponKey) {
            const loyaltyItems = this._data.getProperty("LoyaltyItems");
            const points = OnlineOrderingUtil.GetLoyaltyPoints(currentItem.ItemId, currentItem.SizeId, loyaltyItems);
            await OnlineOrderingUtil.redeemPointsAsync(points, currentItem.ItemKey);
        }

        return this._order.removeCouponByItemKeyAsync(this._loyaltyProvider, currentItem.ItemKey);
    }

    /**
     * Prepares an order item for addition to the order.
     * @param {ICustomizeItemBaseItem} baseItem - The base item data.
     * @param {number} itemKey - The key of the item.
     * @param {number} halfIndex - The half index (1 for first half, 2 for second half).
     * @returns {IOrderItem} The prepared order item.
     */
    private readonly _prepareOrderItem = (baseItem: ICustomizeItemBaseItem, itemKey: number, halfIndex: number, itemRecId?: number, removedCouponIds?: IRemovedCoupon[]): IOrderItem => {
        const item = this._createOrderItem(baseItem);

        item.BeforeTaxDiscount = 0;
        item.AfterTaxDiscount = 0;
        item.DiscountedMarkedQuantity = 0;
        item.DiscountedQuantity = 0;
        item.ItemKey = itemKey;
        item.ItemRecId = this._order.getItemRecordId(itemRecId ? [itemRecId] : []);
        item.HalfIndex = halfIndex;
        item.HalfCount = this._isHalfHalf() ? 2 : 1;
        item.Edit = true;
        item.Instructions = this._item.instructions;

        if (this._isEditing()) {
            if (halfIndex !== 2) {
                item.ItemKey = this._item.itemsToEdit[0].ItemKey;
                item.ItemRecId = this._item.itemsToEdit[0].ItemRecId;
                item.IsRedeemed = this._item.itemsToEdit[0].IsRedeemed;

                if (item.SizeId != this._item.itemsToEdit[0].SizeId) {
                    item.IsRedeemed = false;
                    if (removedCouponIds)
                        removedCouponIds = removedCouponIds.filter(x => x.couponId != CouponIdList.LOYALTY_REDEEM_ITEM);
                }
            } else {
                item.ItemKey = this._item.itemsToEdit[1].ItemKey;
                item.ItemRecId = this._item.itemsToEdit[1].ItemRecId;
            }
        }

        OnlineOrderingUtil.TaxItem(this._mItem, item, this._getItemTaxDataDependencies());
        return item;
    }

    /**
     * Handles adjustments to the order items and coupons after editing an existing item.
     * @param {IOrderItem} item - The first order item.
     * @param {IOrderItem | null} item2 - The second order item, if applicable.
     * @param {IRemovedCoupon[]} removedCouponIds - The list of removed coupons.
     */
    private readonly _handlePostEditingAdjustments = async (item: IOrderItem, removedCouponIds: IRemovedCoupon[]): Promise<void> => {
        if (this._item.itemsToEdit.length === 2 && !this._isHalfHalf())
            this._order.removeItemByItemRecId(this._item.itemsToEdit[1].ItemRecId);

        for (const coupon of removedCouponIds) {
            if (coupon.couponId === CouponIdList.LOYALTY_REDEEM_ITEM) {
                const loyaltyItems = this._data.getProperty("LoyaltyItems");
                const points = OnlineOrderingUtil.GetLoyaltyPoints(item.ItemId, item.SizeId, loyaltyItems);
                item.IsRedeemed = false;
                await OnlineOrderingUtil.redeemPointsAsync(points, item.ItemKey);
            } else {
                const params: IApplyCouponByIdParamsPartial = {
                    loyaltyProvider: this._loyaltyProvider,
                    couponId: coupon.couponId,
                    rewardId: coupon.rewardId,
                    isBankedCurrency: coupon.isBankedCurrency,
                    showErrorMsg: true,
                };
                await this._order.applyCouponByIdAsync(params);
            }
        }
    }

    /**
     * Finalizes the addition of items to the order by updating the cart and closing dialogs.
     * @param {IOrderItem[]} items - The list of items to add to the order.
     */
    private readonly _finalizeOrderItems = (item: IOrderItem, item2: IOrderItem | null): void => {
        const items = (this._isHalfHalf() && item2) ? [item, item2] : [item];
        this._order.addItems(items);

        // 2DO: Trigger an event bus event or update cart as needed.
        // this._eventBus.emit(EventTypes.ITEM_ADD, []);
        // OnlineOrderingUtil.GUI_SetOrder(this._localAolo, aOLOModules);
    }

    /**
     * Toggles the visibility of the item comment box based on the OIC setting.
     */
    private readonly toggleItemCommentBoxVisibility = (): void => {
        // 2DO: Needs to be designed for GUI and implemented

        //if (this._data.getProperty("Settings").OIC) //setting 75
        //    Util.hideElement("div_customize_item_comment");
        //else
        //    Util.showElement("div_customize_item_comment");
    }
}