import EventEmitter from "eventemitter3";
import { Data } from "../../models/data";

export abstract class Page {
    protected _id: string;
    protected _element: HTMLElement | null;
    protected _eventBus: EventEmitter;
    protected _data: Data;

    constructor(id: string, eventBus: EventEmitter, data: Data) {
        this._id = id;
        this._element = null;
        this._eventBus = eventBus;
        this._data = data;
    }

    public init(): void {
        this._element = document.getElementById(this._id);
        this.hide();
    }

    public abstract initAsync(): Promise<void>;

    public show(): void {
        this._element && (this._element.style.display = "")
    }

    public hide(): void {
        this._element && (this._element.style.display = "none");
    }
}