import { AdoraHttpClient } from "../utils/http-client";
import { Util } from "../utils/util";
import { IAddDiscountToOrder, IBatchRedemptionProcess, IFetchGiftCardsBalance, IFetchGiftCards, IGetActiveDiscountBasket, IGetStoreLoyaltyConfigurationInfo, ILoyaltyServiceGenericResponse, IRemoveDiscountFromOrder, ICreateFinishOrderPayloadLoyaltyResult, IPostOrderResponse } from "./loyalty-service.interface";
import { Data } from "../models/data";
import { User } from "../models/user";
import { ISignIn, ISignInSSO, ISignInPhone, ISignInResponse, ISignInJWT } from "./profile-service.interface";
import { IUser } from "../models/user.interface";
import { IaOLOUser, IaOLOUserFavoriteStore, IaOLOUserLoyaltyReward } from "../models/user-olo.interface";

export class LoyaltyService {
    private readonly _apiLoyalty: string;
    private readonly _data: Data;
    private readonly _user: User;

    constructor(data: Data, user: User, apiLoyalty: string) {
        this._data = data;
        this._user = user;
        this._apiLoyalty = apiLoyalty;
    }

    /**
    * Makes an HTTP request.
    * @private
    * @async
    * @param {string} method - The HTTP method (e.g. 'GET', 'POST', 'PUT', 'DELETE').
    * @param {string} url - The URL of the HTTP resource.
    * @param {object|null} payload - The payload to send with the request.
    * @param {string|null} token - The authorization token to send with the request.
    * @returns {Promise<Response>} A promise that resolves to the HTTP response.
    */
    private readonly _httpRequest = async (method: string, url: string, payload: object | null, token: string | null = null, refreshToken: string | null = null, showProgress: boolean = true, noCache: boolean = true): Promise<Response> => {
        let businessDate = new Date().toISOString();
        try {
            businessDate = Util.GetBUSDate(Util.NowStore(globalThis.aOLO.Temp.TimeOffset), globalThis.aOLO).toISOString();
        } catch { }
        const response = await AdoraHttpClient.httpRequest(showProgress, method, url, payload, token, refreshToken, noCache, this._data.getProperty("StoreInfo")?.storeKey, businessDate);

        //if (response.status !== 401) {
        //    if (response.status === 408)
        //        return response;

        //    //// Check for new JWT
        //    //if (response.headers.has('Authorization')) {
        //    //    const authHeader = response.headers.get('Authorization');
        //    //    const token = authHeader?.split(' ')[1] || "";
        //    //    AdoraHttpClient.setJWT(token);
        //    //}
        //    //if (response.headers.has('rtk')) {
        //    //    const refreshToken = response.headers.get('rtk');
        //    //    if (refreshToken)
        //    //        AdoraHttpClient.setRefreshToken(refreshToken);
        //    //}
        //    //return response;
        //}

        //this.notAuthHttpResourceEvent();
        return response;
    }

    /**
     * Callback function for not authorized HTTP resource event.
     * @returns {void}
     */
    public notAuthHttpResourceEvent = (): void => {
    }

    /*********************************
     ******* Loyalty API Calls *******
     *********************************/

    /**
     * Registers a new user account.
     * @async
     * @param cp A complex object representing the user's profile and contact information.
     * @returns {Promise<number | null>} The response data from the server, or null if the request failed.
     */
    public signUp = async (cp: any): Promise<number | null> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": this._data.getProperty("StoreInfo")?.storeKey, "location": "olo", "profile": cp }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/sign_up`, payload);
        if (response.status !== 200) {
            //DialogCreators.messageBoxOk(await AdoraHttpClient.getResponsePayload(response));//DialogCreators.messageBoxOk(await AdoraHttpClient.getResponsePayload(response));
            return null;
        }

        const data = await AdoraHttpClient.getResponsePayload(response);
        if (data.success)
            return data.data;

        return null;
    }

    /**
    * Attempts to log in the user with the given credentials.
    * @async
    * @param brandId The ID of the brand to log in to.
    * @param email The user's email address.
    * @param password The user's password.
    * @param culture The user's preferred language/culture.
    * @param rememberMe The user's selection of the "Remember Me" option.
    * @returns {Promise<ISignInResponse>} 
    *          An object with a `success` property indicating whether the login was successful, and optionally a `user` property containing the user's data if the login was successful.
    */
    public signIn = async (data: ISignIn): Promise<ISignInResponse> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": this._data.getProperty("StoreInfo").storeKey, "profile": { "EML": data.email, "pwd": data.password }, "is_app": data?.app, "remember_me": data?.rememberMe, "incomplete_profile": data?.incompleteProfile, "location": "olo" }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/sign_in`, payload);

        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.User);
                const jwt = data.JWT;
                const token = data.Token ?? null;
                const refreshToken = data.RefreshToken;

                return { success: true, user: user, jwt: jwt, refreshToken: refreshToken, token: token };
            }
            return { success: false }
        }
        return { success: false, timeout: response.status === 408 };
    }

    public signInJWT = async (data: ISignInJWT): Promise<ISignInResponse> => {
        const encryptedPayload = await Util.encrypt(JSON.stringify({ store_key: this._data.getProperty("StoreInfo")?.storeKey }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/get_user`, encryptedPayload, data.jwt, data.refreshToken, true);

        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.profile);
                const jwt = data.jwt;
                return { success: true, user: user, jwt: jwt }
            }
            return { success: false };
        }
        return { success: false, timeout: response.status === 408 };
    }

    public signInSSO = async (data: ISignInSSO): Promise<ISignInResponse> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/sign_in_sso`, payload);

        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.User);
                const jwt = data.JWT;
                const token = data.Token ?? null;
                const refreshToken = data.RefreshToken;
                return { success: true, user: user, jwt: jwt, refreshToken: refreshToken, token: token };
            }
            return { success: false }
        }
        return { success: false, timeout: response.status === 408 };
    }

    public signInApp = async (appToken: string): Promise<ISignInResponse> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": this._data.getProperty("StoreInfo")?.storeKey, "appToken": appToken }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/sign_in_by_app`, payload);
        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.User);
                const jwt = data.JWT;
                const token = data.Token;
                return { success: true, user: user, jwt: jwt, token: token };
            }
            return { success: false }
        }
        return { success: false, timeout: response.status === 408 };
    }

    public signInPhone = async (data: ISignInPhone): Promise<ISignInResponse> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/customer/sign_in_by_phone`, payload);
        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.User);
                const jwt = data.JWT;
                const token = data.Token ?? null;
                const refreshToken = data.RefreshToken;
                return { success: true, user: user, jwt: jwt, refreshToken: refreshToken, token: token, timeout: false };
            }
            return { success: false }
        }
        return { success: false, timeout: response.status === 408 };
    }

    public async addDiscountToOrder(payload: IAddDiscountToOrder): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/discount/add_selection_to_basket`, encryptedPayload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken(), true);

        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async removeDiscountFromOrder(payload: IRemoveDiscountFromOrder): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/discount/remove_item_from_basket`, encryptedPayload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken(), true);

        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async getActiveDiscountBasket(payload: IGetActiveDiscountBasket): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/discount/get_active_basket`, encryptedPayload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken(), true);

        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async fetchGiftCards(payload: IFetchGiftCards): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/fetch_gift_cards`, encryptedPayload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken(), true);
        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async fetchGiftCardsBalance(payload: IFetchGiftCardsBalance): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/fetch_gift_cards_balance`, encryptedPayload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken(), true);
        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async postOrderAsync(payload: ICreateFinishOrderPayloadLoyaltyResult): Promise<IPostOrderResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/post_order`, encryptedPayload, null, null, true);
        if (response.status !== 200)
            return { success: false, message: await AdoraHttpClient.getResponsePayload(response) };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, message: data.message };
    }

    /****************************
     ******* Punchh Calls *******
     ****************************/

    public getStoreLoyaltyConfigurationInfo = async (payload: IGetStoreLoyaltyConfigurationInfo): Promise<ILoyaltyServiceGenericResponse> => {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/configuration`, encryptedPayload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken(), true);
        if (response.status == 401)
            return { success: false, data: null, message: "Unauthorized", timeout: false };

        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: null, timeout: false };
    }

    public async batchRedemptionProcess(payload: IBatchRedemptionProcess): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/discount/batch_redemption`, encryptedPayload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken(), true);
        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    private readonly _mapUser = (data: IaOLOUser): IUser => {
        const user: IUser = {
            CustomerId: data.CID,
            ProfileId: data.PID,
            StoreId: data.SID,
            Name: data.NAM,
            LastName: data.LNAM,
            Phone: data.PHN,
            CountryCodeId: data.CCDID,
            Email: data.EML,
            IsMarketingSurvey: data.ISV,
            IsMarketingEmail: data.IEML,
            IsMarketingText: data.ITXT,
            MarketingPhone: data.MKPHN,
            MarketingCountryId: data.MKCCDID,
            BirthDate: data.BD,
            IsFavoriteStore: data.FAVST,
            FavoriteStoreId: data.FAVSTID,
            FavoriteStores: data.FAVSTRs?.map((x: IaOLOUserFavoriteStore) => x.SKEY) || [],
            CultureCode: data.CLT,
            IsDarkMode: data.IDK,
            IsLoyalty: data.ILY,
            IsBlocked: data.IBLKD,
            IsAge13: data.A13,
            TermsPrivacyPolicy: data.TPP,
            Addresses: data.ADRS,
            Allergies: data.ALGS,
            Dietary: data.DITS,
            Wallet: data.WLTS,
            IsProfileComplete: data.IPC,
            LoyaltyData: data.LOYDT ? {
                Rewards: data.LOYDT.rewards?.map((reward: IaOLOUserLoyaltyReward) => ({
                    RewardId: reward.RID,
                    CouponId: reward.CPID,
                    ExpiryDate: reward.EXDT,
                    Expired: reward.EXP,
                    Names: reward.NAMs
                })) || [],
                CurrentPoints: data.LOYDT.current_points,
                EarnedPoints: data.LOYDT.earned_points,
                PointsLastUpdatedDate: data.LOYDT.points_last_updated_date,
                LoyaltyJoinedDate: data.LOYDT.loyalty_joined_date,
                TierId: data.LOYDT.tier,
                TierName: data.LOYDT.tier_name || null,
                BankedCurrency: data.LOYDT.banked_currency || null,
                UnbankedPoints: data.LOYDT.unbanked_points || null,
            } : null,
            Offers: data.Offers || null,
            DefaultStore: data.DEFST ? {
                StoreKey: data.DEFST.SKEY,
                StreetNumber: data.DEFST.STRNO,
                Address1: data.DEFST.ADDR1,
                Address2: data.DEFST.ADDR2,
                CountryId: data.DEFST.CID
            } : null
        };

        return user;
    }
}