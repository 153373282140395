import EventEmitter from "eventemitter3";
import { EventTypes } from "../core/event-bus";
import { Pages } from "../types/enums";
import { Page } from "../components/pages/page";

export class Router {
    private readonly _eventBus: EventEmitter;
    private readonly _routes: Record<Pages, Page>;
    private _route: Pages;

    constructor(eventBus: EventEmitter, routes: Record<Pages, Page>, route: Pages) {
        this._eventBus = eventBus;
        this._routes = routes;
        this._route = route;

        if (this._route != Pages.LANDING) window.history.pushState({}, "", Pages.LANDING);

        this.forPages(page => page.init());
        this._eventListeners();
        this._navigate(this._route);
    }

    public readonly forPages = (fn: (_: Page) => void): void => {
        return Object.keys(Pages).forEach(
            page => fn(this._routes[Pages[page as keyof typeof Pages]])
        )
    }

    private readonly _eventListeners = (): void => {
        this._eventBus.on(EventTypes.LOCATION_CHANGE, (page: Pages, back: boolean) => {
            if (back)
                this._update(page);
            else
                this._navigate(page);
        });

        window.addEventListener("popstate", () => {
            this._update(window.location.pathname as Pages)
            this._eventBus.emit(EventTypes.LOCATION_CHANGE, window.location.pathname as Pages, true)
        });

        this._eventBus.on(EventTypes.DATA_RETRIEVED, () => {
            this.forPages(page => page.initAsync())
        });
    }

    private readonly _update = (page: Pages): void => {
        this._routes[this._route].hide();
        this._route = page;
        this._routes[this._route].show();
    }

    private readonly _navigate = (page: Pages): void => {
        const url = location.origin + page;
        window.history.pushState({}, "", url);
        this._update(page)
    }
}