import { IStoreLocation, IStoreLocationZone } from "../components/pages/Locations/locations.interface";
import { Data } from "../models/data";
import { AdoraHttpClient } from "../utils/http-client";
import { Util } from "../utils/util";

export class LocationService {
    private readonly _data: Data;
    private readonly _apiLocation: string;

    constructor(data: Data, apiLocation: string) {
        this._data = data;
        this._apiLocation = apiLocation;
    }

    private readonly _httpRequest = async (method: string, url: string, payload: object | null, token: string | null = null, refreshToken: string | null = null, showProgress: boolean = true, noCache: boolean = true): Promise<Response> => {
        let businessDate = new Date().toISOString();
        try {
            businessDate = Util.GetBUSDate(Util.NowStore(globalThis.aOLO.Temp.TimeOffset), globalThis.aOLO).toISOString();
        } catch { }
        const response = await AdoraHttpClient.httpRequest(showProgress, method, url, payload, token, refreshToken, noCache, this._data.getProperty("StoreInfo")?.storeKey, businessDate);

        if (response.status !== 401) {
            // Check for new JWT
            if (response.headers.has('Authorization')) {
                const authHeader = response.headers.get('Authorization');
                const token = authHeader?.split(' ')[1] || "";
                AdoraHttpClient.setJWT(token);
            }
            if (response.headers.has('rtk')) {
                const refreshToken = response.headers.get('rtk');
                if (refreshToken)
                    AdoraHttpClient.setRefreshToken(refreshToken);
            }
            return response;
        }

        return response;
    }

    public loadDeliveryZonesForLocations = async (storeIds: number[]): Promise<{ storeKey: string, zones: IStoreLocationZone[] }[]> => {
        const response = await this._httpRequest("GET", `${this._apiLocation}stores-delivery-zones/?stores=${JSON.stringify(storeIds)}`, null, null);
        if (response.status == 200) {
            const respData = await AdoraHttpClient.getResponsePayload(response);
            if (respData.result != "")
                return this._mapLocationDeliveryZonesData(JSON.parse(respData.result));
        }
        return [];
    }

    private readonly _mapLocationDeliveryZonesData = (data: any): { storeKey: string, zones: IStoreLocationZone[] }[] => {
        const mappedData = data.map((x: any) => ({
            storeKey: x.SKEY,
            zones: x.ZONs?.map((y: any) => ({
                storeId: y.SID,
                id: y.ZID,
                typeId: y.ZTID,
                charge: y.ZCHRG,
                minimumDeliveryAmount: y.MINDEL,
                isLimitedTime: y.ILT,
                limitedTimes: y.LIMTIME?.map((z: any) => ({
                    weekdayId: z.WID,
                    startMinute: z.SMIN,
                    endMinute: z.EMIN
                })) || [],
                polygons: y.PGON?.map((z: any) => ({
                    latitude: z.LAT,
                    longitude: z.LON
                })) || []
            })) || []
        })) || [];

        return mappedData;
    }

    /**
     * Loads the locations associated with the given brand ID
     * @async
     * @param {string} brandId - The ID of the brand whose locations to load
     * @return {Promise<ILocation[]>} A Promise that resolves to an array of locations associated with the given brand ID, or an empty array if no locations were found
     */
    public loadLocations = async (brandId: string): Promise<IStoreLocation[]> => {
        const response = await this._httpRequest("GET", `${this._apiLocation}stores/${brandId}`, null, null);
        if (response.status == 200) {
            const respData = await AdoraHttpClient.getResponsePayload(response);
            if (respData.result != "")
                return this._mapLocationsData(JSON.parse(respData.result));
        }
        return [];
    }

    private readonly _mapLocationsData = (data: any): IStoreLocation[] => {
        const mappedData = data.map((x: any) => ({
            storeKey: x.SKEY,
            storeId: x.SID,
            name: x.NAM,
            phone: x.PHN,
            streetNumber: x.SNUM,
            address1: x.ADD1,
            address2: x.ADD2,
            address3: x.ADD3,
            address4: x.ADD4,
            address5: x.ADD5,
            city: x.CITY,
            state: x.STA,
            zip: x.ZIP,
            countryId: x.CID,
            latitude: x.LAT,
            longitude: x.LON,
            statusId: x.STATID,
            url: x.OOURL,
            settings: x.SETTs.VAL,
            zones: [],
            cities: x.CITs?.map((y: any) => y.CITY) || [],
            orderTypes: x.OTs?.map((y: any) => y.OTID) || [],
            zipCodes: x.ZIPs?.map((y: any) => y.ZIP) || [],
            holidays: x.HOLs?.map((y: any) => ({
                id: y.HID,
                statusId: y.HSID,
                startMinute: y.SMIN,
                endMinute: y.EMIN
            })) || [],
            hours: x.HRs?.map((y: any) => ({
                weekdayId: y.WID,
                startMinute: y.SMIN,
                endMinute: y.EMIN
            })) || []
        })) || [];

        return mappedData;
    }
}