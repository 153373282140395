import EventEmitter from "eventemitter3";
import { Data } from "../../../models/data";
import { Page } from "../page";

export class Rewards extends Page {
    constructor(eventBus: EventEmitter, data: Data) {
        super("page_rewards", eventBus, data)
    }

    public async initAsync(): Promise<void> { }
}