import { IThirdPartyAddressModel } from "../online-ordering/interfaces/order-type.interface";
import {
    IServiceCompleteOrder, IServiceGetCouponCodeById,
    IServiceGetCouponByCode, IServiceGetCouponById,
    IServiceGetGiftCardBalance, IServiceGetLastWaitTimes, IServiceGetOrderNumberId, IServiceGetServerTime,
    IServiceProposedOrder, IServiceSaveOrder, IServiceValidatePromotionCode, IServiceValidatePromotionCodeResult, IServiceGetCouponByCodeOnly,
    IGetOrderItem,
    ICheckAddress
} from "./order-service.interface";
import { AdoraHttpClient } from "../utils/http-client";
import { Util } from "../utils/util";
import { Order } from "../models/order";
import { IUserRecentOrderDetails } from "../models/user.interface";

export class OrderService {
    private _order: Order;
    private _apiOrder: string;
    private _apiOnlineOrdering: string;

    constructor(order: Order, apiOrder: string, apiOnlineOrdering: string) {
        this._order = order;
        this._apiOrder = apiOrder;
        this._apiOnlineOrdering = apiOnlineOrdering;
    }

    get order(): Order {
        return this._order;
    }

    /**
    * Makes an HTTP request.
    * @private
    * @async
    * @param {string} method - The HTTP method (e.g. 'GET', 'POST', 'PUT', 'DELETE').
    * @param {string} url - The URL of the HTTP resource.
    * @param {object|null} payload - The payload to send with the request.
    * @param {string|null} token - The authorization token to send with the request.
    * @returns {Promise<Response>} A promise that resolves to the HTTP response.
    */
    private _httpRequest = async (method: string, url: string, payload: object | null, token: string | null = null, refreshToken: string | null = null, showProgress: boolean = true, noCache: boolean = true): Promise<Response> => {
        let response = await AdoraHttpClient.httpRequest(showProgress, method, url, payload, token, refreshToken, noCache);

        if (response.status === 500) {
            const result = await AdoraHttpClient.getResponsePayload(response);
        }

        return response;
    }

    public postJSError = async (data: string): Promise<void> => {
        await this._httpRequest('POST', `${this._apiOnlineOrdering}js-error`, { "data": data }, null, null, false);
    }

    /**
     * Fetches the items associated with the given order ID
     * @async
     * @param {number} orderId - The ID of the order whose items to fetch
     * @return {Promise<string | null>} A Promise that resolves to a JSON string representing the items associated with the given order ID, or null if the request failed or no items were found
     */
    public getOrderItems = async (orderId: number): Promise<IGetOrderItem[]> => {
        const response = await this._httpRequest("GET", `${this._apiOrder}${orderId}`, null, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : [];
    }

    public getServerTime = async (storeKey: string): Promise<IServiceGetServerTime | null> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}get-server-date`, { "SKY": storeKey }, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    public thirdPartyEstimate = async (data: IThirdPartyAddressModel): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}third-party-estimate`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    public validatePromotionCode = async (data: IServiceValidatePromotionCode): Promise<IServiceValidatePromotionCodeResult> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}validate-promotion-code`, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public invalidatePromotionCode = async (data: IServiceValidatePromotionCode): Promise<IServiceValidatePromotionCodeResult> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}invalidate-promotion-code`, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public getOrderNumberId = async (data: any): Promise<IServiceGetOrderNumberId> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}get-order-number-id`, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public proposedOrder = async (data: any): Promise<IServiceProposedOrder> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}proposed-order`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public completeOrder = async (data: any): Promise<IServiceCompleteOrder> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}complete-order`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public saveOrder = async (order: string): Promise<IServiceSaveOrder> => {
        const payload = await Util.encrypt(order, globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrder}save-order`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, message: "" };
    }

    public saveOrderPayment = async (order: string): Promise<IServiceSaveOrder> => {
        const payload = await Util.encrypt(order, globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrder}save-order-payment`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, message: "" };
    }

    public getLastWaitTimes = async (storeKey: string): Promise<IServiceGetLastWaitTimes> => {
        const response = await this._httpRequest('POST', `${this._apiOrder}get-last-wait-times`, { "SKY": storeKey }, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    public getGiftCardBalance = async (data: { SKY: string, CNO: string, PIN: string }): Promise<IServiceGetGiftCardBalance> => {
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}get-gift-card-balance`, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, cardNo: "", balance: 0 };
    }

    public getCouponById = async (data: { CID: number, SKY: string }): Promise<IServiceGetCouponById> => {
        const response = await this._httpRequest('POST', `${this._apiOrder}get-coupon-by-id`, data, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, coupon: "", serverError: true };
    }

    public getCouponByCouponCode = async (data: IServiceGetCouponByCodeOnly): Promise<IServiceGetCouponCodeById> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrder}get-coupon-by-code`, payload, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, coupon: "", serverError: true };
    }

    public getCouponByPromotionCode = async (data: IServiceGetCouponByCode): Promise<IServiceGetCouponById> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrder}get-coupon-by-promotion-code`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, coupon: "", serverError: true };
    }

    public getCouponByCampaignCode = async (data: IServiceGetCouponByCode): Promise<IServiceGetCouponById> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOrder}get-coupon-by-campaign-code`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, coupon: "", serverError: true };
    }

    public checkAddress = async (data: ICheckAddress): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}check-address`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    public getLimitedTimes = async (data: any): Promise<{ success: boolean, data: { min: number, cnt: number }[] }> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}get-limited-times`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, data: [] };
    }

    public reserveLimitedTimes = async (data: any): Promise<{ success: boolean, data: { min: number, cnt: number }[] | null }> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}reserve-limited-times`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false, data: null };
    }

    public resetLimitedTimeTimer = async (data: any): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}reset-limited-times-timer`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    public removeOrderLimitedTimes = async (data: any): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiOnlineOrdering}remove-order-limited-times`, payload, null, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**
     * Fetches the recent orders of the user, up to 3 orders per page.
     * @async
     * @param {number} pageNumber - the page number to fetch. Starts from 0
     * @returns {Promise<string | null>} a promise that resolves to a json representing the recent orders of user(up to 3), or null if the request failed
     */
    public getRecentOrdersByPage = async (pageNumber: number): Promise<IUserRecentOrderDetails> => {
        const response = await this._httpRequest("GET", `${this._apiOrder}recent-order/${pageNumber}`, null, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : [];
    }
}