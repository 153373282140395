import { DialogCreators } from '../utils/dialog-creators';
import { Names } from './i18n';
import { IaOLO } from '../interfaces/aolo.interface';

/**
 * A utility class for making HTTP requests to a remote server.
 */
export class AdoraHttpClient {

    /**
     * Sets JWT token in local storage.
     * @param {string} jwt - The JWT token to set in local storage.
     * @returns {void}
     */
    public static setJWT(jwt: string): void {
        localStorage.setItem("tk", jwt);
    }

    public static setRefreshToken(refreshToken: string): void {
        localStorage.setItem("rtk", refreshToken);
    }

    public static getJWT(): string | null {
        return localStorage.getItem("tk");
    }

    public static getRefreshToken(): string | null {
        return localStorage.getItem("rtk");
    }

    public static setSSO(sso: string) {
        localStorage.setItem("sso", sso);
    }

    public static getSSO(): string | null {
        return localStorage.getItem("sso");
    }

    /**
     * Sends an HTTP request to the specified URL with the given method and payload.
     * @param {string} method - The HTTP method to use for the request (e.g. GET, POST, etc.).
     * @param {string} url - The URL to send the request to.
     * @param {object | null} payload - The payload to send with the request (optional).
     * @param {string | null} token - An authorization token to send with the request (optional).
     * @returns {Promise<Response>} - A Promise that resolves to the Response object representing the server's response.
     */
    static httpRequest = async (showProgress: boolean, method: string, url: string, payload: object | null, token: string | null = null, refreshToken: string | null = null, noCache: boolean = true, storeKey: string | null = null, businessDate: string | null = null): Promise<Response> => {
        const performHttpRequest = async (): Promise<Response> => {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json; charset=utf-8');

            if (storeKey)
                headers.append('X-Store-Key', storeKey);

            if (businessDate)
                headers.append('X-Business-Date', businessDate);

            if (noCache) {
                headers.append('pragma', 'no-cache');
                headers.append('cache-control', 'no-cache');
            }

            if (token)
                headers.append('Authorization', `Bearer ${token}`);

            if (refreshToken)
                headers.append('rtk', refreshToken);

            const init = {
                method: method,
                headers: headers,
                body: (payload) ? JSON.stringify(payload) : null
            };

            const request = new Request(url);
            const response = await fetch(request, init);
            return response;
        }

        //if (showProgress) {
        //    const response = await DialogCreators.ProgressWrapper(aOLO, performHttpRequest);
        //    return response;
        //} else {
        //    return await performHttpRequest();
        //}

        return await performHttpRequest();
    }

    /**
     * Returns the payload of an HTTP response as a JavaScript object.
     * @param {Response} response - The HTTP response to extract the payload from.
     * @returns {Promise<any>} - A Promise that resolves to the payload of the response as a JavaScript object.
     */
    static getResponsePayload = async (response: Response): Promise<any> => {
        return await response.json();
    }

    /**
     * Shows a default error message based on the status code of an HTTP response.
     * @param {Response} response - The HTTP response to show the error message for.
     * @returns {void}
     */
    static showDefaultError = (response: Response, localAolo: IaOLO): void => {
        if (response.status == 400) {
            DialogCreators.messageBoxOk(Names("400"), localAolo.buttonHoverStyle);
        } else if (response.status == 401) {
            DialogCreators.messageBoxOk(Names("401"), localAolo.buttonHoverStyle);
        } else if (response.status == 403) {
            DialogCreators.messageBoxOk(Names("403"), localAolo.buttonHoverStyle);
        } else if (response.status == 500) {
            DialogCreators.messageBoxOk(Names("500"), localAolo.buttonHoverStyle);
        } else if (response.status == 503) {
            DialogCreators.messageBoxOk(Names("503"), localAolo.buttonHoverStyle);
        } else {
            DialogCreators.messageBoxOk(Names("UnknownError"), localAolo.buttonHoverStyle);
        }
    }
}
