import { IUser } from '../models/user.interface';
import { IMyHistory } from '../shared/interfaces/history.interface';
import { IMyOrders } from '../shared/interfaces/my-orders.interface';
import { ISignIn, ISignInResponse, IChangePassword, IChangePasswordResponse,  IFetchFavoriteStoresResponse, IFetchOrderDetails, IGetPhoneCodeResponse, IForgotPassword, ISignUpResponse, ISignInPhone } from './profile-service.interface';
import { ITracker } from '../brand/interfaces/tracker.interface';
import { AdoraHttpClient } from '../utils/http-client';
import { Util } from '../utils/util';
import { Data } from '../models/data';
import { User } from '../models/user';
import { ILoyaltyProvider } from './loyalty-provider/loyalty-provider.interface';
import { IaOLOUser } from '../models/user-olo.interface';
import { IProfile } from './profile.interfaces';

/**
 * A service class that provides methods to manage user profile related functionality.
 * @class
 */
export class ProfileService {
    private _data: Data;
    private _user: User;
    private _apiProfile: string;
    private _apiLoyalty: string;
    private _loyaltyProvider: ILoyaltyProvider;

    constructor(data: Data, user: User, loyaltyProvider: ILoyaltyProvider, apiProfile: string, apiLoyalty: string) {
        this._apiProfile = apiProfile;
        this._apiLoyalty = apiLoyalty;
        this._data = data;
        this._user = user;
        this._loyaltyProvider = loyaltyProvider;
    }

    get user(): User {
        return this._user;
    }

    /**
     * Makes an HTTP request.
     * @private
     * @async
     * @param {string} method - The HTTP method (e.g. 'GET', 'POST', 'PUT', 'DELETE').
     * @param {string} url - The URL of the HTTP resource.
     * @param {object|null} payload - The payload to send with the request.
     * @param {string|null} token - The authorization token to send with the request.
     * @returns {Promise<Response>} A promise that resolves to the HTTP response.
     */
    private readonly _httpRequest = async (method: string, url: string, payload: object | null, token: string | null = null, refreshToken: string | null = null): Promise<Response> => {
        const response = await AdoraHttpClient.httpRequest(false, method, url, payload, token, refreshToken)
        //if (response.status === 500) {
        //    const response = await response.json();
        //}

        return response;
    }

    //public refreshToken = async (): Promise<void> => {
    //    await this._httpRequest("GET", `${this._apiProfile}refresh_token`, null, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
    //}

    public readonly signUp = async (profile: IProfile): Promise<boolean> => {
        const cp = this._mapProfile(profile)
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": this._data.getProperty("StoreInfo")?.storeKey || "", "location": "olo", "profile": cp }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiLoyalty}olo/sign_up`, payload);
        const data = await AdoraHttpClient.getResponsePayload(response);
        return data.success;
    }

    public readonly signIn = async (data: ISignIn): Promise<boolean> => {
        const response = await this._loyaltyProvider.signIn(data);

        if (response.success) {
            response.user && (this._user.user = response.user);
            response.jwt && AdoraHttpClient.setJWT(response.jwt);
            response.refreshToken && AdoraHttpClient.setRefreshToken(response.refreshToken)
            this._user.setLoggedIn(true);
        }

        return response.success;
    }

    public readonly signInSSO = async (): Promise<boolean> => {
        if (this._loyaltyProvider.hasSingleSignOn()) {
            const sso = AdoraHttpClient.getSSO();

            if (!sso) return false;

            const response = await this._loyaltyProvider.signInSSO({ sso_token: sso, location:  "olo" });
            if (response.success) {
                response.user && (this._user.user = response.user);
                response.jwt && AdoraHttpClient.setJWT(response.jwt);
                response.refreshToken && AdoraHttpClient.setRefreshToken(response.refreshToken)
                this._user.setLoggedIn(true);
            }

            return response.success;
        }

        return false;
    }

    public readonly signInJWT = async (): Promise<boolean> => {
        const jwt = AdoraHttpClient.getJWT();
        const refreshToken = AdoraHttpClient.getRefreshToken();

        if (jwt && refreshToken) {
            const response = await this._loyaltyProvider.signInJWT({ jwt: jwt, refreshToken: refreshToken });
            if (response.success) {
                response.user && (this._user.user = response.user);
                response.jwt && AdoraHttpClient.setJWT(response.jwt);
                response.refreshToken && AdoraHttpClient.setRefreshToken(response.refreshToken)
                this._user.setLoggedIn(true);
            }

            return response.success;
        }

        return false;
    }

    public readonly signInPhone = async (data: ISignInPhone): Promise<boolean> => {
        const response = await this._loyaltyProvider.signInPhone(data);
        return response.success;
    }

    /**
    * Signs the user out.
    * @async
    * @returns {Promise<boolean>} A promise that resolves with true if the sign out was successful, otherwise false.
    */
    public signOut = async (): Promise<boolean> => {
        if (AdoraHttpClient.getJWT() != "" && this._user.getProperty("ProfileId") != 0) {
            const response = await this._httpRequest('POST', `${this._apiProfile}sign-out`, null, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
            if (response.status == 200) {
                localStorage.removeItem("tk");
                localStorage.removeItem("rtk");
                this._user.updateUser({ CustomerId: 0, ProfileId: 0, LoyaltyData: null });
                this.user.setLoggedIn(false);

                return true;
            }

            return false;
        }

        return false;
    }

    /**
     * Checks whether a user account already exists with the given email or phone number, for a logged-in user.
     * @async
     * @param email The email address to check for.
     * @param phone The phone number to check for.
     * @returns {Promise<number>} True if an account already exists with the given email or phone number, false otherwise. Null if the request failed.
     */
    public checkIfLoggedInExists = async (profileId: number, email: string, phone: string, countryId: number): Promise<number> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": this._data.getProperty("StoreInfo")?.storeKey, "profileId": profileId, "email": email, "phone": phone, "countryId": countryId }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiProfile}check`, payload, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : -10;
    }

    public getPhoneCode = async (phone: string, countryId: number): Promise<IGetPhoneCodeResponse> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": this._data.getProperty("StoreInfo")?.storeKey, "phone": phone, "countryId": countryId }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiProfile}get-phone-code`, payload);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    public updateUserDarkMode = (darkMode: boolean): void => {
        this._user.updateUser({ IsDarkMode: darkMode });
        this._httpRequest('POST', `${this._apiProfile}update-user-dark-mode`, { "darkMode": darkMode }, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
    }

    public updateUserLanguage = (language: string): void => {
        this._user.updateUser({ CultureCode: language });
        this._httpRequest('POST', `${this._apiProfile}update-user-language`, { "language": language }, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
    }

    /**************************
     ******** Password ********
     **************************/

    /**
     * Checks whether the user's current password matches the given password.
     * @async
     * @param oldPassword The user's current password.
     * @returns {Promise<boolean>} The response data from the server, or false if the request failed.
     */
    public checkPassword = async (oldPassword: string): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "oldPassword": oldPassword }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiProfile}check-password`, payload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**
     * Changes the user's password.
     * @async
     * @param {string} oldPassword - The current password.
     * @param {string} newPassword - The new password.
     * @returns {Promise<boolean>} - A promise that resolves to `true` if the password was changed successfully, or `false` otherwise.
     */
    public changePassword = async (data: IChangePassword): Promise<IChangePasswordResponse> => {
        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiProfile}change-password`, payload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    };

    ///**
    //* Sends a reset password request to the server.
    //* @async
    //* @param {string} data - The data required for the reset password request.
    //* @returns {Promise<boolean>} A promise that resolves with true if the reset password request was successful, otherwise false.
    //*/
    //public resetPassword = async (data: IResetPassword): Promise<IResetPasswordResponse> => {
    //    const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
    //    const response = await this._httpRequest('POST', `${this._apiProfile}reset-password`, payload);
    //    return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    //}

    /**
     * Sends a password reset request for the specified user.
     * 
     * @param {ISendResetPassword} data - The user data for the password reset request.
     * @returns {Promise<boolean>} - True if the reset was successfully initiated, false otherwise.
     */
    public forgotPassword = async (email: string): Promise<boolean> => {
        const data: IForgotPassword = {
            store_key: this._data.getProperty("StoreInfo")?.storeKey || "",
            email: email
        };

        const payload = await Util.encrypt(JSON.stringify(data), globalThis.aOLO.pkey);
        const response = await this._httpRequest("POST", `${this._apiLoyalty}olo/customer/forgot_password`, payload);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**
     * Saves a profile object to the API and invokes a callback function with a boolean indicating success or failure.
     * @async
     * @param {IaOLOUser} profile - The profile object to be saved.
     * @param {Function} callBack - Callback function to be invoked with a boolean indicating success or failure.
     * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating success or failure.
     */
    public saveProfile = async (profile: IUser, callBack: Function, incompleteProfile: boolean = false): Promise<boolean> => {
        let saved = false;
        if (incompleteProfile)
            saved = await this._saveIncompleteProfile(profile);
        else
            saved = await this._setProfile(profile);

        if (callBack)
            callBack(saved);
        return saved;
    }

    /**
     * Sends a POST request to save a profile object to the API.
     * @async
     * @param {IaOLOUser} profile - The profile object to be saved.
     * @returns {Promise<boolean>} - A promise that resolves with the response data if the request is successful, or null if it fails.
     */
    private _setProfile = async (profile: IUser): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(profile), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiProfile}save-profile`, payload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**
     * Sends a POST request to delete a profile object to the API.
     * @async
     * @param {number} profileId - The profile object to be deleted.
     * @param {password} string - The profile object to be deleted.
     * @returns {Promise<boolean>} - A promise that resolves with the response data if the request is successful, or null if it fails.
     */
    private _deleteProfile = async (profileId: number, password: string): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "profileId": profileId, "password": password }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiProfile}delete-profile`, payload, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }


    private _saveIncompleteProfile = async (profile: IUser): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": this._data.getProperty("StoreInfo")?.storeKey, "profile": profile }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiProfile}save-incomplete-profile`, payload);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**************************
     ******* Locations ********
     **************************/

    /**
     * Saves a favorite store with the given storeKey and name.
     * @async
     * @param {string} storeKey - The key of the store to be saved.
     * @param {string} name - The name of the store to be saved.
     * @param {boolean} isDefaultStore - A boolean indicating whether the store is the user's default store.
     * @returns {Promise<boolean>} A Promise that resolves to a boolean indicating whether the store was saved successfully.
     */
    public saveFavoriteStore = async (storeKey: string): Promise<boolean> => {
        const response = await this._httpRequest("POST", `${this._apiProfile}favorite-store`, { StoreKey: storeKey }, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        const saved = (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;

        if (saved && this._user)
            this._user.getProperty("FavoriteStores")?.push(storeKey);
        return saved;
    }

    /**
     * Deletes a favorite store with the given storeKey.
     * @async
     * @param {string} storeKey - The key of the store to be deleted.
     * @returns {Promise<boolean>} A Promise that resolves to a boolean indicating whether the store was deleted successfully.
     */
    public deleteFavoriteStore = async (storeKey: string): Promise<boolean> => {
        const response = await this._httpRequest("POST", `${this._apiProfile}favorite-store`, { "StoreKey": storeKey }, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        if (response.status == 200) {
            const favorite = this._user.getProperty("FavoriteStores")?.filter(x => x !== storeKey) || [];
            this._user.setProperty("FavoriteStores", favorite);

            return await AdoraHttpClient.getResponsePayload(response);
        }

        return false;
    }

    /**
     * Sends a request to the server to fetch the list of favorite stores.
     * @async
     * @returns {Promise<Array<IFetchFavoriteStoresResponse> | null>} A Promise that resolves to an array of objects, each containing the store key and name, or null if the request failed.
     */
    public fetchFavoriteStores = async (): Promise<IFetchFavoriteStoresResponse[]> => {
        const response = await this._httpRequest("GET", `${this._apiProfile}favorite-store`, null, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : [];
    }

    /**************************
     ******* My Orders ********
     **************************/

    /**
     * Asynchronously fetches orders from the API.
     * @async
     * @returns {Promise<IMyOrders[]>} - A Promise that resolves with an array of orders.
     */
    public getOrders = async (): Promise<IMyOrders[]> => {
        const response = await this._httpRequest("GET", `${this._apiProfile}profile-orders`, null, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : [];
    }

    /**
     * Fetches the details of the order with the given ID
     * @async
     * @param {number} orderId - The ID of the order to fetch details for
     * @return {Promise<IFetchOrderDetails | null>} A Promise that resolves to an object containing details of the order with the given ID, or null if the request failed or no order was found
     */
    public fetchOrderDetails = async (orderId: number): Promise<IFetchOrderDetails | null> => {
        const response = await this._httpRequest("GET", `${this._apiProfile}order-detail/?orderId=${orderId}`, null, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    /**
     * Saves the given order as a favorite with the given name
     * @async
     * @param {number} orderID - The ID of the order to save as a favorite
     * @param {string} name - The name to save the favorite order under
     * @return {Promise<boolean>} A Promise that resolves to true if the operation succeeded, or false otherwise
     */
    public saveFavoriteOrder = async (orderID: number, name: string): Promise<boolean> => {
        const response = await this._httpRequest("POST", `${this._apiProfile}toggle-favorite-order`, { "OrderID": orderID, "Name": name }, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**************************
     ******* My History *******
     **************************/

    /**
     * Fetches loyalty history data for the specified date.
     * @async
     * @param {string} historyDate - The date for which to fetch the loyalty history, in the format "YYYY-MM-DD".
     * @returns {Promise<IMyHistory|null>} - A Promise that resolves with the loyalty history data, or null if the request fails.
     */
    public fetchLoyaltyHistory = async (historyDate: string): Promise<IMyHistory[] | null> => {
        const response = await this._httpRequest("GET", `${this._apiProfile}history/?date=${historyDate}`, null, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    /**************************
     ****** Unsubscribe *******
     **************************/
    public unsubscribe = async (storeKey: string, profileId: number, reasons: number[], campaignRunId: number, otherReason: string): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": storeKey, "profileId": profileId, "reasons": reasons, "campaignRunId": campaignRunId, "otherReason": otherReason }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiProfile}unsubscribe`, payload, null);
        return (response.status == 200);
    }

    public resubscribe = async (storeKey: string, profileId: number): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": storeKey, "profileId": profileId }), globalThis.aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiProfile}resubscribe`, payload, null);
        return (response.status == 200);
    }

    /**************************
     **** Landing Banner ******
     **************************/

    /**
     * Loads the banner data associated with the given store key
     * @async
     * @param {string} storeKey 
     * @return {Promise<string>} 
     */
    public getLandingBanner = async (storeKey: string | null | undefined): Promise<string> => {
        if (!storeKey)
            return '{}';

        const response = await this._httpRequest("GET", `${this._apiProfile}landing-banner/?storeKey=${storeKey}`, null, null);
        if (response.status == 200) {
            const respData = await AdoraHttpClient.getResponsePayload(response);
            if (respData.BAN && respData.BAN != "")
                return respData.BAN;
        }
        return '{}';
    }

    private _mapProfile = (profile: IProfile): IaOLOUser => {
        return {
            CID: profile?.CustomerId,
            PID: profile?.ProfileId,
            LRID: profile?.LoyaltyReferenceID,
            SID: profile?.StoreId,
            NAM: profile?.Name,
            LNAM: profile?.LastName,
            PHN: profile?.Phone,
            CCDID: Number(profile?.CountryCodeId),
            EML: profile?.Email,
            ISV: profile?.IsMarketingSurvey,
            IEML: profile?.IsMarketingEmail,
            ITXT: profile?.IsMarketingText,
            MKPHN: profile?.MarketingPhone,
            MKCCDID: Number(profile?.MarketingCountryCodeId),
            BD: profile?.BirthDate,
            ILY: profile?.IsLoyalty,
            IBLKD: profile?.IsBlocked,
            CLT: profile?.CultureCode,
            IDK: aOLO.Temp?.DarkMode,
            ALGS: profile?.Allergies,
            DITS: profile?.Dietary,
            pwd: profile?.pwd,
            TPP: profile?.TermsPrivacyPolicy,
            A13: profile?.IsAge13,
            FAVST: false,
            FAVSTID: profile?.DefaultStoreId,
            FAVSTRs: [],
            DFSTKEY: profile?.DefaultStoreKey,
            WLTS: [],
            ADRS: profile?.Addresses?.map(x => {
                return {
                    AID: x.AID,
                    STRNO: x.STRNO,
                    ADDR1: x.ADDR1,
                    ADDR2: x.ADDR2,
                    ADDR3: x.ADDR3,
                    ADDR4: x.ADDR4,
                    ADDR5: x.ADDR5,
                    XSTR: x.XSTR,
                    CITY: x.CITY,
                    STA: x.STA,
                    ZIP: x.ZIP,
                    CID: x.CID,
                    LAT: x.LAT,
                    LON: x.LON,
                    IPRM: x.IPRM,
                    ATID: 0,
                    ANAM: "",
                };
            }) || []
        };
    }
}