import './segmented-button.css';

import { ISegmentedButtonData } from './segmented-button.interface';

export class SegmentedButton {
    private readonly _item: ISegmentedButtonData;
    private readonly _element: HTMLElement;

    constructor(item: ISegmentedButtonData) {
        this._item = item;
        this._element = this._createElement();
        //this._setAllWidthsEqual();
    }

    private _createElement(): HTMLElement {
        const parentDiv = document.createElement("div");
        parentDiv.id = `div_${this._item.buttonId}`;
        parentDiv.classList.add("segmented-button");
        parentDiv.setAttribute("role", "group");
        parentDiv.ariaLabel = this._item.ariaGroupLabel;

        for (const item of this._item.buttons) {
            //const label = document.createElement("label");
            //label.id = `${this._item.buttonId}_${item.id}`;
            //label.classList.add("segment");
            //label.setAttribute("for", "");

            //const input = document.createElement("input");
            //input.type = "radio";
            //input.setAttribute("name", this._item.buttonId);
            //input.value = item.id.toString();

            const button = document.createElement("button");
            button.id = `btn_${this._item.buttonId}_${item.id}`;
            button.innerHTML = item.text;
            button.ariaPressed = item.selected.toString();

            if (item.selected)
                button.classList.add("active");

            if (item.ltag)
                button.setAttribute("ltag", item.ltag);

            if (item.ltagj)
                button.setAttribute("ltagj", item.ltagj);

            //label.appendChild(input);
            //label.appendChild(span);

            //button.id = `${this._item.buttonId}_${item.id}`;
            //parentDiv.setAttribute("name", this._item.buttonId);
            ////button.classList.add("tab-bar-tab");
            //button.ariaSelected = "false";
            //button.tabIndex = 0;
            //parentDiv.setAttribute("role", "tab");
            //button.innerText = item.text;


            button.onclick = (event: Event) => {
                this._setActiveTabOnClick(button.id);
                if (item.function)
                    item.function(event);
            };

            parentDiv.appendChild(button);

            //try {
            //    parentDiv.appendChild(button);
            //} catch (ex) {
            //    console.log(ex);
            //}
        }
        return parentDiv;
    }

    public get element(): HTMLElement {
        return this._element;
    }

    private readonly _setActiveTabOnClick = (id: string): void => {
        const buttons = this.element.querySelectorAll("button");
        for (const button of buttons) {
            button.classList.remove("active");
        }

        const button = document.getElementById(id);
        if (button) {
            button.classList.add("active");
            //button.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        } else {
            const defaultTab = buttons[0];
            if (defaultTab) {
                defaultTab.classList.add("active");
                //defaultTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        }
    }

    //private readonly _setAllWidthsEqual = (): void => {
    //    // Create a temporary container to calculate sizes
    //    const tempContainer = document.createElement("div");
    //    tempContainer.style.position = "absolute";
    //    tempContainer.style.visibility = "hidden";
    //    tempContainer.style.height = "auto";
    //    tempContainer.style.width = "auto";
    //    document.body.appendChild(tempContainer);

    //    // Clone your element into the temp container
    //    const tempElement = this.element.cloneNode(true) as HTMLElement;
    //    tempContainer.appendChild(tempElement);

    //    // Get buttons from the temp container
    //    const tempButtons = Array.from(tempElement.querySelectorAll("button")) as HTMLButtonElement[];
    //    let maxWidth = 0;

    //    // Calculate the maximum width of the buttons
    //    tempButtons.forEach(button => {
    //        maxWidth = Math.max(maxWidth, button.offsetWidth);
    //    });

    //    // Apply the maximum width to the original buttons
    //    const buttons = Array.from(this.element.querySelectorAll("button")) as HTMLButtonElement[];
    //    buttons.forEach(button => {
    //        button.style.width = `${maxWidth}px`;
    //    });

    //    // Clean up the temporary container
    //    document.body.removeChild(tempContainer);
    //}
}