import './cart.css';

import EventEmitter from "eventemitter3";
import { EventTypes } from '../../../core/event-bus';
import { Util } from '../../../utils/util';
import { Common } from '../../../common';
import { IName } from '../../../interfaces/global.interfaces';
import { Data } from '../../../models/data';
import { Page } from '../page';
import { Order } from '../../../models/order';
import { OnlineOrderingUtil } from '../../../online-ordering/online-ordering-util';
import { CustomizeItem } from '../../popups/CustomizeItem/customize-item';
import { DataLayerService } from '../../../services/data-layer-service';
import { ILoyaltyProvider } from '../../../services/loyalty-provider/loyalty-provider.interface';
import { Names } from '../../../utils/i18n';
import { User } from '../../../models/user';
import { IApplyCouponByIdParamsPartial, IRemovedCoupon } from '../../../online-ordering/interfaces/coupons.interface';
import { CouponIdList, CouponTypeList } from '../../../types/enums';
import { IIncompleteDiscount, IOrderItem } from '../../../models/order.interface';
import { IGetItemTaxDependencies } from '../../../online-ordering/interfaces/online-ordering.interface';

export class Cart extends Page {
    private readonly _order: Order;
    private readonly _user: User;
    private readonly _dataLayer: DataLayerService;
    private readonly _loyaltyProvider: ILoyaltyProvider;

    /**
     * Creates an instance of the Landing page.
     * @constructor
     */
    constructor(eventBus: EventEmitter, data: Data, order: Order, user: User, dataLayer: DataLayerService, loyaltyProvider: ILoyaltyProvider) {
        super("page_cart", eventBus, data)
        this._order = order;
        this._user = user;
        this._dataLayer = dataLayer;
        this._loyaltyProvider = loyaltyProvider;
    }

    public initAsync = async (): Promise<void> => {
        //    this._showPage();
        await this._renderPageAsync();
    }

    private readonly _renderPageAsync = async (): Promise<void> => {
        const parentDiv = document.getElementById("div_cart_items");
        if (!parentDiv)
            return;

        parentDiv.innerHTML = "";

        //this.GUI_Set_None_Group_Order_Get_Items(divItems, true, false, defaultPreModifierId);
        this.GUI_SetOrder_Total();

        //    if (!subcategoryId) {
        //        const categories = this._data.getProperty("Categories")?.find(x => x.ItemWebCategoryId === categoryId);
        //        this._setHeaderName(categories?.Names || []);

        //        const categoryItems = this._data.getProperty("Items")?.filter(x => x.WebCategoryId === categoryId && x.SubCategoryId === 0) || [];
        //        this._createMenuItemViewAllCards(parentDiv, categoryItems);
        //    } else {
        //        const subcategory = this._data.getProperty("SubCategories")?.find(subCat => subCat.ItemSubCategoryId === subcategoryId);
        //        this._setHeaderName(subcategory?.Names || []);

        //        const subCategoryItems = this._data.getProperty("Items")?.filter(x => x.SubCategoryId === subcategoryId) || [];
        //        this._createMenuItemViewAllCards(parentDiv, subCategoryItems);
        //    }
    }

    private GUI_SetOrder_Total = (): void => {
        let nextToItemTaxTotal = 0.00;
        for (const oItem of this._order.getProperty("Items")) {
            nextToItemTaxTotal += oItem.Taxes.reduce((total, tax) => {
                return tax.DisplayNextToItem ? total + tax.Amount : total;
            }, 0);
        }

        const divTotals = document.getElementById("div_cart_totals");
        if (!divTotals)
            return;

        divTotals.innerHTML = "";

        const subtotal = this._order.getProperty("SubTotal") + nextToItemTaxTotal - this._order.getProperty("TaxInSubTotal");
        divTotals.appendChild(this.GUI_Totals_Display_Pair("Subtotal", subtotal));

        if (this._order.getProperty("Discount") > 0 || (this._order.getIncompleteDiscounts().length > 0)) {
            const div = document.createElement("div");
            div.id = "div_cart_discounts_block";
            div.classList.add("title3", "m1");
            div.style.fontSize = "smaller";

            const span = document.createElement("span");
            span.setAttribute("ltag", "Discounts");
            span.innerText = Names("Discounts");
            div.appendChild(span);

            for (const coupon of this._order.getProperty("Coupons")) {
                const mCpn = this._order.couponGetCoupon(coupon.CouponId, false, null, coupon.ChannelID);

                const tRemoveDiscount = document.createElement("button");
                tRemoveDiscount.classList.add("btnIcon", "icon-cancel");
                tRemoveDiscount.setAttribute("aria-label", "remove");
                tRemoveDiscount.setAttribute("title", "Remove");
                tRemoveDiscount.setAttribute("name", "btn_order_cart_coupon_remove");
                tRemoveDiscount.dataset.couponkey = coupon.CouponKey.toString();
                tRemoveDiscount.style.fontSize = "small";

                const tNameDiscount = document.createElement("div");
                tNameDiscount.style.fontSize = "smaller";
                if (mCpn) {
                    tNameDiscount.setAttribute("ltagj", Util.toLtagj(mCpn.Names));
                    tNameDiscount.innerText = Common.GetName(mCpn.Names, globalThis.aOLO.Temp.languageCode);
                }

                const tSpanDiscount = document.createElement("span");
                tSpanDiscount.classList.add("hidden_label");
                tSpanDiscount.innerText = Names("Remove");

                const tValueDiscount = document.createElement("div");
                tValueDiscount.style.fontSize = "smaller";
                tValueDiscount.innerText = `$(${Util.formatNumber(coupon.Amount)})`;

                const tGroupDiscount = document.createElement("div");
                tGroupDiscount.classList.add("totalLineCoupon");
                tGroupDiscount.appendChild(tNameDiscount);
                tGroupDiscount.appendChild(tValueDiscount);

                tRemoveDiscount.appendChild(tSpanDiscount);
                tGroupDiscount.appendChild(tRemoveDiscount);

                const tParentDiscount = document.createElement("div");
                tParentDiscount.appendChild(tGroupDiscount);

                const hLine = document.createElement("hr");
                hLine.classList.add("orderItemLine");
                hLine.setAttribute("noshade", "true");
                tParentDiscount.appendChild(hLine);
                div.appendChild(tParentDiscount);
            }
            divTotals.appendChild(div);

            this._showIncompleteDiscounts();

            const removeButtons = Array.from(document.getElementsByName("btn_order_cart_coupon_remove"));
            for (const button of removeButtons) {
                button.onclick = async () => {
                    const couponKey = Number(button.dataset.couponkey);
                    const coupon = this._order.getProperty("Coupons").find(x => x.CouponKey == couponKey);
                    if (!coupon)
                        return;

                    // Check if coupon is reward
                    const loyaltyData = this._user.getProperty("LoyaltyData");
                    if (loyaltyData && loyaltyData.Rewards.length > 0) {
                        const reward = loyaltyData.Rewards.find(x => x.RewardId == coupon.RewardId);
                        if (reward) {
                            await this._loyaltyProvider.removeReward(reward);
                            return;
                        }
                    }

                    // Check if coupon is banked currency
                    if (coupon.IsBankedCurrency) {
                        await this._loyaltyProvider.removeBankedCurrency();
                        return;
                    }

                    // Check if coupon is an offer
                    const offers = this._user.getProperty("Offers");
                    if (offers && offers.Codes.length > 0) {
                        const offer = offers.Codes.find(x => x.CouponCode.toLowerCase() == coupon.CouponCode);
                        if (offer)
                            offer.Used = false;
                    }

                    await this._order.removeCouponByKeyAsync(this._loyaltyProvider, couponKey, true);
                };
            }
        }

        const orderTypeCharge = this._order.getProperty("OrderTypeCharge");
        if (orderTypeCharge > 0)
            divTotals.appendChild(this.GUI_Totals_Display_Pair("DeliveryCharge", orderTypeCharge));

        if (this._order.getProperty("ServiceCharges").Amount > 0) {
            for (const serviceCharge of this._order.getProperty("ServiceCharges").Detail) {
                divTotals.appendChild(this.GUI_Totals_Display_Pair_Names(serviceCharge.Names, serviceCharge.Amount, globalThis.aOLO.Temp.languageCode));
            }
        }

        if (this._order.getProperty("Discount") > 0) {
            const discountedTotal = this._order.getProperty("SubTotal") - this._order.getProperty("Discount") + this._order.getProperty("OrderTypeCharge") + this._order.getProperty("ServiceCharges").Amount - this._order.getProperty("TaxInSubTotal");
            divTotals.appendChild(this.GUI_Totals_Display_Pair("DiscountedTotal", discountedTotal));
        }

        for (const tax of this._order.getProperty("TaxesDetail")) {
            if (tax.TaxTypeName)
                divTotals.appendChild(this.GUI_Totals_Display_Pair_Names(tax.TaxTypeName, tax.Amount, globalThis.aOLO.Temp.languageCode));
        }

        const tip = this._order.getProperty("Tip");
        if (tip > 0)
            divTotals.appendChild(this.GUI_Totals_Display_Pair("Tip", tip));

        const totalDonations = this._order.getProperty("TotalDonation");
        if (totalDonations > 0)
            divTotals.appendChild(this.GUI_Totals_Display_Pair("Donation", totalDonations));

        const giftCards = this._order.getProperty("GiftCards");
        const total = this.GUI_Totals_Display_Pair("OrderTotal", this._order.calculateOrderTotal());
        if (giftCards.length === 0)
            total.classList.add("p-0", "title2");
        divTotals.appendChild(total);

        if (giftCards.length > 0) {
            for (const giftCard of giftCards) {
                divTotals.appendChild(this.GUI_Totals_Display_Pair("GiftCard", giftCard.appliedAmount));
            }

            const due = this.GUI_Totals_Display_Pair("Due", this._order.getProperty("AmountDue"));
            due.classList.add("p-0", "title2");
            divTotals.appendChild(due);
        }

        // 2DO: Move to checkout page
        //this.GUI_Giftcard_Display_Values(localAOLO.Temp.GiftCards);
        //this.GUI_CHKOUT_Display_RoundUp_Donation(localAOLO);

        //if (update_CHKOUT_Tip) {
        //    Util.setElement("value", "txt_checkout_tip", Util.formatNumber(localAOLO.Order.Tip));
        //    Util.setElement("value", "txt_APP_CHKOUT_Tip", Util.formatNumber(localAOLO.Order.Tip));
        //}

        //Util.setElement("innerText", "spn_checkout_tip_total", Util.formatNumber(localAOLO.Order.Total - localAOLO.Order.TotalDonation));
        //Util.setElement("innerText", "div_APP_CHKOUT_Total", Util.formatNumber(localAOLO.Order.Total));
        //Util.setElement("innerText", "div_checkout_amount_due", Util.formatMoney(localAOLO.Order.AmountDue));
    }

    private readonly GUI_Totals_Display_Pair = (name: string, value: number): HTMLElement => {
        const html = `
            <div class="summary-row">
                <span ltag="${name}">${Names(name)}</span>
                <span>${Util.formatMoney(value)}</span>
            </div>`;

        return Util.createHtmlElementFromTemplate(html);
    }

    public GUI_Totals_Display_Pair_Names(names: { CULT: string, NAM: string }[], value: number, languageCode: string): HTMLElement {
        const html = `
            <div class="summary-row">
                <span ltagj="${Util.toLtagj(names)}">${Common.GetName(names, languageCode)}</span>
                <span>${Util.formatMoney(value)}</span>
            </div>`;

        return Util.createHtmlElementFromTemplate(html);
    }

    private _showIncompleteDiscounts = (): void => {
        const divDiscounts = document.getElementById("div_cart_discounts_block");
        if (!divDiscounts)
            return;

        const incompleteItems = document.getElementsByName("div_cart_discounts_incomplete");
        incompleteItems.forEach((itm: HTMLElement) => itm.remove());

        for (const incompleteCoupon of this._order.getIncompleteDiscounts()) {
            const coupon = this._order.couponGetCoupon(incompleteCoupon.couponID, false, this._order.getProperty("OrderTypeID"), incompleteCoupon.channelID);
            if (!coupon)
                continue;

            let html = `
                <div name="div_cart_discounts_incomplete" class="incompleteCoupon">
                    <div>
                        <div ltag="InProgress" class="inProgress">${Names(`InProgress`)}</div>
                        <div class="couponName">
                            <div ltagj="${Util.toLtagj(coupon.Names)}">${Common.GetName(coupon.Names, aOLO.Temp.languageCode)}</div>
                            <button id="btn_cart_incomplete_remove_${incompleteCoupon.couponID}" name="btn_cart_incomplete_remove" class="btnIcon icon-cancel" aria-label="remove" title="${Names(`Remove`)}" data-id="${incompleteCoupon.couponID}">
                                <span class="hidden_label" ltag="Remove">${Names(`Remove`)}</span>
                            </button>
                        </div>
                    </div>
                <div>`;

            if (coupon.Items.length > 0)
                html += `
                <button id="btn_cart_incomplete_complete_${incompleteCoupon.couponID}" name="btn_cart_incomplete_complete" class="${globalThis.aOLO.buttonHoverStyle}" title="${Names(`CompleteRequiredItems`)}" ltag="CompleteRequiredItems" data-id="${incompleteCoupon.couponID}">
                    ${Names(`CompleteRequiredItems`)}
                </button>`;

            html += `</div></div>`;

            divDiscounts.innerHTML += html;
        }

        const removeButtons = document.getElementsByName("btn_cart_incomplete_remove");
        for (const button of removeButtons) {
            button.onclick = async () => { await this._order.removeIncompleteCouponByIdAsync(this._loyaltyProvider, Number(button.dataset.id)); }
        }

        const completeButtons = document.getElementsByName("btn_cart_incomplete_complete");
        for (const button of completeButtons) {
            button.onclick = async () => {
                if (this._order.isCouponNotAllowedInFundraiser())
                    return;

                await this._order.checkCouponHasQualifyingItemsAsync(this._loyaltyProvider, Number(button.dataset.id));
            }
        }
    }

    private GUI_Set_None_Group_Order_Get_Items(divItems: HTMLElement, displayMods: boolean, displayPoints: boolean, defaultPreModifierId: number) {
        const orderItems = this._order.getProperty("Items");

        if (orderItems.length > 0)
            divItems.appendChild(Util.createHtmlElementFromTemplate(`<hr class="lineThemeDot75 m2-tb">`));

        let itemCount = 0;
        for (const [index, oItem] of orderItems.entries()) {
            const endLine = (index < orderItems.length - 1 && (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 2)));
            divItems.appendChild(this.GUI_SetOrder_Get_Item(oItem, endLine, displayMods, displayPoints, defaultPreModifierId));

            if (oItem.HalfIndex === 0 || oItem.HalfIndex === 1)
                itemCount += oItem.Quantity;
        }

        (Array.from(document.getElementsByName("ddl_cart_item_quantity")) as HTMLSelectElement[]).forEach(x => x.onchange = () => {
            const qty = parseInt(x.value);
            this.ChangeQuantityAsync(Number(x.dataset.iky), qty);
        });

        document.getElementsByName("btn_cart_item_edit").forEach(x => x.onclick = () => {
            this._editItemOnClick(Number(x.dataset.iky));
        });

        document.getElementsByName("btn_cart_item_remove").forEach(x => x.onclick = () => {
            this._deleteItemOnClickAsync(Number(x.dataset.iky));
        });

        // 2DO: To implement later for Pizza Guys
        //document.getElementsByName("btn_cart_item_redeem").forEach(x => x.onclick = async () => {
        //    const item = this._order.GetItemByItemKey(Number(x.dataset.iky));
        //    if (!item)
        //        return;

        //    const points = this.GetLoyaltyPoints(item.ItemId, item.SizeId, localAOLO.data.LoyaltyItems);
        //    await this._redeemPointsAsync(points, Number(x.dataset.iky));
        //});
    }

    private GUI_SetOrder_Get_Item(oItem: IOrderItem, endLine: boolean, displayMods: boolean, displayPoints: boolean, defaultPreModifierId: number): HTMLElement {
        const divItem = document.createElement("div");
        let loyaltyPoint = 0;
        if (displayPoints && oItem.DiscountedQuantity > 0)
            return divItem;

        const mItem = this._data.getProperty("Items").find(x => x.ItemId === oItem.ItemId);
        if (!mItem)
            return divItem;

        divItem.classList.add("orderItem");

        const itemName = Common.GetName(mItem.Names, globalThis.aOLO.Temp.languageCode);

        if (displayPoints)
            loyaltyPoint = OnlineOrderingUtil.GetLoyaltyPoints(oItem.ItemId, oItem.SizeId, this._data.getProperty("LoyaltyItems")) || 0;

        if (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 1)) {
            // Item Name
            let title = "";
            let sizeName = "";

            if ((oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 1)) && oItem.SizeId > 0) {
                const iSize = OnlineOrderingUtil.GetItemSize(mItem, this._order.getProperty("OrderTypeID"), oItem.SizeId);
                const size = this._data.getProperty("Sizes").find(x => x.SizeId === iSize?.SizeId) || null;
                sizeName = (size === null) ? "Unknown" : `<span ltagj="${Util.toLtagj(size.Names)}">${Common.GetName(size.Names, globalThis.aOLO.Temp.languageCode)}</span> - `;
            }

            title += sizeName;

            if (oItem.HalfCount > 1 && oItem.HalfIndex === 1)
                title += `<span ltag="HHP">${Names("HHP")}</span>`;
            else
                title += `<span ltagj="${Util.toLtagj(mItem.Names)}">${itemName}</span>`;

            // Price
            const price = (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price;

            divItem.innerHTML += `
                <div class="cart-item-name-price">
                        <div>${title}</div>
                        <div>${Util.formatMoney(price * oItem.Quantity)}</div>
                </div>`;
        }

        if (oItem.HalfCount == 2) {
            const half = (oItem.HalfIndex === 1) ? "FirstHalf" : "SecondHalf";
            divItem.innerHTML += `
                <div class="title3">
                    <span ltag="${half}">${Names(half)}</span>
                </div>
                <div>
                    <span ltagj="${Util.toLtagj(mItem.Names)}">${itemName}</span>
                </div>`;
        }

        // Modifiers
        if (displayMods)
            divItem.innerHTML += this.GUI_SetOrder_Get_Item_Mods(oItem, defaultPreModifierId);

        if (oItem.HalfCount == 1 || (oItem.HalfCount == 2 && oItem.HalfIndex == 2)) {
            // Calories
            const calories = OnlineOrderingUtil.getItemCalories(mItem, oItem, this._order.getProperty("OrderTypeID"), oItem.SizeId, defaultPreModifierId, this._data.getProperty("Settings").DSZID);
            divItem.innerHTML += `
                <div class="cart-item-calories">
                    <div ltagj="${Util.toLtagj(calories)}">${Common.GetName(calories, globalThis.aOLO.Temp.languageCode)}</div>
                </div>`;

            // Item tax
            for (const tax of oItem.Taxes.filter(x => x.DisplayNextToItem === true)) {
                const taxData = this._data.getProperty("Taxes").find(t => t.TaxId === tax.TaxId);
                let taxName = "Store-added tax";
                if (taxData && taxData.OrderTypesExcluded.includes(this._order.getProperty("OrderTypeID"))) {
                    continue;
                } else if (tax.TaxTypeName) {
                    const taxTypeNameArray = typeof tax.TaxTypeName === 'string' ? JSON.parse(tax.TaxTypeName) as IName[] : tax.TaxTypeName;
                    taxName = taxTypeNameArray.find((x: { NAM: string; CULT: string; }) => x.CULT.toLowerCase() === globalThis.aOLO.Temp.languageCode.toLowerCase())?.NAM || taxName;
                }

                divItem.innerHTML += `
                    <div class="cart-item-calories">
                        <div>${taxName} ${Util.formatMoney(tax.Amount)}</div>
                    </div>`;
            }

            /* Quantity, Edit, Delete */
            let divQtyEditDel = "";

            // Quantity
            let options = "";
            for (let i = 1; i < 21; i++) {
                options += `<option value="${i}" ${(oItem.Quantity === i) ? `selected="true"` : ""}>${i}</option>`;
            }

            divQtyEditDel += `
                <div class="cart-item-quantity">
                    <div>
                        <label for="ddl_cart_item_quantity_${oItem.ItemKey}" ltag="Quantity">${Names("Quantity")}</label>:
                    </div>
                    <select id="ddl_cart_item_quantity_${oItem.ItemKey}" name="ddl_cart_item_quantity" data-iky="${oItem.ItemKey}">${options}</select>
                </div>`;

            // Edit
            if (oItem.Edit) {
                divQtyEditDel += `
                    <div>
                        <button id="btn_cart_item_edit_${oItem.ItemKey}" name="btn_cart_item_edit" class="btn-icon-link" ltag="Edit" data-iky="${oItem.ItemKey}">${Names("Edit")}</button>
                    </div>`;
            }

            // Delete
            divQtyEditDel += `
                <div>
                    <button id="btn_cart_item_remove_${oItem.ItemKey}" name="btn_cart_item_remove" class="btn-icon-link" ltag="Remove" data-iky="${oItem.ItemKey}">${Names("Remove")}</button>
                </div>`;

            divItem.innerHTML += `<div class="cart-item-quantity-edit-remove">${divQtyEditDel}</div>`;

            // Points
            if (this._loyaltyProvider.hasPointRedemption() && this._user.getProperty("LoyaltyData")) {
                const points = OnlineOrderingUtil.GetLoyaltyPoints(oItem.ItemId, oItem.SizeId, this._data.getProperty("LoyaltyItems"));
                if (points) {
                    let innerText = "";
                    const hidden = (oItem.IsRedeemed || points > (this._user.getProperty("LoyaltyData")?.CurrentPoints || 0));
                    if (!hidden) {
                        if (oItem.Quantity > 1)
                            innerText = (points) ? `<span ltag="Redeem">${Names("Redeem")}</span>&nbsp1&nbsp<span ltag="For">${Names("For")}</span>&nbsp${points}&nbsp<span ltag="Points">${Names("Points")}</span>` : "";
                        else
                            innerText = (points) ? `<span ltag="RedeemFor">${Names("RedeemFor")}</span>&nbsp${points}&nbsp<span ltag="Points">${Names("Points")}</span>` : "";

                        const btnPnts =
                            `<button id="btn_cart_item_redeem_${oItem.ItemKey}" name="btn_cart_item_redeem" class="${globalThis.aOLO.buttonHoverStyle} cart-item-redeem-btn" data-pts="${points}" data-iky="${oItem.ItemKey}">${innerText}</button>`;

                        divItem.innerHTML += btnPnts;
                    }
                }
            }
        }

        // Instructions 
        if (oItem.Instructions && oItem.Instructions.length > 0) {
            let instructionText = "";
            for (const inst of oItem.Instructions) {
                const instruction = this._data.getProperty("Instructions").find(x => x.CommentId === inst.commentId);
                if (instruction)
                    instructionText += `${Common.GetNewName(instruction.Names, globalThis.aOLO.Temp.languageCode)}   `;
            }

            divItem.innerHTML += `<div class="cart-item-row">${instructionText}</div>`;
        }

        const discRedim = this.GUI_SetOrder_Get_Item_Discount(oItem);
        if (discRedim)
            divItem.innerHTML += `<div class="cart-item-row" ltag="${discRedim}">${Names(discRedim)}</div>`;

        if (oItem.Comment !== "" && (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 2)))
            divItem.innerHTML += `<div class="cart-item-row">${oItem.Comment}</div>`;

        if (displayPoints && (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 2)))
            divItem.innerHTML += `<div style="text-align: center;">${loyaltyPoint} Points required</div>`;

        if (endLine)
            divItem.innerHTML += `<hr class="lineThemeDot75 m2-tb">`;

        return divItem;
    }

    private GUI_SetOrder_Get_Item_Mods = (oItem: IOrderItem, defaultPreModifierId: number): string => {
        const listMods: string[] = [];
        for (const mod of oItem.Modifiers) {
            let name = "";
            const modifier = this._data.getProperty("Modifiers").find(x => x.ModifierId === mod.ModifierId);
            const modName = modifier ? Common.GetName(modifier.Names, globalThis.aOLO.Temp.languageCode) : "";
            if (mod.PreModifierId !== defaultPreModifierId) {
                const preMod = OnlineOrderingUtil.GetPreMod(mod.PreModifierId, this._data.getProperty("PreModifiers"));
                const pName = preMod ? Common.GetName(preMod.Names, globalThis.aOLO.Temp.languageCode) : "";
                name = `<span class="warning" ${(preMod && pName != "") ? `ltagj="${Util.toLtagj(preMod?.Names)}"` : ""}>${pName}</span> `;
            }

            if (mod.PreModifierId !== defaultPreModifierId || !mod.IsDefault) {
                let preModClass = "";

                if (mod.PreModifierId !== defaultPreModifierId)
                    preModClass = "modAdjust";
                else if (!mod.IsDefault)
                    preModClass = "modAdd";
                else
                    preModClass = "modDef";

                listMods.push(name + `<span class="${preModClass}" ltagj="${Util.toLtagj(modifier?.Names || [])}">${modName}</span>`);
            }
        }

        return `${listMods.join(", ")}</div>`;
    }

    private GUI_SetOrder_Get_Item_Discount = (oItem: IOrderItem): string | null => {
        const coupon = this._order.getProperty("ItemsCoupons").find(icpn => icpn.ItemKey === oItem.ItemKey);
        if (!!coupon)
            return (this.GUI_SetOrder_GetOrderCouponIDByCouponKey(coupon.CouponKey) === CouponIdList.LOYALTY_REDEEM_ITEM) ? "Redeemed" : "Discounted";

        return null;
    }

    private GUI_SetOrder_GetOrderCouponIDByCouponKey = (key: number): number | null => {
        const coupon = this._order.getProperty("Coupons").find(cpn => cpn.CouponKey === key);
        return coupon ? coupon.CouponId : null;
    }

    private async ChangeQuantityAsync(itemKey: number, quantity: number): Promise<void> {
        const oItems = this._order.getOrderItemsByItemKey(itemKey);
        if (oItems.length == 0)
            return;

        for (const oItem of oItems) {
            oItem.Quantity = quantity;

            // If item is already redeemed, unredeem item, as process changes
            if (oItem.IsRedeemed) {
                const itemCoupon = this._order.getProperty("ItemsCoupons").find(x => x.ItemKey === itemKey) || null;
                if (itemCoupon)
                    await this._order.removeCouponByKeyAsync(this._loyaltyProvider, itemCoupon.CouponKey, true);
            }

            const mItem = this._data.getProperty("Items").find(x => x.ItemId === oItem.ItemId) || null;
            const dependencies: IGetItemTaxDependencies = {
                taxesData: this._data.getProperty("Taxes"),
                zipTaxesData: this._data.getProperty("ZipTaxes"),
                isCallCenter: this._data.getProperty("Settings").ISCC,
                orderTypeId: this._order.getProperty("OrderTypeID"),
                address: globalThis.aOLO.Temp.Address
            };
            OnlineOrderingUtil.TaxItem(mItem, oItem, dependencies);
        }

        const removedCouponIDs = await this._order.removeCouponByItemKeyAsync(this._loyaltyProvider, itemKey, true);
        for (const removedCoupon of removedCouponIDs) {
            const params: IApplyCouponByIdParamsPartial = {
                loyaltyProvider: this._loyaltyProvider,
                couponId: removedCoupon.couponId
            };
            await this._order.applyCouponByIdAsync(params);
        }

        this._order.TaxOrder();
        //Refactor: this.GUI_SetOrder(localAOLO, localAOLOModules);
        await this._order.autoApplyCouponsAsync(this._loyaltyProvider);
    }

    private _editItemOnClick = (itemKey: number): void => {
        const elements = document.getElementsByName("SingleModDG");
        elements.forEach(element => element.remove());

        const oItems = this._order.getOrderItemsByItemKey(itemKey);
        if (oItems.length === 0)
            return;

        const mItem = this._data.getProperty("Items").find(x => x.ItemId === oItems[0].ItemId);
        if (!mItem)
            return;

        const dialog = new CustomizeItem(this._eventBus, this._data, this._order, this._dataLayer, this._loyaltyProvider, mItem, oItems[0].SizeId, oItems[0].Quantity, oItems);
        dialog.init();
        dialog.open();
    }

    private _deleteItemOnClickAsync = async (iKey: number): Promise<void> => {
        let item: IOrderItem | null = null;
        let discountedQuantity = 0;
        let couponsToApplyAfterTotals: IRemovedCoupon[] = [];

        const items = this._order.getProperty("Items")
        // Iterate through the items backward to safely remove elements
        for (let i = items.length - 1; i >= 0; i--) {
            const oItem = items[i];
            if (oItem.ItemKey === iKey) {
                // Clone the item object for later use
                item = { ...oItem };

                // Calculate total discounted quantity, including loyalty point coupons
                discountedQuantity += oItem.DiscountedQuantity + globalThis.aOLO.Temp.loyaltyPointCoupons.filter(x => x.itemKey === iKey).length;

                // Redeem points if the item is redeemed and there are loyalty point coupons
                if (oItem.IsRedeemed && globalThis.aOLO.Temp.loyaltyPointCoupons.length > 0)
                    await OnlineOrderingUtil.redeemPointsAsync(null, oItem.ItemKey);

                // Remove the item directly from the order's items array
                items.splice(i, 1);
            }
        }

        this._order.setProperty("Items", items);

        if (this._dataLayer && item)
            this._dataLayer.remove_from_cart(item);

        if (discountedQuantity > 0 || this._order.getProperty("ItemsCoupons").some(ic => ic.ItemKey == iKey)) {
            const removedCoupons = await this._order.removeCouponByItemKeyAsync(this._loyaltyProvider, iKey);
            this._order.TaxOrder();
            couponsToApplyAfterTotals = await this._reviewRemovedCouponsForOrderAsync(removedCoupons);
        }

        this._order.TaxOrder();
        //Refactor: this.GUI_SetOrder(localAOLO, localAOLOModules);

        //Reapply Percent Off entire order coupon after calculating totals
        if (couponsToApplyAfterTotals.length > 0) {
            for (const reapplyCoupon of couponsToApplyAfterTotals) {
                const params: IApplyCouponByIdParamsPartial = {
                    loyaltyProvider: this._loyaltyProvider,
                    couponId: reapplyCoupon.couponId
                };

                const result = await this._order.applyCouponByIdAsync(params);
                if (!result) {
                    const incompleteDiscount: IIncompleteDiscount = {
                        couponID: reapplyCoupon.couponId,
                        couponCode: reapplyCoupon.offerCode || '',
                        channelID: null,
                        isCampaignCode: reapplyCoupon.offerCode ? true : false,
                        isPromotionCode: false,
                        rewardId: reapplyCoupon.rewardId,
                        isBankedCurrency: reapplyCoupon.isBankedCurrency
                    };
                    this._order.addIncompleteDiscount(this._loyaltyProvider, incompleteDiscount);
                }
            }
        }
        await this._checkItemsCouponsMeetMinimumOrderAmountAsync();
    }

    private _checkItemsCouponsMeetMinimumOrderAmountAsync = async (): Promise<void> => {
        let removedCoupons: IRemovedCoupon[] = [];
        for (const oCoupon of this._order.getProperty("Coupons")) {
            const coupon = this._order.couponGetCoupon(oCoupon.CouponId, true, this._order.getProperty("OrderTypeID"), oCoupon.ChannelID);
            if (coupon) {
                const isMinimumOrderAmountOkay = this._order.checkMinimumOrderAmount(coupon, true, false);

                if (!isMinimumOrderAmountOkay) {
                    //remove coupon by item coupon key
                    const iCoupon = this._order.getProperty("ItemsCoupons").find(ic => ic.CouponKey == oCoupon.CouponKey);
                    if (iCoupon)
                        removedCoupons = await this._order.removeCouponByItemKeyAsync(this._loyaltyProvider, iCoupon.ItemKey, true);
                }
            }
        }

        if (removedCoupons.length > 0) {
            await this._reviewRemovedCouponsForOrderAsync(removedCoupons);
            this._order.TaxOrder();


            // 2DOL Refactor: this.GUI_SetOrder(localAOLO, localAOLOModules);
        }
    }

    private _reviewRemovedCouponsForOrderAsync = async (removedCoupons: IRemovedCoupon[]): Promise<IRemovedCoupon[]> => {
        const couponsToApplyAfterTotals: IRemovedCoupon[] = []
        for (const removedCoupon of removedCoupons) {
            if (removedCoupon.couponId === CouponIdList.LOYALTY_REDEEM_ITEM)
                continue;

            const coupon = this._order.couponGetCoupon(removedCoupon.couponId, true, this._order.getProperty("OrderTypeID"), null);
            if (coupon && coupon.Items.length > 0) {
                let result: boolean | null = false;
                if (coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) {
                    const params: IApplyCouponByIdParamsPartial = {
                        loyaltyProvider: this._loyaltyProvider,
                        couponId: removedCoupon.couponId
                    };
                    result = await this._order.applyCouponByIdAsync(params);
                }

                if (coupon.CouponTypeId == CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER) {
                    couponsToApplyAfterTotals.push(removedCoupon);
                    continue;
                }

                if (!result) {
                    const incompleteDiscount: IIncompleteDiscount = {
                        couponID: removedCoupon.couponId,
                        couponCode: removedCoupon.offerCode || '',
                        channelID: null,
                        isCampaignCode: removedCoupon.offerCode ? true : false,
                        isPromotionCode: false,
                        rewardId: removedCoupon.rewardId,
                        isBankedCurrency: removedCoupon.isBankedCurrency
                    };
                    this._order.addIncompleteDiscount(this._loyaltyProvider, incompleteDiscount);
                }
            } else {
                const params: IApplyCouponByIdParamsPartial = {
                    loyaltyProvider: this._loyaltyProvider,
                    couponId: removedCoupon.couponId,
                    couponCode: removedCoupon.offerCode,
                    rewardId: removedCoupon.rewardId,
                    isBankedCurrency: removedCoupon.isBankedCurrency
                };

                const result = await this._order.applyCouponByIdAsync(params);
                if (!result) {
                    const incompleteDiscount: IIncompleteDiscount = {
                        couponID: removedCoupon.couponId,
                        couponCode: removedCoupon.offerCode || '',
                        channelID: null,
                        isCampaignCode: removedCoupon.offerCode ? true : false,
                        isPromotionCode: false,
                        rewardId: removedCoupon.rewardId,
                        isBankedCurrency: removedCoupon.isBankedCurrency
                    };
                    this._order.addIncompleteDiscount(this._loyaltyProvider, incompleteDiscount);
                    this.GUI_SetOrder_Total();
                }
            }
        }
        return couponsToApplyAfterTotals;
    }

    //private readonly _createMenuItemViewAllCards = (parentDiv: HTMLElement, items: IDataItem[]): void => {
    //    for (const item of items) {
    //        const card = new MenuItemViewAllCard(item, globalThis.aOLO.Temp.languageCode, () => { this._menuItemCardOnClick(item, 1); });
    //        parentDiv.appendChild(card.element);
    //    }
    //}

    //private readonly _menuItemCardOnClick = (mItem: IDataItem, quantity: number): void => {
    //    const defaultSize = OnlineOrderingUtil.GetItemDefaultSize(mItem, this._order.getProperty("OrderTypeID") || 0, null, this._data.getProperty("Settings")?.DSZID || 0);
    //    Common.checkAgeAndCustomizeItem(mItem, globalThis.aOLO, () => {
    //        const dialog = new CustomizeItem(this._eventBus, this._data, this._order, this._dataLayer, this._coupon, this._loyaltyProvider, mItem, defaultSize.SizeId, quantity);
    //        dialog.init();
    //        dialog.open();
    //    });
    //}

    //private readonly _setHeaderName = (names: IName[]): void => {
    //    const name = Common.GetName(names, globalThis.aOLO.Temp.languageCode);
    //    const data = { name: name, ltagj: Util.toLtagj(names) };
    //    this._eventBus.emit(EventTypes.HEADER_NAME_CHANGE, data);
    //}
}