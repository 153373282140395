var ___EXPOSE_LOADER_IMPORT___ = require("-!../../../../node_modules/ts-loader/index.js??ruleSet[1].rules[0].use[1]!./dimmer.ts");
var ___EXPOSE_LOADER_GET_GLOBAL_THIS___ = require("../../../../node_modules/expose-loader/dist/runtime/getGlobalThis.js");
var ___EXPOSE_LOADER_GLOBAL_THIS___ = ___EXPOSE_LOADER_GET_GLOBAL_THIS___;
var ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___ = ___EXPOSE_LOADER_IMPORT___.initiateOLOPage
if (typeof ___EXPOSE_LOADER_GLOBAL_THIS___["initiateOLOPage"] === 'undefined') ___EXPOSE_LOADER_GLOBAL_THIS___["initiateOLOPage"] = ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___;
else throw new Error('[exposes-loader] The "initiateOLOPage" value exists in the global scope, it may not be safe to overwrite it, use the "override" option')
var ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___ = ___EXPOSE_LOADER_IMPORT___.initiateOrderHubPaymentPage
if (typeof ___EXPOSE_LOADER_GLOBAL_THIS___["initiateOrderHubPaymentPage"] === 'undefined') ___EXPOSE_LOADER_GLOBAL_THIS___["initiateOrderHubPaymentPage"] = ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___;
else throw new Error('[exposes-loader] The "initiateOrderHubPaymentPage" value exists in the global scope, it may not be safe to overwrite it, use the "override" option')
module.exports = ___EXPOSE_LOADER_IMPORT___;
