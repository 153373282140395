import { Popup } from "../popup";

export class Dialog extends Popup {
    private readonly _message: string;
    private readonly _buttons: { label: string, value: string }[];
    private readonly _callback: (value: string) => void;

    constructor(callback: (value: string) => void, message: string, buttons: { label: string, value: string }[]) {
        super("modal_dialog");

        this._message = message;
        this._buttons = buttons;

        this._callback = callback;
    }

    public init() {
        super.init();

        const buttons = document.getElementById("modal_dialog_buttons");
        buttons && (buttons.innerHTML = "");
        buttons &&  this._buttons.forEach(e => {
            const button = document.createElement("button");
            button.value = e.value;
            button.innerText = e.label;
            button.addEventListener(
                "click",
                this._onClick);

            buttons.append(button)
        })

        console.log(buttons)

        const message = document.getElementById("modal_dialog_message");
        message && (message.innerText = this._message);

        console.log(message)

        message && this._element?.append(message);
        buttons && this._element?.append(buttons);
    }

    private readonly _onClick = (e: Event) => {
        this.close();
        this._callback((e.target as HTMLInputElement).value);
    }

    protected _setEventListeners(): void { }
}