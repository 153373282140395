import { IUser } from './user.interface';
import { IaOLOUser, IaOLOUserLoyaltyReward, IaOLOUserFavoriteStore } from './user-olo.interface';
import EventEmitter from 'eventemitter3';
import { EventTypes } from '../core/event-bus';

/**
 * Represents a user's profile with favorite stores, loyalty info and login status.
 */
export class User {
    private _user: IUser;
    private _eventBus: EventEmitter;

    /**
     * @private
     * @property {boolean} _loggedIn - A boolean value indicating whether the user is logged in or not.
     */
    private _loggedIn: boolean = false;

    constructor(eventBus: EventEmitter) {
        this._user = {} as IUser;
        this._eventBus = eventBus;
    }

    set user(user: IUser) {
        this._user = user;
    }

    public getProperty<K extends keyof IUser>(property: K): IUser[K] | null {
        if (!this._user)
            return null;

        return this._user[property];
    }

    public setProperty<K extends keyof IUser>(key: K, value: IUser[K]) {
        this._user[key] = value;
    }

    public updateUser(updates: Partial<IUser>): void {
        if (!this._user)
            return;

        this._user = { ...this._user, ...updates };
    }

    public setLoggedIn(loggedIn: boolean) {
        this._loggedIn = loggedIn;

        if (this._loggedIn) this._eventBus.emit(EventTypes.LOGGED_IN);
        else this._eventBus.emit(EventTypes.LOGGED_OUT);
    }

    /**
     * Checks whether the user is currently logged in.
     * @returns {boolean} True if the user is logged in, false otherwise.
     */
    public isLoggedIn = (): boolean => {
        return this._loggedIn;
    }
}