import './recent-order.css'

import { EventEmitter } from 'eventemitter3'; 
import { EventTypes } from '../../../core/event-bus';
import { Names } from '../../../utils/i18n';
import { Data } from '../../../models/data';
import { Pages } from '../../../types/enums';
import { ProfileService } from "../../../services/profile-service";
import { IUserRecentOrder, IUserRecentOrderDetail, IUserRecentOrderDetails } from "../../../models/user.interface";
import { OrderService } from '../../../services/order-service';

export class RecentOrders {
    private readonly _eventBus: EventEmitter;
    private readonly _profileService: ProfileService;
    private readonly _orderService: OrderService;
    private readonly _data: Data;

    constructor(eventBus: EventEmitter, data: Data, profileService: ProfileService, orderService: OrderService) {
        this._eventBus = eventBus;
        this._profileService = profileService;
        this._orderService = orderService;
        this._data = data;
    }

    /**
     * Build the 'Recently Ordered Items' section
     *  1. Loads the full set of orders (if not in cache).
     *  2. Renders them.
     *  3. Adds "Show Earlier Orders" button if we have more to load.
     *  4. If no orders exist, renders a message.
     *  @param {string} divId - the id of the div for recent orders to be rendered
     */
    public buildRecentlyOrderedItemsSection = async (divId: string): Promise<void> => {
        const initialOrders = await this._initUserRecentOrders();

        const parent = document.getElementById(divId);
        if (!parent) {
            console.error("Parent element not found.");
            return;
        }

        parent.classList.add("recent-orders-parent");

        if (!initialOrders || initialOrders.recentOrders.length === 0) {
            this._renderNoRecentOrdersMessage(parent);
            return;
        }

        this._renderOrderList(initialOrders, parent);
        this._setEventListeners();
        const hasMoreOrders = this._profileService.user.getProperty("RecentOrders")?.orderInfo.hasMoreOrders ?? false;
        if (hasMoreOrders) {
            this.addShowEarlierOrdersButton(parent);
        }
    };

    private readonly _setEventListeners = (): void => {
        const buttons = document.getElementsByName("btn_track_order");
        for (const button of buttons) {
            const id = Number(button.dataset.id);
            button.onclick = () => {
                this._eventBus.emit(
                    EventTypes.LOCATION_CHANGE,
                    Pages.TRACK_ORDER,
                    id
                )
            };
        }
    }

    /**
     * Renders the passed-in order list. 
     * If the "Show Earlier Orders" button exists, we insert above it; 
     * otherwise, we append to the parent.
     */
    private readonly _renderOrderList = (userOrders: IUserRecentOrderDetails | { recentOrders: IUserRecentOrder[] }, parent: HTMLElement): void => {
        userOrders.recentOrders.forEach((order) => {
            const orderCard = document.createElement("div");
            orderCard.classList.add("recent-order-main-card");

            const formattedDate = this._formatDate(order.busDate);

            const header = `
            <div class="recent-order-card-header">
                <span class="order-type">${order.orderTypeName}</span>
                <span class="order-status">${this._getOrderStatusText(order.orderStatusId)}</span>
            </div>
        `;

            const details = `
            <div class="recent-order-card-details">
                <p class="recent-order-address">${order.address}</p>
                <p class="recent-order-date">${formattedDate}</p>
            </div>
        `;

            const items = order.orderItems
                .map(
                    (item) => `
                <div class="recent-order-card-item">
                    <img src="${item.imageURL}" alt="${item.name}" />
                    <div class="recent-order-item-details" qty="${item.quantity}">
                        <p>${item.name}</p>
                        <p class="recent-order-item-description">${item.description}</p>
                        <div class="recent-order-item-actions">
                            <button ltag="Customize">${Names("Customize")}</button> 
                            <button ltag="AddToOrder">${Names("AddToOrder")}</button>
                        </div>
                    </div>
                </div>
            ` //2DO add functionality to customize + add to order
                )
                .join("");

            const footer = `
            <div class="recent-order-card-footer">
                ${order.orderStatusId === 0
                    ? `<button ltag="Reorder">${Names("Reorder")}</button>` //2DO
                : `<button ltag="TrackOrder" id="btn_track_order_${order.orderId}" name="btn_track_order" data-id="${order.orderId}">${Names("TrackOrder")}</button>` //2DO
                }
            </div>
        `;

            orderCard.innerHTML =
                header + details + `<div class="recent-order-card-items">${items}</div>` + footer;

            const existingButton = parent.querySelector(".show-earlier-orders-btn") as HTMLElement;
            if (existingButton) {
                const wrapperCard = existingButton.closest(".recent-order-main-card") as HTMLElement;
                if (wrapperCard && wrapperCard.parentNode === parent) {
                    parent.insertBefore(orderCard, wrapperCard);
                } else {
                    parent.appendChild(orderCard);
                }
            } else {
                parent.appendChild(orderCard);
            }
        });
    };

    /**
     * Creates a "Show Earlier Orders" button if one doesn't already exist.
     * On click, fetches and renders only the NEW items.
     * If none remain, removes itself.
     * @param {HTMLElement} parent - the parent element to which the button will be appended
     */
    public addShowEarlierOrdersButton = (parent: HTMLElement): void => {
        const existingButton = parent.querySelector(".show-earlier-orders-btn") as HTMLButtonElement;
        if (existingButton) {
            return;
        }

        const button = document.createElement("button");
        button.classList.add("show-earlier-orders-btn");
        button.innerText = Names("ShowEarlierOrders");

        button.addEventListener("click", async () => {
            const newOrders = await this._getMoreUserRecentOrders();
            if (!newOrders || newOrders.length === 0) return;

            const firstNewOrderIndex = parent.children.length - 2;
            this._renderOrderList({ recentOrders: newOrders }, parent);

            if (firstNewOrderIndex < 0) return;

            const firstNewOrder = parent.children.item(firstNewOrderIndex) as HTMLElement;
            if (firstNewOrder) {
                const cardFooter = firstNewOrder.querySelector<HTMLElement>(".recent-order-card-footer");
                if (cardFooter) {
                    cardFooter.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
                }
            }

            const hasMoreOrders = this._profileService.user.getProperty("RecentOrders")?.orderInfo.hasMoreOrders ?? false;
            if (!hasMoreOrders) {
                button.remove();
            }
        });

        //Hack to make buttons the same width without using a listener
        const cardWrapper = document.createElement("div");
        cardWrapper.classList.add("recent-order-main-card", "pseudo");

        const footer = document.createElement("div");
        footer.classList.add("recent-order-card-footer", "pseudo");

        footer.appendChild(button);
        cardWrapper.appendChild(footer);
        parent.appendChild(cardWrapper);
    };

    private readonly _renderNoRecentOrdersMessage = (parent: HTMLElement): void => {
        const noOrdersMessage = document.createElement("div");
        noOrdersMessage.classList.add("no-recent-orders-message");
        noOrdersMessage.innerText = Names("NoRecentOrders");
        parent.appendChild(noOrdersMessage);
    };

    private readonly _initUserRecentOrders = async (): Promise<IUserRecentOrderDetails | null> => {
        const result = await this._fetchRecentOrders(true);
        if (!result || !result.allOrders) {
            return null;
        }
        return result.allOrders;
    };

    private readonly _getMoreUserRecentOrders = async (): Promise<IUserRecentOrder[] | null> => {
        const result = await this._fetchRecentOrders(false);
        if (!result || result.newOrders.length === 0) {
            return null;
        }
        return result.newOrders;
    };

    /**
     * Fetches recent orders by page.
     * 
     * Returns:
     *   - allOrders: The merged set of all known orders so far
     *   - newOrders: Only the newly fetched orders from the last fetch
     *   - hasMore:   Whether more orders remain to be fetched
     *   @param {boolean} isInit - whether this is the first fetch
     */
    private readonly _fetchRecentOrders = async (isInit: boolean): Promise<{ allOrders: IUserRecentOrderDetails | null; newOrders: IUserRecentOrder[]; hasMore: boolean; } | null> => {
        let userOrders = this._profileService.user.getProperty("RecentOrders") as IUserRecentOrderDetail | null;

        const oldCount = userOrders?.orderDetails.recentOrders.length ?? 0;

        if (isInit && userOrders) {
            return {
                allOrders: userOrders.orderDetails,
                newOrders: [],
                hasMore: userOrders.orderInfo.hasMoreOrders,
            };
        }

        if (!userOrders) { //init user orders if doesnt exist
            userOrders = {
                orderInfo: {
                    pageNumber: 0,
                    hasMoreOrders: false
                },
                orderDetails: {
                    pagination: 0,
                    recentOrders: []
                }
            };
        }

        const data = await this._orderService.getRecentOrdersByPage(this._profileService.user.getProperty("RecentOrders")?.orderInfo.pageNumber || 0);

        // 1) If data.pagination=1 => more orders remain
        if (data.pagination === 1) {
            userOrders.orderInfo.hasMoreOrders = true;
            userOrders.orderInfo.pageNumber += 1;
        } else {
            userOrders.orderInfo.hasMoreOrders = false;
        }

        if (data.recentOrders && data.recentOrders.length > 0) {
            if (!userOrders.orderDetails) {
                // No existing orderDetails => just set them
                userOrders.orderDetails = {
                    pagination: data.pagination,
                    recentOrders: data.recentOrders
                };
            } else {
                // Already have orderDetails => merge
                userOrders.orderDetails.pagination = data.pagination;
                userOrders.orderDetails.recentOrders = [
                    ...userOrders.orderDetails.recentOrders,
                    ...data.recentOrders
                ];
            }
        }

        const newCount = userOrders.orderDetails.recentOrders.length ?? 0;
        const newlyFetched = userOrders
            ? userOrders.orderDetails.recentOrders.slice(oldCount, newCount)
            : [];

        const hasMore = data.pagination === 1;
        this._profileService.user.updateUser({ RecentOrders: userOrders });

        return {
            allOrders: userOrders.orderDetails,
            newOrders: newlyFetched,
            hasMore
        };
    };

    /**
     * Utility to format date as "Day Name, Month Name Day Number, Year"
     */
    private _formatDate(dateString: string): string {
        const options: Intl.DateTimeFormatOptions = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        const date = new Date(dateString + "T00:00:00");
        const cultureCode = this._profileService.user.getProperty("CultureCode") ?? "en-US";
        return date.toLocaleDateString(cultureCode, options);
    }

    /**
     * Utility method to get order status text
     */
    private _getOrderStatusText(statusId: number): string {
        switch (statusId) {
            case 0:
                return Names("Complete");
            case 1:
                return Names("Future");
            case 2:
                return Names("InProgress");
            default:
                return "";
        }
    }
}