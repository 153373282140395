import '../../css/icons.css';
import '../../css/dialog.css';
import '../../css/grid.css';
import '../../css/default.css';
import '../../css/check-radio.css';
import '../../css/radio-bar.css';
import '../../css/shared/dark-mode.css';
import '../../css/button.css';
import '../../css/fancy-title.css';
import '../../css/modal.css';
import '../../css/dimmer.css';

import { Data } from '../models/data';
import { Order } from '../models/order';
import { User } from '../models/user';

import { Coupon } from './coupon';
import { IaOLO } from '../interfaces/aolo.interface';

import { ProfileService } from '../services/profile-service';
import { createEventBus, EventTypes } from '../core/event-bus';
import { LoyaltyProviderFactory } from '../services/loyalty-provider/loyalty-provider-factory';
import { LoyaltyService } from '../services/loyalty-service';
import { AddressService } from '../services/address-service';
import { OrderService } from '../services/order-service';
import { TrackerService } from "../services/tracker-service";

import { Account } from "../components/pages/Account/account";
import { Contact } from "../components/pages/Contact/contact";
import { Favorites } from "../components/pages/Favorites/favorites";
import { Landing } from "../components/pages/Landing/landing";
import { Locations } from "../components/pages/Locations/locations";
import { Menu } from "../components/pages/Menu/menu";
import { MenuViewAll } from "../components/pages/MenuViewAll/menu-view-all";
import { Orders } from "../components/pages/Orders/orders";
import { Rewards } from "../components/pages/Rewards/rewards";
import { Settings } from "../components/pages/Settings/settings";
import { Terms } from "../components/pages/Terms/terms";
import { TrackOrder } from "../components/pages/TrackOrder/track-order";
import { ICouponFunctions } from './interfaces/coupons.interface';
import { LocationService } from '../services/location-service';
import { DataLayerService } from '../services/data-layer-service';

import { Router } from "../core/router"
import { Pages } from '../types/enums';
import { ITemp } from '../interfaces/temp.interface';
import { Header } from '../components/shared/Header/header';
import { NavigationBar } from '../components/shared/NavigationBar/navigation-bar';
import { Login } from '../components/popups/Login/login';
import { Hamburger } from '../components/popups/Hamburger/Hamburger';
import { Signup } from '../components/popups/Signup/signup';
import { setPageLanguage } from '../utils/i18n';
import { Util } from '../utils/util';
import { Cart } from '../components/pages/Cart/cart';
import { OnlineOrderingService } from '../online-ordering/online-ordering-service';

export function initiateOLOPage(globalvar: any, cultureCode: string): void {
    const eventBus = createEventBus();

    globalThis.aOLO = globalvar as IaOLO;
    globalThis.aOLO.Temp = { languageCode: cultureCode } as ITemp;

    Util.getDesktopLocationAsync();
    setPageLanguage(globalThis.aOLO.Temp.languageCode);

    //initiatePage(globalvar, cultureCode)

    const dataLayerService = new DataLayerService();
    const onlineOrderingService = new OnlineOrderingService(globalThis.aOLO.AddressLookupUrl)

    const data = new Data(eventBus, globalThis.aOLO.PublicStorageUrl, globalThis.aOLO.storeInfo.BrandID);
    const user = new User(eventBus);
    const order = new Order(eventBus, data, user, dataLayerService, onlineOrderingService);

    const apiVersion = "1.0";
    const apiLoyalty = globalThis.aOLO.LoyaltyApiUrl;
    const apiProfile = `${location.origin}/api/v${apiVersion}/profile/`;
    const apiTracker = `${location.origin}/api/v${apiVersion}/tracker/`;
    const apiLocation = `${location.origin}/api/v${apiVersion}/locations/`;
    const apiAddress = globalThis.aOLO.AddressLookupUrl;
    const apiOrder = `${location.origin}/api/v${apiVersion}/order/`;
    const apiOnlineOrdering = `${location.origin}/api/v${apiVersion}/olo/`;

    //const coupon = new Coupon(eventBus, data, order, user, {} as ICouponFunctions);
    const loyaltyService = new LoyaltyService(data, user, apiLoyalty);
    const loyaltyProvider = LoyaltyProviderFactory.getLoyaltyProvider(data, user, order, loyaltyService);
    const profileService = new ProfileService(data, user, loyaltyProvider, apiProfile, apiLoyalty);
    const locationService = new LocationService(data, apiLocation);
    //const addressService = new AddressService(data, locationService, apiAddress, "", "", "", [], () => { });
    const trackerService = new TrackerService(data, apiTracker);
    const orderService = new OrderService(order, apiOrder, apiOnlineOrdering);

    const signup = new Signup(eventBus, data, profileService);
    signup.init();
    const login = new Login(eventBus, profileService, signup);
    login.init();
    const hamburger = new Hamburger(eventBus, profileService, login, signup);
    hamburger.init();
    const header = new Header(eventBus, profileService, hamburger, login, order);
    header.init();
    const navbar = new NavigationBar(eventBus);
    navbar.init();

    new Router(eventBus, {
        [Pages.ACCOUNT]: new Account(eventBus, data),
        [Pages.CONTACT]: new Contact(eventBus, data),
        [Pages.FAVORITES]: new Favorites(eventBus, profileService, orderService, data),
        [Pages.LANDING]: new Landing(eventBus, data, profileService),
        [Pages.LOCATIONS]: new Locations(eventBus, data, profileService, orderService, locationService, apiAddress),
        [Pages.MENU]: new Menu(eventBus, data, profileService, orderService, dataLayerService, loyaltyProvider),
        [Pages.MENU_VIEW_ALL]: new MenuViewAll(eventBus, data, order, dataLayerService, loyaltyProvider, 0, 0),
        [Pages.ORDERS]: new Orders(eventBus, data, order, user, profileService, orderService),
        [Pages.REWARDS]: new Rewards(eventBus, data),
        [Pages.SETTINGS]: new Settings(eventBus, data),
        [Pages.TERMS]: new Terms(eventBus, data),
        [Pages.TRACK_ORDER]: new TrackOrder(eventBus, trackerService, data, order, user),
        [Pages.CART]: new Cart(eventBus, data, order, user, dataLayerService, loyaltyProvider)
    }, location.pathname as Pages)

    eventBus.emit(EventTypes.STORE_CHANGED, globalThis.aOLO.storeInfo.StoreKey);
    eventBus.on(EventTypes.DATA_RETRIEVED, () => profileService.signInJWT())
}