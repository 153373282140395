import './order-type-selector.css';

import { EventEmitter } from 'eventemitter3';
import { Util } from '../../../utils/util';
import { Common } from '../../../common';
import { EventTypes } from '../../../core/event-bus';
import { OrderType } from '../../../types/enums';
import { Data } from '../../../models/data';

export class OrderTypeSelector {
    private readonly _eventBus: EventEmitter;
    private readonly _data: Data;
    private readonly _parentId: string;
    private readonly _location: string;

    constructor(eventBus: EventEmitter, data: Data, parentId: string, location: string) {
        this._eventBus = eventBus;
        this._data = data;
        this._parentId = parentId;
        this._location = location;
    }

    public init = (orderTypeId: OrderType = OrderType.TAKE_OUT): void => {
        this._eventBusListeners();
        this._renderPage(orderTypeId);
        this._setEventListeners();
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.ORDER_TYPE_CHANGED, this._orderTypeChanged);
    }

    private readonly _renderPage = (orderTypeId: OrderType): void => {
        this._createOrderTypes(orderTypeId);
    }

    private readonly _setEventListeners = (): void => {
        for (const ot of this._data.getProperty("OrderTypeSubTypes") || []) {
            Util.setElement("onclick", `btn_${this._location}_order_type_${ot.OrderTypeId}`, () => {
                this._eventBus.emit(EventTypes.ORDER_TYPE_CHANGED, ot.OrderTypeId);
            });
        }
    }

    private readonly _createOrderTypes = (orderTypeId: OrderType): void => {
        const parentDiv = document.getElementById(this._parentId);
        if (!parentDiv)
            return;

        parentDiv.innerHTML = "";

        let buttonsHtml = ""
        for (const ot of this._data.getProperty("OrderTypeSubTypes") || []) {
            const orderTypeName = Common.GetName(ot.Names, globalThis.aOLO.Temp.languageCode);
            const active = ot.OrderTypeId === orderTypeId ? "active" : "";
            buttonsHtml += `<button id="btn_${this._location}_order_type_${ot.OrderTypeId}" class="toggle-btn ${active}">${orderTypeName}</button>`;
        }

        parentDiv.innerHTML = `
            <div class="toggle-buttons">
                ${buttonsHtml}
            </div>`;

        Util.showElement(parentDiv);

        // Make buttons same width
        //const buttons = Array.from(document.querySelectorAll('.toggle-btn')) as HTMLButtonElement[];
        //let maxWidth = 0;

        //buttons.forEach(button => {
        //    // Get the natural width of each button
        //    maxWidth = Math.max(maxWidth, button.offsetWidth);
        //});

        //// Set each button to the width of the largest button
        //buttons.forEach(button => {
        //    button.style.width = `${maxWidth}px`;
        //});
    }

    private readonly _orderTypeChanged = (orderTypeId: OrderType): void => {
        const buttons = document.querySelectorAll(".toggle-btn");
        for (const button of buttons) {
            button.classList.remove("active");
        }
        Util.setElementClass("add", `btn_${this._location}_order_type_${orderTypeId}`, "active");
    }
}