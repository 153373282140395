import './order.css';

import { Util } from "../../../utils/util";
import { EventEmitter } from 'eventemitter3';
import { EventTypes } from '../../../core/event-bus';
import { User } from '../../../models/user';
import { Data } from '../../../models/data';
import { Order } from '../../../models/order';
import { ProfileService } from "../../../services/profile-service";
import { OrderTypeAddressNavBar } from '../../shared/OrderTypeAddressNavBar/order-type-address-nav-bar';
import { RecentOrders } from '../../shared/RecentOrder/recent-orders';
import { OrderType } from '../../../types/enums';
import { OrderService } from '../../../services/order-service';
import { Page } from '../page';

export class Orders extends Page {
    private readonly _divRecentOrders = "div_recent_orders_main";
    private readonly _profileService: ProfileService;
    private readonly _orderService: OrderService;
    private readonly _order: Order;
    private readonly _user: User;

    constructor(eventBus: EventEmitter, data: Data, order: Order, user: User, profileService: ProfileService, orderService: OrderService) {
        super("page_orders", eventBus, data)
        this._profileService = profileService;
        this._orderService = orderService;
        this._user = user;
        this._order = order;
        this._eventBusListeners();
    }

    public async initAsync(): Promise<void> {
        //this._showPage();
        this._buildNavBar();

        if (this._user.isLoggedIn())
            this._buildRecentOrderSection();
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.LOGGED_IN, this._buildRecentOrderSection)
        this._eventBus.on(EventTypes.LOGGED_OUT, this._clearRecentlyOrderedItemsSection);
    }

    //private _showPage(): void {
    //    Util.showComponent("orders");
    //}

    private readonly _buildRecentOrderSection = () => {
        const recentOrderComponent = new RecentOrders(this._eventBus, this._data, this._profileService, this._orderService);
        recentOrderComponent.buildRecentlyOrderedItemsSection(this._divRecentOrders);
    }

    private readonly _clearRecentlyOrderedItemsSection = () => {
        Util.setElement("innerHTML", this._divRecentOrders, "");
    }

    private readonly _buildNavBar = (): void => {
        let address = "";
        if (this._order.getProperty("OrderTypeID") === OrderType.DELIVERY) {
            const deliveryAddress = this._order.getProperty("Address");
            if (deliveryAddress) {
                address = Util.formatAddressObject({
                    StreetNo: deliveryAddress.StreetNo,
                    Address1: deliveryAddress.StreetName,
                    Address2: deliveryAddress.Address2,
                    Address3: deliveryAddress.Address3,
                    Address4: deliveryAddress.Address4,
                    Address5: deliveryAddress.Address5,
                    City: deliveryAddress.City,
                    State: deliveryAddress.State,
                    ZipCode: deliveryAddress.Zip,
                    CountryID: deliveryAddress.CountryID || 1,
                    AddressTypeID: 0
                }, this._data.getProperty("Countries") || []).address1;
            }
        } else {
            const store = this._data.getProperty("StoreInfo");
            if (store) {
                address = Util.formatAddressObject({
                    StreetNo: store.streetNumber,
                    Address1: store.address1,
                    Address2: store.address2,
                    Address3: store.address3,
                    Address4: store.address4,
                    Address5: store.address5,
                    City: store.city,
                    State: store.state,
                    ZipCode: store.zip,
                    CountryID: store.countryId || 1,
                    AddressTypeID: 0
                }, this._data.getProperty("Countries") || []).address1;
            }
        }
        const navbar = new OrderTypeAddressNavBar(this._eventBus, this._data, globalThis.aOLO.PublicStorageUrl, this._order.getProperty("OrderTypeID") || 0, address);
        navbar.render("div_order_type_address_nav_bar");
        Util.showElement("div_order_type_address_nav_bar");
        Util.setElementClass("add", "footer", "nav-bar");
    }
}