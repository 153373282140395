import { LoyaltyProvider } from "../../types/enums";
import { LoyaltyService } from "../loyalty-service";
import { ILoyaltyProvider } from "./loyalty-provider.interface";
import { AdoraProvider } from "./adora-provider";
import { NoneProvider } from "./none-provider";
import { PunchhProvider } from "./punchh-provider";
import { Data } from "../../models/data";
import { Order } from "../../models/order";
import { User } from "../../models/user";

export class LoyaltyProviderFactory {
    static getLoyaltyProvider(data: Data, user: User, order: Order, loyaltyService: LoyaltyService ): ILoyaltyProvider {
        // 2D0: fix the vodoo magic values below
        const couponLimit = data.getProperty("Settings")?.CPNLIM;
        let oneCouponLimit = false;
        if (couponLimit)
            oneCouponLimit = (couponLimit === 2 || couponLimit === 3);

        switch (data.getProperty("Loyalty")?.ProviderId) {
            case LoyaltyProvider.ADORA:
                return new AdoraProvider(data, user, order, loyaltyService, oneCouponLimit);
            case LoyaltyProvider.PUNCHH:
                return new PunchhProvider(data, user, order, loyaltyService, oneCouponLimit);
            default: return new NoneProvider(data, user, order, loyaltyService, oneCouponLimit);
        }
    }
}