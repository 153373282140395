import './favorites.css';

import EventEmitter from "eventemitter3";
import { ProfileService } from '../../../services/profile-service';
import { OrderService } from '../../../services/order-service';
import { Data } from "../../../models/data";
import { Page } from "../page";
import { OrderType, Pages } from "../../../types/enums";
import { Util } from "../../../utils/util";
import { OrderTypeAddressNavBar } from "../../shared/OrderTypeAddressNavBar/order-type-address-nav-bar";
import { ITabBarData } from "../../shared/TabBar/tab-bar.interface";
import { Names } from "../../../utils/i18n";
import { TabBar } from "../../shared/TabBar/tab-bar";
import { EventTypes } from '../../../core/event-bus';

export class Favorites extends Page {
    private readonly _profileService: ProfileService;
    private readonly _orderService: OrderService;

    constructor(eventBus: EventEmitter, profileService: ProfileService, orderService: OrderService, data: Data) {
        super("page_favorites", eventBus, data);
        this._profileService = profileService;
        this._orderService = orderService;
        this._eventBusListeners();
    }

    public readonly initAsync = async (): Promise<void> => {
        await this._dataRetrievedAsync();
    }

    private readonly _renderPageAsync = async (): Promise<void> => {
        this._buildNavBar();
        this._buildFavoritesTabBar();
        this._onTabButtonClick(-1); //on init default to favorite menu items
    }

    private readonly _eventBusListeners = () => {
    }

    private readonly _dataRetrievedAsync = async (): Promise<void> => {
        //2DO implement functionality for favorites
        this._renderNoFavMenuItem();
        this._renderNoFavLocation();

        await this._renderPageAsync();
    }

    private readonly _buildNavBar = (): void => {
        let address = "";

        const orderTypeId = this._orderService.order.getProperty("OrderTypeID");
        if (orderTypeId == undefined) return;

        if (orderTypeId === OrderType.DELIVERY) {
            const deliveryAddress = this._orderService.order.getProperty("Address");
            if (deliveryAddress) {
                address = Util.formatAddressObject({
                    StreetNo: deliveryAddress.StreetNo,
                    Address1: deliveryAddress.StreetName,
                    Address2: deliveryAddress.Address2,
                    Address3: deliveryAddress.Address3,
                    Address4: deliveryAddress.Address4,
                    Address5: deliveryAddress.Address5,
                    City: deliveryAddress.City,
                    State: deliveryAddress.State,
                    ZipCode: deliveryAddress.Zip,
                    CountryID: deliveryAddress.CountryID || 1,
                    AddressTypeID: 0
                }, this._data.getProperty("Countries") || []).address1;
            }
        } else {
            const store = this._data.getProperty("StoreInfo");
            if (store) {
                address = Util.formatAddressObject({
                    StreetNo: store.streetNumber,
                    Address1: store.address1,
                    Address2: store.address2,
                    Address3: store.address3,
                    Address4: store.address4,
                    Address5: store.address5,
                    City: store.city,
                    State: store.state,
                    ZipCode: store.zip,
                    CountryID: store.countryId || 1,
                    AddressTypeID: 0
                }, this._data.getProperty("Countries") || []).address1;
            }
        }

        const navbar = new OrderTypeAddressNavBar(this._eventBus, this._data, globalThis.aOLO.PublicStorageUrl, orderTypeId, address);
        navbar.render("div_order_type_address_nav_bar");
        Util.showElement("div_order_type_address_nav_bar");
        Util.setElementClass("add", "footer", "nav-bar");
    }

    private readonly _buildFavoritesTabBar = (): ITabBarData => {
        const tabsDiv = document.getElementById("div_favorite_tabs");
        if (!tabsDiv)
            return { buttonId: "", tabs: [] };

        tabsDiv.innerHTML = "";

        const self = this;
        const tabData: ITabBarData = {
            buttonId: "btn_menu_favorite_tab",
            tabs:
                [
                    { id: -1, text: Names("MenuItems"), ltag: "MenuItems", function: () => self._onTabButtonClick(-1) },
                    { id: -2, text: Names("Locations"), ltag: "Locations", function: () => self._onTabButtonClick(-2) }
                ]
        };

        const tabBar = new TabBar(tabData);
        tabsDiv.appendChild(tabBar.element);

        return tabData;
    }

    private readonly _onTabButtonClick = (tabId: number): void => {
        document.querySelectorAll(".menu-tab").forEach(tab => tab.classList.remove("active"));

        Util.showElement(tabId === -1 ? "div_favorite_items_parent" : "div_favorite_locations_parent");
        Util.hideElement(tabId === -1 ? "div_favorite_locations_parent" : "div_favorite_items_parent");

        const tabToActivate = document.getElementById(`btn_menu_favorite_tab_${tabId}`) || document.getElementById("btn_menu_favorite_tab_-1");
        tabToActivate?.classList.add("active");
    };

    private readonly _renderNoFavUI = (parentDivId: string, objectKey: string, instructionReplacement: string, eventType: string, page: string ): void => {
        const parentDiv = document.getElementById(parentDivId);
        if (!parentDiv) {
            return;
        }

        parentDiv.innerHTML = "";

        const noFavContainer = document.createElement("div");
        noFavContainer.classList.add("no-fav-container");

        const card = document.createElement("div");
        card.classList.add("no-fav-card");

        const heading = document.createElement("p");
        heading.textContent = Names("FavoriteObjectNone").replaceAll("{{object}}", Names(objectKey).toUpperCase());
        heading.classList.add("no-fav-heading");
        card.appendChild(heading);

        const instructions = document.createElement("p");
        instructions.textContent = Names("FavoriteObjectInstruction").replaceAll("{{object}}", instructionReplacement);
        instructions.classList.add("no-fav-instructions");
        card.appendChild(instructions);

        noFavContainer.appendChild(card);

        const button = document.createElement("button");
        button.textContent = `${Names("Find")} ${Names(objectKey)}`;
        button.classList.add("no-fav-button");
        button.onclick = () => {
            this._eventBus.emit(eventType, page);
        };

        noFavContainer.appendChild(button);

        parentDiv.appendChild(noFavContainer);
    };

    private readonly _renderNoFavMenuItem = (): void => {
        this._renderNoFavUI(
            "div_favorite_items_parent",
            "MenuItems",
            Names("MenuItems").toLowerCase(),
            EventTypes.LOCATION_CHANGE,
            Pages.MENU
        );
    };

    private readonly _renderNoFavLocation = (): void => {
        this._renderNoFavUI(
            "div_favorite_locations_parent",
            "Locations",
            "stores",
            EventTypes.LOCATION_CHANGE,
            Pages.LOCATIONS
        );
    };



}