import { LoyaltyProvider } from "../../types/enums";
import { IUserLoyaltyReward } from "../../models/user.interface";
import { Util } from "../../utils/util";
import { ISignIn, ISignInJWT, ISignInPhone, ISignInResponse } from "../profile-service.interface";
import { LoyaltyService } from "../loyalty-service";
import { ILoyaltyProvider } from "./loyalty-provider.interface";
import { Common } from "../../common";
import { Names } from "../../utils/i18n";
import { IDataReward } from "../../models/data.interface";
import { IProfile } from "../profile.interfaces";
import { DialogCreators } from "../../utils/dialog-creators";
import { IOrder } from "../../models/order.interface";
import { IAddressFormat } from "../../interfaces/global.interfaces";
import { ICreateFinishOrderPayloadLoyaltyResult, ICreateFinishOrderPayloadLoyaltyResult_Data_MenuItem } from "../loyalty-service.interface";
import { Data } from "../../models/data";
import { Order } from "../../models/order";
import { User } from "../../models/user";

export class AdoraProvider implements ILoyaltyProvider {
    private _data: Data;
    private _user: User;
    private _order: Order;

    private _loyaltyProvider: LoyaltyProvider = LoyaltyProvider.ADORA;
    private _loyaltyService: LoyaltyService;

    private _phoneFormatLanguage: string;
    private _hasPointRedemption: boolean = false;
    private _hasRewardRedemption: boolean = false;
    private _hasMembershipTiers: boolean = false;
    private _hasBankedCurrency: boolean = false;
    private _hasDiscountCodes: boolean = false;
    private _hasSingleSignOn: boolean = false;
    private _onlyOneDiscountAllowed: boolean = false;

    constructor(data: Data, user: User, order: Order, loyaltyService: LoyaltyService, onlyOneDiscountAllowed: boolean) {
        this._data = data;
        this._user = user;
        this._order = order;

        this._loyaltyService = loyaltyService;
        this._onlyOneDiscountAllowed = onlyOneDiscountAllowed;
        this._phoneFormatLanguage = Util.getDefaultCultureCode(this._user.getProperty("CountryCodeId") || this._data.getProperty("StoreInfo")?.countryId || 1, this._data.getProperty("Countries") || []);
        this._init();
    }

    private _init = async (): Promise<void> => {
        await this._setPrograms();
    }

    private _setPrograms = async (): Promise<void> => {
        const programTypes = this._data.getProperty("Loyalty")?.ProgramTypes.map(x => x.ProgramTypeId) || [];

        this._hasPointRedemption = programTypes.includes(2);
        this._hasRewardRedemption = programTypes.includes(1);
        this._hasMembershipTiers = programTypes.includes(3);
        this._hasBankedCurrency = programTypes.includes(4);
        this._hasDiscountCodes = programTypes.includes(5);
        this._hasSingleSignOn = false;
    }

    hasPointRedemption(): boolean {
        return this._hasPointRedemption;
    }

    hasRewardRedemption(): boolean {
        return this._hasRewardRedemption;
    }

    hasMembershipTiers(): boolean {
        return this._hasMembershipTiers;
    }

    hasBankedCurrency(): boolean {
        return this._hasBankedCurrency;
    }

    hasDiscountCodes(): boolean {
        return this._hasDiscountCodes;
    }

    hasSingleSignOn(): boolean {
        return this._hasSingleSignOn;
    }

    onlyOneDiscountAllowed(rewardId: number | null): boolean {
        if (!this._onlyOneDiscountAllowed)
            return false;

        if (this._order.getProperty("Coupons")?.length) {
            //DialogCreators.messageBoxOk(Names("OnlyOneDiscountAllowedAtATimeError"), this._aOLO.buttonHoverStyle);
            return true;
        }

        // 2DO: Incomplete discounts and loyalty refactor
        //if (this._aOLO.Temp.IncompleteDiscounts.length > 0 && !this._aOLO.Temp.IncompleteDiscounts.map(x => x.rewardId).includes(rewardId)) {
        //    DialogCreators.messageBoxOk(Names("OnlyOneDiscountAllowedAtATimeError"), this._aOLO.buttonHoverStyle);
        //    return true;
        //}

        return false;
    }

    discountValidForOrderTime(expiryDate: string | null): boolean {
        if (expiryDate) {
            const rewardExpDate = new Date(expiryDate);
            let promiseDate;
            const futureDate = this._order.getProperty("FutureDate");
            if (futureDate === null) {
                const tOffset = Util.NowStore(aOLO.Temp.TimeOffset);
                promiseDate = new Date(Util.GetBUSDate(tOffset, aOLO));
            } else {
                promiseDate = new Date(futureDate);
            }
            if (promiseDate.getTime() > rewardExpDate.getTime()) {
                DialogCreators.messageBoxOk(Names("InvalidReward"), globalThis.aOLO.buttonHoverStyle);
                return false;
            }
        }

        return true;
    }

    isLoyaltyDown(): boolean {
        return false;
    }

    getLoyaltyProvider(): LoyaltyProvider {
        return this._loyaltyProvider;
    }

    getHamburgerMenuOptions(): string[] {
        return [
            'li_vertical_menu_rewards',
            'li_vertical_menu_profile',
            'li_vertical_menu_orders',
            'li_vertical_menu_password',
            'li_vertical_menu_logout',
            'li_vertical_menu_history'
        ];
    }

    public readonly signIn = async (data: ISignIn): Promise<ISignInResponse> => {
        const result = await this._loyaltyService.signIn(data);

        const isApp = Util.isAppView();
        if (isApp && result.success && result.token) {
            //@ts-ignore
            if (typeof saveAppUserToken == "function" && result.token != null)
                //@ts-ignore
                saveAppUserToken(result.token);
        }
        return result;
    }

    public readonly signInJWT = async (data: ISignInJWT): Promise<ISignInResponse> => {
        return await this._loyaltyService.signInJWT(data);
    }

    public readonly signInApp = async (token: string): Promise<ISignInResponse> => {
        const result = await this._loyaltyService.signInApp(token);

        const isApp = Util.isAppView();
        if (isApp && result.success && result.token) {
            //@ts-ignore
            if (typeof saveAppUserToken == "function" && result.token != null)
                //@ts-ignore
                saveAppUserToken(result.token);
        }
        return result;
    }

    public readonly signInSSO = async (): Promise<ISignInResponse> => {
        throw new Error("Method not implemented.");
    }

    public readonly signInPhone = async (data: ISignInPhone): Promise<ISignInResponse> => {
        const response = await this._loyaltyService.signInPhone(data);
        return response;
    }

    public readonly getStartupInfo = async (): Promise<void> => {
        return;
    }

    public readonly getActiveDiscountBasket = async (): Promise<void> => {
        return;
    }

    public readonly addReward = async (reward: IUserLoyaltyReward): Promise<void> => {
        if (this.onlyOneDiscountAllowed(reward.RewardId) || !this.discountValidForOrderTime(reward.ExpiryDate)) {
            return;
        }

        await this._order.checkCouponHasQualifyingItemsAsync(this, reward.CouponId, null, null, reward);
        reward.Used = true;
        return;
    }

    public readonly removeReward = async (reward: IUserLoyaltyReward): Promise<void> => {
        reward.Used = false;

        const coupon = this._order.getProperty("Coupons")?.find(x => x.RewardId === reward.RewardId);
        if (coupon) {
            await this._order.removeCouponByKeyAsync(this, coupon.CouponKey, true);
            return;
        }

        //const index = this._aOLO.Temp.IncompleteDiscounts.findIndex(x => x.rewardId === reward.RewardId);
        //if (index > -1) {
        //    this._aOLO.Temp.IncompleteDiscounts.splice(index, 1);
        //    OnlineOrderingUtil.GUI_SetOrder_Total(false, this._aOLO, aOLOModules);
        //}
    }

    public readonly getAppliedBankedCurrency = (): number => {
        return 0;
    }

    public readonly applyBankedCurrency = async (currencyToApply: number): Promise<void> => {
        if (this.onlyOneDiscountAllowed(null)) {
            return;
        }

        return;
    }

    removeBankedCurrency = async (): Promise<void> => {
        return;
    }

    batchComparison = async (query: boolean): Promise<boolean> => {
        return true;
    }

    fetchGiftCards = async (passcode: string | null): Promise<any> => {
        return;
    }

    fetchGiftCardsBalance = async (uuids: string[]): Promise<any> => {
        return;
    }

    //public renderOffersList = (brandFunction: Function | null, closeDialogFunction: Function): void => {
    //    let offersDiv = document.getElementById("div_loyalty_existing_offers");
    //    if (!offersDiv)
    //        return;

    //    offersDiv.innerHTML = "";
    //    Util.showElement("div_loyalty_offers");

    //    if (aOLO.User?.Offers && aOLO.User.Offers?.Codes.length > 0) {
    //        Util.setElement("innerHTML", "spn_loyalty_offers", `(${aOLO.User.Offers.Codes.length})`);
    //        Util.hideElement("div_loyalty_offers_no_offers");
    //        Util.hideElement("div_loyalty_offers_list_no_offers");

    //        for (const cpn of aOLO.User.Offers.Codes)
    //            offersDiv.innerHTML += this._createOfferTile(cpn);

    //        const self = this;
    //        const redeemBtns = Array.from(document.getElementsByName("btn_loyalty_offers_redeem"));

    //        for (const button of redeemBtns) {
    //            button.onclick = async () => {
    //                const offers = this._user.getProperty("Offers");
    //                const coupons = this._order.getProperty("Coupons");

    //                if (aOLO.isBrandSignIn) {
    //                    const defaultStore = this._user.getProperty("DefaultStore");
    //                    const profileId = this._user.getProperty("ProfileId");

    //                    if (defaultStore && profileId) {
    //                        const code = this._user.getProperty("Offers")?.Codes.find(x => x.CouponCode == button.dataset.ccd);
    //                        if (!code)
    //                            return;

    //                        const payload: IGetOfferUrlModel = {
    //                            StoreKey: defaultStore.StoreKey,
    //                            ProfileId: profileId,
    //                            CouponCode: code.CouponCode,
    //                            IsCampaignCode: !code.IsCouponPromotionCode
    //                        };

    //                        const couponUrl = await new BrandService().getOfferUrl(payload, aOLO);
    //                        const url = (couponUrl.url != "") ? `${location.origin}/?coupon=${couponUrl.url}` : `${location.origin}/?id=${defaultStore?.StoreKey}`;
    //                        window.open(url, '_blank');
    //                    } else {
    //                        aOLO.Temp.BrandOfferCode = button.dataset.ccd || "";
    //                        if (brandFunction)
    //                            brandFunction("locations", true);
    //                    }
    //                } else {
    //                    await Common.redeemOffersOnClick(button.dataset.ccd || "", offers, coupons || [], this._coupon, aOLO.Temp.IncompleteDiscounts);
    //                    closeDialogFunction();
    //                }
    //            };
    //        }
    //    } else {
    //        Util.setElement("innerHTML", "spn_loyalty_offers", `(0)`);
    //        Util.showElement("div_loyalty_offers_no_offers");
    //    }
    //}

    //private _getPointsToNextReward(): { points: number, reward: IDataReward | null } {
    //    let points: { points: number, reward: IDataReward | null } = { points: 99999, reward: null };
    //    const rewards = this._data.getProperty("Rewards");

    //    if (rewards?.length == 0)
    //        return points;

    //    if (this.hasMembershipTiers()) {
    //        let pointstoNextRewardTier: { points: number, reward: IDataReward | null } = { points: 99999, reward: null };
    //        const currentTier = this._data.getProperty("MembershipTiers")?.find(x => x.MembershipId == aOLO.User?.LoyaltyData?.TierId);
    //        if (currentTier && currentTier.Rewards.length != 0) {
    //            const availableRewards = currentTier.Rewards.map(x => x.RewardId);
    //            const tierRewards = rewards?.filter(x => (availableRewards.includes(x.RewardId) && (x.IsTierBased || x.IsTierUpgrade))) || [];
    //            pointstoNextRewardTier = this._calculatePointsToNextReward(tierRewards, this._user.getProperty("LoyaltyData")?.EarnedPoints || 0);
    //        }
    //        const pointsToNextRewardNonTier = this._calculatePointsToNextReward(rewards?.filter(x => !x.IsTierBased) || [], this._user.getProperty("LoyaltyData")?.EarnedPoints || 0);

    //        const value = Math.min(...[pointsToNextRewardNonTier.points, pointstoNextRewardTier.points].filter(x => x !== undefined && x !== null));
    //        points = value == pointsToNextRewardNonTier.points ? pointsToNextRewardNonTier : pointstoNextRewardTier;
    //    } else
    //        points = this._calculatePointsToNextReward(rewards?.filter(x => !x.IsTierBased) || [], this._user.getProperty("LoyaltyData")?.EarnedPoints || 0);

    //    return points;
    //}

    ///**
    // * Renders the rewards list on the loyalty summary page.
    // * @private
    // * @returns {void}
    // */
    //public renderRewardsList = (closeDialogFunction: Function): void => {
    //    const currentPoints = aOLO.User.LoyaltyData?.EarnedPoints || 0;
    //    let rewCnt = this._getRewardsCount();
    //    Util.setElement("innerHTML", "spn_loyalty_rewards", `(${rewCnt})`);

    //    if (aOLO.User?.CustomerId !== 0 && currentPoints !== 0) {
    //        Util.hideElement("div_loyalty_rewards_has_rewards");
    //        Util.showElement("div_loyalty_existing_rewards");
    //        Util.hideElement("div_loyalty_rewards_no_rewards");

    //        const pointsToNextReward = this._getPointsToNextReward();
    //        if (pointsToNextReward.reward) {
    //            Util.setElement("innerHTML", "spn_loyalty_rewards_next_name", `${Common.GetName(pointsToNextReward.reward.Names, aOLO.Temp.languageCode)} `);

    //            Util.setElement("innerHTML", "spn_loyalty_rewards_points_needed", `${pointsToNextReward.points} `);
    //            Util.setElement("innerHTML", "spn_loyalty_rewards_bar_points", `${pointsToNextReward.reward.Points - pointsToNextReward.points} `);
    //            Util.setElement("innerHTML", "spn_loyalty_rewards_next_bar_points", `${pointsToNextReward.reward.Points} `);

    //            let widthDiv = document.getElementById("div_loyalty_rewards_bar_points_width");
    //            if (widthDiv) {
    //                let width = Math.round(((pointsToNextReward.reward.Points - pointsToNextReward.points) / pointsToNextReward.reward.Points) * 100);
    //                widthDiv.style.width = (width > 100 ? 100 : width) + "%";
    //            }
    //            Util.showElement("div_loyalty_rewards_has_rewards");
    //            Util.showElement("div_loyalty_rewards");
    //        }

    //        this._loadRewards(closeDialogFunction);
    //    } else {
    //        Util.hideElement("div_loyalty_rewards_has_rewards");
    //        Util.hideElement("div_loyalty_existing_rewards");
    //        Util.showElement("div_loyalty_rewards_no_rewards");
    //    }
    //}

    private _calculatePointsToNextReward(rewards: IDataReward[], earnedPoints: number): { points: number; reward: IDataReward | null; } {
        const oneTimeReward = rewards.filter(x => x.FrequencyId == 1 && x.Points > earnedPoints)
            .slice()
            .sort((a, b) => a.Points - b.Points)[0];
        let oneTimeRewardsNextPoints = oneTimeReward ? oneTimeReward.Points : 99999;

        let recurringRewards = rewards.filter(x => x.FrequencyId == 2);
        if (aOLO.data.MembershipTiers.length > 0) {
            const tierId = this._user.getProperty("LoyaltyData")?.TierId;
            const membershipRewards = this._data.getProperty("MembershipTiers")?.filter(x => x.MembershipId == tierId)?.flatMap(x => x.Rewards);
            const rewardIds = membershipRewards?.map(x => x.RewardId);
            recurringRewards = recurringRewards?.filter(x => !x.IsTierBased || (x.IsTierBased && rewardIds?.includes(x.RewardId)));
        }

        let recurringNextPoints = 0;
        let nextReward = null;
        for (const reward of recurringRewards) {
            let tmp = reward.Points - (earnedPoints % reward.Points);
            if ((tmp < recurringNextPoints && recurringNextPoints != 0) || (tmp > recurringNextPoints && recurringNextPoints == 0)) {
                recurringNextPoints = tmp;
                nextReward = reward;
            }
        }

        if (oneTimeRewardsNextPoints != 0)
            oneTimeRewardsNextPoints = oneTimeRewardsNextPoints - earnedPoints;

        if (recurringNextPoints < oneTimeRewardsNextPoints || oneTimeRewardsNextPoints == 0)
            return (recurringNextPoints == 0) ? { points: 99999, reward: null } : { points: recurringNextPoints, reward: nextReward };
        return { points: oneTimeRewardsNextPoints, reward: oneTimeReward };
    }

    /**
     * Loads the rewards data and creates the HTML for the existing rewards section.
     * @private
     * @return {void}
     */
    private _loadRewards = (closeDialogFunction: Function): void => {
        let rewardsDiv = document.getElementById("div_loyalty_existing_rewards");
        if (!rewardsDiv)
            return;

        rewardsDiv.innerHTML = "";

        if (!aOLO.User?.LoyaltyData)
            return;

        aOLO.User.LoyaltyData.Rewards.forEach(reward => {
            if (rewardsDiv && !reward.Expired)
                rewardsDiv.innerHTML += this._createRewardTile(reward);
        });

        const redeemBtns = Array.from(document.getElementsByName("btn_loyalty_reward_redeem"));
        for (const button of redeemBtns) {
            button.onclick = async () => {
                if (!aOLO.isBrandSignIn) {
                    await Common.redeemRewardOnClick(Number(button.dataset.rid), aOLO.User.LoyaltyData);
                    closeDialogFunction();
                }
            };
        }
    }

    /**
     * Gets the count of rewards that the user has earned.
     * @private
     * @returns {number} The count of rewards that the user has earned.
     */
    private _getRewardsCount = (): number => {
        return (aOLO.User?.LoyaltyData) ? aOLO.User.LoyaltyData.Rewards.length : 0;
    }

    /**
     * Creates the HTML for a reward tile.
     * @private
     * @param {Object} item - The reward data object.
     * @return {string} The HTML for the reward tile.
     */
    private _createRewardTile = (reward: IUserLoyaltyReward): string => {
        const redeemText = reward.Used ? "Void" : "Redeem";
        let html = `
            <div class="reward gridCols1xAuto_resp">
                <span class="bold gridCol1" ltagj='${Util.toLtagj(reward.Names)}'>${Common.GetName(reward.Names, aOLO.Temp.languageCode)}</span>
                <div class="gridCol2 right">
                    ${(reward.ExpiryDate != null) ? `<span ltag="Expires" class="fontSmaller">${Names("Expires")}</span> <span>${Util.formatDate(new Date(reward.ExpiryDate))}</span>` : ``}<br />
                    ${!aOLO.isBrandSignIn ? `<button id="btn_loyalty_reward_redeem_${reward.RewardId}" name="btn_loyalty_reward_redeem" data-rid="${reward.RewardId}" class="btnLink m0" ltag="${redeemText}">${Names(redeemText)}</button>` : ``}
                </div>
            </div>`;

        return html;
    }

    //public renderUserLoyaltyBalance() {
    //    if (aOLO.User?.LoyaltyData) {
    //        Util.setElement("innerHTML", "spn_loyalty_last_trans_date", (aOLO.User.LoyaltyData.PointsLastUpdatedDate ? Util.formatDate(new Date(aOLO.User.LoyaltyData.PointsLastUpdatedDate)) : "n/a"));
    //        Util.setElement("innerHTML", "spn_loyalty_joined_date", (aOLO.User.LoyaltyData.LoyaltyJoinedDate ? Util.formatDate(new Date(aOLO.User.LoyaltyData.LoyaltyJoinedDate)) : "n/a"));
    //        // Loyalty Section
    //        Util.setElement("innerHTML", "spn_loyalty_lifetime_points", aOLO.User.LoyaltyData.EarnedPoints.toString());
    //        Util.setElement("innerHTML", "spn_loyalty_points", aOLO.User.LoyaltyData.CurrentPoints.toString());
    //        Util.setElement("innerHTML", "spn_loyalty_last_points_date", (aOLO.User.LoyaltyData.PointsLastUpdatedDate ? Util.formatDate(new Date(aOLO.User.LoyaltyData.PointsLastUpdatedDate)) : "n/a"));
    //    }
    //}

    ///**
    // * Renders the membership list on the loyalty summary page.
    // * @public
    // * @returns {void}
    // */
    //public renderMembershipList = (): void => {
    //    const currentPoints = aOLO.User.LoyaltyData?.EarnedPoints || 0;
    //    const currentTier = aOLO.data.MembershipTiers.find(x => x.MembershipId == aOLO.User?.LoyaltyData?.TierId);

    //    Util.setElement("innerHTML", "spn_loyalty_membership", (currentTier ? Common.GetName(currentTier.Names, aOLO.Temp.languageCode) : ""));
    //    Util.setElement("ltagj", "spn_loyalty_membership", (currentTier ? Util.toLtagj(currentTier.Names) : ""));

    //    if (aOLO.User && currentPoints !== 0) {
    //        Util.hideElement("div_loyalty_membership_has_membership");
    //        Util.hideElement("div_loyalty_membership_no_membership");

    //        const nextMembershipTier = this._getNextMembershipTier();
    //        if (nextMembershipTier != null) {
    //            Util.setElement("innerHTML", "spn_loyalty_membership_next_name", `${Common.GetName(nextMembershipTier.Names, aOLO.Temp.languageCode)} `);
    //            Util.setElement("innerHTML", "spn_loyalty_membership_points_needed", `${nextMembershipTier.LifetimePoints - currentPoints} `);
    //            Util.setElement("innerHTML", "spn_loyalty_membership_bar_points", `${currentPoints} `);
    //            Util.setElement("innerHTML", "spn_loyalty_membership_next_bar_points", `${nextMembershipTier.LifetimePoints} `);
    //            let widthDiv = document.getElementById("div_loyalty_membership_bar_points_width");
    //            if (widthDiv) {
    //                let width = Math.round((currentPoints / nextMembershipTier.LifetimePoints) * 100);
    //                widthDiv.style.width = (width > 100 ? 100 : width) + "%";
    //            }
    //            Util.showElement("div_loyalty_membership_has_membership");
    //        }
    //    }
    //    else {
    //        Util.hideElement("div_loyalty_membership_has_membership");
    //        Util.showElement("div_loyalty_membership_no_membership");
    //    }
    //}

    //private _getNextMembershipTier(): IDataMembership | null {
    //    const currentPoints = aOLO.User?.LoyaltyData?.EarnedPoints || 0;
    //    const membershipsGreaterThan = aOLO.data.MembershipTiers.filter(x => x.LifetimePoints > currentPoints).slice().sort((a, b) => a.LifetimePoints - b.LifetimePoints);
    //    return (membershipsGreaterThan.length > 0) ? membershipsGreaterThan[0] : null;
    //}

    //private _createOfferTile = (offerCode: IUserCampaignCodesData): string => {
    //    if (offerCode.Expires != null && new Date(offerCode.Expires) < Util.NowStore(aOLO.Temp.TimeOffset))
    //        return "";

    //    let offerCoupon = aOLO.User.Offers?.Coupons.find(x => x.CouponId === offerCode.CouponId);
    //    if (!offerCoupon)
    //        return "";

    //    let cpnName = Common.GetNewName(offerCoupon.Names, aOLO.Temp.languageCode) || '';
    //    const redeemText = offerCode.Used ? "Void" : "Redeem";
    //    const expireDate = offerCode.Expires ? Util.formatDate(new Date(offerCode.Expires)) : null;
    //    const html = `
    //        <div class="reward m1-t gridCols1xAuto_resp">
    //            <span class="bold gridCol1">${cpnName}</span>
    //            <div class="gridCol2 right">
    //                ${(expireDate != null) ? `<span class="fontSmaller">${Names("Expires")}</span> <span>${expireDate}</span>` : ``}<br />
    //                <button id="btn_loyalty_offers_redeem_${offerCode.CouponCode}" name="btn_loyalty_offers_redeem" data-ccd="${offerCode.CouponCode}" class="btnLink m0" ltag="${redeemText}">${Names(redeemText)}</button>
    //            </div>
    //        </div>`;
    //    return html;
    //}

    ///**
    // * Public method that renders the list of survey offers for the user's loyalty program on the loyalty summary page.
    // * @public
    // * @return {void}
    // */
    //public renderSurveyOffersList = (closeDialogFunction: Function, brandFunction: Function | null): void => {
    //    let offersDiv = document.getElementById("div_loyalty_existing_offers");
    //    if (!offersDiv)
    //        return;

    //    offersDiv.innerHTML = "";
    //    Util.showElement("div_loyalty_offers");

    //    if (aOLO.User?.Offers && aOLO.User.Offers?.Codes.length > 0) {
    //        Util.setElement("innerHTML", "spn_loyalty_offers", `(${aOLO.User.Offers.Codes.length})`);
    //        Util.hideElement("div_loyalty_offers_no_offers");
    //        Util.hideElement("div_loyalty_offers_list_no_offers");

    //        for (const cpn of aOLO.User.Offers.Codes)
    //            offersDiv.innerHTML += this._createOfferTile(cpn);

    //        const self = this;
    //        const redeemBtns = Array.from(document.getElementsByName("btn_loyalty_offers_redeem"));
    //        for (const button of redeemBtns) {
    //            button.onclick = async () => {
    //                if (aOLO.isBrandSignIn) {
    //                    if (aOLO.User?.DefaultStore) {
    //                        const code = aOLO.User.Offers?.Codes.find(x => x.CouponCode == button.dataset.ccd);
    //                        if (!code)
    //                            return;

    //                        const payload: IGetOfferUrlModel = {
    //                            StoreKey: aOLO.User.DefaultStore.StoreKey,
    //                            ProfileId: aOLO.User.ProfileId,
    //                            CouponCode: code.CouponCode,
    //                            IsCampaignCode: !code.IsCouponPromotionCode
    //                        };

    //                        const couponUrl = await new BrandService().getOfferUrl(payload, aOLO);
    //                        const url = (couponUrl.url != "") ? `${location.origin}/?coupon=${couponUrl.url}` : `${location.origin}/?id=${aOLO.User.DefaultStore.StoreKey}`;
    //                        window.open(url, '_blank');
    //                    } else {
    //                        aOLO.Temp.BrandOfferCode = button.dataset.ccd || "";
    //                        if (brandFunction)
    //                            brandFunction("locations", true);
    //                    }
    //                } else {
    //                    await Common.redeemOffersOnClick(button.dataset.ccd || "", aOLO.User.Offers, aOLO.Order.Coupons, aOLO.Modules.Coupon, aOLO.Temp.IncompleteDiscounts);
    //                    closeDialogFunction();
    //                }
    //            };
    //        }
    //    } else {
    //        Util.setElement("innerHTML", "spn_loyalty_offers", `(0)`);
    //        Util.showElement("div_loyalty_offers_no_offers");
    //    }
    //}

    setIncompleteReward(rewardId: number | null): void {
        return;
    }

    setProfileValues = (profile: IProfile, isSignUp: boolean, monthOnClickFunction: Function, signUpFunction: Function, updateProfileFunction: Function,
        renderAddressListFunction: Function, setUserAllergiesFunction: Function, setUserDietaryFunction: Function, deleteProfileFunction: Function): void => {
        let header = document.getElementById("h2_profile_header");
        let btn = document.getElementById("btn_profile_profile");
        let btnDelete = document.getElementById("btn_profile_delete");


        if (aOLO.User.IsBlocked)
            Util.hideElement("div_profile_join_loyalty");
        else {
            Util.showElement("div_profile_join_loyalty");
            profile.IsLoyalty = aOLO.User.IsLoyalty != undefined ? aOLO.User.IsLoyalty : true;
        }

        monthOnClickFunction();

        Util.hideElement("div_profile_age_13");
        Util.setElementClass("remove", "div_profile", "condensed");

        const allergies = document.getElementById("div_profile_allergies");
        const dietary = document.getElementById("div_profile_dietary");

        if (profile.ProfileId != 0) {
            Util.showElement("div_profile_delete");
            Util.hideElement("div_profile_password1");
            Util.hideElement("div_profile_password2");
            Util.hideElement("div_profile_terms_privacy_policy");
            Util.showElement("div_profile_addresses")
            //Util.showElement("div_profile_wallet")

            if (header) {
                header.innerText = Names("Profile");
                header.setAttribute("ltag", "Profile");
            }
            if (btnDelete) {
                btnDelete.innerText = Names("DeleteMyProfile");
                btnDelete.setAttribute("ltag", "DeleteMyProfile");
                btnDelete.onclick = function () {
                    deleteProfileFunction();
                };
            }
            if (btn) {
                btn.innerText = Names("UpdateMyProfile");
                btn.setAttribute("ltag", "UpdateMyProfile");
                btn.onclick = function () {
                    updateProfileFunction();
                };
            }

            Util.setElement("disabled", "txt_profile_mark_promo_text", !profile.IsMarketingText);

            if (profile.Addresses?.length > 0) {
                Util.showElement("div_profile_all_addresses");
                Util.hideElement("spn_profile_addresses")
                renderAddressListFunction()
            } else {
                Util.hideElement("div_profile_all_addresses")
                Util.showElement("spn_profile_addresses");
            }

            if (profile.IsLoyalty)
                Util.hideElement("div_profile_join_loyalty");

            if (aOLO.data.Settings?.PRIVPOL === 0 && !profile.IsAge13)
                Util.showElement("div_profile_age_13");

            if (aOLO.User && aOLO.User.IsProfileComplete == false) {
                Util.showElement("div_profile_terms_privacy_policy");
                //if (!profile.Email) {
                Util.showElement("div_profile_password1");
                Util.showElement("div_profile_password2");
                //}
            }
        } else {
            Util.hideElement("div_profile_delete");
            Util.showElement("div_profile_password1");
            Util.showElement("div_profile_password2");
            Util.showElement("div_profile_terms_privacy_policy");
            Util.hideElement("div_profile_addresses")
            Util.hideElement("div_profile_wallet")

            if (header) {
                header.innerText = Names("CreateAccount");
                header.setAttribute("ltag", "CreateAccount");
            }

            if (btn) {
                btn.innerText = Names("SignUp");
                btn.setAttribute("ltag", "SignUp");
                btn.onclick = function () { signUpFunction() };
            }

            if (aOLO.SignUpLoyaltyLink?.PhoneNumber)
                Util.setElement("value", "txt_profile_phone", Util.formatPhoneNumber(profile.Phone, Names("PhoneFormat", this._phoneFormatLanguage)));

            Util.setElement("disabled", "txt_profile_mark_promo_text", true);

            if (aOLO.data.Settings?.PRIVPOL === 0)
                Util.showElement("div_profile_age_13");

            if (allergies && allergies.classList.contains("hidden") && dietary && dietary.classList.contains("hidden"))
                Util.setElementClass("add", "div_profile", "condensed");
        }

        if (aOLO.data.Settings?.BDAYREQ === 0)
            Util.hideElement("div_profile_birthday");

        Util.setElement("value", "txt_profile_phone", Util.formatPhoneNumber(profile.Phone, Names("PhoneFormat", this._phoneFormatLanguage)));

        Util.setElement("value", "sel_profile_birth_month", profile.BirthMonth);
        Util.setElement("value", "sel_profile_birth_day", profile.BirthDay);
        Util.setElement("value", "txt_profile_mark_promo_text", (profile.MarketingPhone ? Util.formatPhoneNumber(profile.MarketingPhone, Names("PhoneFormat", this._phoneFormatLanguage)) : Util.formatPhoneNumber(profile.Phone, Names("PhoneFormat", this._phoneFormatLanguage))));

        setUserAllergiesFunction();
        setUserDietaryFunction()

        if (aOLO.User && aOLO.User.IsProfileComplete == false)
            Util.showElement("spn_profile_incomplete_error");

        if (aOLO.isBrandSignIn)
            Util.showElement("div_profile_order_now");
        else
            Util.hideElement("div_profile_order_now");
    }

    renderAddressList = (profile: IProfile, addressEditFunction: Function, addressRemoveFunction: Function, addressSetDefaultFunction: Function): void => {
        let div = document.getElementById("div_profile_all_addresses");
        if (div) {
            div.innerHTML = "";

            for (const address of profile.Addresses) {
                const tempAddress: IAddressFormat = {
                    StreetNo: address.STRNO,
                    Address1: address.ADDR1,
                    Address2: address.ADDR2,
                    Address3: address.ADDR3,
                    Address4: address.ADDR4,
                    Address5: address.ADDR5,
                    City: address.CITY,
                    State: address.STA,
                    ZipCode: address.ZIP,
                    CountryID: address.CID || 1,
                    AddressTypeID: 0
                };

                const addressObject = Util.formatAddressObject(tempAddress, aOLO.data.Countries);
                const adr = `${addressObject.address1}, ${addressObject.address3 ? `${addressObject.address3}, ` : ""}${addressObject.cityState}`;

                let html = `
                    <div id="div_profile_address_${address.AID}" class="m1-tb">
                        <span>${adr}</span> <br>
                        <div>
                            <a id="a_profile_address_${address.AID}_edit" name="a_profile_address_edit" data-id="${address.AID}" class="aolo-link" ltag="Edit">${Names("Edit")}</a>  |  <a id="a_profile_address_${address.AID}_remove" name="a_profile_address_remove" data-id="${address.AID}" class="aolo-link" ltag="Remove">${Names("Remove")}</a>
                            ${(address.IPRM == false) ? ` | <a id="a_profile_address_${address.AID}_default" name="a_profile_address_default" data-id="${address.AID}" class="aolo-link" ltag="SetAsDefault">${Names("SetAsDefault")}</a>` : ``}
                        </div>
                    </div>`;

                if (div)
                    div.innerHTML += html;
            }
        }

        let editButtons = document.getElementsByName("a_profile_address_edit");
        editButtons.forEach(button => {
            Util.setElement("onclick", button, addressEditFunction.bind(null, button.dataset.id || ""));
        });

        let removeButtons = document.getElementsByName("a_profile_address_remove");
        removeButtons.forEach(button => {
            Util.setElement("onclick", button, addressRemoveFunction.bind(null, button.dataset.id || ""));
        });

        let defaultButtons = document.getElementsByName("a_profile_address_default");
        defaultButtons.forEach(button => {
            Util.setElement("onclick", button, addressSetDefaultFunction.bind(null, button.dataset.id || ""));
        });
    }

    postOrder = async (order: IOrder, redemptionIds?: number[]): Promise<void> => {
        const payload: ICreateFinishOrderPayloadLoyaltyResult = {
            store_key: this._data.getProperty("StoreInfo")?.storeKey || "",
            data: {
                user_id: this._user.getProperty("ProfileId"),
                check_in: true,
                pos_version: "2",
                order_id: order.OrderID,
                is_posted: false,
                status_id: 1,
                receipt_datetime: "",
                order_type_sub_type_id: order.OrderTypeSubID.toString(),
                order_type_sub_type_name: "",
                subtotal_amount: order.SubTotal,
                gross_amount: order.Total,
                email: this._user.getProperty("Email") || "",
                employee_id: 0,
                employee_name: "",
                redemption_ids: redemptionIds ? redemptionIds : [],
                menu_items: [],
                items: []
            }
        };

        // Menu Items
        for (const item of order.Items) {
            const itemPayload: ICreateFinishOrderPayloadLoyaltyResult_Data_MenuItem = {
                item_id: item.ItemId,
                item_name: "",
                size_id: item.SizeId,
                item_qty: item.Quantity,
                item_amount: item.Price,
                before_tax_discount: item.BeforeTaxDiscount,
                after_tax_discount: item.AfterTaxDiscount,
                item_type: "",
                category_id: `${item.ItemWebCategoryId}`,
                index: ""
            };
            payload.data.menu_items.push(itemPayload);
        }

        const result = await this._loyaltyService.postOrderAsync(payload);
        if (!result.success)
            DialogCreators.messageBoxOk(result.message || "", globalThis.aOLO.buttonHoverStyle);

        return;
    }
}