import './tab-bar.css';

import { ITabBarData } from './tab-bar.interface';

export class TabBar {
    private readonly _item: ITabBarData;
    private readonly _element: HTMLElement;

    constructor(item: ITabBarData) {
        this._item = item;
        this._element = this._createElement();
    }

    private _createElement(): HTMLElement {
        const parentDiv = document.createElement("div");
        parentDiv.classList.add("tabs-bar");
        parentDiv.setAttribute("role", "tablist");

        for (const tab of this._item.tabs) {
            const button = document.createElement("button");
            button.id = `${this._item.buttonId}_${tab.id}`;
            button.classList.add("tab-bar-tab");
            button.ariaSelected = "false";
            button.tabIndex = 0;
            button.innerText = tab.text;

            if (tab.ltag)
                button.setAttribute("ltag", tab.ltag);

            if (tab.ltagj)
                button.setAttribute("ltagj", tab.ltagj);

            button.onclick = () => {
                this._setActiveTabOnClick(button.id);
                if (tab.function)
                    tab.function();
            };

            try {
                parentDiv.appendChild(button);
            } catch (ex) {
                console.log(ex);
            }
        }
        return parentDiv;
    }

    public get element(): HTMLElement {
        return this._element;
    }

    private readonly _setActiveTabOnClick = (id: string): void => {
        const tabs = this.element.querySelectorAll(".tab-bar-tab");
        for (const tab of tabs) {
            tab.classList.remove("active");
        }

        const tab = document.getElementById(id);
        if (tab) {
            tab.classList.add("active");
            tab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        } else {
            const defaultTab = tabs[0];
            if (defaultTab) {
                defaultTab.classList.add("active");
                defaultTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        }
    }
}