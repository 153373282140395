import './header.css';

import EventEmitter from "eventemitter3";
import { EventTypes } from '../../../core/event-bus';
import { Pages } from '../../../types/enums';
import { Names } from '../../../utils/i18n';
import { Util } from '../../../utils/util';
import { Login } from '../../popups/Login/login';
import { Hamburger } from "../../popups/Hamburger/Hamburger";
import { ProfileService } from '../../../services/profile-service';
import { Order } from '../../../models/order';

export class Header {
    private readonly _eventBus: EventEmitter;
    private readonly _displayList: { [key: string]: any } = {};
    private readonly _profileService: ProfileService;
    private readonly _hamburger: Hamburger;
    private readonly _login: Login;
    private readonly _order: Order;

    constructor(eventBus: EventEmitter, profileService: ProfileService, hamburger: Hamburger, login: Login, order: Order) {
        this._eventBus = eventBus;
        this._profileService = profileService;
        this._hamburger = hamburger;
        this._login = login;
        this._order = order;

        this._createDisplayList();
        this._eventBusListeners();
    }

    private readonly _createDisplayList = (): void => {
        this._displayList[Pages.LANDING] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.LOCATIONS] = { showHamburger: false, showBackArrow: true, showCancel: false, showLogo: false, text: "SelectLocation", showRightSide: false };
        this._displayList[Pages.MENU] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: false, text: "Menu", showRightSide: true };
        this._displayList[Pages.MENU_VIEW_ALL] = { showHamburger: false, showBackArrow: true, showCancel: false, showLogo: false, text: "", showRightSide: true };
        this._displayList[Pages.REWARDS] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.FAVORITES] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.ORDERS] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.TRACK_ORDER] = { showHamburger: false, showBackArrow: false, showCancel: true, showLogo: false, text: "", showRightSide: false };
        this._displayList[Pages.SETTINGS] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.TERMS] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.CONTACT] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.ACCOUNT] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.CART] = { showHamburger: true, showBackArrow: false, showCancel: false, showLogo: true, text: "", showRightSide: true };
    }

    public init = (): void => {
        this._renderPage();
        this._setEventListeners();

        this._hamburger.init();
        this._login.init();
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.LOGGED_IN, this._loggedInEvent);
        this._eventBus.on(EventTypes.LOGGED_OUT, this._loggedOutEvent);
        this._eventBus.on(EventTypes.LOCATION_CHANGE, this._locationChanged);
        this._eventBus.on(EventTypes.HEADER_NAME_CHANGE, this._headerNameChange);
        this._eventBus.on(EventTypes.ITEM_ADD, this._itemsChanged);
        this._eventBus.on(EventTypes.ITEM_REMOVE, this._itemsChanged);
    }

    private readonly _renderPage = (): void => {
        this._itemsChanged();
    }

    private readonly _setEventListeners = (): void => {
        document.getElementById("div_header_hamburger_menu")?.addEventListener("click", (e: Event) => {
            e.stopPropagation();
            this._hamburger.open();
        });

        document.getElementById("btn_header_login")?.addEventListener("click", () => {
            this._login.open();
        });

        Util.setElement("onclick", "div_header_arrow_back", () => { window.history.back(); });
        Util.setElement("onclick", "div_header_cancel", () => { window.history.back(); });
        Util.setElement("onclick", "div_header_store_logo", () => { this._eventBus.emit(EventTypes.LOCATION_CHANGE, Pages.LANDING); });
        Util.setElement("onclick", "div_header_profile", () => { this._eventBus.emit(EventTypes.LOCATION_CHANGE, Pages.ACCOUNT); });
        Util.setElement("onclick", "div_header_shopping_bag", () => { this._eventBus.emit(EventTypes.LOCATION_CHANGE, Pages.CART); });
    }

    private readonly _loggedInEvent = (): void => {
        Util.hideElement("btn_header_login");
        Util.showElement("div_header_profile");
    }

    private readonly _loggedOutEvent = (): void => {
        Util.showElement("btn_header_login");
        Util.hideElement("div_header_profile");
    }

    private readonly _locationChanged = (page: Pages): void => {
        const temp = this._displayList[page];
        if (!temp)
            return;

        (temp.showHamburger) ? Util.showElement("div_header_hamburger_menu") : Util.hideElement("div_header_hamburger_menu");
        (temp.showBackArrow) ? Util.showElement("div_header_arrow_back") : Util.hideElement("div_header_arrow_back");
        (temp.showCancel) ? Util.showElement("div_header_cancel") : Util.hideElement("div_header_cancel");
        (temp.showLogo) ? Util.showElement("div_header_store_logo") : Util.hideElement("div_header_store_logo");
        (temp.showRightSide) ? Util.showElement("div_header_right") : Util.hideElement("div_header_right");

        if (temp.text !== null) {
            if (temp.text !== "") {
                Util.showElement("div_header_center_text");
                Util.setElement("innerText", "div_header_center_text", Names(temp.text));
            } else {
                Util.hideElement("div_header_center_text");
                Util.setElement("innerText", "div_header_center_text", "");
            }
        } else {
            Util.showElement("div_header_center_text");
        }
        Util.hideElement("div_header_center_subtext");
    }

    private readonly _headerNameChange = (data: { name: string, ltag?: string, ltagj?: string }): void => {
        if (data.name !== "") {
            Util.showElement("div_header_center_text");
            Util.setElement("innerText", "div_header_center_text", data.name);

            if (data.ltag)
                Util.setElement("ltag", "div_header_center_text", data.ltag);

            if (data.ltagj)
                Util.setElement("ltagj", "div_header_center_text", data.ltagj);
        }
    }

    private readonly _itemsChanged = (): void => {
        if (this._order.getProperty("Items").length === 0)
            Util.hideElement("spn_header_shopping_bag_items");
        else {
            const items = this._order.getProperty("Items").filter(x => [0, 1].includes(x.HalfIndex));
            Util.setElement("innerText", "spn_header_shopping_bag_items", items.length);
            Util.showElement("spn_header_shopping_bag_items");
        }
    }
}