import { ITracker } from "../brand/interfaces/tracker.interface";
import { Data } from "../models/data";
import { AdoraHttpClient } from "../utils/http-client";

export class TrackerService {
    private readonly _data: Data;
    private readonly _apiTracker: string;

    constructor(data: Data, apiTracker: string) {
        this._data = data;
        this._apiTracker = apiTracker;
    }

    /**
     * Makes an HTTP request.
     * @private
     * @async
     * @param {string} method - The HTTP method (e.g. 'GET', 'POST', 'PUT', 'DELETE').
     * @param {string} url - The URL of the HTTP resource.
     * @param {object|null} payload - The payload to send with the request.
     * @param {string|null} token - The authorization token to send with the request.
     * @returns {Promise<Response>} A promise that resolves to the HTTP response.
     */
    private readonly _httpRequest = async (method: string, url: string, payload: object | null, token: string | null = null, refreshToken: string | null = null): Promise<Response> => {
        const response = await AdoraHttpClient.httpRequest(false, method, url, payload, token, refreshToken)
        if (response.status === 500) {
            const result = await response.json();
        }

        if (response.status !== 401) {
            // Check for new JWT
            if (response.headers.has('Authorization')) {
                const authHeader = response.headers.get('Authorization');
                const token = authHeader?.split(' ')[1] || "";
                AdoraHttpClient.setJWT(token);
            }
            if (response.headers.has('rtk')) {
                const refreshToken = response.headers.get('rtk');
                if (refreshToken)
                    AdoraHttpClient.setRefreshToken(refreshToken);
            }
            return response;
        }

        return response;
    }

    /**
     * Fetches tracker data for the specified order ID.
     * @async
     * @param {number} orderId - The ID of the order for which to fetch tracker data.
     * @returns {Promise<IFetchTrackerDataResponse|null>} - A Promise that resolves with the tracker data, or null if the request fails.
     */
    public fetchTrackerData = async (orderId: number): Promise<ITracker | null> => {
        const response = await this._httpRequest("POST", this._apiTracker, { "storeKey": this._data.getProperty("StoreInfo")?.storeKey, "orderId": orderId }, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    /**
     * Fetches tracker user data for the specified order ID.
     * @async
     * @param {number} orderId - The ID of the order for which to fetch tracker user data.
     * @returns {Promise<IFetchTrackerDataResponse|null>} - A Promise that resolves with the tracker user data, or null if the request fails.
     */
    public fetchTrackerUserData = async (orderId: number): Promise<ITracker | null> => {
        const storeKey = this._data.getProperty("StoreInfo")?.storeKey;
        if (storeKey) {
            //Util.LogError(`fetchTrackerUserData -> ${JSON.stringify(localAolo.storeInfo)}`, new Error(), localAolo);
        }
        const response = await this._httpRequest("POST", `${this._apiTracker}user`, { "storeKey": storeKey, "orderId": orderId }, AdoraHttpClient.getJWT(), AdoraHttpClient.getRefreshToken());
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }
}