import { EventEmitter } from 'eventemitter3';
import { EventTypes } from '../core/event-bus';
import { IData, IDataBrand, IDataSettings } from './data.interface';
import { Common } from '../common';
import { Util } from '../utils/util';
import { ICalorieData } from './data.interface';

export class Data {
    private readonly _eventBus: EventEmitter;
    private readonly _datas: Record<string, IData> = {};
    private readonly _brandId: string;
    private readonly _brand: IDataBrand = {} as IDataBrand;
    private readonly _calories: Record<number, ICalorieData[]> = {};
    private readonly _contentFolderUrl: string;
    private _selectedStoreKey: string = "";

    constructor(eventBus: EventEmitter, contentFolderUrl: string, brandId: string) {
        this._eventBus = eventBus;
        this._contentFolderUrl = contentFolderUrl;
        this._brandId = brandId;
        this._eventBusListeners();
    }

    get brandId(): string {
        return this._brandId;
    }

    get brand(): IDataBrand {
        return this._brand;
    }

    private readonly _eventBusListeners = (): void => {
        this._eventBus.on(EventTypes.STORE_CHANGED, this._storeChangedAsync);
    }

    private readonly _storeChangedAsync = async (storeKey: string): Promise<void> => {
        this._selectedStoreKey = storeKey;
        await this._loadDataAsync(storeKey || "");
    }

    public getProperty<K extends keyof IData>(property: K): IData[K] {
        const storeData = this._datas[this._selectedStoreKey];
        if (!storeData)
            return this._getDataDefaultValue(property);

        return storeData[property];
    }

    public setProperty<K extends keyof IData>(key: K, value: IData[K]) {
        this._datas[this._selectedStoreKey][key] = value;
    }

    private readonly _loadDataAsync = async (storeKey: string): Promise<void> => {
        if (this._datas[storeKey]) {
            this._emitDataRetrieved(storeKey);
            return;
        }

        const dataUrl = `${this._contentFolderUrl}/online/data/online-data-${storeKey}.json`;

        try {
            const data = await this._fetchWithRetry(() => Common.LoadStoreData(dataUrl), 3000, 5);

            if (data) {
                this._mapData(storeKey, data);
                this._getSpecialPremodifierIds(storeKey);
                this._emitDataRetrieved(storeKey);
            }
        } catch (error) {
            this._handleError("LoadData -> LoadStoreData", error);
        } finally {
            //DialogCreators.Progress(false, this._aOLO);
        }

        // Handle calorie data in the background
        this._fetchCalorieDataInBackground(storeKey);
    }

    private readonly _fetchCalorieDataInBackground = async (storeKey: string): Promise<void> => {
        try {
            const calorieData = await this._fetchWithRetry(() => this._loadCalorieDataAsync(storeKey), 10000, 5);

            if (calorieData) {
                this._mapCalorieData(storeKey, calorieData);
            }
        } catch (error) {
            this._handleError("LoadData -> LoadCalorieData", error);
        }
    }

    private readonly _emitDataRetrieved = (storeKey: string): void => {
        if (this._datas[storeKey]) {
            this._eventBus.emit(EventTypes.DATA_RETRIEVED);
        }
    }

    private async _fetchWithRetry<T>(fetchFn: () => Promise<T | null>, retryInterval: number, maxRetries: number): Promise<T | null> {
        let attempts = 0;

        while (attempts < maxRetries) {
            try {
                const result = await fetchFn();
                if (result !== null)
                    return result;
            } catch (error) {
                // Log error or debug if needed for specific retries
            }

            attempts++;
            await this._delay(retryInterval);
        }

        return null;
    }

    private readonly _delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms))

    private readonly _handleError = (context: string, error: unknown): void => {
        if (error instanceof Error) {
            //Util.LogError(`${context}; isMobile: ${localStorage.MobileView}`, error, this._aOLO);
        }
    }

    private readonly _loadCalorieDataAsync = async (storeKey: string): Promise<null | any[]> => {
        const storeData = this._datas[storeKey];
        if (!storeData)
            return [];

        if (this._calories[storeData.StoreInfo.menuGroupId])
            return this._calories[storeData.StoreInfo.menuGroupId];

        const dataCaloriesUrl = `${this._contentFolderUrl}/data/online-calorie-data-${storeData.StoreInfo.menuGroupId}.json`;

        const myInit = Common.GetInit();
        const myRequest = new Request(dataCaloriesUrl);
        const response = await fetch(myRequest, myInit);
        if (response.status === 404)
            return null;
        else {
            try {
                return await response.json();
            } catch {
                return [];
            }
        }
    }

    private _getDataDefaultValue<K extends keyof IData>(property: K): IData[K] {
        // Fallback type map for default values
        const fallbackTypes: { [key in keyof IData]: IData[key] } = {
            DesktopBanner: {
                Banners: [],
                RotationActive: false,
                RotationPeriod: 1,
                SlideIdx: 1
            },
            Categories: [],
            Challenges: [],
            Channels: [],
            Cities: "",
            Countries: [],
            Coupons: [],
            DefaultPriceGroupId: 0,
            Donations: [],
            Fundraisers: [],
            Holidays: [],
            Hours: [],
            Info: "",
            Instructions: [],
            Items: [],
            Loyalty: {
                ProviderId: 0,
                Connection: "",
                ProgramTypes: []
            },
            LoyaltyItems: [],
            MembershipTiers: [],
            ModifierCategories: [],
            ModifierDisplayGroups: [],
            Modifiers: [],
            OrderTypeSubTypes: [],
            PreModifiers: [],
            Rewards: [],
            StoreBusinessDate: "",
            Settings: {
                BDAYREQ: 0,
                CPNIMG: 0,
                BGM: 0,
                BRNDPPURL: "",
                BRNDTACURL: "",
                BSM: 0,
                BZIPOLO: false,
                CASH: false,
                CCS: false,
                CCV: false,
                CCG: false,
                CCI: false,
                CGID: 0,
                CLESS: 0,
                COMSG: "",
                CPNBTN: 0,
                CPNCD: false,
                CPNLIM: 0,
                CRBMSG: "",
                CRGMIL: 0,
                CURBSD: 0,
                CURL: "",
                DSZID: 0,
                EMILES: 0,
                FPT: "",
                GOGAC: 0,
                HCW: false,
                HCWM: false,
                HPK: "",
                HHP: 0,
                HML: false,
                HMLM: false,
                HPRP: false,
                IWTT: false,
                ISCC: false,
                LAT: 0,
                LGID: 0,
                LIMTIMEMAX: 0,
                LNG: 0,
                MCC: false,
                MDCRGMIL: 0,
                MODIMG: 0,
                NCDG: [],
                OCL: 0,
                OLBTN: 0,
                OIC: false,
                OPIL: 0,
                OOC: false,
                PIS: false,
                PMODDM: 0,
                PPAY: false,
                PRIVPOL: 0,
                SIGNINOPT: 0,
                STAT: "",
                STID: 0,
                TGI: "",
                TPDS: false,
                TPDM: 0,
                TXNTFY: "",
                WURL: "",
                ADDRLOOKUP: 0,
                MRKEML: 0,
                OPO: []
            },
            Sizes: [],
            ServiceCharges: [],
            Stores: [],
            SubCategories: [],
            Taxes: [],
            ZipCodes: "",
            DeliveryZones: [],
            ZipTaxes: [],
            OpenPayMerchant: undefined,
            MerchantPids: [],
            OPDevSId: "",
            OPSourceId: "",
            StoreInfo: {
                storeId: 0,
                storeKey: "",
                storeKeyEncoded: "",
                storeCode: 0,
                brandId: "",
                streetNumber: "",
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                address5: "",
                city: "",
                state: "",
                zip: "",
                countryId: 1,
                menuGroupId: 1001
            },
            UISettings: {
                showSvgLocationIcons: true
            },
            DefaultPreModifierId: 0,
            NonePreModifierId: 0
        };

        return fallbackTypes[property];
    }

    private readonly _mapData = (storeKey: string, data: any): void => {
        try {
            const settings: IDataSettings = data.SETS || {};
            settings.OPO = data.SETS.OPO.Values?.map((x: any) => ({
                OrderTypeID: x.OTID,
                PayInStore: x.PIS,
                PayInStoreLimit: x.PISL,
                PayInStoreAlternate: x.PISA,
                Cash: x.CSH,
                CashLimit: x.CSHL,
                CashAlternate: x.CSHA,
                CardOnFile: x.COF,
                CardOnFileCvvOption: x.COFC,
                CardOnFileCvvThreshold: x.COFCT,
                CreditCard: x.CC,
                CreditCardOption: x.CCO,
                CreditCardCvvOption: x.CCCO,
                CreditCardCvvThreshhold: x.CCCT,
                CreditCardAvsOption: x.CCAO,
                CreditCardAvsThreshhold: x.CCAT,
                CreditCardPreValidationOption: x.CCPVO,
                CreditCardPreValidationThreshhold: x.CCPVOT,
                GooglePay: x.GPAY,
                ApplePay: x.APAY
            })) || [];

            const newData: IData = {
                DesktopBanner: {
                    Banners: data.DBAN?.Data?.map((banner: any) => ({
                        CouponID: banner.LCID,
                        ImageURL: banner.IURL
                    })) || [],
                    RotationActive: data.DBAN?.RIA || false,
                    RotationPeriod: data.DBAN?.RP || 1,
                    SlideIdx: 1
                },
                Categories: data.CATS?.map((cat: any) => ({
                    Default: cat.DEF,
                    Descriptions: cat.DSCRs,
                    Index: cat.IDX,
                    ImageUrl: cat.IMGU,
                    IsMealDeal: cat.ISMD,
                    ItemWebCategoryId: cat.IWCID,
                    Names: cat.NAMs,
                    Upsale: cat.UPS
                })) || [],
                Challenges: data.CLGs?.map((clg: any) => ({
                    ChallengeId: clg.ID,
                    Names: clg.NAMs,
                    StartDate: new Date(Util.formatDateFunc(clg.SDT)),
                    EndDate: new Date(Util.formatDateFunc(clg.EDT)),
                    Members: clg.MEMs?.map((mem: any) => ({
                        MemberId: mem.MID,
                        Name: mem.NAM,
                        Events: mem.EVTs?.map((evt: any) => ({
                            EventId: evt.EID,
                            EventName: evt.ENAM,
                            EventDate: new Date(Util.formatDateFunc(evt.EDATE)),
                            FundraisingPercentage: evt.FUND,
                            IsAllowCoupons: evt.IACPN,
                            IsSubMemberField: evt.ISMEM,
                            SubMemberTitle: evt.SMEMTL,
                            EntryTypeId: evt.ETID,
                            IsRequired: evt.IREQ,
                            IsMultipleSelectionsAllowed: evt.IMSALL,
                            SubMembers: evt.SMEMs?.map((smem: any) => ({
                                SubMemberId: smem.SMID,
                                SubMemberName: smem.SMNAM,
                                IsExtraField: smem.IEFLD,
                                ExtraFieldTitle: smem.EFLDTL,
                                EntryTypeId: smem.ETID,
                                IsRequired: smem.IREQ,
                                IsMultipleSelectionsAllowed: smem.IMSALL,
                                PredefinedValues: smem.PVALS
                            })) || []
                        })) || []
                    })) || []
                })) || [],
                Channels: data.CHLS?.map((chl: any) => ({
                    ChannelID: chl.CHID,
                    ChannelName: chl.NAM,
                    PromoCodePrefix: chl.PCP
                })) || [],
                Cities: data.CITIS || "",
                Countries: data.COUNTRIES?.map((country: any) => ({
                    CountryID: country.CountryID,
                    Name: country.Name,
                    IsoCode: country.IsoCode,
                    CountryCode: country.CountryCode,
                    UnitOfMeasurement: country.UnitOfMeasurement,
                    DefaultCurrencySymbol: country.DefaultCurrencySymbol,
                    DefaultCultureCode: country.DefaultCultureCode
                })) || [],
                Coupons: data.CPNS?.map((cpn: any) => ({
                    CouponId: cpn.CPID,
                    CouponTypeId: cpn.CPTID,
                    AllowOtherCoupons: cpn.AOC,
                    AutoApplyExactMatch: cpn.AAEXCMCH,
                    BackgroundColor: cpn.TBGC,
                    BOGOSelectionTypeID: cpn.BGSTID,
                    Descriptions: cpn.DSCRs,
                    Discount: cpn.DIC,
                    ExactMatch: cpn.EXCMCH,
                    ImageURL: cpn.IURL,
                    IsBeforeTax: cpn.IBTX,
                    IsBOGO: cpn.BOGO,
                    IsHiddenOnline: cpn.IHDN,
                    IsPriceReducer: cpn.IPR,
                    IsProfileRequired: cpn.IOPR,
                    IsScheduled: cpn.SCH,
                    IsVerificationNeeded: cpn.IVN,
                    IsAutoApply: cpn.IAA,
                    IsAppOnly: cpn.IAPPO,
                    LimitsPerOrder: cpn.LPO,
                    MaximumDiscountAmount: cpn.MXAMT,
                    MinimumItemQuantity: cpn.MINITMQTY,
                    MinimumOrderAmount: cpn.MIAMT,
                    CalculationMethodID: cpn.CALCID,
                    Names: cpn.NAMs,
                    Priority: cpn.PRITY,
                    WebCategoryId: cpn.WCID,
                    StartDate: cpn.SDATE,
                    EndDate: cpn.EDATE,
                    Codes: cpn.CCODS?.map((cd: any) => ({
                        CouponCode: cd.CCOD
                    })) || [],
                    Items: cpn.CITMS?.map((itm: any) => ({
                        AddOnPrice: itm.AOPRC,
                        GroupIndex: itm.GRP,
                        ItemId: itm.IID,
                        ItemIndex: itm.IIDX,
                        ModifierCount: itm.MCNT,
                        Quantity: itm.QTY,
                        SizeId: itm.SZID
                    })) || [],
                    ItemCategories: cpn.CITMCATS?.map((cat: any) => ({
                        CategoryId: cat.CATID
                    })) || [],
                    OrderTypeSubTypes: cpn.OTSTS?.map((ot: any) => ({
                        OrderTypeId: ot.OT,
                        OrderTypeSubId: ot.OST
                    })) || [],
                    Schedules: cpn.SCHS?.map((sch: any) => ({
                        WeekDayNumber: sch.WKDNO,
                        IsActive: sch.ISACT,
                        StartDayMinute: sch.STRTMIN,
                        EndDayMinute: sch.ENDMIN
                    })) || [],
                    Upgrades: cpn.UGRDS?.map((upg: any) => ({
                        BuySizeId: upg.BSZID,
                        PriceSizeId: upg.PSZID,
                        UpgradeIndex: upg.UGRDIDX
                    })) || []
                })) || [],
                DefaultPriceGroupId: data.DEFPGID,
                Donations: data.DON?.map((don: any) => ({
                    Amount1: don.AMT1,
                    Amount2: don.AMT2,
                    Amount3: don.AMT3,
                    Description: don.DES,
                    DonationId: don.DID,
                    ImageUrl: don.IMG,
                    IsRounded: don.IRND,
                    Names: don.NAM,
                    RedirectURL: don.URL,
                    Threshold: don.TH
                })) || [],
                Fundraisers: data.FUN?.map((fun: any) => ({
                    FundraiserId: fun.FID,
                    Name: fun.NAM,
                    StartDateTime: fun.SDAT,
                    EndDateTime: fun.EDAT,
                    Code: fun.CDE,
                    IsCouponAllowed: fun.ICPN,
                    IsCustomField1: fun.ICF1,
                    IsCustomField2: fun.ICF2,
                    CustomField1Names: fun.CF1NAMs,
                    CustomField2Names: fun.CF2NAMs
                })) || [],
                Holidays: data.HDAYS?.map((hol: any) => ({
                    HolidayId: hol.HID,
                    HolidayStatusId: hol.HSID,
                    StartMinute: hol.SMIN,
                    EndMinute: hol.EMIN,
                    Info: hol.THRINFO ? JSON.parse(hol.THRINFO) : null
                })) || [],
                Hours: data.HUR?.map((hr: any) => ({
                    OrderTakingStartDayMinute: hr.OTSDM,
                    OrderTakingEndDayMinute: hr.OTEDM,
                    OpenDayMinute: hr.ODM,
                    CloseDayMinute: hr.CDM
                })) || [],
                Info: data.INFO,
                Instructions: data.INST?.map((com: any) => ({
                    CommentId: com.COMMID,
                    Items: com.ITM?.map((itm: any) => ({
                        ItemWebCategoryId: itm.ICID,
                        SizeId: itm.SID
                    })) || [],
                    Names: com.TEXT,
                    SpecialInstructionsId: com.SICID,
                    SpecialInstructionsNames: com.SICNAMs || [],
                    IsMultiSelect: com.SICIMS || false
                })) || [],
                Items: data.ITMS?.map((itm: any, index: number) => ({
                    ItemId: itm.IID,
                    Index: index,
                    ItemCategoryId: itm.ICID,
                    WebCategoryId: itm.IWCID,
                    WebCategoryIndex: itm.WCIDX,
                    SubCategoryId: itm.ISCID,
                    SubCategoryIndex: itm.SCIDX,
                    IsDefault: itm.IDEF,
                    IsHalfable: itm.IHAF,
                    CssClassName: itm.BCOL,
                    ImageURL: itm.IMG,
                    MinimumModifierCount: itm.MINMCNT,
                    MaximumModifierCount: itm.MAXMCNT,
                    PrepTime: itm.PTIM,
                    MakeTime: itm.MTIM,
                    CookTime: itm.CTIM,
                    IsBuildYourOwn: itm.IBYO,
                    IsGiftCard: itm.IGCD,
                    IsGiftCert: itm.IGCT,
                    IsLoyalty: itm.ILOY,
                    IsCutWrap: itm.ISCR,
                    IsMakeLine: itm.ISML,
                    CalorieDisplayId: itm.CALDID,
                    MinimumCustomerAge: itm.AGE,
                    Names: itm.INAMS,
                    Descriptions: itm.IDSCRS,
                    ServingNames: itm.SNAMS,
                    ModifierDisplayGroups: itm.MODGs?.map((mdg: any) => ({
                        ModifierDisplayGroupId: mdg.MDGRPID,
                        Index: mdg.IDX,
                        Modifiers: mdg.MODS?.map((mod: any) => ({
                            AddsToPrice: mod.ATPRC,
                            CountForPricing: mod.CFPRC,
                            CountForPricingByItself: mod.CFPRCBI,
                            IsDefault: mod.IDEF,
                            Index: mod.IDX,
                            IsHiddenOnOrder: mod.IHOORD,
                            ModifierId: mod.MID,
                            Name: mod.NAMS,
                            MergedModifierDisplayGroupId: mod.MMDGID,
                            ModifierWebCategoryId: mod.MWCID,
                            ModifierWebCategoryIndex: mod.MWCIDX,
                            PreModifierException: mod.XPMOD,
                            Calories: [],
                            Prices: mod.MPRCS?.map((prc: any) => ({
                                SizeId: prc.SZID,
                                AddOnPrice: prc.APRC
                            })) || []
                        })) || []
                    })) || [],
                    OrderTypes: itm.OTSS?.map((ot: any) => ({
                        CutWrap: ot.CWP,
                        Label: ot.LBL,
                        Make: ot.MAK,
                        OrderTypeId: ot.OTID,
                        Prep: ot.PRP,
                        Print: ot.PRT,
                        Sizes: ot.SIZS?.map((sz: any) => ({
                            IsDefault: sz.IDEF,
                            Index: sz.IDX,
                            IsHalfable: sz.IHLF,
                            MinimumCalories: sz.MICALS,
                            IsOfferedQR: sz.QR,
                            SizeId: sz.SZID
                        })) || [],
                    })) || [],
                    SizeCalories: itm.SIZCALS?.map((scal: any) => ({
                        MaximumCalories: scal.MXCALS,
                        MinimumCalories: scal.MICALS,
                        Servings: scal.SRVS,
                        SizeId: scal.SZID
                    })) || [],
                    Prices: itm.PRCS?.map((prc: any) => ({
                        MinimumPrice: prc.MIPRC,
                        MaximumPrice: prc.MXPRC,
                        PriceGroupId: prc.PGID,
                        PerModifierPrice: prc.PMPRC,
                        TaxIncluded: prc.TXIC,
                        Price: prc.PRC,
                        SizeId: prc.SZID
                    })) || [],
                    Taxes: itm.TAXS?.map((tax: any) => ({
                        TaxId: tax.TID,
                        Index: tax.IDX
                    })) || []
                })) || [],
                Loyalty: {
                    ProviderId: data.LOYINTG2?.ID || 0,
                    Connection: data.LOYINTG2?.CON || null,
                    ProgramTypes: data.LOYINTG2?.PTYPEs?.map((loy: any) => ({
                        ProgramTypeId: loy.PTID
                    })) || []
                },
                LoyaltyItems: data.LOYITMS?.map((itm: any) => ({
                    ItemId: itm.IID,
                    SizeId: itm.SID,
                    Points: itm.PON,
                    IsChargeable: itm.ICHR
                })) || [],
                MembershipTiers: data.MEMs?.map((mem: any) => ({
                    MembershipId: mem.ID,
                    LifetimePoints: mem.LPTS,
                    Names: mem.NAMs,
                    Rewards: mem.RIDs?.map((rew: any) => ({
                        MembershipId: rew.ID,
                        RewardId: rew.RID
                    })) || [],
                })) || [],
                ModifierCategories: data.MODCATS?.map((mcat: any) => ({
                    ModifierWebCategoryId: mcat.MWCID,
                    Descriptions: mcat.DSCRs,
                    Index: mcat.IDX,
                    Names: mcat.NAMs
                })) || [],
                ModifierDisplayGroups: data.MDGS?.map((mdg: any) => ({
                    ModifierDisplayGroupId: mdg.MDGRPID,
                    IsWeightChangeable: mdg.IWCHNG,
                    IsHalfable: mdg.IHAF,
                    IsVisible: mdg.ISVSB,
                    MaxSelectionCount: mdg.MAXCNT,
                    MinSelectionCount: mdg.MINCNT,
                    MustToggle: mdg.MTGL,
                    Names: mdg.NAMs
                })) || [],
                Modifiers: data.MODS?.map((mod: any) => ({
                    ModifierId: mod.modID,
                    Descriptions: mod.DSCRs,
                    ImageUrl: mod.IMGU,
                    Index: mod.IDX,
                    IsCrust: mod.ICRST,
                    ModifierWebCategoryId: mod.MWCID,
                    Names: mod.NAMs
                })) || [],
                OrderTypeSubTypes: data.OTSTS?.map((otst: any) => ({
                    OrderTypeId: otst.OTID,
                    OrderTypeSubId: otst.OSTID,
                    PriceGroupId: otst.PGID,
                    Alias: otst.ALI,
                    AskCustomerInfo: otst.ACINF,
                    AskCustomerAddress: otst.ACADR,
                    DefaultPay: otst.DPAY,
                    EndMinute: otst.EMIN,
                    FutureOrders: otst.FUO,
                    MinAmount: otst.MAMT,
                    Names: otst.TNAMs,
                    OrderTypeCharge: otst.OTCH,
                    OrderTypeChargeTaxId: otst.TXID,
                    PausedUntil: otst.PT,
                    Rate: otst.OTTXR,
                    StartMinute: otst.SMIN,
                    TaxTypeId: otst.TTID,
                    TaxName: otst.TXNAM,
                    WaitTime: otst.WT
                })) || [],
                PreModifiers: data.PMODS?.map((pmod: any) => ({
                    PreModifierId: pmod.PMID,
                    Descriptions: pmod.DSCRs,
                    InventoryMultiplier: pmod.ICMUL,
                    IsDefault: pmod.IDEF,
                    IsNone: pmod.INON,
                    IsHidden: pmod.IHID,
                    IsDeleted: pmod.IDLT,
                    Names: pmod.NAMs,
                    PriceCountMultiplier: pmod.PCMUL
                })) || [],
                Rewards: data.REWs?.map((rew: any) => ({
                    RewardId: rew.ID,
                    Points: rew.PTS,
                    IsTierBased: rew.ITB,
                    IsTierUpgrade: rew.ITU,
                    FrequencyId: rew.FID,
                    Names: rew.NAMs
                })) || [],
                StoreBusinessDate: data.SBDAT,
                Settings: settings,
                Sizes: data.SZS?.map((size: any) => ({
                    SizeId: size.SZID,
                    Descriptions: size.DSCRs,
                    Index: size.IDX,
                    Names: size.NAMs
                })) || [],
                ServiceCharges: data.SRVCHRG?.map((srvchg: any) => ({
                    ServiceChargeId: srvchg.SCHID,
                    FixAmount: srvchg.FIXAMT,
                    IncludesDeliveryCharge: srvchg.INCDC,
                    IncludesSubtotal: srvchg.INCST,
                    IncludesTax: srvchg.INCTX,
                    MaxAmount: srvchg.MAXAMT,
                    MaxCharge: srvchg.MAXCHRG,
                    MinAmount: srvchg.MINAMT,
                    MinCharge: srvchg.MINCHRG,
                    Names: srvchg.NAM,
                    Percentage: srvchg.PERCNT,
                    TaxId: srvchg.TAXID,
                    TaxName: srvchg.TAXNAM,
                    TaxRate: srvchg.TAXRAT,
                    TaxTypeId: srvchg.TAXTID,
                    OrderTypeSubTypes: srvchg.OTSTS?.map((ot: any) => ({
                        OrderTypeId: ot.OTID,
                        OrderTypeSubId: ot.OTSID,
                    })) || []
                })) || [],
                Stores: data.STRS?.map((str: any) => ({
                    StoreId: str.SID,
                    Color: str.COL,
                    Name: str.NAM,
                    SortingIndex: str.SNO,
                    Nickname: str.NNAM,
                    Address1: str.ADD1,
                    City: str.CT,
                    State: str.ST,
                    ZipCode: str.ZP,
                    Latitude: str.LT,
                    Longitude: str.LG,
                    Heartland: str.HPK,
                    Items: str.AITM,
                    Distance: 0,
                    Holidays: str.HDAYS,
                    Taxes: str.TAX?.map((tax: any) => ({
                        FixedAmount: tax.FAMT,
                        Rate: tax.RAT,
                        SizeId: tax.SZID,
                        TaxId: tax.TID
                    })) || [],
                    WaitTimes: str.WTS?.map((wt: any) => ({
                        FutureOrders: true,
                        OrderTypeId: wt.OTID,
                        WaitTime: wt.WT
                    })) || []
                })) || [],
                SubCategories: data.SUBCATS?.map((scat: any) => ({
                    ItemSubCategoryId: scat.SCID,
                    Descriptions: scat.DSCRs,
                    Index: scat.IDX,
                    Names: scat.NAMs
                })) || [],
                Taxes: data.TAXES?.map((tax: any) => ({
                    TaxId: tax.TID,
                    FixAmount: tax.FAMT,
                    OrderTypesExcluded: JSON.parse(tax.OTEXCs) || [],
                    IsPercentageBase: tax.IPBAS,
                    IsFixedAmountBase: tax.IFAMT,
                    IsSizeBase: tax.ISZBAS,
                    IsAddToSalesAmount: tax.IATSAMT,
                    IsAffectedByDiscounts: tax.IEFFDIS,
                    IsPerOrder: tax.IPORD,
                    IsNextToItemIndividually: tax.DNTITM,
                    Names: tax.NAMs,
                    Rate: tax.TRT,
                    SizeId: tax.SZID,
                    TaxTypeId: tax.TTID
                })) || [],
                ZipCodes: data.ZIPS || "",
                DeliveryZones: data.ZONS?.map((zn: any) => ({
                    ZoneId: zn.ZID,
                    IsLimitedTime: zn.ILT,
                    StoreId: zn.SID,
                    ZoneCharge: zn.ZCHRG,
                    ZoneMinDeliveryAmount: zn.MINDEL,
                    ZoneTypeId: zn.ZTID,
                    Polygons: zn.PGON?.map((pol: any) => ({
                        Latitude: pol.Lt,
                        Longitude: pol.Lg
                    })) || [],
                    LimitedTimes: zn.LIMTIME?.map((tm: any) => ({
                        WeekDay: tm.wDay,
                        StartMinute: tm.sMin,
                        EndMinute: tm.eMin
                    })) || []
                })) || [],
                ZipTaxes: data.ZTAXES?.map((ztax: any) => ({
                    TaxId: ztax.TID,
                    FixAmount: ztax.FAMT,
                    Rate: ztax.RT,
                    SizeId: ztax.SZID,
                    StoreId: ztax.SID,
                    ZipCode: ztax.ZCD
                })) || [],
                OpenPayMerchant: data.OPMCH,
                MerchantPids: data.MPIDS,
                OPDevSId: "",
                OPSourceId: "",
                StoreInfo: {
                    storeId: data.SINFO?.SID || 0,
                    storeKey: data.SINFO?.SKEY || "LE5AR",
                    storeKeyEncoded: data.SINFO?.SKEYENC || "",
                    storeCode: data.SINFO?.SCODE || "",
                    brandId: data.SINFO?.BID || "",
                    streetNumber: data.SINFO?.SNUM || "",
                    address1: data.SINFO?.ADD1 || "",
                    address2: data.SINFO?.ADD2 || "",
                    address3: data.SINFO?.ADD3 || "",
                    address4: data.SINFO?.ADD4 || "",
                    address5: data.SINFO?.ADD5 || "",
                    city: data.SINFO?.CITY || "",
                    state: data.SINFO?.STA || "",
                    zip: data.SINFO?.ZIP || "",
                    countryId: data.SINFO?.CID || 1,
                    menuGroupId: data.SINFO?.SMGID || 1006
                },
                UISettings: {
                    showSvgLocationIcons: true
                },
                DefaultPreModifierId: 0,
                NonePreModifierId: 0
            };

            this._datas[storeKey] = newData;
        }
        catch (error) {
            console.error("Error occurred in mapData:", error);
        }
    }

    private readonly _mapCalorieData = (storeKey: string, data: any[]): void => {
        if (data.length == 0)
            return;

        const storeData = this._datas[storeKey];
        if (!storeData)
            return;

        // Create a lookup table
        const lookup = data.reduce((acc: any, cur: any) => {
            if (cur.CALS) {
                const key = `${cur.IID}-${cur.MID}`;
                acc[key] = cur.CALS.map((cal: any) => ({
                    Calories: cal.CALS,
                    ModifierCount: cal.MCNT,
                    PreModifierId: cal.PMID,
                    SizeId: cal.SID
                })) || [];
            }
            return acc;
        }, {});

        this._calories[storeData.StoreInfo.menuGroupId] = lookup;

        // Iterate over items
        for (const item of storeData.Items) {
            for (const mdg of item.ModifierDisplayGroups) {
                for (const mod of mdg.Modifiers) {
                    const key = `${item.ItemId}-${mod.ModifierId}`;
                    if (lookup[key])
                        mod.Calories.push(...lookup[key]);
                }
            }
        }
    }

    private readonly _getSpecialPremodifierIds = (storeKey: string): void => {
        const data = this._datas[storeKey];
        if (!data)
            return;

        const premodifiers = data.PreModifiers;
        if (premodifiers.length === 0)
            return;

        for (const premodifier of premodifiers) {
            if (premodifier.IsDefault)
                data.DefaultPreModifierId = premodifier.PreModifierId;
            else if (premodifier.IsNone)
                data.NonePreModifierId = premodifier.PreModifierId;
        }
    }

    //private readonly _mapBrandData = (storeKey: string, data: any) : void => {
    //    this._brand
    //}
}