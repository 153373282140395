export class Dimmer {
    private readonly _dimmerId: string;
    private readonly _dimmerElement: HTMLElement;
    private readonly _parentId: string;

    constructor(parentId: string) {
        this._parentId = parentId;
        this._dimmerId = this._parentId + "_dimmer";

        let dimmerElement = document.getElementById(this._dimmerId);

        if (!dimmerElement) {
            dimmerElement = document.createElement("div");
            dimmerElement.id = this._dimmerId;
        }

        this._dimmerElement = dimmerElement;
    }

    public on(): void {
        const parentElement = document.getElementById(this._parentId)
        parentElement?.parentElement?.insertBefore(this._dimmerElement, parentElement);
        setTimeout(() => this._dimmerElement.classList.toggle("on", true), 5);
    }

    public off(): void {
        this._dimmerElement.classList.toggle("on", false);
        this._dimmerElement.remove()
    }
}