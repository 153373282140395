import { IName, INewName } from '../interfaces/global.interfaces';
import { Util } from '../utils/util';
import { TranslationMap } from './interfaces/i18n.interface';

/**
 * Returns the normalized language code
 * @param {string} [languageCode=aOLO.Temp.languageCode] - The language code to use for retrieving the name.
 * @returns {string} The name corresponding to the provided `name` and `languageCode`. If no name is found, it returns "undefined".
 */
function NormalizeLanguageCode(languageCode: string): string {
    switch (languageCode.toLowerCase()) {
        case 'en-us':
            return 'en-US';
        case 'es-mx':
        case 'sp-mx':
            return 'es-MX';
        default:
            return languageCode;
    }
}

/**
 * Returns the name corresponding to the provided `name` and `languageCode`.
 * @param {string} name - The name to retrieve.
 * @param {string} [languageCode=aOLO.Temp.languageCode] - The language code to use for retrieving the name.
 * @returns {string} The name corresponding to the provided `name` and `languageCode`. If no name is found, it returns "undefined".
 */
export function Names(name: string, languageCode?: string): string {
    if (!languageCode)
        languageCode = globalThis.aOLO.Temp.languageCode;

    languageCode = NormalizeLanguageCode(languageCode);

    const languageTranslations = translations[languageCode];
    const phoneFormatLanguage = translations[NormalizeLanguageCode(aOLO.storeInfo?.Country?.DefaultCultureCode || "en-us")];

    if (languageTranslations) {
        const translation = languageTranslations[name];
        const formattedLocalPhoneNumber = phoneFormatLanguage["PhoneFormat"]
        if (translation) {
            return translation
                .replace(/%year%/g, new Date().getFullYear().toString())
                .replace(/%storePhoneNumber%/g, Util.formatPhoneNumber(aOLO.storeInfo?.Phone, formattedLocalPhoneNumber));
        }
    }

    return 'undefined';
}

/**
 * Returns the localized name from a JSON string based on the specified language code.
 * @param nameJson - A JSON string containing the names for different languages.
 * @param languageCode - Optional. The language code for which to retrieve the name. If not provided, the default language code is used.
 * @returns The localized name from the JSON string, or "undefined" if the name is not found.
 */
export function NamesJson(nameJson: string, languageCode?: string): string {
    if (!languageCode)
        languageCode = aOLO.Temp.languageCode;

    const names = JSON.parse(nameJson);
    const name = names.find((n: IName) => n.CULT === languageCode)?.NAM;

    return name || "undefined";
}

/**
 * Sets the page language and updates all elements with localization attributes accordingly.
 * @param languageCode - The language code to set.
 * @returns void
 */
export function setPageLanguage(languageCode: string): void {
    localStorage.setItem("languageCode", languageCode);
    //languageCode = "sp-mx"
    globalThis.aOLO.Temp.languageCode = languageCode;
    document.body.lang = NormalizeLanguageCode(languageCode).split('-')[0];

    const tags = document.querySelectorAll<HTMLElement>('[ltag]');
    tags.forEach((el: HTMLElement) => {
        const ltag = el.getAttribute("ltag");
        if (ltag)
            el.innerHTML = stringMapData(Names(ltag, languageCode));
    });

    const placeholders = document.querySelectorAll<HTMLElement>('[ltagp]');
    placeholders.forEach((el: HTMLElement) => {
        const ltagp = el.getAttribute("ltagp");
        if (ltagp)
            el.setAttribute("placeholder", stringMapData(Names(ltagp, languageCode)));
    });

    const ltagJson = document.querySelectorAll<HTMLElement>('[ltagj]');

    for (const el of ltagJson) {
        let ltagj = el.getAttribute("ltagj");
        if (ltagj) {
            const names = JSON.parse(decodeURIComponent(ltagj));
            let nam = names.find((x: IName) => x.CULT === languageCode);
            if (nam) {
                el.innerText = nam.NAM ? stringMapData(nam.NAM) : stringMapData(nam.DSCR);
                continue;
            }

            if (languageCode === "en-us") {
                nam = names.find((x: INewName) => x.lc === "en-US");
                if (nam) {
                    el.innerText = stringMapData(nam.text);
                    continue;
                }
            }
            if (languageCode === "sp-mx") {
                nam = names.find((x: INewName) => x.lc === "es-MX");
                if (nam) {
                    el.innerText = stringMapData(nam.text);
                    continue;
                }
            }
        }
    }

    interface IDataMapping {
        keyword: string,
        data: string | undefined
    }

    function stringMapData(instr: string) {
        const keywords: IDataMapping[] =
            [
                { keyword: "{{brand}}", data: aOLO.brandInfo?.brandName }
            ];

        let temp: string = instr;

        keywords.forEach(x => {
            if (temp.includes(x.keyword) && x.data)
                temp = temp.replace(x.keyword, x.data);
        });
        return temp;
    }
}

export function getSupportedLanguages(): string[] {
    return ["en-us", "sp-mx"];
}

/**
 * Merges the provided external translations with the existing translations.
 *
 * If the language already exists in the current translations, the new translations are merged with the existing ones.
 * If the language does not exist, the new translations are added.
 *
 * @param externalTranslations - An object containing external translations mapped by language codes.
 * @returns void
 */
export function mergeTranslations(externalTranslations: TranslationMap | null | undefined): void {
    if (!externalTranslations) {
        console.error('External translations cannot be null');
        return;
    }

    Object.keys(externalTranslations).forEach(lang => {
        translations[lang] = Object.assign({}, translations[lang], externalTranslations[lang]);
    });
}

export const translations: Record<string, Record<string, string>> = {
    'en-US': {
        '1stHalf': "1st Half",
        '2ndHalf': "2nd Half",
        '400': "An unexpected error occurred, please contact the store.",
        '401': "Unauthorized access.",
        '403': "Forbidden resource can't be accessed.",
        '500': "Internal server error.",
        '503': "Service unavailable.",
        'Abort': "Request was aborted by the server!",
        'Account': "Account",
        'Achieved': "Achieved",
        'Address': "Address",
        'Address1': "Address1",
        'AddressDir': "Address",
        'Addresses': "Addresses",
        'AddressSaveError': "There was an error updating your address. Please try again.",
        'AddToOrder': "Add To Order",
        'AGE': "Are you ?? years old or older?",
        'Age13': "Yes, I am 13 years of age or older.",
        'AllergyPreferences': "Allergies",
        'ALREADYAMEMBER': "ALREADY A MEMBER?",
        'AmountDue': "Amount Due:",
        'AmountRedeemCurrencyApplyToOrder': "Enter the amount to apply to the order",
        'AmountToUse': "Amount to use",
        'And': "and",
        'Applied': "Applied",
        'AppliedToOrder': "Applied to Order:",
        'Apply': "Apply",
        'ApplyChanges': "Apply Changes",
        'April': "04 - April",
        'AprilOnly': "April",
        'AptUnit': "Apt / Unit / Room No",
        'AptSuiteRoom': "Apt / Suite / Room",
        'AptSuiteRoomPlaceholder': "Apt, Suite, Room #",
        'AptUnitSuite': "Apt/Unit/Suite",
        'AreYouSureSignOutOrderCleared': "Are you sure you want to sign out? Your order will be cleared.",
        'ASAP': "ASAP",
        'August': "08 - August",
        'AugustOnly': "August",
        'AutocompleteText': "Enter 4+ characters for autocomplete...",
        'AvailableAmount': "Available Amount:",
        'Balance': "Balance",
        'BankedCurrency': "Banked Currency",
        'BillAddr': "Billing Address:",
        'BS': "Bahamas (+1)",
        'Addr': "Address:",
        'BillStName': "Billing street name",
        'BillStNo': "Billing street No.",
        'BillZip': "Zip Code:",
        'Birthday': "Birthday",
        'BoxTopMsg': "Discount will be applied to your order as soon as all the required items are added to the order.",
        'BrowserNotSupported': "We're sorry for the inconvenience, but our website does not support Internet Explorer or<br>Safari versions older than version 14. These browsers are outdated or unsupported and may not<br>display all features of our site correctly. We highly recommend updating your browser to the<br>latest version or using a different browser such as Google Chrome, Mozilla Firefox, or<br>Microsoft Edge for the best experience on our site. Thank you for your understanding.",
        'BusinessDayIsClosed': "Business day is closed.",
        'CallStore': "Please call the store @ %storePhoneNumber% to place your order.",
        'CalorieFractionMin': "?? cal. per one ??? ????",
        'CalorieFractionMinMax': "?? - ??? cal. per one ???? ?????",
        'CaloriesPerFraction': "{{calorie}} cal. per 1/{{serving}} {{servingName}}",
        'CaloriesPerServing': "{{calorie}} cal. / {{servingName}}",
        'CalorieServingMin': "?? cal. per ???",
        'CalorieServingMinMax': "?? - ??? cal. per ????",
        'Cancel': "Cancel",
        'CannotResolveAddress': "Cannot resolve address",
        'CannotUseCouponTotalZero': "Cannot apply reward when total is $0. Try adding something to your cart.",
        'CardNo': "Card Number:",
        'CardholderName': "Card Holder Name",
        'CardOnFile': "Card on file",
        'carMakeModel': "Car make, model and color",
        'Cash': "Cash",
        'CashLimit': "Oops, this order reached our store's cash limit of ?? but you can pay by credit card. We apologize for any inconvenience.",
        'CCNotInte': "Please have your credit card and ID ready for the driver.",
        'Challenge_Participation': "You are now participating in",
        'Change': "Change",
        'ChangeDeliveryAddress': "Change Delivery Address",
        'ChangeOrderDateTime': "Change Order Date & Time",
        'ChangeOrderType': "Change Order Type",
        'ChangePassword': "Change Password",
        'ChangeTime': "Change Time",
        'CharNotAllowed': "Your password contains characters that are not allowed.</br>The only allowed special characters are",
        'CHECKOUT': "CHECKOUT",
        'Checkout': "Checkout",
        'CheckTerms': "Please read and agree the terms of use and privacy policy by checking the box before placing the order.",
        'ChgOrdTypItmErrMsg': "??? ?? is not available for ????.</br>Please remove the ??? ?? from the order before changing the order type.",
        'ChooseMany': "Choose Many",
        'ChooseOne': "Choose One",
        'City': "City",
        'Close': "Close",
        'ClosedForTheHoliday': "Closed for the holiday",
        'ClosedHoliday': "Store is closed on ??.",
        'ClosedOpensAt': "Closed - Opens at {time} {weekday}",
        'ComingSoon': "Coming Soon",
        'Comment': "Comment",
        'CompleteIncompleteDiscounts': "Please complete your current discount's item criteria before adding more rewards.",
        'CompleteRequiredItems': "Complete Required Items",
        'Complete': "Complete",
        'ConfirmPassword': "Confirm Password",
        'Conf1': "Your order submitted successfully.",
        'Conf2': "You will be informed by email as soon as the store confirms your order on ??.",
        'Conf3': "You will be informed by email as soon as the store confirms your order.",
        'Conf4': "Transaction #",
        'Conf5': "Order #",
        'Confirm': "Confirm",
        'ContactInformation': "Contact Information",
        'Contactless': "Contactless Delivery",
        'ContactlessInfo': "Contactless delivery option is not available for cash payments. Contactless Delivery allows us to deliver your food with minimal personal contact. Driver will leave the order at your door, protected by a barrier from the ground, knock, step back a safe distance and wait for you to pick it up. Since this is a contactless delivery please consider pre-tipping the driver before checking out.",
        'ContactUs': "Contact Us",
        'Continue': "Continue",
        'Copyright': "© 2015 - %year% Adora POS, LLC. All rights reserved.",
        'CouldNotGetOrderNumber': "Could not get order number. Please try again.",
        'Countdown': "Countdown",
        'Country': "Country",
        'CountryCode': "Country Code",
        'CouponApplied': "Coupon Applied",
        'CouponCannotApplyMultiple': "This coupon cannot be applied multiple times.",
        'CouponCode': "Coupon Code",
        'CouponCodeErrorWithFundraiser': "Coupons are ineligible for use with ?? Fundraiser.",
        'CouponDisclaimer': "Not valid with any other offers, discounts, fundraisers or promotions. Valid for take-out, dine-in and delivery. delivery charges apply.",
        'CouponNotActive': "Coupon is not active in this store",
        'CouponNotQualified': "Coupon Not Qualified!",
        'CouponRedeemed': "Coupon has already been redeemed.",
        'CP_DeleteLoyProfileConfirmation': "Are you sure you want to delete your loyalty profile?<br>This cannot be undone.",
        'CPNLIMIT': "There is limit of ?? per order for selected coupon.",
        'CpnLimitOne': "Oops! Only one coupon per order can be redeemed at a time. Please try again.",
        'CPNMINAMT': "Minimum order amount of ?? is required for the selected coupon.",
        'CpnOTNotOffered': "Applied discounts are not offered on selected order type.  If continue is selected, the discount(s) will be removed.",
        'CreateAccount': "Create Profile",
        'CreateAccountConfirmationPage': "Create Profile",
        'CreateAFavorite': "Create a Favorite",
        'CreateAFavorite_BodyText': "Add any meal from recent orders to easily order it again.",
        'CreditCard': "Credit Card",
        'CreditcardExpectCall': "You will receive a call from the store to get your credit card information.",
        'CreditcardPhotoID': "Please have the credit card and a valid photo ID present when receiving the order.",
        'CreditCardPreAuthNote': "Note: This credit card has pre-authorization for this purchase; the final payment will be charged in-store at pickup time.",
        'CreditCardPrepareInfo': "Credit Card (via phone)",
        'CreditCardUponDelivery': "Credit Card Upon Delivery",
        'Curbside': "Curbside Pickup",
        'Curbside_CarMakeModel': "Car's make and model",
        'Curbside_ConfirmCarModel': "Please confirm your car's make, model and color",
        'Curbside_EstimatedDistanceFromRestaurant': "Estimated distance from the restaurant is ?? ???",
        'Curbside_SelectStallNumber': "Please select your parking stall number.",
        'Curbside_StoreHasBeenNotified': "Your request has been sent to the store.  When your order is ready, we will bring it to you.",
        'Curbside_StoreWillBeNorified': "The store will be notified that you are here.",
        'CurbsideInfo': "Let us bring your order to your car! Please pull up to the white canopy. We will direct you where to park, and we'll bring your food out when ready. Must be paid by credit card at time of order. Thanks for helping us stay safe!",
        'CurrentPassword': "Current Password",
        'Custom': "Custom",
        'Customize': "Customize",
        'Date': "Date",
        'DateInPast': "Date must not be in the past.",
        'Day': "Day",
        'December': "12 - December",
        'DecemberOnly': "December",
        'DefaultAddress': "Make this my default address",
        'DeleteProfile': "Delete Profile",
        'DeleteProfileMessage': "Are you sure you want to delete your profile? </br>This is a permanent deletion of all records in your profile including order history, </br>loyalty points, earned rewards, existing offers, and orders. </br></br> Enter your password to confirm you want to delete your profile.",
        'DeliverTo': "Deliver To",
        'Delivery': "Delivery",
        'DeliveryAddress': "Delivery Address",
        'DeliveryAddressOutsideAllZonesError': "Delivery is not available for the address provided as it is outside the delivery area of any location. Please choose a different address or select an alternative ordering option.",
        'DeliveryCharge': "Delivery Charge",
        'DeliveryInstructions': "Delivery Instructions",
        'DeliveryInstructionsPlaceholder': "E.g., use back door, gate code",
        'DeliveryOutLimitedTimeError': "Currently Only Offering Pre-Order",
        'DelLastAddr': "Cannot Delete. One address must remain on file.",
        'DietaryPreferences': "Dietary Preferences",
        'DigitalGC': "Digital Gift Card",
        'DineIn': "Dine In",
        'DineInAt': "Dine In at",
        'Discount': "Discount",
        'Discounted': "Discounted",
        'DiscountedTotal': "Discounted Total",
        'Discounts': "Discounts",
        'Donate': "Donate",
        'Donation': "Donation",
        'DonationNoThanks': "No Thanks",
        'DonationQuestion': "Would you like to donate to ????",
        'DonationRoundUp': "Round Up",
        'Done': "Done",
        'DontContinue': "Don't continue",
        'DontForget': "DON'T FORGET!",
        'DontHaveAnAccount': "Don't Have an Account?",
        'DontSave': "Don't Save",
        'DowngradedTo': "Downgraded to",
        'Due': "Due",
        'EarnedPTS': "Earned",
        'EarnedREW': "Earned",
        'Edit': "Edit",
        'EditingDiscountedItem': "Editing a discounted item will remove the discount. You may re-apply the discount after you have finished editing. Do you want to proceed with editing the item?",
        'Email': "Email",
        'EmailAddress': "Email Address",
        'EmailNoMatch': "The e-mail addresses you entered do not match.",
        'EmailNotFound': "We did not find the email address '??' in our records.",
        'EmailOrPhoneNumber': "Email or Phone Number",
        'EmailUsedByAnotherCustomer': "Email you provided is being used by another Customer.",
        'EmptyDate': "Date must be not empty.",
        'EmptyMemberList': "You must add at least one member for a group order.",
        'EmptyTime': "Time must be not empty.",
        'EndingBalance': "Ending Balance:",
        'EndPrivTerm': ".",
        'EnoughPoints': "You do not have enough points to redeem the selected item.",
        'EnterDeliveryAddress': "Enter Delivery Address",
        'EnterPasscode': "Enter Passcode",
        'EnterPasscodeExpiresInXMinutes': "Please enter the passcode texted to you.<br>Passcode expires in 15 minutes.",
        'EnterYourPasswordPlaceholder': "Enter your password",
        'ErrorGettingServerDate': "Error getting server date.",
        'Error_ConfigurationGenericError': "The online ordering system is currently unavailable due to a configuration issue.<br>Please try again later or contact the store directly for assistance.<br>We apologize for the inconvenience.",
        'Error_HalfNotAvailableForSelectedSize': "The Half & Half option is not available for the selected size.<br>Please choose a different size or option.",
        'Error_ModifierIsNotBeingOfferedOnSize': "{{modifier}} is not available for the {{size}} size. Please choose a different size or remove {{modifier}} to proceed.",
        'Error_ReachedMaxNumberOfModifiers': "You have reached the maximum number of toppings allowed. Please adjust your selection to continue.",
        'Error_ReachedMaxNumberOfModifiersOnItem': "You have reached the maximum number of modifiers allowed for this item.<br>Please review your selections.",
        'Error_SelectedModifierNoSizeSwitch': "{{modifier}} is not available for the {{size}} {{item}}.<br>Would you like to switch to a size where {{modifier}} is available?",
        'Error_SelectToppings1Half': "Please select a topping for the first half.<br>For example, choose a cheese topping for a cheese pizza.",
        'Error_SelectToppings2Half': "Please select a topping for the second half.<br>For example, choose a cheese topping for a cheese pizza.",
        'Error_UnableToFindProperSizeToApply': "We are unable to identify a suitable size for the selected item.<br>Please review your selection and try again.",
        'Estimated': "Estimated",
        'EstimatedDelivery': "Estimated Delivery",
        'ExistingCreditCard': "Existing Credit Card",
        'ExistingCustomerSignIn': "Existing customer sign in",
        'ExpDate': "Expiration Date:",
        'Expired': "Expired",
        'Expires': "Expires",
        'ExtraToppings': "Extra Toppings",
        'FailUpdateInfo': "Unable to update info at the moment.",
        'Favorites': "Favorites",
        'FavoriteObjectNone': "YOU HAVEN'T SAVED ANY FAVORITE {{object}} YET!",
        'FavoriteObjectInstruction': "Tap the heart icon next to your favorite {{object}} to save them here for quick access.",
        'FavoriteOrders': "Favorite Orders",
        'favoriteStoreDelete_Error': "There was an error deleting the favorite store. Please try again.",
        'favoriteStoreSaveName_Error': "There was an error saving the favorite store. Please try again.",
        'FavStore': "Make this store my favorite store",
        'February': "02 - February",
        'FebruaryOnly': "February",
        'feet': "feet",
        'Find': "Find",
        'FindStore': "Find a Store",
        'FinishCoupon': "Finish Coupon",
        'FinishX': "Please finish your order within ?? minutes.",
        'FirstHalf': "First Half",
        'FirstName': "First Name",
        'For': "for",
        'ForgotPassword': "Forgot Password",
        'Franchising': "Franchising",
        'Friday': "Friday",
        'FulfillmentTime': "The fulfillment time for this order has been changed to As Soon As Possible, ??? time is approximately ?? minutes after your order has been placed.",
        'FundraiserCode': "Fundraiser Code",
        'FundraiserCodeErrorWithCoupon': "If you want to participate in ?? Fundraiser, please remove your applied coupons.",
        'FundraiserCodeOK': "Your Fundraiser Code has been applied.",
        'FundraiserValueIsEmpty': "Incomplete field. Please enter the ",
        'Future': "Future",
        'GiftCard': "Gift Card",
        'GiftCardAlreadyApplied': "Gift card is already applied to your order.",
        'GiftCardBalanceIs0': "The gift card balance is $0.00.",
        'GiftCards': "Gift Cards",
        'GO_ErrorDuringGOSave': "An error occured while saving the group order.",
        'GO_ErrorDuringGOSubmit': "An error occured during group order submit.",
        'GO_FinalizeQuestionYesNo': "Finalize the group order and proceed to checkout?",
        'GO_FinalizeWillDeleteWarning': "Finalizing the group order will delete member' orders that have not been submitted.",
        'GO_NotStartedMembers': "member orders not started in this group order belong to:",
        'GO_NotSubmittedMembers': "member orders not submitted in this group order belong to:",
        'GO_OrderFinalization': "<h1 class='warning'> Order finalization</h1><br>Group order is being finalized.<br>Please wait.",
        'GO_OrderUndefined': "The group order is undefined.",
        'GO_UnsubmitOrders3': "Continue submitting the group order will cause unsubmitted members' orders to be deleted and<br>will no longer allow you to add members to the current group order.<br>Are you sure you want to proceed with the checkout?",
        'GoogleMapsErrorAmbiguous': "Google Maps error: Ambiguous addresses at",
        'GoogleMapsErrorResolvingAddress': "Google Maps error: Error while resolving address",
        'Gratuity': "Gratuity",
        'GROUPORDER': "GROUP ORDER",
        'GroupOrderStatus1': "NOT STARTED",
        'GroupOrderStatus2': "IN PROGRESS",
        'GroupOrderStatus3': "ORDERED",
        'GroupOrderStatus4': "NOT PARTICIPATING",
        'GroupOrderStatus5': "SUBMITTING",
        'GroupOrderStatus6': "CANCELED",
        'GroupOrderTime': "Group Order Time",
        'GRPORD_AddMember': "Add Member",
        'GRPORD_AddNewGroupMember': "Add new group member",
        'GRPORD_AreYouSureCancel': "Are you sure you want to cancel your Group Order?",
        'GRPORD_CancelFailed': "Group Order Cancel failed.",
        'GRPORD_CancelGroupOrder': "Group order has been canceled.",
        'GRPORD_CreateGroupOrder': "Create Group Order",
        'GRPORD_CreateOrder': "Create Group Order",
        'GRPORD_Date': "Date",
        'GRPORD_PayAllDescr': "If Pay for all members is unchecked, each member will be paying for their own meal.",
        'GRPORD_PayAllMembers': "Pay for all members",
        'GRPORD_SelectAll': "Select all group members",
        'GRPORD_SelectMembers': "Select members for your group order",
        'GRPORD_Time': "Time",
        'GRPORD_Welcome': "Invite your friends to join a group order. Each member will get a link to order their meal and have it added to the group.",
        'Guest': "Continue as Guest",
        'HalfHalfPizzaOrderDetail': "?? Half & Half Pizza",
        'Hello': "Hello",
        'Hey': "Hey",
        'HHP': "Half & Half Pizza",
        'Hol100': "New Year's Day",
        'Hol150': "Valentine's Day",
        'Hol200': "Easter",
        'Hol300': "Memorial Day",
        'Hol400': "Independence Day",
        'Hol500': "Labor Day",
        'Hol550': "Halloween",
        'Hol600': "Thanksgiving Day",
        'Hol700': "Christmas Eve",
        'Hol800': "Christmas Day",
        'Hol900': "New Year's Eve",
        'Hol950': "Super Bowl",
        'Home': "Home",
        'Inactive': "Inactive",
        'DeleteMyProfile': "Delete My Profile",
        'IncompleteProfileError': "There is some information missing from your profile. Please complete all the fields to continue.",
        'InProgress': "In Progress",
        'InStoreLimit': "Oops, this order reached our store's pay in store limit of ?? but you can pay by credit card. We apologize for any inconvenience.",
        'Instructions': "Instructions",
        'ImportDigitalGiftCards': "Import Digital Gift Cards",
        'InvalidAddr': "Invalid billing address.",
        'InvalidAddress': "Invalid Address",
        'InvalidAddressNoStreetNumber': "The address is invalid or missing a street number. Please review and try again.",
        'InvalidAddressNoZipCode': "The address is invalid or missing a zip code. Please review and try again.",
        'InvalidAge13': "Must acknowledge that you are at least 13 years of age.",
        'InvalidCardNumber': "Invalid card number.",
        'InvalidCarInfo': "Car make, model and color is required for curbside pickup.",
        'InvalidCC': "Invalid credit card number.",
        'InvalidCode': "Invalid Code!",
        'InvalidCommonSize': "Invalid Common Size.",
        'InvalidCouponCode': "Invalid coupon code.",
        'InvalidCVV': "Invalid CVV(Card verification value)",
        'InvalidDate': "Invalid date.",
        'InvalidDay': "Must select a day.",
        'InvalidEmail': "Invalid email address.",
        'InvalidEmailOrPassword': "Invalid email and/or password. Please enter valid credentials, reset your password, or try logging in with your phone number.",
        'InvalidEmailOrPasswordNoPhone': "Invalid email and/or password. Please enter valid credentials or reset your password.",
        'InvalidEmailPhone': "Invalid email address or phone number.",
        'InvalidExp': "Invalid expiration date.",
        'InvalidFirstName': "Invalid first name",
        'InvalidFundraiserCode': "Invalid fundraiser code.",
        'InvalidLastname': "Invalid last name.",
        'InvalidMonth': "Please select a month",
        'InvalidOrderTypeCode': "The coupon is not valid for ??.",
        'InvalidPassword': "Password must contain at least one number, one lowercase letter, one uppercase letter, one symbol (* + @ # $ % & !) and a minimum of eight characters.",
        'InvalidPasswordLogin': "Invalid password.",
        'InvalidPasswordMatch': "The passwords do not match.",
        'InvalidPhone': "Invalid phone number.",
        'InvalidPin': "Invalid pin.",
        'InvalidPromotionCode': "Invalid promotion code.",
        'InvalidReward': "The reward is not currently valid or redeemable at this order time.",
        'InvalidScheduleCode': "The coupon is not valid Today/Now.",
        'InvalidStName': "Invalid billing street name.",
        'InvalidTermsAndPrivacy': "Must acknowledge the Terms of Use and the Privacy Policy.",
        'InvalidTip': "Invalid tip amount.",
        'InvalidZip': "Invalid Billing zip code.",
        'InvalidZip1': "Invalid zip code.",
        'InvalidZipCode': "Invalid zip code.",
        'IsClosedNow': "Store is closed now.",
        'Item': "Item",
        'ItemAdded': "Item Added",
        'ItemComment': "Note: Toppings and/or Coupon Codes added in the box will <strong>NOT</strong> be applied to the item.",
        'Items': "Items",
        'ItemSizeNotHalfHalf': "??? is not available in the selected size. Item size has been updated to ??.",
        'January': "01 - January",
        'JanuaryOnly': "January",
        'Join': "Join",
        'Joined': "Joined",
        'JoinLoyaltyProgramButton': "Join Loyalty Program",
        'JoinLoyaltySignUpConfirmPrivacy': "Privacy Policy",
        'JoinLoyaltySignUpConfirmTerms': "Terms of Use",
        'JoinOurLoyaltyProgram': "Join our loyalty program and earn reward &<br/>exclusive offers.",
        'JoinOurTeam': "Join Our Team",
        'JoinRewards': "Join Rewards",
        'JoinRewardsProgram': "Join Rewards Program",
        'JsonError': "Error.</br>Parsing JSON Request failed!",
        'July': "07 - July",
        'JulyOnly': "July",
        'June': "06 - June",
        'JuneOnly': "June",
        'kilometer': "kilometer",
        'kilometers': "kilometers",
        'kilometer_short': "km",
        'LangAbbr': "EN",
        'LangHeader': "Choose your language",
        'LastName': "Last Name",
        'LastOrderDate': "Last Order Date",
        'LastTransaction': "Last Transaction",
        'Later': "Later",
        'LearnMore': "Learn more",
        'Left': "Left",
        'LifetimePoints': "Lifetime Earned Points",
        'LikeToReceiveOrderStatusNotificationsViaEmail': "I would like to receive order status notifications via email:",
        'LikeToReceiveOrderStatusNotificationsViaEmailAndText': "I would like to receive order status notifications via email and text:",
        'LikeToReceiveOrderStatusNotificationsViaText': "I would like to receive order status notifications via text:",
        'LIMITHOUS': "Limited hours",
        'Loading': "Loading...",
        'LocationNotFound': "Location not found. Try a city or zip code.",
        'Locations': "Locations",
        'Locations_Heart_NoName_Error': "You must insert a store name. Please try again.",
        'Locations_Heart_SaveLocationName_NotSignedIn': "To create a favorite store you need to Sign In or Create a Profile.</br>Would you like to get directed to the Sign In page?",
        'Locations_Heart_SaveLocationName_RemoveHeart': "Are you sure you want to remove the favorite store?",
        'Locations_Heart_SaveLocationName_SignedIn': "Would you like to give this store a name?",
        'Locations_Search': "Search: City, State, or Zip Code...",
        'LocationServicesBlocked': "Your location services are blocked. For the store to find your location quickly, please grant location access.",
        'LogIn': "Log In",
        'LogonError': "Unknown logon error happened. Please retype your email address and password then try to login again.",
        'LogOut': "Log Out",
        'LoyaltyCantReachInfoError': "Oops! We can't reach your loyalty info, please try again later.",
        'LoyaltyCarrierMsg': "*carrier text messaging charges may apply",
        'LoyaltyCreateWelcome': "This is our new loyalty program!",
        'LoyaltyServerOffline': "Oops! Something is not working. Please try again later.<br>If the issue persists, please proceed as a guest and place your order.",
        'LoyaltyStoreMsg': "If you like to join ??? located at ???? loyalty program, please enter your cell phone number below",
        'LoyaltyVerifyMsg': "A verification code will be send to your phone",
        'MakeItAGroupOrder': "Make It a Group Order",
        'Makeithalfhalfpizza': "Make it half & half pizza",
        'MakeMyDefaultStore': "Make My Default Store",
        'March': "03 - March",
        'MarchOnly': "March",
        'MarkAttributesDidNotSaved': "Loyalty program attributes have not been saved. To update them please use Customer Profile feature.",
        'MarkEmail': "Email",
        'MarkInfo': "I would like to receive offers and product information via:",
        'MarkSelDay': "Select a day",
        'MarkSelMonth': "Select a month",
        'MarkText': "Text",
        'May': "05 - May",
        'MayOnly': "May",
        'MeetPromiseTimeError': "We cannot meet the order promise time. Please call store @ %storePhoneNumber%",
        'MeetPromiseTimeWarning': "The store will stop accepting online ?? orders in 5 minutes. Please finish your order within 5 minutes.",
        'Menu': "Menu",
        'MenuItems': "Menu Items",
        'MX': "Mexico (+52)",
        'mile_short': "mi",
        'mile': "mile",
        'miles': "miles",
        'MinDelAmt': "This purchase is below the minimum order amount for delivery of $??. Please try again.",
        'minMod': "You have to select minimum ",
        'Minutes': "Minutes",
        'MISSING': "Missing information!<br/>Please complete before placing your order.",
        'ModifierIsNotCompatible': "??? is not offered on selected half.",
        'Monday': "Monday",
        'Month': "Month",
        'MultipleMemberOrderWindowAlert': "Another session for this member order has been opened. This window will be redirected to Store home page.",
        'MyAvailableGiftCards': "My available gift cards",
        'MYGROUPORDER': "MY GROUP ORDER",
        'MyHistory': "My History",
        'MyHistory_FirstTimeMessage': "All your history will be available here. Start accumulating rewards today!",
        'MyMembership': "My Membership",
        'MyOffers': "My Offers",
        'MYORDER': "MY ORDER",
        'MyOrders': "My Orders",
        'MyOrders2_FavoriteIconClick': "Name your favorite order",
        'MyOrders2_FetchOrderData_Error': "There was an error retrieving your orders. Please try again.",
        'MyOrders2_FetchOrderDetails_Error': "There was an error retrieving your order details. Please try again.",
        'MyOrders2_ToggleFavoriteOrder_Error': "There was an error saving your favorite order changes. Please try again.",
        'MyOrders_ReOrder_AllError': "These items are not currently available. You may select other items instead.",
        'MyOrders_ReOrder_MultipleError': "are currently unavailable. You may select another item instead.",
        'MyOrders_ReOrder_SingleError': "is currently unavailable. You may select another item instead.",
        'MyPoints&Rewards': "My Points & Rewards",
        'MyPoints': "My Points",
        'MyProfile': "My Profile",
        'MyRewards': "My Rewards",
        'MyRewardsAndOffers': "My Rewards & Offers",
        'MyRewardsSlogan': "<div>My</div><div>Rewards</div>",
        'Name': "Name",
        'Nearby': "Nearby",
        'NewAddress': "New Address",
        'NewDelAddr': "Enter New Delivery Address.",
        'NewPassword': "New Password",
        'No': "No",
        'NoAddressesAddedToAccount': "There are no addresses added to your account",
        'NoAvailableGiftCards': "You have no available gift cards.",
        'NoAvailableRewardsOrOffers': "You have no available rewards or offers.",
        'NoCardsAddedToAccount': "There are no cards added to your account",
        'NoDiscountAssociatedWithCode': "No discount associated with this code.",
        'NoFavoriteItems': "You haven’t added any Favorite Items yet!",
        'NoFutureOnlineAtMoment': "Store is not accepting???deferred orders at the moment.",
        'NoFutureOnlineOrder': "Store is not accepting deferred online orders on the selected date.",
        'NoImNot': "No, I am not.",
        'NoItemAdded': "No Item has been added to order.",
        'NoMenuItemsHaveBeenSelected': "No Menu Items Have Been Selected",
        'None': "None",
        'NoOffers': "No Offers",
        'NoOnlineAtMoment': "Store is not accepting online orders at the moment.",
        'NoOnlineNow': "Store in not accepting online orders now. however you may place an order for later today. ",
        'NoOT1AtMoment': "Not available at the moment.",
        'NoOT2AtMoment': "Not available at the moment.",
        'NoOT3AtMoment': "Delivery service is not available at the moment.",
        'NoOtherStore': "No (Select another location)",
        'NoPayment': "No payment has been selected.",
        'NoRecentOrders': "You have no recent orders.",
        'NoRewards': "You have no rewards to show.",
        'NOTAMEMBER': "NOT A MEMBER?",
        'NotEnrolled': "Not enrolled in loyalty program",
        'NoThanks': "NO THANKS!",
        'Notifications': "Notifications",
        'notifs': "Notifications",
        'notifsAnd': " and ",
        'notifsConsent': "I would like to receive order status notifications via ",
        'notifsEmail': "email",
        'notifsText': "text",
        'NoTime': "Unfortunately there is not enough time to make an online ??? order.",
        'NotOfferedInSelStore': "?? is not being offered in the selected store.",
        'November': "11 - November",
        'NovemberOnly': "November",
        'Now': "Now",
        'Number': "Number",
        'October': "10 - October",
        'OctoberOnly': "October",
        'off': "off",
        'OFF': "OFF",
        'Ok': "Ok",
        'OK': "Ok",
        'OLO_General_Error': "<h1 class='warning'>Oops!</h1>It looks like something went wrong. Please try again!<br /><br />If the issue persists, we invite you to call the restaurant at %storePhoneNumber% and place your order over the phone.<br /><br />We sincerely apologize for the inconvenience.<br /><span style='color:#dddddd; font-size:8px;'>noconnt</span>",
        'OnePrimAddr': "Please select one primary address. ",
        'OnlyOneDiscountAllowedAtATimeError': "Only one discount is allowed at a time.<br>Please remove the existing discount to continue.",
        'OpenOrders': "Open Orders",
        'OpensAt': "Opens at {time}",
        'OpenUntil': "Open until {time}",
        'Optional': "Optional",
        'or': "or",
        'Or': "Or",
        'Order': "Order",
        'OrderCenter': "Order Center",
        'OrderComment': "Note: Coupons added in the box will <strong>NOT</strong> be applied to your order.",
        'OrderDateTime': "Order Date & Time",
        'OrderDetailItemName': "?? ???",
        'OrderDetails': "Order Details",
        'OrderIsClosed': "Order is closed",
        'OrderNow': "Order Now",
        'OrderNumber': "Order Number",
        'OrderTime': "Order Time",
        'OrderTotal': "Order total",
        'OrderType': "Order Type",
        'OrderTypeNotOfferedAtThisLocation': "{{order_type}} is not offered at this location",
        'Other': "Other",
        'OtherReason': "The \"Other\" reason is required.",
        'OutDelivery': "The address is out of store's delivery area.",
        'OutTimeDelivery': "The address is in a zone outside of store's delivery hours.",
        'PaidAmount': "Paid Amount",
        'PartialUpdateInfo': "Partial info was successfully updated.",
        'pass6Char': "Your password must be at least 6 characters.",
        'passDigit': "Your password must contain at least one digit.",
        'passLetterLower': "Your password must contain at least one lowercase letter.",
        'passLetterUpper': "Your password must contain at least one uppercase letter.",
        'Password': "Password",
        'PasswordExpiredSentNewEmail': "Your password has expired. We have sent you an email to reset your password.",
        'PasswordNoMatch': "The passwords you entered do not match.",
        'PasswordReset': "We've sent a message to {email}. Please check your spam folder if you haven't received the email in 5 minutes.",
        'PasswordSaveError': "There was an error updating your password. Please try again.",
        'PasswordUpdatedSuccessfully': "Your password has been updated successfully.",
        'PayInStore': "Pay In Store",
        'Payment': "Payment",
        'Phone': "Phone",
        'PhoneCodeIsInvalidOrExpired': "The code is either invalid or expired.<br>Please try again.",
        'PhoneFormat': "(XXX) XXX-XXXX",
        'PhoneNoMatch': "The phone numbers you entered do not match.",
        'PhoneUsedByAnotherCustomer': "Phone number you provided is being used by another Customer.",
        'PickUp': "Pick Up",
        'PickUpFrom': "Pick Up From",
        'PlaceAnOrderToSeeYourNextMembership': "Place an order to see your membership",
        'PlaceAnOrderToSeeYourNextReward': "Place an order to start earning rewards",
        'PleaseEnterAmountGreaterThan': "Please enter an amount greater than {{x}}",
        'PleaseEnterAmountLessThanAmountDue': "Please enter an amount less the the amount due of {{x}}",
        'PleaseEnterAmountWithinRangeFrom': "Please enter an amount within the range from {{x}} to {{y}}",
        'PleaseSelectItems': "Please Select Items",
        'point': "point",
        'points': "points",
        'Points': "points",
        'PointsAvailable': "Points Available",
        'PointsEarned': "Loyalty points earned",
        'PointsLastUpdated': "Points Last Updated",
        'PointsRedeemed': "Points Redeemed",
        'PointsRemain': "Points Remain",
        'PointsTo': "Points to ",
        'PointsToNextReward': "points to next reward",
        'PointsToTheNextTier': "Points to the next tier",
        'PoweredBy': "Powered by",
        'PreModsNotSet': "Premodifiers have not been set.",
        'PR': "Puerto Rico (+1)",
        'Price': "Price",
        'Primary': "Primary",
        'privacy': "privacy policy",
        'PrivacyPolicy': "By checking this box, you acknowledge the receipt of, review of, and agreement to the ",
        'PrivacyTerms': "I have read and agree to the ",
        'Profile': "Profile",
        'ProfileWithPhoneDoesntExist': "There was a problem retrieving the phone number. Please try again or use a different number.",
        'PromiseTime': "Promise Time",
        'PromosRewards': "Promos & Rewards",
        'PromotionHasBeenRedeemed': "Promotion has been redeemed.",
        'PromotionHasExpired': "Promotion has expired.",
        'Promotions': "Promotions",
        'pts': "pts",
        'ptsEarned': "pts earned",
        'Quantity': "Quantity",
        'RecentOrders': "Recent Orders",
        'RecentlyOrdered': "Recently Ordered",
        'Redeem': "Redeem",
        'RedeemableItems': "Redeemable Items",
        'Redeemed': "Redeemed",
        'RedeemFor': "Redeem for",
        'RedeemCurrency': "Redeem Rewards",
        'RedeemPoints': "Redeem Points",
        'RedeemRewards': "Redeem Rewards",
        'RedeemRewardHasAlreadyBeenAppliedError': "A redeem reward has already been applied.<br>Please remove the already applied redeem reward.",
        'ReEmail': "Re-enter Email",
        'ReEnterExisting': "Please Re-Enter your existing password",
        'RemainingAmount': "Remaining Amount:",
        'RemainingBalance': "Remaining Balance",
        'RememberMe': "Remember Me",
        'RememberMe30Days': "Remember Me for 30 Days",
        'Remove': "Remove",
        'RemoveCoupon': "Remove Coupon",
        'Reorder': "Reorder",
        'RePassword': "Re-enter Password",
        'RepeatPassword': "Repeat Password",
        'RePhone': "Re-enter Phone",
        'RequestResetPassword': "Request password reset",
        'Required': "(required)",
        'ResendCode': "Resend Code",
        'ResetPass_SessionExpired_Error': "Your password reset session has expired. Try to reset your password again.",
        'ResetPass_Unexpected_Error': "Unexpected error happened while trying to reset your password. Please try again later.<br>Error: ",
        'ResetPassword': "Reset Password",
        'ResetPasswordSuccessful': "Your password was successfully reset.",
        'Resubscribed': "Your subscription has been confirmed. You've been added to our list and will hear from us soon.",
        'RewardsFAQ': "Rewards FAQ",
        'Right': "Right",
        'Saturday': "Saturday",
        'Save': "Save",
        'Save_your_profile_changes': "Save your profile changes?",
        'SaveCreditCard': "I would like to have my credit card info saved for future orders.",
        'SavingPassword': "Saving Password",
        'Search': "Search",
        'SearchCityStateOrZipCode': "Search City, State or Zip Code...",
        'SearchingCities': "Searching cities..",
        'SearchingZipCodes': "Searching Zip codes..",
        'SecondHalf': "Second Half",
        'SecurityCode': "Security Code:",
        'Select': "Select",
        'SelectAnotherLocation': "Select another location",
        'SelectAvailableTimeSlot': "Select a time from the available time slots:",
        'SelectDeliveryAddress': "Select Delivery Address",
        'SELECTED': "Selected",
        'SelectedStore': "Selected Store",
        'SelectedTime': "Selected Time",
        'SelectedTimeNotAvailableSelectNew': "The selected time is not available.<br>Please select a new time.",
        'SelectEvent(s)': "Select Event(s)",
        'SelectItem': "Select Item",
        'SelectLocation': "Select Location",
        'SelectMemberAssignPointsChallenge': "Select member(s) to assign points for this Challenge.",
        'SelectMembers(s)': "Select Member(s)",
        'SelectTime': "Select Time",
        'SelectWhenYouWantToPlaceOrder': "Please select when you want your order.",
        'SelModNoSize': "{modName} is not offered on {sizeName} {itemName}.",
        'SelModNoSize1': "Selected ?? is only being offered on ???. <br/>Please change the size to ???? and try again.",
        'SelModNoSize2': "Selected ?? is not being offered on ???. ???? is available on following sizes ?????.",
        'SelSizNoMod': "Selected size is not valid for ",
        'SelTop': "Please select a topping.",
        'SendingOrder': "Sending Order...",
        'September': "09 - September",
        'SeptemberOnly': "September",
        'SetAsDefault': "Set as Default",
        'Settings': "Settings",
        'ShowEarlierOrders': "Show Earlier Orders",
        'ShowMore': "Show More",
        'SignIn': "Sign In",
        'SignInSlogan': "Start earning points to <span class='highlight'>redeem</span> towards your <span class='highlight'>favorite</span> menu items",
        'SignInWithApple': "Sign In With Apple",
        'SignInWithFacebook': "Sign In With Facebook",
        'SignInTextPrivacyTerms': "By selecting Sign In, you agree to receive a passcode via text and agree to our ",
        'SignOut': "Sign Out",
        'SignOut_AreYouSure': "Are you sure you want to sign out?",
        'SignOut_AreYouSure_Empty_EmptyCart': "Are you sure you want to sign out?",
        'SignOut_AreYouSure_EmptyCart': "Are you sure you want to sign out, your basket will be emptied?",
        'SignUp': "Sign Up",
        'SignUp2': "Sign Up",
        'SIZE': "Size",
        'Size': "Size",
        'Skip': "Skip",
        'Slice': "Slice",
        'SomethingWentWrong': "Something went wrong.",
        'spChar': "Your password must contain at least one of<br/>these special characters ",
        'SpecialInstructions': "Special Instructions",
        'SameAsDel': "Same As Delivery Address",
        'StartOrder': "Start Order",
        'StartOrderWithOrderType': "Start {{order_type}} Order",
        'StartingBalance': "Starting Balance:",
        'State': "State",
        'StoreAddress': "Store Address",
        'StoreClosedOn': "The store is closed on ??.",
        'StoreDistanceAway': "{distance} {type} away",
        'StoreDoesNotAcceptDeferredOnlineOrdersOn': "The store does not accept deferred online orders on ??.",
        'StoreDoesNotAcceptOnlineOrdersOn': "The store does not accept online orders on ??.</br>Please call the store at ??? to place your order over the phone.",
        'StoreHours': "Store Hours",
        'storeInfoName': "Ordering From:",
        'StoreIsAlreadyClosed': "The store is already notified",
        'StoreLocatorDesc': "Location services are disabled. To enable, exit this screen, activate location services, and retry. If not, choose your country and enter your zip code.",
        'storeNotFound': "There are no stores within 25 miles of your Zip Code (%zipcode%).Please, try a new Zip Code in the Search Field,. Otherwise, please enable location services.",
        'StreetAddress': "Street Address",
        'StreetAddressPlaceholder': 'Enter Your Address',
        'StreetName': 'Street Name',
        'StreetNo': 'Street Number',
        'SUBMIT': "SUBMIT",
        'SubmitOrder': "Place your order",
        'SubSurv': "Subscribe to Order Survey emails",
        'Subtotal': "Subtotal",
        'SuccessfullyEnrolled': "You have successfully enrolled in the loyalty program.",
        'SuccessfullyJoinedLoyaltyProgram_MsgBoxOK': "You have successfully joined our Loyalty program!",
        'SuccessfullyUpdatedProfilePage_MsgBoxOK': "Your profile has been updated successfully.",
        'SuccessfullyUpdatedProfilePageJoinedLoyalty_MsgBoxOK': "Your profile has been updated successfully.</br>You have successfully joined our Loyalty program!",
        'SuccessUpdateInfo': "Successfully updated info.",
        'Sunday': "Sunday",
        'SystemCannotLocateAddress': "System is not able to locate the address.",
        'Table': "Table",
        'TakeOut': "Take Out",
        'Tax': "Tax",
        'TemporarilyClosed': "Temporarily Closed",
        'terms': "terms of use",
        'TermsConditions': "Terms of Use",
        'TestBrand': "Welcome to {{brand}}",
        'Thankyou': "Thank you",
        'Thursday': "Thursday",
        'tier': "tier",
        'Time': "Time",
        'TimeFrameToExpireContinue': "The time frame to place this order is about to expire.<br>Would you like to continue?",
        'Timeout': "Request timeout!",
        'Tip': "Tip",
        'ToChange': "Change",
        'TogoSupplies': "To-Go Utensils",
        'tomorrow': "tomorrow",
        'Toppings': "Toppings",
        'Total': "Total",
        'Tracker_FetchTrackerData_Error': "There was an error retrieving your order. Please try again.",
        'Tracker_IncorrectAccount_Error': "This order does not belong to this account. Please sign in with the correct account.",
        'Tracker_Status_Error': "Your order preparation progress will be available starting from ",
        'TrackMyOrder': "Track My Order",
        'TrackOrder': "Track Order",
        'TrackOrder_FetchTrackOrderData_Error': "Unable to load order details. Please try again later.",
        'TrackYourOrder': "Track Your Order",
        'Transaction': "Transaction",
        'TransactionCarriedOutThrough': "Transactions carried out through:",
        'TransactionNumber': "Transaction Number",
        'TransactionNumberAbr': "Transaction No.",
        'Tuesday': "Tuesday",
        'UnableEnrolled': "Unable to enroll in loyalty program..",
        'UnableEditProfile': "Please use your Loyalty Application to update the profile. We are working to enable editing here. We apologize for any inconvenience this may cause.",
        'UnableLogin': "Unable to login. Please try again.",
        'UnableResetPassword': "Unable to reset your password.<br/>If this is your first time visiting our new online ordering, continue as guest and create a new user profile at the end of this order.",
        'UnableToDelete': "Unable to delete your profile.",
        'UnableToRetrieveCampaignCoupon': "Unable to retrieve the campaign coupon. We apologize for the error.<br>Please try again later.",
        'UnableToRetrieveReward': "Unable to retrieve the reward. We apologize for the error.<br>Please try again later.",
        'UnableToRetrieveSurveyCoupon': "Unable to retrieve the survey coupon. We apologize for the error.<br>Please try again later.",
        'UnableVerifyPhone': "Unable to verify your phone number.",
        'UnavailableItemModifications1': "The following item(s) have been removed from the order as the exact modifications from the previous order are no longer available:",
        'UnavailableItemModifications2': "The items are still available to order, but the modifications will need to be reselected.",
        'UnexpectedError': "An unexpected error occured.",
        'UnexpectedError500': "An unexpected error occurred.</br>Please try again later.",
        'US': "United States (+1)",
        'UnknownAddressError': "Invalid address or the address is out of store's delivery area.",
        'UnknownError': "Unknown Error!",
        'Unsubscribe': "Unsubscribe",
        'Unsubscribe_BtnResubscribe': "Re-subscribe",
        'Unsubscribe_BtnUnsubscribe': "Yes, unsubscribe me",
        'Unsubscribe_ByAccident': "Did you unsubscribe by accident?",
        'Unsubscribe_ReasonDescr': "If you have a moment, please let us know why you unsubscribed (optional)",
        'Unsubscribe_RemovedFromList': "You have been successfully removed from subscriber list and won't receive campaign emails from us.",
        'Unsubscribed': "This address has been unsubscribed and will no longer receive marketing emails.<br><br>If the removing subscription was an error, opt back in:",
        'UpdateMyProfile': "Update My Profile",
        'UPGRADE': "UPGRADE",
        'UppercaseOr': "OR",
        'ValidOnlineOnly': "Valid Online Only",
        'ViewAll': "View All",
        'ViewAllSelected': "View All Selected",
        'ViewCurrentPromotions': "View Current Promotions",
        'ViewOrderDetails': "View Order Details",
        'Void': "Void",
        'Wallet': "Wallet",
        'Wednesday': "Wednesday",
        'WELCOME': "Welcome to",
        'welcome': "Welcome",
        'WelcomeTo': "Welcome to",
        'Whole': "Whole",
        'WillClose': "Store will close in ?? minutes.",
        'WillOpen': "Store is closed now. Opens {{time}}",
        'XtraMiles': "The selected store is ?? miles from your current location!<br/>Are you sure you want to proceed with this order?",
        'XtraKilometers': "The selected store is ?? kilometers from your current location!<br/>Are you sure you want to proceed with this order?",
        'Year': "Year",
        'Yes': "Yes",
        'YesDeleteMyProfile': "Yes, Delete My Profile",
        'YesIm': "Yes, I am.",
        'YesProceed': "Yes (Proceed with order)",
        'YouAreNotEnrolled': "You are not enrolled in loyalty program.",
        'YouAreParticipatingIn': "You are participating in",
        'YourInformation': "Your Information",
        'YourProfileDeleted': "Your profile deleted successfully",
        'YouveGot': "You've got",
        'ZENDLOGIN': "Unable to log you in.</br>If this is your first time visiting our new online ordering, please complete all the fields (including the password) to create a new user profile.",
        'ZIP': "ZIP",
        'Zip': 'Zip',
        'ZipCode': "ZIP Code",
        'ZipCodeRequired': "Zip Code required.",
        'ZWELCOME': "Welcome,</br>We have upgraded our online ordering system to provide faster more secure service for you. This requires that you create a new user profile. At the end of this order please complete the requested information and we will be sure your profile is securely updated. Thank you for being a loyal customer!!",
    },
    'es-MX': {
        '1stHalf': "1da Mitad",
        '2ndHalf': "2da Mitad",
        '400': "Servidor ha entendido la petición, pero el contenido de la solicitud no era válida.",
        '401': "El acceso no autorizado.",
        '403': "Prohibida recurso no se puede acceder.",
        '500': "Error Interno del Servidor.",
        '503': "Servicio No Disponible.",
        'Abort': "La solicitud ha sido abortada por el servidor!",
        'Achieved': "Alcanzó",
        'Address': "Calle",
        'Address1': "Dirección1",
        'AddressDir': "Dirección",
        'Addresses': "Direcciones",
        'AddressSaveError': "Hubo un error al actualizar su dirección. Inténtalo de nuevo.",
        'AddToOrder': "Añadir",
        'AGE': "¿Tienes ?? años o más?",
        'Age13': "Sí, tengo 13 años o más.",
        'AllergyPreferences': "Alergias",
        'ALREADYAMEMBER': "¿YA ERES USUARIO?",
        'AmountDue': "Saldo Pendiente:",
        'AmountRedeemCurrencyApplyToOrder': "Introduce el importe a aplicar al pedido",
        'AmountToUse': "Cantidad a Utilizar",
        'And': " y la ",
        'Applied': "Aplicado",
        'AppliedToOrder': "Aplicado al Pedido:",
        'Apply': "Aplicar",
        'ApplyChanges': "Aplicar Cambios",
        'April': "04 - Abril",
        'AprilOnly': "Abril",
        'AptUnit': "N.º Apto. / N.º Unidad / N.º Habitación",
        'AptSuiteRoom': "Piso / Apartamento / Habitación",
        'AptSuiteRoomPlaceholder': "Piso, Apartamento, Habitación #",
        'AptUnitSuite': "Apartamento/Unidad/Suite",
        'AreYouSureSignOutOrderCleared': "¿Está seguro de que desea cerrar la sesión? Su pedido será borrado.",
        'ASAP': "Lo antes posible",
        'August': "08 - Agosto",
        'AugustOnly': "Agosto",
        'AutocompleteText': "Introduzca más de 4 caracteres para autocompletar...",
        'AvailableAmount': "Cantidad Disponible:",
        'Balance': "Saldo",
        'BankedCurrency': "Saldo Acumulado",
        'BS': "Bahamas (+1)",
        'BillAddr': "Dirección de Facturación:",
        'Addr': "Direccion de Facturación",
        'BillStName': "Nombre de la calle de facturación",
        'BillStNo': "Número de la calle de facturación",
        'BillZip': "Código Postal",
        'Birthday': "Cumpleaños",
        'BoxTopMsg': "El descuento se aplicará a su pedido tan pronto como todos los artículos requeridos se agreguen al pedido.",
        'BrowserNotSupported': "Lamentamos las molestias, pero nuestro web no es compatible con las versiones de Internet Explorer<br>o Safari anteriores a la version 14. Estos navegadores están desactualizados o no son<br>compatibles y es posible que no muestren todas las funciones de nuestro sitio correctamente. <br>Recomendamos actualizar su navegador a la última versión o usar un navegador diferente como Google Chrome,<br>Mozilla Firefox o Microsoft Edge para obtener la mejor experiencia en nuestro sitio. Gracias por su comprensión.",
        'BusinessDayIsClosed': "El día hábil está cerrado.",
        'CallStore': "Por favor llame a la tienda @ %storePhoneNumber% y el lugar que pedido por teléfono.",
        'CalorieFractionMin': "?? cal. por un/a ??? ????",
        'CalorieFractionMinMax': "?? - ??? cal. por un/a ???? ?????",
        'CaloriesPerFraction': "{{calorie}} cal. por 1/{{serving}} {{servingName}}",
        'CaloriesPerServing': "{{calorie}} cal. / {{servingName}}",
        'CalorieServingMin': "?? cal. por ???",
        'CalorieServingMinMax': "?? - ??? cal. por ????",
        'Cancel': "Cancelar",
        'CannotResolveAddress': "No se puede resolver la dirección",
        'CannotUseCouponTotalZero': "No se puede aplicar la recompensa cuando el total es $0. Intenta agregar algo a tu carrito.",
        'CardNo': "Número de Tarjeta",
        'CardOnFile': "Tarjeta en archivo",
        'carMakeModel': "Marca, modelo y color del coche",
        'Cash': "Efectivo",
        'CashLimit': "Este pedido ha alcanzado el límite de pago en fectivo de nuestra tienda ?? pero usted puede pagar con tarjeta de crédito. Nos disculpamos por cualquier inconveniente.",
        'CCNotInte': "Por favor tenga su tarjeta de crédito y de identificación listo para el conductor.",
        'Challenge_Participation': "Ahora estás participando en",
        'Change': "Cambio",
        'ChangeDeliveryAddress': "Cambio de dirección de entrega",
        'ChangeOrderDateTime': "Cambiar fecha y hora del pedido",
        'ChangeOrderType': "Cambiar su Pedido",
        'ChangePassword': "Cambiar la Contraseña",
        'ChangeTime': "Cambiar la Hora",
        'CharNotAllowed': "Su contraseña contiene caracteres que no están permitidos.</br>Los caracteres especiales sólo permitidos son",
        'CHECKOUT': "Procesar mi pedido",
        'Checkout': "Checkout",
        'CheckTerms': "Por favor, lea y acepte los términos de uso y política de privacidad antes de realizar tu pedido.",
        'ChgOrdTypItmErrMsg': "?? ??? no está disponible para ????. Elimine la ?? ??? del pedido antes de cambiar el tipo de pedido.",
        'ChooseMany': "Elige Varios",
        'ChooseOne': "Elige Uno",
        'City': "Ciudad",
        'Close': "Cerrar",
        'ClosedForTheHoliday': "Cerrado por vacaciones",
        'ClosedHoliday': "Tienda está cerrada en el ??.",
        'ClosedOpensAt': "Cerrado - Abre el {weekday} a las {time}",
        'ComingSoon': "Próximamente",
        'Comment': "Comentario",
        'Complete': "Completo",
        'CompleteIncompleteDiscounts': "Complete los criterios de artículos de su descuento actual antes de agregar más recompensas.",
        'CompleteRequiredItems': "Complete los Elementos Requeridos",
        'ConfirmPassword': "Confirmar Contraseña",
        'Conf1': "Su pedido ha sido recibido correctamente.",
        'Conf2': "Usted sera informado por correo electrónico tan pronto como la tienda haya confirmado su pedido en ??.",
        'Conf3': "Usted será informado por correo electrónico tan pronto como la tienda haya confirmado su pedido.",
        'Conf4': "Número de transacción",
        'Conf5': "Número de su pedido",
        'Confirm': "Confirmar",
        'ContactInformation': "Información Del Contacto",
        'Contactless': "Entrega sin contacto",
        'ContactlessInfo': "La opción de entrega sin contacto no está disponible para pagos en efectivo. La entrega sin contacto nos permite entregar su comida con el contacto personal mínimo. El conductor dejará el pedido en su puerta, empacado y protegido por una barrera que evitará el contacto con el suelo, llamará a su puerta, retrocederá una distancia segura y esperará a que usted recoja su pedido. Como se trata de una entrega sin contacto, por favor considere incluir su propina al momento de procesar su pedido y antes de pagar.",
        'ContactUs': "Contacta con Nosotros",
        'Continue': "Continuar",
        'Copyright': "© 2015 - %year% Adora POS, LLC. Reservados todos los derechos.",
        'CouldNotGetOrderNumber': "No se pudo obtener el número de pedido. Inténtalo de nuevo.",
        'Countdown': "Cuenta atrás",
        'Country': "País",
        'CountryCode': "Código de País",
        'CouponApplied': "Cupón Aplicado",
        'CouponCannotApplyMultiple': "Este cupón no se puede aplicar varias veces.",
        'CouponCode': "Código promocional",
        'CouponCodeErrorWithFundraiser': "Los cupones no pueden aplicarse junto a recaudaciones de fondos para ??.",
        'CouponDisclaimer': "No es válido con otras ofertas, descuentos o promociones, recaudación de fondos. válida para llevar, comer en el restaurante y el parto. se aplican los gastos de envío.",
        'CouponNotActive': "Cupón no activo en esta tienda",
        'CouponNotQualified': "Cupón no califica",
        'CouponRedeemed': "El cupón ya ha sido canjeado.",
        'CP_DeleteLoyProfileConfirmation': "¿Está seguro de que desea eliminar su perfil de fidelización?<br>Esto no se puede deshacer.",
        'CPNLIMIT': "No hay límite del ?? por pedido de cupón seleccionado.",
        'CpnLimitOne': "¡Ups! Solo se puede canjear un cupón por pedido a la vez. Inténtalo de nuevo.",
        'CPNMINAMT': "Se requiere un pedido mínimo de ?? para el cupón seleccionado.",
        'CpnOTNotOffered': "Los descuentos aplicados no se ofrecen en el tipo de pedido seleccionado. Si se selecciona Continuar, se eliminarán los descuentos.",
        'CreateAccount': "Crear Perfil",
        'CreateAccountConfirmationPage': "Crear Perfil",
        'CreateAFavorite': "Crear un Favorito",
        'CreateAFavorite_BodyText': "Agregue cualquier comida de los pedidos recientes para volver a pedirla fácilmente.",
        'CreditCard': "Tarjeta de crédito",
        'CreditcardExpectCall': "Recibirá una llamada de la tienda para obtener la información de su tarjeta de crédito.",
        'CreditcardPhotoID': "Tenga a mano la tarjeta de crédito y una identificación válida presente al recibir el pedido.",
        'CreditCardPreAuthNote': "Nota: Esta tarjeta de crédito tiene preautorización para esta compra; El pago final se cobrará en la tienda al momento de la recogida.",
        'CreditCardPrepareInfo': "Tarjeta de crédito (por teléfono)",
        'CreditCardUponDelivery': "Tarjeta de Crédito en el Momento de la Entrega",
        'Curbside': "Recogida en la acera",
        'Curbside_CarMakeModel': "La marca y el modelo de su coche",
        'Curbside_ConfirmCarModel': "Por favor, confirme la marca, el modelo y el color de su coche",
        'Curbside_EstimatedDistanceFromRestaurant': "La distancia estimada desde el restaurante es ?? ???",
        'Curbside_SelectStallNumber': "Seleccione su número de puesto de estacionamiento.",
        'Curbside_StoreHasBeenNotified': "Su solicitud ha sido enviada a la tienda. Cuando tu pedido esté listo, te lo llevaremos.",
        'Curbside_StoreWillBeNorified': "La tienda será notificada de que estás aquí.",
        'CurbsideInfo': "¡Permítanos llevar su pedido a su automóvil! Tire hacia el dosel blanco. Le indicaremos dónde estacionar y sacaremos su comida cuando esté lista. Debe pagarse con tarjeta de crédito al momento del pedido. ¡Gracias por ayudarnos a mantenernos a salvo!",
        'CurrentPassword': "Contraseña Actual",
        'Custom': "Otro",
        'Customize': "Personalizar",
        'Date': "Fecha",
        'DateInPast': "La fecha no debe estar en el pasado.",
        'Day': "Día",
        'December': "12 - Diciembre",
        'DecemberOnly': "Diciembre",
        'DefaultAddress': "Hacer que esta sea mi dirección predeterminada",
        'DeleteProfile': "Borrar perfil",
        'DeleteProfileMessage': "¿Estás seguro de que quieres eliminar tu perfil?</br>Se trata de una eliminación permanente de todos los registros de su perfil,</br>incluido el historial de pedidos, los puntos de fidelidad,</br>las recompensas obtenidas, las ofertas existentes y los pedidos.</br></br>Ingrese su contraseña para confirmar que desea eliminar su perfil.",
        'DeliverTo': "Entregar a",
        'Delivery': "Entrega",
        'DeliveryAddress': "Dirección de Entrega",
        'DeliveryAddressOutsideAllZonesError': "La entrega no está disponible para la dirección proporcionada, ya que se encuentra fuera del área de entrega de cualquier ubicación. Por favor, elige una dirección diferente o selecciona otra opción de pedido.",
        'DeliveryCharge': "Coste de envío",
        'DeliveryInstructions': "Instrucciones de Entrega",
        'DeliveryInstructionsPlaceholder': "Ej.: usa la puerta trasera, código de acceso",
        'DeliveryOutLimitedTimeError': "Actualmente Solo Ofrece Pedidos Anticipados",
        'DelLastAddr': "No se puede borrar. Una dirección debe permanecer en el archivo.",
        'DietaryPreferences': "Preferencias alimentarias",
        'DigitalGC': "Tarjeta regalo digital",
        'DineIn': "En restaurante",
        'DineInAt': "Comer en",
        'Discount': "Descuento",
        'Discounted': "Descuento",
        'DiscountedTotal': "Total Descontado",
        'Discounts': "Descuentos",
        'Donate': "Donar",
        'Donation': "Donación",
        'DonationNoThanks': "No, gracias",
        'DonationQuestion': "¿Te gustaría donar a ????",
        'DonationRoundUp': "Redondear",
        'Done': "Hecho",
        'DontContinue': "No continúes",
        'DontForget': "¡NO LO OLVIDES!",
        'DontHaveAnAccount': "¿No tienes una cuenta?",
        'DontSave': "No Guardar",
        'DowngradedTo': "Descendió",
        'Due': "Debida",
        'EarnedPTS': "Ganó",
        'EarnedREW': "Consiguió",
        'Edit': "Editar",
        'EditingDiscountedItem': "La edición de un artículo con descuento eliminará el descuento. Puede volver a aplicar el descuento una vez que haya terminado de editar. ¿Quieres continuar con la edición del artículo?",
        'Email': "Correo Electrónico",
        'EmailAddress': "Dirección de Correo Electrónico",
        'EmailNoMatch': "La direccion de correo electrónico que ha introducido no conciden.",
        'EmailNotFound': "No se encontró la dirección de correo electrónico '??' en nuestros registros.",
        'EmailOrPhoneNumber': "Correo Electrónico o Número de Teléfono",
        'EmailUsedByAnotherCustomer': "Otro Cliente está utilizando el correo electrónico que proporcionó.",
        'EmptyDate': "La fecha no debe estar vacía.",
        'EmptyMemberList': "Debe agregar al menos un miembro para un pedido grupal.",
        'EmptyTime': "El tiempo no debe estar vacío.",
        'EndingBalance': "Balance final:",
        'EndPrivTerm': ".",
        'EnoughPoints': "Usted no tiene suficientes puntos para canjear el elemento seleccionado.",
        'EnterDeliveryAddress': "Introduzca la dirección de entrega",
        'EnterPasscode': "Introduce el Código de Acceso",
        'EnterPasscodeExpiresInXMinutes': "Ingrese el código de acceso que se le envió por SMS.<br>El código de acceso caduca en 15 minutos.",
        'EnterYourPasswordPlaceholder': "Introduce tu contraseña",
        'ErrorGettingServerDate': "Error al obtener la fecha del servidor.",
        'Error_ConfigurationGenericError': "El sistema de pedidos en línea no está disponible en este momento debido a un problema de configuración.<br>Por favor, inténtelo de nuevo más tarde o póngase en contacto directamente con la tienda para obtener ayuda.<br>Lamentamos las molestias.",
        'Error_HalfNotAvailableForSelectedSize': "La opción de Mitad & Mitad no está disponible para el tamaño seleccionado.<br>Por favor, elija un tamaño u opción diferente.",
        'Error_ModifierIsNotBeingOfferedOnSize': "{{modifier}} no está disponible para el tamaño {{size}}. Por favor, elige otro tamaño o elimina {{modifier}} para continuar.",
        'Error_ReachedMaxNumberOfModifiers': "Has alcanzado el número máximo de toppings permitidos. Por favor, ajusta tu selección para continuar.",
        'Error_ReachedMaxNumberOfModifiersOnItem': "Has alcanzado el número máximo de modificadores permitidos para este artículo.<br>Por favor, revisa tus selecciones.",
        'Error_SelectedModifierNoSizeSwitch': "{{modifier}} no está disponible para el/la {{item}} de tamaño {{size}}.<br>¿Te gustaría cambiar a un tamaño donde {{modifier}} esté disponible?",
        'Error_SelectToppings1Half': "Por favor, selecciona un topping para la primera mitad.<br>Por ejemplo, elige un topping de queso para una pizza de queso.",
        'Error_SelectToppings2Half': "Por favor, selecciona un topping para la segunda mitad.<br>Por ejemplo, elige un topping de queso para una pizza de queso.",
        'Error_UnableToFindProperSizeToApply': "No podemos identificar un tamaño adecuado para el artículo seleccionado.<br>Por favor, revise su selección e inténtelo de nuevo.",
        'Estimated': "Estimado",
        'EstimatedDelivery': "Entrega Estimada",
        'ExistingCreditCard': "Tarjeta de Crédito Existente",
        'ExistingCustomerSignIn': "Inicia sesión con tu cuenta",
        'ExpDate': "Fecha de Caducidad",
        'Expired': "Caducado",
        'Expires': "Válido hasta",
        'ExtraToppings': "Ingredientes adicionales",
        'FailUpdateInfo': "No se puede actualizar la información en este momento.",
        'FavoriteOrders': "Pedidos Favoritos",
        'Favorites': "Favoritos",
        'FavoriteObjectNone': "No hay {{object}} favoritos en este momento.",
        'FavoriteObjectInstruction': "Agrega cualquier {{object}} de los pedidos recientes para volver a pedirlo fácilmente.",
        'favoriteStoreDelete_Error': "Hubo un error al eliminar la tienda favorita. Inténtalo de nuevo.",
        'favoriteStoreSaveName_Error': "Hubo un error al guardar la tienda favorita. Inténtalo de nuevo.",
        'FavStore': "Haz de esta tienda mi tienda favorita",
        'February': "02 - Febrero",
        'FebruaryOnly': "Febrero",
        'feet': "pies",
        'Find': "Buscar",
        'FindStore': "Encuentra una Tienda",
        'FinishCoupon': "Terminar Cupón",
        'FinishX': "Por favor, Caja dentro de los ?? minutos.",
        'FirstHalf': "Primera Mitad",
        'FirstName': "Nombre",
        'For': "por",
        'ForgotPassword': "¿Has Olvidado tu Contraseña?",
        'Franchising': "Franquicia",
        'Friday': "Viernes",
        'FulfillmentTime': "El tiempo de cumplimiento de este pedido se ha cambiado a Lo antes posible, ??? el tiempo es de aproximadamente ?? minutos después de que su pedido haya sido realizado.",
        'FundraiserCode': "Código de recaudación de fondos",
        'FundraiserCodeErrorWithCoupon': "Si desea participar en la recaudación de fondos para ??, elimine los cupones aplicados.",
        'FundraiserCodeOK': "Se ha aplicado su código de recaudación de fondos.",
        'FundraiserValueIsEmpty': "Campo incompleto. Por favor introduzca el ",
        'Future': "Futuro",
        'GiftCard': "Tarjeta de regalo",
        'GiftCardAlreadyApplied': "La tarjeta de regalo ya se aplicó a su pedido.",
        'GiftCardBalanceIs0': "El saldo de la tarjeta de regalo es de $0.00.",
        'GiftCards': "Tarjetas de Regalo",
        'GO_ErrorDuringGOSave': "Ocurrió un error al guardar el pedido de grupo.",
        'GO_ErrorDuringGOSubmit': "Ocurrió un error durante el envío del pedido grupal.",
        'GO_FinalizeQuestionYesNo': "¿Finalizar el pedido de grupo y pasar a la caja?",
        'GO_FinalizeWillDeleteWarning': "Al finalizar el pedido de grupo, se eliminarán los pedidos de los miembros que no se hayan enviado.",
        'GO_NotStartedMembers': "de los pedidos de miembros no iniciados en este pedido de grupo pertenecen a:", // plural
        'GO_NotSubmittedMembers': "de los pedidos de miembros no enviados en este pedido de grupo pertenecen a:", // plural
        'GO_OrderFinalization': "<h1 class='warning'> Finalización del pedido</h1><br>El pedido de grupo se está finalizando.<br>Por favor, espere.",
        'GO_OrderUndefined': "El pedido del grupo no está definido.",
        'GO_UnsubmitOrders3': "Continuar enviando el pedido grupal hará que se eliminen los pedidos de los miembros no enviados y<br>ya no le permitirá agregar miembros a el pedido de grupo actual<br>¿Está seguro de que desea continuar con el pago?",
        'GoogleMapsErrorAmbiguous': "Error de Google Maps: direcciones ambiguas en",
        'GoogleMapsErrorResolvingAddress': "Error de Google Maps: error al resolver la dirección",
        'Gratuity': "Propina",
        'GROUPORDER': "PEDIDO DE GRUPO",
        'GroupOrderStatus1': "NO EMPEZADO",
        'GroupOrderStatus2': "EN CURSO",
        'GroupOrderStatus3': "PEDIDO",
        'GroupOrderStatus4': "NO PARTICIPANDO",
        'GroupOrderStatus5': "ENVIAR",
        'GroupOrderStatus6': "CANCELADO",
        'GroupOrderTime': "Tiempo del Pedido de Grupo",
        'GRPORD_AddMember': "Añadir Miembro",
        'GRPORD_AddNewGroupMember': "Agregar nuevo miembro del grupo",
        'GRPORD_AreYouSureCancel': "¿Está seguro de que desea cancelar su pedido de grupo?",
        'GRPORD_CancelFailed': "La cancelación del pedido grupal falló.",
        'GRPORD_CancelGroupOrder': "El pedido de grupo ha sido cancelado.",
        'GRPORD_CreateGroupOrder': "Crear un pedido de grupo",
        'GRPORD_CreateOrder': "Crear pedido de grupo",
        'GRPORD_Date': "Fecha",
        'GRPORD_PayAllDescr': "Si Pagar para todos los miembros no está marcado, cada miembro pagará su propia comida.",
        'GRPORD_PayAllMembers': "Pagar por todos los miembros",
        'GRPORD_SelectAll': "Seleccionar todos los miembros del grupo",
        'GRPORD_SelectMembers': "Seleccione miembros para su pedido de grupo",
        'GRPORD_Time': "Hora",
        'GRPORD_Welcome': "Invita a tus amigos a unirse a un pedido grupal.Cada miembro recibirá un enlace para pedir su comida y agregarla al grupo.",
        'Guest': "Continua como invitado",
        'HalfHalfPizzaOrderDetail': "Pizza Mitad y Mitad ??",
        'Hello': "Hola",
        'Hey': "Hola",
        'HHP': "Mitad y Mitad de la pizza",
        'Hol100': "Día de Año Nuevo",
        'Hol150': "Día de San Valentín",
        'Hol200': "Pascua de Resurrección",
        'Hol300': "Día Conmemorativo",
        'Hol400': "Día de la Independencia",
        'Hol500': "Día del Trabajo",
        'Hol550': "Víspera de Todos los Santos",
        'Hol600': "Día de Acción de Gracias",
        'Hol700': "Nochebuena",
        'Hol800': "Día de Navidad",
        'Hol900': "Vispera de Año Nuevo",
        'Hol950': "Super Bowl",
        'Home': "Inicio",
        'Inactive': "Inactiva",
        'IncompleteProfileError': "Falta información en tu perfil. Por favor complete todos los campos para continuar.",
        'DeleteMyProfile': "Eliminar mi perfil",
        'InProgress': "En Progreso",
        'InStoreLimit': "Este pedido llegó al limite de pago de nuestra tienda ?? pero usted puede pagar con tarjeta de crédito. Nos disculpamos por cualquier inconveniente.",
        'Instructions': "Instrucciones",
        'ImportDigitalGiftCards': "Importar tarjeta de regalo digital",
        'InvalidAddr': "Dirección de facturación no válida.",
        'InvalidAddress': "La dirección no es válida",
        'InvalidAddressNoStreetNumber': "La dirección no es válida o le falta un número de calle. Por favor revíselo e inténtelo nuevamente.",
        'InvalidAddressNoZipCode': "La dirección no es válida o falta un código postal. Por favor revise y vuelva a intentarlo.",
        'InvalidAge13': "Debe reconocer que tiene al menos 13 años de edad.",
        'InvalidCardNumber': "Numero de tarjeta invalido.",
        'InvalidCarInfo': "Se requiere la marca, el modelo y el color del coche para la recogida en la acera.",
        'InvalidCC': "Número de tarjeta de crédito no es válido.",
        'InvalidCode': "¡Código inválido!",
        'InvalidCommonSize': "Tamaño común no válido.",
        'InvalidCouponCode': "Código promocional no es válido",
        'InvalidCVV': "CVV no es válido.(codigo de verificación de la tarjeta)",
        'InvalidDate': "Fecha invalida.",
        'InvalidDay': "Debe seleccionar un día.",
        'InvalidEmail': "Dirección de correo electrónico es invalida.",
        'InvalidEmailOrPassword': "Correo electrónico inválido y/o contraseña. Ingrese credenciales válidas, restablezca su contraseña o intente iniciar sesión con su número de teléfono.",
        'InvalidEmailOrPasswordNoPhone': "Correo electrónico inválido y/o contraseña. Ingrese credenciales válidas o restablezca su contraseña.",
        'InvalidEmailPhone': "Dirección de correo electrónico o número de teléfono inválido.",
        'InvalidExp': "La fecha de caducidad no es válida.",
        'InvalidFirstName': "Nombre inválido",
        'InvalidFundraiserCode': "Código de recaudación de fondos no válido.",
        'InvalidLastname': "Invalido apellido.",
        'InvalidMonth': "Por favor seleccione un mes",
        'InvalidOrderTypeCode': "El cupón no es válido para la ??.",
        'InvalidPassword': "La contraseña debe contener al menos un número, una letra minúscula, una letra mayúscula, un símbolo (* + @ # $ % & !) y un mínimo de ocho caracteres.",
        'InvalidPasswordLogin': "Contraseña invalida.",
        'InvalidPasswordMatch': "Las contraseñas no coinciden.",
        'InvalidPhone': "Número de teléfono es inválido.",
        'InvalidPin': "Pin inválido.",
        'InvalidPromotionCode': "Código de promoción no válido.",
        'InvalidReward': "La recompensa no es válida ni canjeable en este momento del pedido.",
        'InvalidScheduleCode': "El cupón no es válido hoy /  ahora.",
        'InvalidStName': "Nombre de la calle de facturación no válida.",
        'InvalidTermsAndPrivacy': "Debe reconocer los Términos de Uso y la Política de Privacidad.",
        'InvalidTip': "Cantidad de propina no válida.",
        'InvalidZip': "Inválido código postal de facturación.",
        'InvalidZip1': "Código postal no válido.",
        'InvalidZipCode': "Código postal no válido.",
        'IsClosedNow': "La tienda esta cerrada ahora.",
        'Item': "Artículo",
        'ItemAdded': "Agregar al pedido",
        'ItemComment': "Nota: Los ingredientes y / o códigos de cupón agregados en el cuadro <strong> NO </strong> se aplicarán al artículo.",
        'Items': "Artículos",
        'ItemSizeNotHalfHalf': "??? no está disponible en el tamaño seleccionado.El tamaño del artículo se ha actualizado para ??.",
        'January': "01 - Enero",
        'JanuaryOnly': "Enero",
        'Join': "Unirse",
        'Joined': "Unido/a",
        'JoinLoyaltyProgramButton': "Únase al Programa de Lealtad",
        'JoinLoyaltySignUpConfirmPrivacy': "Política de Privacidad",
        'JoinLoyaltySignUpConfirmTerms': "Términos de Uso",
        'JoinOurLoyaltyProgram': "Únase a nuestro programa de fidelización y<br/>gane recompensas y ofertas exclusivas.",
        'JoinOurTeam': "Únete a nuestro equipo",
        'JoinRewards': "Unirme a las Recompensas",
        'JoinRewardsProgram': "Unirme al Programa de Recompensas",
        'JsonError': "Error.</br>El análisis de JSON petición ha fallado.!",
        'July': "07 - Julio",
        'JulyOnly': "Julio",
        'June': "06 - Junio",
        'JuneOnly': "Junio",
        'kilometer': "kilómetro",
        'kilometers': "kilómetros",
        'kilometer_short': "km",
        'LangAbbr': "ES",
        'LangHeader': "Selecciona tu idioma",
        'LastName': "Apellido",
        'LastOrderDate': "Fecha del Último Pedido",
        'LastTransaction': "Última transacción",
        'Later': "Realizar pedido ahora para más tarde",
        'LearnMore': "Aprende más",
        'Left': "Izquierda",
        'LifetimePoints': "Puntos Conseguidos",
        'LikeToReceiveOrderStatusNotificationsViaEmail': "Me gustaría recibir notificaciones del estado del pedido por correo electrónico:",
        'LikeToReceiveOrderStatusNotificationsViaEmailAndText': "Me gustaría recibir notificaciones del estado del pedido por correo electrónico y mensaje de texto:",
        'LikeToReceiveOrderStatusNotificationsViaText': "Me gustaría recibir notificaciones del estado del pedido por mensaje de texto:",
        'LIMITHOUS': "Horas limitadas",
        'Loading': "Cargando...",
        'LocationNotFound': "Ubicación no encontrada.Pruebe con una ciudad o código postal.",
        'Locations': "Tiendas",
        'Locations_Heart_NoName_Error': "Debe insertar un nombre de tienda. Inténtalo de nuevo.",
        'Locations_Heart_SaveLocationName_NotSignedIn': "Para crear una tienda favorita, debe iniciar sesión o crear un perfil.</br>¿Le gustaría ser dirigido a la página de inicio de sesión?",
        'Locations_Heart_SaveLocationName_RemoveHeart': "¿Estás seguro/a de que quieres eliminar la tienda favorita?",
        'Locations_Heart_SaveLocationName_SignedIn': "¿Te gustaría ponerle un nombre a esta tienda?",
        'Locations_Search': "Buscar: Ciudad, Estado, o Código Postal...",
        'LocationServicesBlocked': "Sus servicios de ubicación están bloqueados. Para que la tienda encuentre su ubicación rápidamente, conceda acceso a la ubicación.",
        'LogIn': "Iniciar sesión",
        'LogonError': "Pasó de error de inicio de sesión Desconocido. Por favor, vuelva a escribir su dirección de correo electrónico y contraseña a continuación, intente iniciar sesión de nuevo.",
        'LogOut': "Log Out",
        'LoyaltyCantReachInfoError': "¡Ups! No podemos acceder a tu información de lealtad,<br>por favor inténtalo de nuevo más tarde.",
        'LoyaltyCarrierMsg': "*se pueden aplicar cargos por mensajería de texto del operador",
        'LoyaltyCreateWelcome': "¡Este es nuestro nuevo programa de fidelización!",
        'LoyaltyServerOffline': "¡Ups! Algo no está funcionando. Por favor, inténtalo de nuevo más tarde.<br>Si el problema persiste, por favor continúa como invitado y realiza tu pedido.",
        'LoyaltyStoreMsg': "Si te gusta unirte ??? situado en ???? programa de lealtad, ingrese su número de teléfono celular a continuación.",
        'LoyaltyVerifyMsg': "Se enviará un código de verificación a su teléfono",
        'MakeItAGroupOrder': "Conviértalo en un Pedido de Grupo",
        'Makeithalfhalfpizza': "Puede pedir mitad y mitad de la pizza",
        'MakeMyDefaultStore': "Haz Mi Tienda Predeterminada",
        'March': "03 - Marzo",
        'MarchOnly': "Marzo",
        'MarkAttributesDidNotSaved': "No se han guardado los atributos del programa de fidelización. Utilice la función de perfil de cliente para actualizarlos.",
        'MarkEmail': "Email",
        'MarkInfo': "Me gustaría recibir ofertas e información de productos a través de:",
        'MarkSelDay': "Seleccione un dia",
        'MarkSelMonth': "Selecciona un mes",
        'MarkText': "SMS",
        'May': "05 - Mayo",
        'MayOnly': "Mayo",
        'MeetPromiseTimeError': "No podemos cumplir con el tiempo prometido del pedido. Por favor llame a la tienda @ %storePhoneNumber%",
        'MeetPromiseTimeWarning': "El almacén dejará de aceptar órdenes de ?? en línea en 5 minutos. Por favor, Caja dentro de los 5 minutos.",
        'Menu': "Menú",
        'MenuItems': "Elementos del Menú",
        'MX': "México (+52)",
        'mile_short': "mi",
        'mile': "milla",
        'miles': "millas",
        'MinDelAmt': "Esta compra está por debajo del monto mínimo de pedido para la entrega de $??. Inténtalo de nuevo.",
        'minMod': "Tienes que seleccionar un mínimo ",
        'Minutes': "Minutos",
        'MISSING': "Hay falta de información, por favor complete antes de realizar su pedido.",
        'Monday': "Lunes",
        'ModifierIsNotCompatible': "??? no se ofrece en la mitad seleccionada.",
        'Month': "Mes",
        'MultipleMemberOrderWindowAlert': "Se ha abierto en otra sesión de pedido para este miembro. Esta ventana será redirigida a la página de inicio de la tienda.",
        'MYGROUPORDER': "MI PEDIDO DE GRUPO",
        'MyAvailableGiftCards': "Mis tarjetas de regalo disponibles",
        'MyHistory': "Mi Historial",
        'MyHistory_FirstTimeMessage': "Todo tu historial estará disponible aquí. ¡Comienza a acumular recompensas hoy!",
        'MyMembership': "Mi Membresía",
        'MyOffers': "Mis ofertas",
        'MYORDER': "MI PEDIDO",
        'MyOrders': "Mis Pedidos",
        'MyOrders2_FavoriteIconClick': "Nombra tu pedido favorito",
        'MyOrders2_FetchOrderData_Error': "Hubo un error al recuperar sus pedidos. Inténtalo de nuevo.",
        'MyOrders2_FetchOrderDetails_Error': "Hubo un error al recuperar los detalles de su pedido. Inténtalo de nuevo.",
        'MyOrders2_ToggleFavoriteOrder_Error': "Se produjo un error al guardar los cambios de pedido favoritos. Inténtalo de nuevo.",
        'MyOrders_ReOrder_AllError': "Estos artículos no están disponibles actualmente. Puede seleccionar otros elementos en su lugar.",
        'MyOrders_ReOrder_MultipleError': "actualmente no están disponibles. Puede seleccionar otro elemento en su lugar.",
        'MyOrders_ReOrder_SingleError': "no está disponible. Puede seleccionar otro elemento en su lugar.",
        'MyPoints&Rewards': "Mis Puntos y Recompensas",
        'MyPoints': "Mis Puntos",
        'MyProfile': "Mi Perfil",
        'MyRewards': "Mis Recompensas",
        'MyRewardsAndOffers': "Mis Recompensas y Ofertas",
        'MyRewardsSlogan': "<div>Mis</div><div>Recompensas</div>",
        'Name': "Nombre",
        'Nearby': "Cerca",
        'NewAddress': "Nueva direccion",
        'NewDelAddr': "Introduzca la nueva dirección de entrega.",
        'NewPassword': "Nueva Contraseña",
        'No': "No",
        'NoAddressesAddedToAccount': "No hay direcciones agregadas a su cuenta",
        'NoAvailableGiftCards': "No tienes tarjetas regalo disponibles.",
        'NoAvailableRewardsOrOffers': "No tienes recompensas ni ofertas disponibles.",
        'NoCardsAddedToAccount': "No hay tarjetas agregadas a su cuenta",
        'NoDiscountAssociatedWithCode': "No hay descuento asociado con este código.",
        'NoFavoriteItems': "No tienes favoritos para mostrar.",
        'NoFutureOnlineAtMoment': "La tienda no acepta pedidos???diferidos en este momento.",
        'NoFutureOnlineOrder': "La tienda no acepta pedidos en línea diferidos en la fecha seleccionada.",
        'NoImNot': "No.",
        'NoItemAdded': "No se ha añadido ningún artículo al pedido.",
        'NoMenuItemsHaveBeenSelected': "No Se Ha Seleccionado Ningún Elemento del Menú",
        'None': "Ninguno",
        'NoOffers': "No hay ofertas",
        'NoOnlineAtMoment': "Tienda no está aceptando pedidos en línea en este momento.",
        'NoOnlineNow': "Almacenar en no aceptar pedidos en línea ahora. sin embargo es posible hacer un pedido para el día de hoy.",
        'NoOT1AtMoment': "No disponible por el momento.",
        'NoOT2AtMoment': "No disponible por el momento.",
        'NoOT3AtMoment': "El servicio de entrega no está disponible en este momento.",
        'NoOtherStore': "No (Seleccionar otra ubicación)",
        'NoPayment': "Su pago no ha sido seleccionado.",
        'NoRecentOrders': "No hay pedidos recientes.",
        'NoRewards': "No tienes recompensas para mostrar.",
        'NOTAMEMBER': "¿NO ERES MIEMBRO?",
        'NotEnrolled': "No se han inscrito en el programa de fidelización",
        'NoThanks': "¡NO, GRACIAS!",
        'Notifications': "Notificaciones",
        'notifs': "Notificaciones",
        'notifsAnd': " y ",
        'notifsConsent': "Me gustaría recibir notificaciones sobre el estado del pedido por ",
        'notifsEmail': "correo electrónico",
        'notifsText': "SMS",
        'NoTime': "Por desgracia no hay tiempo suficiente para procesar un pedido ??? online.",
        'NotOfferedInSelStore': "No se ofrece ?? en la tienda seleccionada.",
        'November': "11 - Noviembre",
        'NovemberOnly': "Noviembre",
        'Now': "Ahora",
        'Number': "Número",
        'October': "10 - Octubre",
        'OctoberOnly': "Octubre",
        'off': "de descuento",
        'OFF': "De Descuento",
        'Ok': "Ok",
        'OK': "Ok",
        'OLO_General_Error': "<h1 class='warning'>¡Ups!</h1>Parece que algo salió mal. ¡Inténtalo de nuevo!<br /><br />Si el problema persiste, lo invitamos a llamar al restaurante al %storePhoneNumber% y hacer su pedido por teléfono.<br /><br />Nos disculpamos sinceramente por las molestias.<br /><span style='color:#dddddd; font-size:8px;'>noconnt</span>",
        'OnePrimAddr': "Por favor seleccione una dirección primaria.",
        'OnlyOneDiscountAllowedAtATimeError': "Solo se permite un descuento a la vez.<br>Elimine el descuento existente para continuar.",
        'OpenOrders': "Pedidos Abiertos",
        'OpensAt': "Abre a las {time}",
        'OpenUntil': "Abierto hasta las {time}",
        'Optional': "Opcional",
        'or': "o",
        'Or': "O",
        'Order': "Pedido",
        'OrderCenter': "Centro de Pedidos",
        'OrderComment': "Nota: Los cupones agregados en el cuadro <strong> NO </strong> se aplicarán a su pedido.",
        'OrderDateTime': "Pedido de Fecha y Hora",
        'OrderDetailItemName': "??? ??",
        'OrderDetails': "Detalles del Pedido",
        'OrderIsClosed': "El pedido está cerrado",
        'OrderNow': "Iniciar Pedido",
        'OrderNumber': "Número de pedido",
        'OrderTime': "Tiempo del pedido",
        'OrderTotal': "Total del pedido",
        'OrderType': "Tipo de pedido",
        'OrderTypeNotOfferedAtThisLocation': "{{order_type}} no está disponible en esta ubicación.",
        'Other': "Otro",
        'OtherReason': "El motivo \"Otro\" es obligatorio.",
        'OutDelivery': "La dirección está fuera del área de entrega de nuestra tienda.",
        'OutTimeDelivery': "La dirección está en un área fuera del horario de entrega de la tienda.",
        'PaidAmount': "Monto de Pago",
        'PartialUpdateInfo': "La información parcial fue actualizada con éxito.",
        'pass6Char': "Tu contraseña debe tener al menos 6 caracteres.",
        'passDigit': "Su contraseña debe contener al menos un dígito.",
        'passLetterLower': "Su contraseña debe contener al menos una letra en minúscula.",
        'passLetterUpper': "Su contraseña debe contener al menos una letra mayúscula.",
        'Password': "Contraseña",
        'PasswordExpiredSentNewEmail': "Su contraseña ha caducado. Le enviamos un correo electrónico para restablecer su contraseña.",
        'PasswordNoMatch': "Las contraseñas no conciden.",
        'PasswordReset': "Hemos enviado un mensaje a {email}. Revise su carpeta de spam si no ha recibido el correo electrónico en 5 minutos.",
        'PasswordSaveError': "Hubo un error al actualizar su contraseña. Inténtalo de nuevo.",
        'PasswordUpdatedSuccessfully': "Su contraseña se ha actualizado correctamente.",
        'PayInStore': "Pagar en tienda",
        'Payment': "Pago",
        'Phone': "Teléfono",
        'PhoneCodeIsInvalidOrExpired': "El código no es válido o ha caducado.<br>Vuelva a intentarlo.",
        'PhoneFormat': "XX XXXX XXXX",
        'PhoneNoMatch': "Los números de teléfono que ha introducido no conciden.",
        'PhoneUsedByAnotherCustomer': "Otro Cliente está utilizando el número de teléfono que proporcionó.",
        'PickUp': "Recoger",
        'PickUpFrom': "Recoger en",
        'PlaceAnOrderToSeeYourNextMembership': "Haz un pedido para ver tu membresía",
        'PlaceAnOrderToSeeYourNextReward': "Haz un pedido para empezar a ganar recompensas",
        'PleaseEnterAmountGreaterThan': "Por favor utilize una cantidad mayor que {{x}}",
        'PleaseEnterAmountLessThanAmountDue': "Por favor, utilize una cantidad menor del pedido de {{x}}",
        'PleaseEnterAmountWithinRangeFrom': "Por favor, utilize una cantidad dentro del rango de {{x}} a {{y}}",
        'PleaseSelectItems': "Seleccione Artículos",
        'point': "punto",
        'points': "puntos",
        'Points': "Puntos",
        'PointsAvailable': "Puntos Disponibles",
        'PointsEarned': "Puntos de fidelidad ganados",
        'PointsLastUpdated': "Última Actualización de Puntos",
        'PointsRedeemed': "Puntos Canjeados",
        'PointsRemain': "Quedan Puntos",
        'PointsTo': "Puntos para ",
        'PointsToNextReward': "puntos hasta el siguiente recompensa",
        'PointsToTheNextTier': "Puntos para el siguiente nivel",
        'PoweredBy': "Energizado por",
        'PreModsNotSet': "No se han establecido premodificadores.",
        'PR': "Puerto Rico (+1)",
        'Price': "Precio",
        'Primary': "Primario",
        'privacy': "política de privacidad",
        'PrivacyPolicy': "Al marcar esta casilla, reconoces la recepción, revisión y aceptación de los ",
        'PrivacyTerms': "He leído y acepto los ",
        'Profile': "Perfil",
        'ProfileWithPhoneDoesntExist': "Hubo un problema al recuperar el número de teléfono. Vuelva a intentarlo o utilice un número diferente.",
        'PromiseTime': "Tiempo de Promesa",
        'PromosRewards': "Promociones y recompensas",
        'PromotionHasBeenRedeemed': "La promoción ha sido canjeada.",
        'PromotionHasExpired': "La promoción ha caducado.",
        'Promotions': "Coupon",
        'pts': "ptos",
        'ptsEarned': "ptos ganado",
        'Quantity': "Cantidad",
        'RecentOrders': "Pedidos Recientes",
        'RecentlyOrdered': "Pedidos Recientes",
        'Redeem': "Canjear",
        'RedeemableItems': "Los Productos Rescatables",
        'Redeemed': "canjeado",
        'RedeemFor': "Canjear por",
        'RedeemCurrency': "Canjear Recompensas",
        'RedeemPoints': "Canjear Puntos",
        'RedeemRewards': "Canjear Recompensas",
        'RedeemRewardHasAlreadyBeenAppliedError': "​Ya se ha aplicado una recompensa de canje. br>Elimine la recompensa de canje ya aplicada.",
        'ReEmail': "Vuelva a introducir Email",
        'ReEnterExisting': "Por favor, vuelva a introducir la contraseña existente",
        'RemainingAmount': "Cantidad Restante:",
        'RemainingBalance': "Balance Restante",
        'RememberMe': "Recuérdame",
        'RememberMe30Days': "Recuérdame por 30 días",
        'Remove': "Eliminar",
        'RemoveCoupon': "Eliminar Cupón",
        'Reorder': "Volver a Pedir",
        'RePassword': "Escriba la contraseña otra vez",
        'RepeatPassword': "Repita la Contraseña",
        'RePhone': "Vuelva a introducir teléfono",
        'RequestResetPassword': "Solicitar restablecimiento de contraseña",
        'Required': "(requerido)",
        'ResendCode': "Reenviar Codigo",
        'ResetPass_SessionExpired_Error': "Su sesión de restablecimiento de contraseña ha expirado. Intente restablecer su contraseña nuevamente.",
        'ResetPass_Unexpected_Error': "Ocurrió un error inesperado al intentar restablecer su contraseña. Por favor, inténtelo de nuevo más tarde.<br>Error: ",
        'ResetPassword': "Restablecer Contraseña",
        'ResetPasswordSuccessful': "Su contraseña fue restablecida con éxito.",
        'Resubscribed': "Su suscripción ha sido confirmada. Usted ha sido agregado a nuestra lista y pronto tendrá noticias nuestras.",
        'RewardsFAQ': "Información Sobre las Recompensas",
        'Right': "Derecha",
        'Saturday': "Sábado",
        'Save': "Guardar",
        'SameAsDel': "Igual que la Dirección de Envío",
        'Save_your_profile_changes': "¿Guardar los cambios de tu perfil?",
        'SaveCreditCard': "Me gustaría que se guardara la información de mi tarjeta de crédito para futuros pedidos.",
        'SavingPassword': "Guardar Contraseña",
        'Search': "Buscar",
        'SearchCityStateOrZipCode': "Buscar Ciudad, Estado o Código Postal...",
        'SearchingCities': "Buscando ciudades..",
        'SearchingZipCodes': "Buscando códigos postales..",
        'SecondHalf': "Segunda Mitad",
        'SecurityCode': "Código de Seguridad",
        'Select': "Seleccionar",
        'SelectAnotherLocation': "Seleccione otra ubicación",
        'SelectAvailableTimeSlot': "Selecciona un horario entre las franjas horarias disponibles:",
        'SelectDeliveryAddress': "Seleccione Dirección de Entrega",
        'SELECTED': "Seleccionado",
        'SelectedStore': "Tienda seleccionada",
        'SelectedTime': "Hora Seleccionada",
        'SelectedTimeNotAvailableSelectNew': "La hora seleccionada no está disponible.<br>Seleccione una nueva hora.",
        'SelectEvent(s)': "Seleccionar Evento(s)",
        'SelectItem': "Seleccione el artículo",
        'SelectLocation': "Seleccionar Tienda",
        'SelectMemberAssignPointsChallenge': "Seleccione miembro(s) para asignar puntos para este Desafío.",
        'SelectMembers(s)': "Seleccionar Miembro(s)",
        'SelectTime': "Seleccionar Hora",
        'SelectWhenYouWantToPlaceOrder': "Por favor, seleccione cuando desea su pedido.",
        'SelModNoSize': "{modName} no se ofrece en {sizeName} {itemName}.",
        'SelModNoSize1': "Seleccionado ?? solo se ofrece en ???. <br/>Cambie el tamaño a ???? e intenta de nuevo.",
        'SelModNoSize2': "Seleccionado ?? no se ofrece en ???. ???? está disponible en los siguientes tamaños ?????",
        'SelSizNoMod': "Tamaño seleccionado no es válido para ",
        'SelTop': "Por favor, seleccione un topping.",
        'SendingOrder': "Enviando pedido...",
        'September': "09 - Septiembre",
        'SeptemberOnly': "Septiembre",
        'SetAsDefault': "Establecer por defecto",
        'Settings': "Configuración",
        'ShowEarlierOrders': "Mostrar pedidos anteriores",
        'ShowMore': "Mostrar Más",
        'SignIn': "Iniciar Sesión",
        'SignInSlogan': "Comienza a ganar puntos para <span class='highlight'>canjear</span> por tus platos <span class='highlight'>favoritos</span> del menú",
        'SignInWithApple': "Inicia Sesión con Apple",
        'SignInWithFacebook': "Inicia Sesión con Facebook",
        'SignInTextPrivacyTerms': "Al seleccionar Iniciar Sesión, acepta recibir un código de acceso por mensaje de texto y acepta nuestros ",
        'SignOut': "Desconectar",
        'SignOut_AreYouSure': "¿Estás seguro de que quieres cerrar sesión?",
        'SignOut_AreYouSure_Empty_EmptyCart': "¿Estás seguro de que quieres cerrar sesión?",
        'SignOut_AreYouSure_EmptyCart': "¿Estás seguro de que quieres cerrar sesión, tu cesta se vaciará?",
        'SignUp': "Regístrate",
        'SignUp2': "Regístrate",
        'SIZE': "Tamaño",
        'Size': "Tamaño",
        'Skip': "Omitir",
        'Slice': "Rebanada",
        'SomethingWentWrong': "Algo salió mal.",
        'spChar': "Su contraseña debe contener al menos uno de<br/>estos caracteres especiales",
        'SpecialInstructions': "Instrucciones Especiales",
        'StartOrder': "Iniciar Pedido",
        'StartOrderWithOrderType': "Iniciar {{order_type}} Pedido",
        'StartingBalance': "Balance inicial:",
        'State': "Estado",
        'StoreAddress': "Dirección de la Tienda",
        'StoreClosedOn': "La tienda está cerrada ??.",
        'StoreDistanceAway': "A {distance} {type}",
        'StoreDoesNotAcceptDeferredOnlineOrdersOn': "La tienda no acepta pedidos en línea diferidos en ??.",
        'StoreDoesNotAcceptOnlineOrdersOn': "La tienda no acepta pedidos en línea el ??.</br>Llame a la tienda al ??? para hacer su pedido por teléfono.",
        'StoreHours': "Horario de Tienda",
        'storeInfoName': "Pedido Desde:",
        'StoreIsAlreadyClosed': "La tienda ya está notificada",
        'StoreLocatorDesc': "Los servicios de ubicación están deshabilitados. Para habilitarlo, salga de esta pantalla, active los servicios de ubicación y vuelva a intentarlo. Si no, elige tu país e ingresa tu código postal.",
        'storeNotFound': "No hay tiendas dentro de 25 millas de su código postal (%zipcode%). Pruebe con un nuevo código postal en el campo de búsqueda",
        'StreetAddress': "Dirección de la Calle",
        'StreetAddressPlaceholder': 'Introduce tu Dirección',
        'StreetName': 'Nombre de la calle',
        'StreetNo': 'Número de calle',
        'SUBMIT': "ENTREGAR",
        'SubmitOrder': "Realizar Pedido",
        'SubSurv': "Suscribirme a las encuestas por email sobre los pedidos",
        'Subtotal': "Subtotal",
        'SuccessfullyEnrolled': "Se ha inscrito con éxito en el programa de fidelización.",
        'SuccessfullyJoinedLoyaltyProgram_MsgBoxOK': "Te has unido con éxito a nuestro programa de Lealtad!",
        'SuccessfullyUpdatedProfilePage_MsgBoxOK': "Tu perfil ha sido actualizado exitosamente.",
        'SuccessfullyUpdatedProfilePageJoinedLoyalty_MsgBoxOK': "Tu perfil ha sido actualizado exitosamente.</br>Te has unido con éxito a nuestro programa de Lealtad!",
        'SuccessUpdateInfo': "Información actualizada con éxito",
        'Sunday': "Domingo",
        'SystemCannotLocateAddress': "El sistema no puede localizar la dirección.",
        'Table': "Mesa",
        'TakeOut': "Para llevar",
        'Tax': "Impuesto",
        'TemporarilyClosed': "Cerrado Temporalmente",
        'terms': "términos de uso",
        'TermsConditions': "Términos de Uso",
        'TestBrand': "Bienvenido a {{brand}}",
        'Thankyou': "Gracias",
        'Thursday': "Jueves",
        'tier': "nivel",
        'Time': "Tiempo",
        'TimeFrameToExpireContinue': "El plazo para realizar este pedido está a punto de expirar.<br>¿Le gustaría continuar?",
        'Timeout': "Solicitud de espera!",
        'Tip': "Propina",
        'ToChange': "Cambiar",
        'TogoSupplies': "Para Llevar",
        'tomorrow': "mañana",
        'Toppings': "Ingredientes",
        'Total': "Total",
        'Tracker_FetchTrackerData_Error': "Hubo un error al recuperar su pedido. Inténtalo de nuevo.",
        'Tracker_IncorrectAccount_Error': "Este pedido no pertenece a esta cuenta. Inicie sesión con la cuenta correcta.",
        'Tracker_Status_Error': "El progreso de preparación de su pedido estará disponible a partir de las ",
        'TrackMyOrder': "Seguimiento de mi Pedido",
        'TrackOrder': "Seguimiento del Pedido",
        'TrackOrder_FetchTrackOrderData_Error': "No se pueden cargar los detalles del pedido. Por favor, inténtelo de nuevo más tarde.",
        'TrackYourOrder': "Sigue tu Pedido",
        'Transaction': "Transacción",
        'TransactionCarriedOutThrough': "Transacciones realizadas a través de:",
        'TransactionNumber': "Número de transacción",
        'TransactionNumberAbr': "Transaction No.",
        'Tuesday': "Martes",
        'UnableEnrolled': "No es posible inscribirse en el programa de fidelización.",
        'UnableEditProfile': "Utilice su solicitud de fidelización para actualizar el perfil. Estamos trabajando para permitir la edición aquí. Pedimos disculpas por cualquier inconveniente que esto pueda causar.",
        'UnableLogin': "No es posible entrar. Por favor, vuelva a intentarlo.",
        'UnableResetPassword': "No se ha podido restablecer su contraseña. <br/> Si es tu primera vez que visita nuestro nuevo pedidos en línea, siguen como invitado y crear un nuevo perfil de usuario al final de este pedido.",
        'UnableToDelete': "No se puede eliminar tu perfil.",
        'UnableToRetrieveCampaignCoupon': "No se puede recuperar el cupón de la campaña. Pedimos disculpas por el error.<br>Vuelva a intentarlo más tarde.",
        'UnableToRetrieveReward': "No se puede recuperar la recompensa. Pedimos disculpas por el error.<br>Vuelva a intentarlo más tarde.",
        'UnableToRetrieveSurveyCoupon': "No se puede recuperar el cupón de la encuesta. Pedimos disculpas por el error.<br>Vuelva a intentarlo más tarde.",
        'UnableVerifyPhone': "No se puede verificar su número de teléfono.",
        'UnavailableItemModifications1': "El / los siguiente(s) artículo(s) se han eliminado del pedido ya que las modificaciones exactas del pedido anterior ya no están disponibles:",
        'UnavailableItemModifications2': "Los artículos aún están disponibles para pedir, pero será necesario volver a seleccionar las modificaciones.",
        'UnexpectedError': "Ocurrió un error inesperado.",
        'UnexpectedError500': "Ocurrió un error inesperado.</br>Por favor, inténtelo de nuevo más tarde.",
        'US': "Estados Unidos (+1)",
        'UnknownAddressError': "Dirección no válida o la dirección está fuera del área de entrega de la tienda.",
        'UnknownError': "error no conocido!",
        'Unsubscribe': "Darse de baja",
        'Unsubscribe_BtnResubscribe': "Volver a suscribirse",
        'Unsubscribe_BtnUnsubscribe': "Sí, darme de baja",
        'Unsubscribe_ByAccident': "¿Se dio de baja por accidente?",
        'Unsubscribe_ReasonDescr': "Si tiene un momento, háganos saber por qué se dio de baja (opcional)",
        'Unsubscribe_RemovedFromList': "Ha sido eliminado con éxito de la lista de suscriptores y no recibirá nuestros correos electrónicos de campaña.",
        'Unsubscribed': "Este correo electrónico se ha cancelado y ya no recibirá correos electrónicos de marketing.<br><br>Si la eliminación de la suscripción fue un error, vuelve a activarla:",
        'UpdateMyProfile': "Actualizar Mi Perfil",
        'UPGRADE': "Mejorar",
        'UppercaseOr': "O",
        'ValidOnlineOnly': "Sólo es válido en web",
        'ViewAll': "Ver Todos",
        'ViewAllSelected': "Ver Todos los Seleccionados",
        'ViewCurrentPromotions': "Ver Promociones Actuales",
        'ViewOrderDetails': "Ver Detalles del Pedido",
        'Void': "Nulo",
        'Wallet': "Cartera",
        'Wednesday': "Miércoles",
        'WELCOME': "Bienvenido a",
        'welcome': "Hola",
        'WelcomeTo': "Bienvenido a",
        'Whole': "Entera",
        'WillClose': "Tienda estará cerrada en ?? minutos.",
        'WillOpen': "La tienda está cerrada ahora. Abre a las {{time}}",
        'XtraMiles': "La tienda seleccionada está a ?? millas de su ubicación actual!<br/>¿Estás seguro de que desea continuar con este pedido?",
        'XtraKilometers': "La tienda seleccionada está a ?? kilómetros de tu ubicación actual.<br/>¿Estás seguro de que quieres proceder con este pedido?",
        'Year': "Año",
        'Yes': "Sí",
        'YesDeleteMyProfile': "Sí, eliminar mi perfil",
        'YesIm': "Sí.",
        'YesProceed': "Sí (Continuar con el pedido)",
        'YouAreNotEnrolled': "No está inscrito en el programa de fidelización.",
        'YouAreParticipatingIn': "Estas participando en ",
        'YourInformation': "Tu Información",
        'YouveGot': "Tienes",
        'ZENDLOGIN': "No se puede entrar. </br> Si es tu primera vez que visita nuestro nuevo pedido en línea, por favor rellene todos los campos (incluyendo la contraseña) para crear un nuevo perfil de usuario.",
        'ZIP': "Código Postal",
        'Zip': 'Código Postal',
        'ZipCode': "Código Postal",
        'ZipCodeRequired': "Se requiere código postal.",
        'ZWELCOME': "Bienvenido, </ br> Hemos actualizado nuestro sistema de pedidos online para ofrecer un servicio más rápido y seguro para usted. Esto requiere que usted se cree un nuevo perfil de usuario. Por favor, al final de este pedido complete la información solicitada y nos aseguraremos de que su perfil se actualiza de forma segura. Gracias por ser un cliente fiel !!",
        'CardholderName': "Nombre del Titular"
    }
};