import EventEmitter from "eventemitter3";
import "./hamburger.css"
import { EventTypes } from "../../../core/event-bus";
import { Pages } from "../../../types/enums";
import { Login } from "../Login/login";
import { Signup } from "../Signup/signup";
import { Popup } from "../../popups/popup";
import { Util } from "../../../utils/util"
import { ProfileService } from "../../../services/profile-service";

export class Hamburger extends Popup {
    private readonly _eventBus: EventEmitter;
    private readonly _profileService: ProfileService;
    private readonly _login: Login;
    private readonly _signup: Signup;

    private _menuItems: HTMLElement | null = null;
    private _menuAccount: HTMLElement | null = null;
    private _menuFavorites: HTMLElement | null = null;
    private _menuOrder: HTMLElement | null = null;
    private _menuRewards: HTMLElement | null = null;
    private _buttonLogin: HTMLElement | null = null;
    private _loyalty: HTMLElement | null = null;
    private _loyaltyGuest: HTMLElement | null = null;
    private _loyaltyAccount: HTMLElement | null = null;
    private _loyaltyName: HTMLElement | null = null;
    private _loyaltyPoints: HTMLElement | null = null;
    private _buttonLogout: HTMLElement | null = null;
    private _buttonSignup: HTMLElement | null = null;
    private _ellipse: HTMLElement | null = null;

    constructor(eventBus: EventEmitter, profileService: ProfileService, login: Login, signup: Signup) {
        super("modal_hamburger", false, true)

        this._eventBus = eventBus;
        this._profileService = profileService;

        this._login = login;
        this._signup = signup;
        this._eventBusListeners();
    }

    public init(): void {
        super.init();

        this._menuItems = document.getElementById("hamburger_items");
        this._menuAccount = document.querySelector("[data-page=ACCOUNT]");
        this._menuFavorites = document.querySelector("[data-page=FAVORITES]");
        this._menuOrder = document.querySelector("[data-page=ORDERS]");
        this._menuRewards = document.querySelector("[data-page=REWARDS]");
        this._loyalty = document.getElementById("hamburger_loyalty");
        this._loyaltyGuest = document.getElementById("hamburger_loyalty_guest");
        this._loyaltyAccount = document.getElementById("hamburger_loyalty_account");
        this._loyaltyName = document.getElementById("hamburger_loyalty_account_name")
        this._loyaltyPoints = document.getElementById("hamburger_loyalty_points")
        this._buttonLogin = document.getElementById("hamburger_login");
        this._buttonSignup = document.getElementById("hamburger_signup");
        this._buttonLogout = document.getElementById("hamburger_logout");
        this._ellipse = document.getElementById("hamburger_ellipse");

        this._setEventListeners();

        this._login.init();
        this._signup.init();

        if (Util.isAppView()) {
            const element = document.getElementById("#hamburger_footer")
            element && (element.style.display = "none");
        }

        this._profileService.user.isLoggedIn() ? this._updateLogin() : this._updateLogout();
    }

    public override open(): void {
        super.open();
    }

    public override close(): void {
        super.close();
    }

    private _eventBusListeners(): void {
        this._eventBus.on(
            EventTypes.LOGGED_IN,
            this._updateLogin);

        this._eventBus.on(
            EventTypes.LOGGED_OUT,
            this._updateLogout);
    }

    private _updateLogout = (): void => {
        this._loyaltyGuest && (this._loyaltyGuest.style.display = "")
        this._loyaltyAccount && (this._loyaltyAccount.style.display = "none")
        this._buttonLogout && (this._buttonLogout.style.display = "none")
        this._menuAccount && (this._menuAccount.style.display = "none");
        this._menuFavorites && (this._menuFavorites.style.display = "none");
        this._menuOrder && (this._menuOrder.style.display = "none");
    }

    private _updateLogin = (): void => {
        this._loyaltyGuest && (this._loyaltyGuest.style.display = "none")
        this._loyaltyAccount && (this._loyaltyAccount.style.display = "")
        this._buttonLogout && (this._buttonLogout.style.display = "")
        this._menuAccount && (this._menuAccount.style.display = "");
        this._menuFavorites && (this._menuFavorites.style.display = "");
        this._menuOrder && (this._menuOrder.style.display = "");

        if (this._loyaltyName && !this._loyaltyName.innerText) {
            this._loyaltyName.innerText = this?._profileService.user.getProperty("Name") || "";
        }

        if (this._loyaltyPoints && this?._profileService.user.getProperty("LoyaltyData")?.CurrentPoints) {
            this._loyaltyPoints.innerText = this?._profileService.user.getProperty("LoyaltyData")?.CurrentPoints.toString() || "";
        }
    }

    private _updateEllipse = (): void => {
        this._ellipse && (this._ellipse.style.height = `${(this._menuItems?.offsetTop || 0) * 5}px`);
    }

    protected _setEventListeners(): void {
        document.getElementById(this._elementId)?.addEventListener("click", (e: Event) =>
            e.stopPropagation()
        );

        this?._menuItems?.querySelectorAll("[data-page]")?.forEach(
            (e: Element) => e.addEventListener("click",
                (e: Event) => {
                    e.stopImmediatePropagation();
                    const element = (e.target as HTMLElement)?.closest("[data-page]") as HTMLElement;
                    this.close();

                    const page = Pages[element.dataset.page as keyof typeof Pages];
                    this._navigate(page);
                }
            ));

        this?._buttonLogin?.addEventListener("click", () => {
            this.close();
            this._login.open();
        });

        this?._buttonSignup?.addEventListener("click", () => {
            this.close();
            this._signup.open();
        });

        this?._buttonLogout?.addEventListener("click", () => {
            this._profileService.signOut();
        });
    }

    private _navigate(page: Pages) {
        const user = this._profileService.user;

        if (page == Pages.MENU && (!user.isLoggedIn() || !user.getProperty("DefaultStore"))) {
            page = Pages.LOCATIONS;
        }

        if (page == Pages.REWARDS && !user.isLoggedIn()) {
            this.close();
            this._login.open();
            return;
        }

        this._eventBus.emit(
            EventTypes.LOCATION_CHANGE,
            page,
        )
    }
}