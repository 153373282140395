import './login.css';

import EventEmitter from "eventemitter3";
import { EventTypes } from '../../../core/event-bus';
import { ILogin } from './login.interface';
import { TwoWayBindingHelper } from '../../../utils/two-way-binding';
import { Util } from '../../../utils/util';
import { Dialog } from "../Dialog/dialog";
import { Common } from '../../../common';
import { FieldType } from '../../../types/enums';
import { ProfileService } from '../../../services/profile-service';
import { Popup } from '../popup';
import { Signup } from '../Signup/signup';
import { DialogCreators } from '../../../utils/dialog-creators';

export class Login extends Popup {
    private readonly _eventBus: EventEmitter;
    private readonly _profileService: ProfileService;
    private readonly _item: ILogin;
    private readonly _bindingHelper: TwoWayBindingHelper<ILogin>;
    private readonly _signup: Signup;

    constructor(eventBus: EventEmitter, profileService: ProfileService, signup: Signup) {
        super("modal_login", true);
        this._eventBus = eventBus;
        this._profileService = profileService;
        this._signup = signup;
        this._item = this._createModel();
        this._bindingHelper = new TwoWayBindingHelper(this._item);
        this._eventBusListeners();
    }

    public override init(): void {
        super.init();
        this._hideErrorElements();
        this._setTwoWayListeners();
        this._setEventListeners();
    }

    private readonly _createModel = (): ILogin => {
        return {
            email: "",
            password: "",
            rememberMe: false
        };
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.LOGGED_IN, this.close);
    }

    protected readonly _setEventListeners = (): void => {
        document.getElementById("btn_login_forgot_password")?.addEventListener("click", this._forgotPasswordOnClickAsync);
        document.getElementById("btn_login_sign_in")?.addEventListener("click", this._signInOnClickAsync);
        document.getElementById("btn_login_sign_up")?.addEventListener("click", this._signUpOnClick);
        document.getElementById("btn_login_sign_in_facebook")?.addEventListener("click", this._signInByFacebookOnClick);
        document.getElementById("btn_login_sign_in_apple")?.addEventListener("click", this._signInByAppleOnClick);
    }

    private readonly _setTwoWayListeners = (): void => {
        this._bindingHelper.bindToInput("email", "txt_login_email");
        this._bindingHelper.bindToInput("password", "txt_login_password");
        this._bindingHelper.bindToInput("rememberMe", "chk_login_remember_me");
    }

    private readonly _signInOnClickAsync = async (): Promise<void> => {
        const validated = await this._checkSubmittedData();
        if (!validated)
            return;

        const success = await this._profileService.signIn({ email: this._item.email, password: this._item.password, rememberMe: this._item.rememberMe });

        if (success) {
            this.close();
        }
    }

    private readonly _forgotPasswordOnClickAsync = async (): Promise<void> => {
        if (!Util.IsValidEmail(this._item.email)) {
            const dialog = new Dialog(
                () => {},
                "Invalid Email",
                [{
                    label: "Ok", value: "ok"
                }, {label: "No", value: "no"}]);
            dialog.init();
            dialog.open();

            this.close();

            return;
        }

        await this._profileService.forgotPassword(this._item.email);
    }

    private readonly _signInByFacebookOnClick = (): void => {
        // 2DO: US #47654
    }

    private readonly _signInByAppleOnClick = (): void => {
        // 2DO: US #47655
    }

    private readonly _signUpOnClick = (): void => {
        this.close();
        this._signup.init();
        this._signup.open();
    }

    private readonly _hideErrorElements = (): void => {
        Util.hideElement("spn_login_email_error");
        Util.hideElement("spn_login_password_error");

        Util.setElementClass("remove", "txt_login_email", "invalid-input");
        Util.setElementClass("remove", "txt_login_password", "invalid-input");
    }

    private readonly _checkSubmittedData = async (): Promise<boolean> => {
        this._hideErrorElements();

        const emailError = await Common.ValidateInput(true,
            this._item.email,
            FieldType.EMAIL,
            "spn_login_email_error",
            true,
        );
        if (emailError)
            Util.setElementClass("add", "txt_login_email", "invalid-input");

        const passwordError = await Common.ValidateInput(true,
            this._item.password,
            FieldType.PASSWORD,
            "spn_login_password_error",
            true,
        );
        if (passwordError)
            Util.setElementClass("add", "txt_login_password", "invalid-input");

        return !(emailError || passwordError);
    }
}